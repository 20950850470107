// Modules
import React from "react";

// Components
import { Fragment } from "react";
import {
  RecruitmentGatewayHero,
  RecruitmentGatewayBase,
} from "../../components";

export const RecruitmentGateway = () => {
  return (
    <Fragment>
      <RecruitmentGatewayHero />
      <RecruitmentGatewayBase />
    </Fragment>
  );
};
