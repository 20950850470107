// Modules
import React from "react";

// Components
import {
  Modal,
  ModalOverlay,
  Button,
  EmptyContent,
  LevelList,
} from "../../../..";

// Hooks
import { useParams } from "react-router-dom";
import { useLevel } from "../../../../../hooks";

// Utils
import { getTrainingAcronym } from "../../../../../utils";

// Style
import "./level-modal-base.styles.scss";

export const LevelModalBase = ({ className }) => {
  const { trainingId } = useParams();
  const { isLevelLoading, levelErrorMessage, levels, deleteLevel, dispatch } =
    useLevel();
  const training = levels[0]?.training;

  /**
   * Delete A Level
   * @param {string} levelId Level id
   */
  const onHandleDeleteLevel = (levelId) => {
    dispatch(deleteLevel(levelId));
  };

  // Create Level Button
  const createLevelBtn = (
    <Button
      className="level-modal-base__btn btn--primary btn--sv8 btn--rv5 admin-layout__content-btn"
      to={`/admin/trainings/${trainingId}/levels/1`}
    >
      Add Level
    </Button>
  );

  return (
    <ModalOverlay
      className={`level-modal-base-overlay ${className}`}
      to="/admin/trainings"
    >
      <Modal className="level-modal-base">
        <EmptyContent
          className="level-modal-base__empty"
          isLoading={isLevelLoading}
          errorMessage={levelErrorMessage}
          additionalErrorChildren={createLevelBtn}
        >
          <div className="level-modal-base__header">
            <h3 className="level-modal-base__title">
              {getTrainingAcronym(training?.title)} Levels
            </h3>

            {createLevelBtn}
          </div>

          <div className="level-modal-base__main">
            <LevelList
              className="level-modal-base__list"
              items={levels}
              onDeleteLevel={onHandleDeleteLevel}
            />
          </div>
        </EmptyContent>
      </Modal>
    </ModalOverlay>
  );
};

LevelModalBase.defaultProps = {
  className: "",
};
