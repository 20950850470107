// Modules
import React from "react";

// Components
import { Button, StudentPaidSubjectList, EmptyContent } from "../../../../";

// Hooks
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStudent } from "../../../../../hooks";

// Style
import "./student-paid-subject-base.styles.scss";

export const StudentPaidSubjectBase = ({ className }) => {
  const [isLoading, setIsLoading] = useState();
  const {
    enrolledSubjects,
    dispatch,
    getStudentEnrolledSubjects,
    studentErrorMessage,
  } = useStudent();
  const { studentId } = useParams();

  useEffect(() => {
    (async () => {
      // Start Loading
      setIsLoading(true);

      // Get Student Enrolled Subjects
      await dispatch(getStudentEnrolledSubjects(studentId));

      // Stop Loading
      setIsLoading(false);
    })();
  }, [studentId]);

  return (
    <div className={`student-paid-subject-base ${className}`}>
      <div className="student-paid-subject-base__container container">
        <div className="student-paid-subject-base__header">
          <h3 className="student-paid-subject-base__heading">
            Student Enrolled Subjects
          </h3>

          <Button
            className="student-paid-subject-base__btn btn--primary btn--sv8 btn--rv5"
            to={`/admin/students/${studentId}/trainings`}
          >
            Add/Remove Subject
          </Button>
        </div>

        <div className="student-paid-subject-base__list">
          <EmptyContent
            className="student-paid-subject-base__empty"
            isLoading={isLoading}
            errorMessage={studentErrorMessage}
          >
            <StudentPaidSubjectList
              className="student-paid-subject-base__list"
              items={enrolledSubjects}
            />
          </EmptyContent>
        </div>
      </div>
    </div>
  );
};

StudentPaidSubjectBase.defaultProps = {
  className: "",
};
