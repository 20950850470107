// Modules
import { createSlice } from "@reduxjs/toolkit";

// Common Reducers
import { loadingReducers, errorMessageReducers } from "../common";

// Utils
import { stateSelector } from "../../utils";

// Initial State
const initialState = {
  staffs: [],
  staffStat: { totalStaffs: 0, currentWeekLastActiveAtStaffCount: 0 },
  errorMessage: "",
  isLoading: false,
};

// Slice Name
const sliceName = "staff";

// Staff Slice
const staffSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ...loadingReducers,
    ...errorMessageReducers,
    setStaffs(state, action) {
      state.staffs = action.payload;
    },
    resetStaffs(state) {
      state.staffs = [];
    },
    setStaffStat(state, action) {
      state.staffStat = action.payload;
    },
    resetStaffStat(state) {
      state.staffStat = {
        totalStaffs: 0,
        currentWeekLastActiveAtStaffCount: 0,
      };
    },
    delStaff(state, action) {
      // Get Staff Id
      const staffId = action.payload;

      // Get Staffs
      const { staffs } = state;

      // Update Staffs With Staff That Does Not Contain The Specified Staff Id
      state.staffs = staffs.filter((staff) => staff.id !== staffId);

      // If Staffs Is Empty, Then Set Error Message
      if (!state.staffs.length) {
        state.errorMessage = "No Data Available. Wait For Data Upload";
      }
    },
  },
});

export const {
  setStaffs,
  resetStaffs,
  setStaffStat,
  resetStaffStat,
  delStaff,
  startLoading: staffStartLoading,
  stopLoading: staffStopLoading,
  setErrorMessage: setStaffErrorMessage,
  resetErrorMessage: resetStaffErrorMessage,
} = staffSlice.actions;
export * from "./staffAction";
export const selectStaffs = stateSelector(sliceName, "staffs");
export const selectStaffStat = stateSelector(sliceName, "staffStat");
export const selectIsStaffLoading = stateSelector(sliceName, "isLoading");
export const selectStaffErrorMessage = stateSelector(sliceName, "errorMessage");
export default staffSlice.reducer;
