// Modules
import { createSlice } from "@reduxjs/toolkit";

// Utils
import { stateSelector } from "../../utils";

// Common Reducers
import { loadingReducers } from "../common";

// Initial State
const initialState = {
  isLoading: false,
};

// Slice Name
const sliceName = "newsletter";

// Newsletter Slice
const newsletterSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: { ...loadingReducers },
});

export const {
  startLoading: newsLetterStartLoading,
  stopLoading: newsLetterStopLoading,
} = newsletterSlice.actions;
export * from "./newsletterAction";
export const selectIsNewsLetterLoading = stateSelector(sliceName, "isLoading");
export default newsletterSlice.reducer;
