// Modules
import React from "react";

// Components
import { Paper, Button } from "../../../..";

// Utils
import { getProfileImage } from "../../../../../utils";

// Style
import "./student-profile.styles.scss";

export const StudentProfile = ({ className, student }) => {
  return (
    <div className={`student-profile ${className}`}>
      <Paper className="student-profile__paper student-profile__paper--profile">
        <div className="student-profile__paper-profile-summary">
          <div className="student-profile__paper-profile-summary-left">
            <img
              className="student-profile__paper-profile-photo"
              src={getProfileImage(student.avatar?.url, 100, 100)}
              alt={student.firstName}
            />
          </div>
          <div className="student-profile__paper-profile-summary-right">
            <p className="student-profile__paper-profile-name">
              {student.firstName} {student.lastName}
            </p>
            <p className="student-profile__paper-profile-student-id">
              Student ID: {student.studentId}
            </p>
            <Button className="student-profile__paper-profile-status btn--sv10 btn--rv6 btn--gray-1">
              {student.isActive ? "Active" : "Inactive"} Student
            </Button>
          </div>
        </div>

        <div className="student-profile__paper-profile-detail">
          <ul className="student-profile__paper-profile-detail-list">
            <li className="student-profile__paper-profile-detail-item">
              <p className="student-profile__paper-profile-item-title">Email</p>
              <p className="student-profile__paper-profile-item-content">
                {student.email}
              </p>
            </li>
            <li className="student-profile__paper-profile-detail-item">
              <p className="student-profile__paper-profile-item-title">
                Phone Number
              </p>
              <p className="student-profile__paper-profile-item-content">
                {student.phoneNumber ? student.phoneNumber : "Unknown"}
              </p>
            </li>
            <li className="student-profile__paper-profile-detail-item">
              <p className="student-profile__paper-profile-item-title">
                Program
              </p>
              <p className="student-profile__paper-profile-item-content">
                {student.program}
              </p>
            </li>
            <li className="student-profile__paper-profile-detail-item">
              <p className="student-profile__paper-profile-item-title">
                How did you hear of us
              </p>
              <p className="student-profile__paper-profile-item-content">
                {student.referrerMedium}
              </p>
            </li>
            <li className="student-profile__paper-profile-detail-item">
              <p className="student-profile__paper-profile-item-title">
                Signed-up with Google
              </p>
              <p className="student-profile__paper-profile-item-content">
                {student.signupMedium === "google" ? "Yes" : "No"}
              </p>
            </li>
            <li className="student-profile__paper-profile-detail-item">
              <p className="student-profile__paper-profile-item-title">
                Signed-up with Facebook
              </p>
              <p className="student-profile__paper-profile-item-content">
                {student.signupMedium === "facebook" ? "Yes" : "No"}
              </p>
            </li>
            <li className="student-profile__paper-profile-detail-item">
              <p className="student-profile__paper-profile-item-title">
                Signed-up with Password
              </p>
              <p className="student-profile__paper-profile-item-content">
                {student.signupMedium === "password" ? "Yes" : "No"}
              </p>
            </li>
          </ul>
        </div>
      </Paper>

      <Paper
        className="student-profile__paper student-profile__paper--employment"
        title="Employment Details"
      >
        <div className="student-profile__paper-profile-detail">
          <ul className="student-profile__paper-profile-detail-list">
            <li className="student-profile__paper-profile-detail-item">
              <p className="student-profile__paper-profile-item-title">
                Employment Details
              </p>
              <p className="student-profile__paper-profile-item-content">
                {student.employment?.empName
                  ? student.employment?.empName
                  : "Unknown"}
              </p>
            </li>
            <li className="student-profile__paper-profile-detail-item">
              <p className="student-profile__paper-profile-item-title">
                Business Name
              </p>
              <p className="student-profile__paper-profile-item-content">
                {student.employment?.empCompany
                  ? student.employment?.empCompany
                  : "Unknown"}
              </p>
            </li>
            <li className="student-profile__paper-profile-detail-item">
              <p className="student-profile__paper-profile-item-title">Email</p>
              <p className="student-profile__paper-profile-item-content">
                {student.employment?.empEmail
                  ? student.employment?.empEmail
                  : "Unknown"}
              </p>
            </li>
            <li className="student-profile__paper-profile-detail-item">
              <p className="student-profile__paper-profile-item-title">
                Address
              </p>
              <p className="student-profile__paper-profile-item-content">
                {student.employment?.officeAddress
                  ? student.employment?.officeAddress
                  : "Unknown"}
              </p>
            </li>
            <li className="student-profile__paper-profile-detail-item">
              <p className="student-profile__paper-profile-item-title">Role</p>
              <p className="student-profile__paper-profile-item-content">
                {student.employment?.empRole
                  ? student.employment?.empRole
                  : "Unknown"}
              </p>
            </li>

            <li className="student-profile__paper-profile-detail-item">
              <p className="student-profile__paper-profile-item-title">
                Sponsored
              </p>
              <p className="student-profile__paper-profile-item-content">
                {student.employment?.isSponsored ? "Yes" : "No"}
              </p>
            </li>
          </ul>
        </div>
      </Paper>
    </div>
  );
};

StudentProfile.defaultProps = {
  className: "",
  student: {},
};
