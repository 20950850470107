// Redux
import { paymentStartLoading, paymentStopLoading } from "./";
import { uiSA } from "..";

// Apis
import * as apis from "../../apis";

// Destructure Redux Imports
const { showNotification } = uiSA;

/**
 * Create Payment Token
 * @param {object} paymentOptions Payment options
 * @param {object} cart Cart
 * @param {string} paymentType Payment type
 */
export const createPaymentToken =
  (paymentOptions, cart) => async (dispatch) => {
    try {
      // Start Loading
      dispatch(paymentStartLoading());

      // Create Payment Token
      const transactionToken = await apis.createPaymentToken(
        paymentOptions,
        cart
      );

      // Proceed To Payment
      window.location.replace(
        `https://secure.3gdirectpay.com/payv3.php?ID=${transactionToken.TransToken}`
      );
    } catch (err) {
      // Show Error In Notification
      dispatch(showNotification(err.message));
    } finally {
      // Stop Loading
      dispatch(paymentStopLoading());
    }
  };
