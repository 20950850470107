// Modules
import React from "react";
import { toast } from "react-toastify";

// Hooks
import { useEffect } from "react";
import { useUi } from "../../../hooks";

// Components
import { ToastContainer } from "react-toastify";

// Style
import "react-toastify/dist/ReactToastify.css";

export const ToastComponent = () => {
  const { notification, closeNotification, dispatch } = useUi();

  useEffect(() => {
    // If Notification Is Open Then Toast A Notification
    if (notification.open) {
      // Toast Notification
      toast[notification.type](notification.message);

      // Reset Notification
      dispatch(closeNotification());
    }
  }, [notification]);

  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnHover
      theme="light"
    />
  );
};
