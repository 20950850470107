// Modules
import React from "react";

// Components
import {
  ProfessionalTrainingHero,
  ProfessionalTrainingCourseBase,
} from "../../components";

export const ProfessionalTraining = () => {
  return (
    <div className="professional-training">
      <div className="professional-training__container">
        <ProfessionalTrainingHero />
        <ProfessionalTrainingCourseBase />
      </div>
    </div>
  );
};
