// Modules
import React from "react";

// Components
import { Outlet } from "react-router-dom";
import { PricingBase } from "../../components";

export const Pricing = () => {
  return (
    <div className="pricing">
      <div className="pricing__container">
        <PricingBase />
        <Outlet />
      </div>
    </div>
  );
};
