// Modules
import React from "react";

// Hooks
import { useSubscription, useUi } from "../../../hooks";

// Components
import { EmptyContent, SubscriptionHistoryList } from "../../";

export const SubscriptionHistoryBase = ({ className }) => {
  const {
    subscriptionErrorMessage,
    isSubscriptionlLoading,
    subscriptionHistories,
  } = useSubscription();
  const { pageTitle } = useUi();

  return (
    <div className={`subscription-history-base is-height-full ${className}`}>
      <div className="subscription-history__container is-height-full">
        <EmptyContent
          className="subscription-history__empty"
          isLoading={isSubscriptionlLoading}
          errorMessage={subscriptionErrorMessage}
        >
          <h2 className="subscription-history__heading student-base-heading student-base-heading--media-sensitive is-text-secondary-color">
            {pageTitle}
          </h2>

          <SubscriptionHistoryList
            className="subscription-history__list"
            items={subscriptionHistories}
          />
        </EmptyContent>
      </div>
    </div>
  );
};

SubscriptionHistoryBase.defaultProps = {
  className: "",
};
