// Modules
import writeXlsxFile from "write-excel-file";

// Student Excel Schema
const studentExcelSchema = [
  {
    column: "Student Id",
    type: String,
    value: (student) => student.studentId,
  },
  {
    column: "FullName",
    type: String,
    value: (student) => `${student.firstName} ${student.lastName}`,
  },
  {
    column: "Email",
    type: String,
    value: (student) => student.email,
  },
  {
    column: "City",
    type: String,
    value: (student) => student.city,
  },
  {
    column: "Country",
    type: String,
    value: (student) => student.country,
  },
  {
    column: "Program",
    type: String,
    value: (student) => student.program,
  },
  {
    column: "Status",
    type: String,
    value: (student) => (student.isActive ? "Active" : "Inactive"),
  },
  {
    column: "Subjects",
    type: Number,
    value: (student) => student.enrolledSubjectsCount,
  },
];

/**
 * Download Data As Excel
 * @param {array} Data Data
 * @param {string|array} schema schema name
 */
export const downloadDataAsExcel = async (data, schema, filename) => {
  // Computed Schema
  let computedSchema;

  // Compute Schema Base On The Schema String
  if (schema === "student") {
    computedSchema = studentExcelSchema;
  }

  await writeXlsxFile(data, {
    schema: computedSchema,
    fileName: `${filename}.xlsx`,
  });
};
