// Modules
import React from "react";
import { closestCenter } from "@dnd-kit/core";
import { verticalListSortingStrategy, arrayMove } from "@dnd-kit/sortable";

// Components
import { DndContext } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";

export const VideoPlaylistDraggable = ({
  className,
  children,
  items,
  onUpdatePlaylistFile,
}) => {
  /**
   * Handle Playlist Card Drag End
   */
  const onHandleDragEnd = (event) => {
    // Get Active And Over Playlist Card
    const { active, over } = event;

    // Get Active Index
    const activeIndex = items.findIndex((item) => item?.id === active?.id);

    // Get Over Index
    const overIndex = items.findIndex((item) => item?.id === over?.id);

    // If Active Index Is Different From Over Index Then Update Items
    if (activeIndex !== overIndex) {
      const newItems = arrayMove(items, activeIndex, overIndex).map(
        (item, index) => ({ ...item, positionId: index + 1 })
      );
      onUpdatePlaylistFile(newItems);
    }
  };

  return (
    <div className={`video-playlist-draggable ${className}`}>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={onHandleDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {children}
        </SortableContext>
      </DndContext>
    </div>
  );
};

VideoPlaylistDraggable.defaultProps = {
  className: "",
  items: [],
};
