// Modules
import React from "react";

// Hooks
import { useEffect, useState } from "react";
import { useChapterFile, useChapter } from "../../../hooks";

// Components
import { VideoPlaylist } from "../..";

// Style
import "./video-playlist-base.styles.scss";

export const VideoPlaylistBase = ({
  className,
  enableDragging,
  isEditMode,
  onUpdateChapterFiles,
  onDeleteChapterFile,
}) => {
  const [activeChapterIdChapterFiles, setActiveChapterIdChapterFiles] =
    useState([]);
  const {
    chapterFiles,
    setChapterFileErrorMessage,
    dispatch,
    isChapterFileLoading,
    activeChapterFile,
    setActiveChapterFile,
  } = useChapterFile();
  const { activeChapter } = useChapter();

  useEffect(() => {
    // If Chapter File Is Not Loading
    if (!isChapterFileLoading) {
      // Get Active Chapter Id Chapter Files
      const activeChapterIdChapterFiles = chapterFiles.filter(
        (chapterFile) =>
          chapterFile.chapterId?.["_path"]?.["segments"]?.[1] === activeChapter
      );

      // If ActiveChapterIdChapterFiles Is Undefined, Then Update Error Messasge, Else Reset Error Message
      if (!activeChapterIdChapterFiles.length) {
        dispatch(
          setChapterFileErrorMessage(
            "No Chapter File Available. Wait For Data Upload"
          )
        );
      } else {
        dispatch(setChapterFileErrorMessage(""));
      }

      // Update ActiveChapterIdChapterFiles In State
      setActiveChapterIdChapterFiles(activeChapterIdChapterFiles);
    }
  }, [chapterFiles, activeChapter]);

  /**
   * Set Active Chapter File
   * @param {string} chapterFileId Chapter File Id
   */
  const onSetActiveChapterFile = (chapterFileId) => {
    dispatch(setActiveChapterFile(chapterFileId));
  };

  /**
   * Handle Update Chapter Files
   * @param {array} chapterFiles Updated Chapter Files
   */
  const onHandleUpdateChapterFiles = (chapterFiles) => {
    setActiveChapterIdChapterFiles(chapterFiles);
    onUpdateChapterFiles(chapterFiles);
  };

  return (
    <div className={`video-playlist-base ${className}`}>
      <h3 className="video-playlist-base__heading">Chapter Playlists</h3>
      <VideoPlaylist
        className="video-playlist-base__list"
        items={activeChapterIdChapterFiles}
        isEditMode={isEditMode}
        activePlaylist={activeChapterFile}
        enableDragging={enableDragging}
        setActivePlaylist={onSetActiveChapterFile}
        onDeletePlaylistFile={onDeleteChapterFile}
        onUpdatePlaylistFile={onHandleUpdateChapterFiles}
      />
    </div>
  );
};

VideoPlaylistBase.defaultProps = {
  className: "",
  enableDragging: false,
};
