// Firebase
import { addDoc, doc } from "firebase/firestore";
import {
  feedbackCollectionRef,
  userCollectionRef,
  chapterCollectionRef,
  chapterFileCollectionRef,
} from "../../utils";

// Utils
import { AppError } from "../../utils";

/**
 * Create New Feedback
 * @param {object} feedbackData Feedback data
 * @param {string} studentId Student id
 * @param {string} chapterId Chapter id
 * @param {string} chapterFileId Chapter file id
 */
export const createFeedback = async (
  feedbackData,
  studentId,
  chapterId,
  chapterFileId
) => {
  try {
    // Create Document References
    const studentRef = doc(userCollectionRef, studentId);
    const chapterRef = doc(chapterCollectionRef, chapterId);
    const chapterFileRef = doc(chapterFileCollectionRef, chapterFileId);

    // Create Feedback
    await addDoc(feedbackCollectionRef, {
      ...feedbackData,
      studentId: studentRef,
      chapterId: chapterRef,
      chapterFileId: chapterFileRef,
    });
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};
