// Modules
import React from "react";

// Components
import { PrimaryTimestamp } from "../../";

// Style
import "./book-private-session-timestamp-list.styles.scss";

export const BookPrivateSessionTimestampList = ({
  className,
  activeMeetingDate,
  formik,
}) => {
  const { values, setFieldValue } = formik;

  /**
   * Update Meeting Time
   * @param {object} time Booking time
   */
  const onHandleUpdateMeetingTime = (time) => {
    setFieldValue("meetingTime", time);
  };

  // Booking Timestamp Items
  const renderedBookingTimestampItems = activeMeetingDate?.times.map(
    (bookingTime, index) => (
      <PrimaryTimestamp
        key={index}
        className="book-private-session-timestamp-list__timestamp-item"
        value={bookingTime}
        isActive={bookingTime === values.meetingTime}
        onClick={onHandleUpdateMeetingTime}
      />
    )
  );

  return (
    <div className={`book-private-session-timestamp-list ${className}`}>
      {renderedBookingTimestampItems}
    </div>
  );
};

BookPrivateSessionTimestampList.defaultProps = {
  className: "",
};
