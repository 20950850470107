// Modules
import React from "react";

// Hooks
import { useMemo } from "react";
import { useAuth, useLevel } from "../../../hooks";
import { useLocation, useNavigate } from "react-router-dom";

// Utils
import { getTrainingAcronym } from "../../../utils";

// Components
import { Button } from "../../";

// Styles
import "./wire-transfer-checkout-modal.styles.scss";

export const WireTransferCheckoutModal = () => {
  const { user } = useAuth();
  const { levels } = useLevel();
  const { state } = useLocation();
  const navigate = useNavigate();
  const cart = state.cart;

  const level = useMemo(
    () => levels.find((level) => level.id === cart.items[0].levelId),
    [levels]
  );

  return (
    <div className="wire-transfer-checkout-modal">
      <h3 className="wire-transfer-checkout-modal__heading">Wire Transfer</h3>

      <ul className="wire-transfer-checkout-modal__list">
        <li className="wire-transfer-checkout-modal__item">
          <strong>Bank Name:</strong> Guaranteed Trust Bank
        </li>

        <li className="wire-transfer-checkout-modal__item">
          <strong>Bank Account Number:</strong> 211-245-440-151-180
        </li>

        <li className="wire-transfer-checkout-modal__item">
          <strong>Bank Currency:</strong> RWF
        </li>

        <li className="wire-transfer-checkout-modal__item">
          <strong>Total Amount:</strong> {cart.totalPrice} USD
        </li>

        <li className="wire-transfer-checkout-modal__item">
          <strong>ID:</strong> {user.studentId}
        </li>

        <li className="wire-transfer-checkout-modal__item">
          <strong>Item Purchased:</strong>{" "}
          {cart.items.length > 1
            ? "Custom"
            : `${getTrainingAcronym(level?.training?.title)}/${level?.name}`}
        </li>

        <li className="wire-transfer-checkout-modal__item">
          <strong>Note:</strong> Kindly add your ID to the payment description.
          Send a proof of payment to example@email.com. You will be enrolled
          within 1 business day of payment confirmation.
        </li>
      </ul>

      <Button
        type="submit"
        wrapperClassName="d-block"
        className="wire-transfer-checkout-modal__btn btn--primary btn--sv5 btn--rv1 is-width-full is-text-extra-thick"
        onClick={navigate.bind(null, -1)}
      >
        Got it
      </Button>
    </div>
  );
};
