// Modules
import React from "react";

// Hooks
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDelete } from "../../../../hooks";

// Components
import { Fragment } from "react";
import {
  Button,
  Dialog as DeleteConfirmationDialog,
  UpgradeSubscriptionDialog,
} from "../../../";

// Utils
import { getTrainingAcronym, humanReadableDate } from "../../../../utils";

// Data
import { subscriptionTypes } from "../../../../data";

// Style
import "./subscription-history-card.styles.scss";

export const SubscriptionHistoryCard = ({
  className,
  paidAt,
  subscriptionType,
  renewalDate,
  amountPaid,
  studyMethod,
  level,
  training,
}) => {
  const trainingAcronym = getTrainingAcronym(training.title);
  const levelName = level.name;

  const upgradeSubscriptionTypes = subscriptionTypes.filter(
    (_subscriptionType) =>
      _subscriptionType.type.toLowerCase().trim() !==
      subscriptionType.toLowerCase().trim()
  );

  const [upgradeSubscriptionType, setUpgradeSubscriptionType] = useState(
    upgradeSubscriptionTypes[0].type
  );
  const navigate = useNavigate();
  const {
    isDeleteDialogOpen,
    deleteCloseActionText,
    deleteConfirmActionText,
    onHandleCloseDeleteDialog,
    onHandleOpenDeleteDialog,
    onHandleDeleteConfirm,
  } = useDelete(cancelSubscription);

  const {
    isDeleteDialogOpen: isUpgradeSubscriptionDialogOpen,
    onHandleCloseDeleteDialog: onHandleCloseUpgradeSubscriptionDialog,
    onHandleOpenDeleteDialog: onHandleOpenUpgradeSubscriptionDialog,
    onHandleDeleteConfirm: onHandleUpgradeSubscriptionDialogConfirm,
  } = useDelete(upgradeSubscription);

  /**
   * Cancel Subscription
   */
  function cancelSubscription() {
    // Perform Checkout
    navigate("/student/profile/subscription-histories/checkout", {
      state: {
        cart: {
          items: [
            {
              trainingId: training.id,
              levelId: level.id,
            },
          ],
          paymentType: "cancelSubscription",
        },
      },
    });
  }

  /**
   * Get Subscription Price
   * @param {string} subscriptionType Subscription type
   */
  const getSubscriptionPrice = (subscriptionType) => {
    switch (subscriptionType.toLowerCase()) {
      case "monthly":
        return level.pricePerMonth[studyMethod];
      case "quarterly":
        return level.pricePerQuarter[studyMethod];
      case "yearly":
        return level.pricePerYear[studyMethod];
    }
  };

  /**
   * Upgrade Subscription
   */
  function upgradeSubscription() {
    // Perform Checkout
    navigate("/student/profile/subscription-histories/checkout", {
      state: {
        cart: {
          items: [
            {
              trainingId: training.id,
              levelId: level.id,
            },
          ],
          paymentType: "upgradeSubscription",
          totalPrice: getSubscriptionPrice(upgradeSubscriptionType),
        },
      },
    });
  }

  return (
    <Fragment>
      <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        dialogTitle={`Confirm Cancel ${levelName} ${trainingAcronym} Course ${subscriptionType} Subscription`}
        dialogDescription={`You are about to cancel your ${level.name} ${trainingAcronym} Course ${subscriptionType} subscription. Confirm with yes to continue`}
        closeActionText={deleteCloseActionText}
        confirmActionText={deleteConfirmActionText}
        onClose={onHandleCloseDeleteDialog}
        onConfirm={onHandleDeleteConfirm}
      />

      <UpgradeSubscriptionDialog
        open={isUpgradeSubscriptionDialogOpen}
        dialogTitle={`Upgrade ${levelName} ${trainingAcronym} Course Subscription`}
        dialogDescription={`Upgrade your ${levelName} ${trainingAcronym} course ${subscriptionType} subscription for ${
          studyMethod === "offline" ? "physical" : studyMethod
        } class. Select an option below and proceed to checkout`}
        upgradeSubscriptionType={upgradeSubscriptionType}
        upgradeSubscriptionTypes={upgradeSubscriptionTypes}
        setUpgradeSubscriptionType={setUpgradeSubscriptionType}
        onClose={onHandleCloseUpgradeSubscriptionDialog}
        onConfirm={onHandleUpgradeSubscriptionDialogConfirm}
      />

      <div className={`subscription-history-card ${className}`}>
        <div className="subscription-history-card__header">
          <p className="subscription-history-card__p1">
            You have <strong>active</strong> {subscriptionType} subscription for{" "}
            <strong className="subscription-history-card__highlight">
              {levelName}
            </strong>{" "}
            {trainingAcronym}
            course
          </p>

          <p className="subscription-history-card__p2">
            Paid At Date:{" "}
            <strong className="subscription-history-card__highlight">
              {humanReadableDate(undefined, paidAt)}
            </strong>
          </p>

          <p className="subscription-history-card__p2">
            Renewal Date:{" "}
            <strong className="subscription-history-card__highlight">
              {humanReadableDate(undefined, renewalDate.toDate())}
            </strong>
          </p>

          <p className="subscription-history-card__p2">
            Amount Paid:{" "}
            <strong className="subscription-history-card__highlight">
              ${amountPaid}
            </strong>
          </p>
        </div>
        <div className="subscription-history-card__footer">
          <Button
            wrapperClassName="subscription-history-card__btn-wrapper"
            className="subscription-history-card__btn btn--dark btn--rv1 btn--sv3"
            onClick={onHandleOpenUpgradeSubscriptionDialog}
          >
            Upgrade
          </Button>

          <Button
            wrapperClassName="subscription-history-card__btn-wrapper"
            className="subscription-history-card__btn btn--primary btn--rv1 btn--sv3"
            onClick={onHandleOpenDeleteDialog}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

SubscriptionHistoryCard.defaultProps = {
  className: "",
};
