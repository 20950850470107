// Hooks
import { useSelector, useDispatch } from "react-redux";

// Redux
import { trainingSA } from "../store";

// Destructure Redux Imports
const {
  selectIsTrainingLoading,
  selectTrainings,
  selectTrainingErrorMessage,
  selectTrainingStat,
} = trainingSA;

/**
 * Training Hook
 */
export const useTraining = () => {
  const dispatch = useDispatch();
  const trainings = useSelector(selectTrainings);
  const trainingStat = useSelector(selectTrainingStat);
  const trainingErrorMessage = useSelector(selectTrainingErrorMessage);
  const isTrainingLoading = useSelector(selectIsTrainingLoading);

  return {
    trainings,
    trainingErrorMessage,
    isTrainingLoading,
    trainingStat,
    dispatch,
    ...trainingSA,
  };
};
