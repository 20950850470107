// Modules
import React from "react";

// Hooks
import { useEffect } from "react";
import { useUrlAsTitle, useSubscription, useAuth } from "../../../hooks";

// Components
import { Outlet } from "react-router-dom";
import { SubscriptionHistoryBase } from "../../../components";

export const SubscriptionHistory = () => {
  const { dispatch, getStudentSubscriptionHistories } = useSubscription();
  const { user } = useAuth();
  // Set Page Custom Title
  useUrlAsTitle("Subscription History");

  useEffect(() => {
    // Get Student Subscription Histories
    dispatch(getStudentSubscriptionHistories(user.uid, false, true));
  }, []);

  return (
    <div className="subscription-history is-height-full">
      <div className="subscription-history__container is-height-full">
        <SubscriptionHistoryBase />
        <Outlet />
      </div>
    </div>
  );
};
