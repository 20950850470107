// Modules
import React from "react";

// Components
import { Button } from "..";

// Style
import "./status-message.styles.scss";

export const StatusMessage = ({
  className,
  status,
  message,
  backContent,
  backUrl,
  ...otherProps
}) => {
  return (
    <div className={`status-message ${className}`} {...otherProps}>
      <div className="status-message__container container">
        <h2 className="status-message__status">{status}</h2>
        <p className="status-message__message">{message}</p>
        <Button
          className="status-message__btn btn--primary btn--rv1 btn--sv5"
          to={backUrl}
        >
          {backContent}
        </Button>
      </div>
    </div>
  );
};

StatusMessage.defaultProps = {
  className: "",
};
