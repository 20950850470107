// Redux
import {
  setReferrerMediums,
  resetReferrerMediumErrorMessage,
  referrerMediumStartLoading,
  referrerMediumStopLoading,
  setReferrerMediumErrorMessage,
  setReferrerMediumsStat,
} from ".";

// Apis
import * as apis from "../../apis";

/**
 * Get Referrer Mediums Stat For Month
 * @param {number|undefined} month Lookup month (Note: month should start from zero)
 */
export const getMonthReferrerMediumsStat = (month) => async (dispatch) => {
  try {
    // Reset Error
    dispatch(resetReferrerMediumErrorMessage());

    // Start Loading
    dispatch(referrerMediumStartLoading());

    // Get Referrer Mediums Stat For Month
    const stat = await apis.getMonthReferrerMediumsStat(month);

    // Update Referrer Mediums Stat
    dispatch(setReferrerMediumsStat(stat));
  } catch (err) {
    // Update Error Message
    dispatch(setReferrerMediumErrorMessage(err.message.message));
  } finally {
    // Stop Loading
    dispatch(referrerMediumStopLoading());
  }
};

/**
 * Get All Referrer Mediums
 */
export const getAllReferrerMediums = () => async (dispatch) => {
  try {
    // Reset Error
    dispatch(resetReferrerMediumErrorMessage());

    // Start Loading
    dispatch(referrerMediumStartLoading());

    // Get All Referrer Mediums
    const referrerMediums = await apis.getAllReferrerMediums();

    // Update ReferrerMediums
    dispatch(setReferrerMediums(referrerMediums));
  } catch (err) {
    // Update Error Message
    dispatch(setReferrerMediumErrorMessage(err.message.message));
  } finally {
    // Stop Loading
    dispatch(referrerMediumStopLoading());
  }
};
