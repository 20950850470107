// Modules
import React from "react";

// Hooks
import { useState, useEffect } from "react";

// Components
import { IconButton } from "@mui/material";
import { FiMoreVertical } from "react-icons/fi";

// Style
import "./more-action.styles.scss";

export const MoreAction = ({ className, children }) => {
  const [isMoreActionDropdownOpen, setIsMoreActionDropdownOpen] =
    useState(false);

  useEffect(() => {
    // Close More Action Dropdown Visibility
    const closeMoreActionDropdownVisibility = (e) => {
      const moreActionEl = e.target.closest(".more-action");
      if (moreActionEl) return;
      setIsMoreActionDropdownOpen(false);
    };

    // Close More Action Dropdown On Body Click
    document.body.addEventListener("click", closeMoreActionDropdownVisibility);

    return () => {
      document.body.removeEventListener(
        "click",
        closeMoreActionDropdownVisibility
      );
    };
  }, []);

  /**
   * Toggle More Action Dropdown Visibility
   */
  const toggleMoreActionDropdownVisibility = () => {
    setIsMoreActionDropdownOpen(!isMoreActionDropdownOpen);
  };

  return (
    <div
      className={`more-action ${className}`}
      onClick={toggleMoreActionDropdownVisibility}
    >
      <IconButton className="more-action__icon-btn">
        <FiMoreVertical
          className="more-action__icon"
          aria-label="more action"
        />
      </IconButton>

      <div
        className={`more-action__dropdown animate-3s ${
          isMoreActionDropdownOpen ? "box-open" : "box-close"
        }`}
      >
        <ul className="more-action__dropdown-list">{children}</ul>
      </div>
    </div>
  );
};

MoreAction.defaultProps = {
  className: "",
};
