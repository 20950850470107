// Modules
import React from "react";

// Hooks
import { useState } from "react";

// Components
import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import {
  StudentSideNavbar,
  StudentNavbar,
  NotVerified,
} from "../../components";

// Hocs
import { preventIfNotAuthHoc } from "../../hocs";

// Style
import "./student-layout.styles.scss";

export const _StudentLayout = () => {
  const [isSidenavbarOpen, setIsSidenavbarOpen] = useState(false);

  return (
    <Fragment>
      <NotVerified />

      <div className="student-layout">
        <div className="student-layout__container">
          <div className="student-layout__sidenavbar-wrapper">
            <StudentSideNavbar
              className="student-layout__sidenavbar"
              isSidenavbarOpen={isSidenavbarOpen}
              setIsSidenavbarOpen={setIsSidenavbarOpen}
            />
          </div>

          <div className="student-layout__main">
            <StudentNavbar
              className="student-layout__navbar"
              isSidenavbarOpen={isSidenavbarOpen}
              setIsSidenavbarOpen={setIsSidenavbarOpen}
            />

            <div className="student-layout__content">
              <div className="student-layout__content-container container">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// Prevent User From Accessing Student Layout If User Is Not Authenticated
export const StudentLayout = preventIfNotAuthHoc(_StudentLayout);
