// Modules
import React from "react";

// Components
import { MdOutlineLocalLibrary } from "react-icons/md";

// Utils
import { getRandomColor } from "../../../utils";

// Style
import "./student-table-subject-preview.styles.scss";

export const StudentTableSubjectPreview = ({
  className,
  subject,
  totalVideos,
  totalPdfs,
}) => {
  const previewColor = getRandomColor();
  const iconBoxStyle = { background: `rgba(${previewColor}, 0.36)` };
  const iconStyle = { color: `rgb(${previewColor})` };

  return (
    <div className={`student-table-subject-preview ${className}`}>
      <div className="student-table-subject-preview__left">
        <div
          className="student-table-subject-preview__icon-box"
          style={iconBoxStyle}
        >
          <MdOutlineLocalLibrary
            className="student-table-subject-preview__icon"
            style={iconStyle}
            aria-label={subject.name}
          />
        </div>
      </div>
      <div className="student-table-subject-preview__right">
        <p className="student-table-subject-preview__title">{subject.name}</p>
        <p className="student-table-subject-preview__stat">
          <span className="student-table-subject-preview__stat-item">
            {totalVideos} Videos
          </span>
          <span className="student-table-subject-preview__stat-item">
            {totalPdfs} Pdfs
          </span>
        </p>
      </div>
    </div>
  );
};

StudentTableSubjectPreview.defaultProps = {
  className: "",
};
