// Modules
import React from "react";

// Styles
import "./accounting-solution-group.styles.scss";

export const AccountingSolutionGroup = ({ className, children, title }) => {
  const [firstTitle, secondTitle] = title.split(" ");

  return (
    <div className={`accounting-solution-group ${className}`}>
      <h4 className="accounting-solution-group__heading">
        {firstTitle} <span className="is-text-accent-400">{secondTitle}</span>
      </h4>
      {children}
    </div>
  );
};

AccountingSolutionGroup.defaultProps = {
  className: "",
};
