// Firebase
import {
  query,
  where,
  orderBy,
  getDocs,
  updateDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import { firebaseLooper, transactionCollectionRef } from "../../utils";

// Utils
import {
  AppError,
  getCurrentMonthCount,
  getCurrentMonthCountSuccess,
  getFieldFromObject,
  getCurrentMonthSum,
  getCurrentWeekSum,
  getCurrentDaySum,
  getAllMonthsSum,
} from "../../utils";

// Apis
import * as apis from "..";

/**
 * Populate Student Field In Transactions
 * @param {array} transactions Transactions
 */
const populateStudentInTransactions = async (transactions) => {
  try {
    // Get Transactions With Student Populated
    const transactionsWithStudent = await Promise.all(
      transactions.map(async (transaction) => ({
        ...transaction,
        studentId: transaction.studentId.id,
        student:
          (await apis.getStudent(transaction.studentId.id, false, false)) || {},
      }))
    );

    // Return Transactions With Student
    return transactionsWithStudent;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get All Transactions
 * @param {boolean} populateStudentField If true populate student field
 */
export const getTransactions = async (populateStudentField) => {
  try {
    // Create Transaction Query
    const transactionQuery = query(
      transactionCollectionRef,
      where("isActive", "==", true),
      orderBy("createdAt", "desc")
    );

    // Get Transactions
    const querySnapshot = await getDocs(transactionQuery);

    // If Transactions Is Empty, Then Throw Custom Error
    if (querySnapshot.empty) {
      throw new AppError("empty-data");
    }

    // Populate Student Field In Transactions If PopulateStudentField Is True
    let transactions = firebaseLooper(querySnapshot);
    transactions = populateStudentField
      ? await populateStudentInTransactions(transactions)
      : transactions;

    // Return Transactions
    return transactions;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Transaction Sales Stat
 * @param {array} transactions Transactions
 */
export const getTransactionSalesStat = (transactions) => {
  // Get Total Transaction Sales For Current Month
  const currentMonthTransactionSales = getCurrentMonthSum(
    transactions,
    "createdAt",
    "priceUSD"
  );

  // Get Total Transaction Sales For Current Week
  const currentWeekTransactionSales = getCurrentWeekSum(
    transactions,
    "createdAt",
    "priceUSD"
  );

  // Get Total Transaction Sales For Current Day
  const currentDayTransactionSales = getCurrentDaySum(
    transactions,
    "createdAt",
    "priceUSD"
  );

  // Return Transaction Sales  Stat
  return {
    currentMonthTransactionSales,
    currentWeekTransactionSales,
    currentDayTransactionSales,
  };
};

/**
 * Get Graph Transaction Sales Stat
 * @param {number} startYear Start year
 * @param {number} compareYear Compare year
 */
export const getGraphTransactionSalesStat = async (startYear, compareYear) => {
  /**
   * Get Transactions For Year
   * @param {number} year Year
   */
  const getTransactionsForYear = async (year) => {
    // Get Begining Of Year Date
    const beginningOfYearDate = new Date(year, 0, 1);

    // Get End Of Year Date
    const endOfYearDate = new Date(year, 11, 31);

    // Create Transaction Query
    const transactionQuery = query(
      transactionCollectionRef,
      where("isActive", "==", true),
      where("status", "==", true),
      where("createdAt", ">=", Timestamp.fromDate(beginningOfYearDate)),
      where("createdAt", "<=", Timestamp.fromDate(endOfYearDate))
    );

    // Get Transactions
    const querySnapshot = await getDocs(transactionQuery);

    // Return Transactions
    return firebaseLooper(querySnapshot);
  };

  try {
    // Get Start Year Transactions
    const startYearTransactions = await getTransactionsForYear(startYear);

    // Get Compare Year Transactions
    const compareYearTransactions = await getTransactionsForYear(compareYear);

    // Get Start Year Transaction Sales Stat
    const startYearTransationSalesStat = getAllMonthsSum(
      startYearTransactions,
      "createdAt",
      "priceUSD"
    );

    // Get Compare Year Transaction Sales Stat
    const compareYearTransactionSalesStat = getAllMonthsSum(
      compareYearTransactions,
      "createdAt",
      "priceUSD"
    );

    // Return Start Year And Compare Year Transactions Sales Stat
    return {
      comparisonStat: {
        startYearStat: startYearTransationSalesStat,
        compareYearStat: compareYearTransactionSalesStat,
      },
    };
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Transaction Sales For Month
 * @param {number|undefined} month Lookup month (Note: month should start from zero)
 */
export const getMonthTransactionSalesStat = async (month) => {
  /**
   * Get Transaction For Month
   * @param {number} month
   */
  const getTransactionForMonth = async (month) => {
    // Get Begining Of Month Date
    const beginningOfMonthDate = new Date(
      new Date().setMonth(month)
    ).moveToFirstDayOfMonth();

    // Get End Of Month Date
    const endOfMonthDate = new Date(
      new Date().setMonth(month)
    ).moveToLastDayOfMonth();

    // Create Transaction Query
    const transactionQuery = query(
      transactionCollectionRef,
      where("isActive", "==", true),
      where("status", "==", true),
      where("createdAt", ">=", Timestamp.fromDate(beginningOfMonthDate)),
      where("createdAt", "<=", Timestamp.fromDate(endOfMonthDate))
    );

    // Get Transactions
    const querySnapshot = await getDocs(transactionQuery);

    // Return Transactions
    return firebaseLooper(querySnapshot);
  };

  try {
    // Get Month Transaction Sales Stat
    let monthTransactionSalesStat;

    // Get Current Month Transaction Sales Stat
    let currentMonthTransactionSalesStat;

    // Get Transactions For Specified Month If Month Exist
    if (month) {
      const transactions = await getTransactionForMonth(month);

      // Get Month Transaction Sales Stat
      monthTransactionSalesStat = {
        currentMonthTransactionSales: transactions.reduce(
          (sum, transaction) => sum + transaction.priceUSD,
          0
        ),
      };
    }

    // Get Current Month Transactions
    const transactions = await getTransactionForMonth(Date.today().getMonth());

    // Get Current Month Transaction Sales Stat
    currentMonthTransactionSalesStat = getTransactionSalesStat(transactions);

    // Get Total Transaction Sales
    const querySnapshot = await getDocs(
      query(
        transactionCollectionRef,
        where("isActive", "==", true),
        where("status", "==", true)
      )
    );
    const totalTransactionSales = querySnapshot.docs.reduce(
      (sum, snapshot) => sum + snapshot.data().priceUSD,
      0
    );

    // Return Sales Stat
    return {
      totalTransactionSales,
      currentMonthStat: getFieldFromObject(
        [
          "currentMonthTransactionSales",
          "currentWeekTransactionSales",
          "currentDayTransactionSales",
        ],
        currentMonthTransactionSalesStat
      ),
      monthStat: getFieldFromObject(
        ["currentMonthTransactionSales"],
        monthTransactionSalesStat
      ),
    };
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Transactions Stat
 * @param {array} transactions Transactions
 */
export const getTransactionsStat = (transactions) => {
  // Get Total Transactions Count
  const totalTransactions = transactions.length;

  // Get Total Transactions Count For Current Month
  const currentMonthTotalTransactionCount = getCurrentMonthCount(
    transactions,
    "createdAt"
  );

  // Get Total Successfull Transactions Count For Current Month
  const currentMonthTotalSuccessTransactionCount = getCurrentMonthCountSuccess(
    transactions,
    "createdAt",
    "status"
  );

  // Return Stat
  return {
    totalTransactions,
    currentMonthTotalTransactionCount,
    currentMonthTotalSuccessTransactionCount,
  };
};

/**
 * Get All Transactions And Stat
 * @param {boolean} populateStudentField If true populate student field
 */
export const getTransactionsAndStat = async (populateStudentField) => {
  try {
    // Get Transactions
    const transactions = await getTransactions(populateStudentField);

    // Get Transactions stat
    const stat = getTransactionsStat(transactions);

    // Return Transactions And Stat
    return { transactions, stat };
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Delete A Transaction
 * @param {string} transactionId Transaction id
 */
export const deleteTransaction = async (transactionId) => {
  try {
    // Transaction Doc Reference
    const transactionRef = doc(transactionCollectionRef, transactionId);

    // Delete Transaction
    await updateDoc(transactionRef, { isActive: false });

    // Get Transactions Stat
    const { stat } = await getTransactionsAndStat();

    // Return Transaction Stat
    return stat;
  } catch (err) {
    switch (err.code) {
      case "not-found":
        // If The Transaction Id Does Not Exist, Throw A Custom Error
        throw new AppError("delete/empty-data");
      default:
        // Re-Throw Error
        throw new AppError(err);
    }
  }
};
