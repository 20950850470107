// Modules
import React from "react";
import { CSS } from "@dnd-kit/utilities";

// Components
import { VideoPlaylistCard } from "../..";

// Hooks
import { useSortable } from "@dnd-kit/sortable";

// Style
import "./video-playlist-card-sortable.styles.scss";

export const VideoPlaylistCardSortable = (props) => {
  const { setNodeRef, attributes, listeners, transform, transition } =
    useSortable({
      id: props.playlistId,
    });

  // Drag Style
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className="video-playlist-card-sortable"
      style={style}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <VideoPlaylistCard {...props} />
    </div>
  );
};

VideoPlaylistCardSortable.defaultProps = {
  className: "",
};
