// Modules
import { object, string, boolean, ref, number, date, mixed, array } from "yup";

// Authentication Validation Schema
export const authSchema = object().shape({
  authType: string(),
  email: string()
    .required("Email field is required")
    .email("Enter a valid email address"),
  password: string().when("authType", (authType, schema) => {
    const required = schema.required("Password field is required");
    switch (authType) {
      case "register":
        return required.min(8, "Your password is too short");
      case "login":
        return required;
      default:
        return schema;
    }
  }),
  firstName: string().when("authType", {
    is: "register",
    then: string().required("First name field is required"),
  }),
  lastName: string().when("authType", {
    is: "register",
    then: string().required("Last name field is required"),
  }),
  passwordConfirm: string().when("authType", {
    is: "register",
    then: string()
      .required("Confirm password field is required")
      .oneOf([ref("password"), null], "Passwords must match"),
  }),
  referrerMedium: string().when("authType", {
    is: "register",
    then: string().required("Referrer medium field is required"),
  }),
  phoneNumber: string().when("authType", {
    is: "register",
    then: string().required("Phone number field is required"),
  }),
  tc: boolean().when("authType", {
    is: "register",
    then: boolean()
      .required("Terms and condition field is required")
      .oneOf([true, null], "Terms and condition field must be checked"),
  }),
});

// Profile Update Validation Schema
export const profileUpdateSchema = object().shape({
  firstName: object({
    value: string().required("First name field is required"),
  }),
  lastName: object({
    value: string().required("Last name field is required"),
  }),
  email: object({
    value: string()
      .required("Email field is required")
      .email("Enter a valid email address"),
  }),
  country: object({
    value: string().required("Country field is required"),
  }),
  phoneNumber: object({
    value: string().required("Phone number field is required"),
  }),
  isEmployed: boolean(),
  isSponsored: boolean(),
  empName: object().when("isEmployed", {
    is: true,
    then: object({
      value: string().required("Employer name field is required"),
    }),
  }),
  empEmail: object().when("isEmployed", {
    is: true,
    then: object({
      value: string()
        .required("Employer email field is required")
        .email("Enter a valid email address"),
    }),
  }),
  empRole: object().when("isEmployed", {
    is: true,
    then: object({
      value: string().required("Employment role field is required"),
    }),
  }),
  empCompany: object().when("isEmployed", {
    is: true,
    then: object({
      value: string().required("Company name field is required"),
    }),
  }),
  officeAddress: object().when("isEmployed", {
    is: true,
    then: object({
      value: string().required("Office address field is required"),
    }),
  }),
  roleSummary: object().when("isEmployed", {
    is: true,
    then: object({
      value: string().required("Role summary field is required"),
    }),
  }),
  sponsorshipType: string().when(["isSponsored", "isEmployed"], {
    is: true,
    then: string().required("SponsorshipType field is required"),
  }),
});

// Add Staff Validation Schema
export const addStaffSchema = object().shape({
  firstName: string().required("First name field is required"),
  lastName: string().required("Last name field is required"),
  email: string()
    .required("Email field is required")
    .email("Enter a valid email address"),
  empRole: string().required("Organization role field is required"),
  roleSummary: string()
    .required("Role summary field is required")
    .min(80, "Role summary must be greater than or equal 80 characters")
    .max(100, "Role Summary must be less or equal to 100 characters"),
});

// Add Training Validation Schema
export const addTrainingSchema = object().shape({
  title: string().required("Title field is required"),
  summary: string()
    .required("Summary field is required")
    .min(80, "Summary must be greater than or equal 80 characters")
    .max(100, "Summary must be less or equal to 100 characters"),
  description: string().required("Description field is required"),
});

// Add Subject Validation Schema
export const addSubjectSchema = object().shape({
  thumbnail: mixed().required("Thumbnail field is required"),
  name: string().required("Name field is required"),
  tutorId: string().required("Author name field is required"),
  examDate: date()
    .typeError("Exam date must be a date")
    .required("Exam date field is required"),
  onlinePrice: number()
    .typeError("Online price per subject must be a number")
    .required("Online price per subject is required")
    .positive("Online price per subject must be a positive number"),
  offlinePrice: number()
    .typeError("Offline price per subject must be a number")
    .required("Offline price per subject is required")
    .positive("Offline price per subject must be a positive number"),
});

// Add Level Validation Schema
export const addLevelSchema = object().shape({
  name: string().required("Level name field is required"),
  pricePerMonthOnline: number()
    .typeError("Online price per month must be a number")
    .required("Online price per month is required")
    .positive("Online price per month must be a positive number"),
  pricePerMonthOffline: number()
    .typeError("Offline price per month must be a number")
    .required("Offline price per month is required")
    .positive("Offline price per month must be a positive number"),
  pricePerQuarterOnline: number()
    .typeError("Online price per quarter must be a number")
    .required("Online price per quarter is required")
    .positive("Online price per quarter must be a positive number"),
  pricePerQuarterOffline: number()
    .typeError("Offline price per quarter must be a number")
    .required("Offline price per quarter is required")
    .positive("Offline price per quarter must be a positive number"),
  pricePerYearOnline: number()
    .typeError("Online price per year must be a number")
    .required("Online price per year is required")
    .positive("Online price per year must be a positive number"),
  pricePerYearOffline: number()
    .typeError("Offline price per year must be a number")
    .required("Offline price per year is required")
    .positive("Offline price per year must be a positive number"),
});

// Add Chapter Validation Schema
export const addChapterSchema = object().shape({
  name: string().required("Chapter name field is required"),
});

// Add Private Session Validation Schema
export const addPrivateSessionSchema = object().shape({
  name: string().required("Event name field is required"),
  description: string()
    .required("Event description field is required")
    .min(80, "Event description must be greater than or equal 80 characters")
    .max(110, "Event description must be less or equal to 110 characters"),
  price: number()
    .typeError("Support price must be a number")
    .required("Support price field is required")
    .positive("Support price must be a positive number"),
  tutorId: string().required("Support staff field is required"),
  availabilityDates: array()
    .required("Event dates field is required")
    .min(1, "Event dates field must have atleast one date and one time"),
});

// Private Session Booking Validation Schema
export const addPrivateSessionBookingSchema = object().shape({
  meetingTitle: string().required("Meeting title field is required"),
  meetingReason: string().required("Meeting reason field is required"),
});

// Video Feedback Validation Schema
export const videoFeedbackSchema = object().shape({
  title: string().required("Feedback title field is required"),
  description: string()
    .required("Feedback description field is required")
    .min(10, "Feedback description field value must be >= 10 characters"),
});

// Contact Us Validation Schema
export const contactUsSchema = object().shape({
  fullName: string().required("Full name field is required"),
  email: string()
    .required("Email field is required")
    .email("Enter a valid email address"),
  phoneNumber: string().required("Phone number field is required"),
  message: string().required("Message field is required"),
  referrerMedium: string().required("Referrer medium is required"),
});
