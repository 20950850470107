// Modules
import React from "react";

// Hooks
import { useState } from "react";
import { useUi } from "../../../../hooks";

// Components
import { Dialog } from "@mui/material";
import Calendar from "react-calendar";
import { Button } from "../../../";

// Style
import "./dashboard-filter-modal.styles.scss";

export const DashboardFilterModal = ({
  className,
  chartFilterDate,
  setChartFilterDate,
  isYearFilter,
  open,
  onClose,
}) => {
  const { dispatch, showNotification } = useUi();
  const { from, to } = chartFilterDate;
  const [fromDate, setFromDate] = useState(from);
  const [toDate, setToDate] = useState(to);
  const fromPreviewValue = isYearFilter && fromDate.toString("yyyy");
  const toPreviewValue = isYearFilter ? toDate.toString("yyyy") : "";

  /**
   * Update Chart Filter Date
   */
  const handleUpdateChartFilterDate = () => {
    if (isYearFilter && fromPreviewValue === toPreviewValue) {
      return dispatch(
        showNotification({
          type: "error",
          message: "From/To year value must be different",
        })
      );
    }

    // Perform Update
    setChartFilterDate({ from: fromDate, to: toDate });
  };

  return (
    <Dialog
      className={`dashboard-filter-modal ${className}`}
      maxWidth="lg"
      open={open}
      onClose={onClose}
      aria-labelledby="Dashboard chart filter"
    >
      <div className="dashboard-filter-modal__preview">
        <h2 className="dashboard-filter-modal__preview-title">
          Filter By Date
        </h2>

        {isYearFilter && (
          <p className="dashboard-filter-modal__preview-text">
            <strong className="dashboard-filter-modal__preview-text--highlight">
              From:
            </strong>{" "}
            {fromPreviewValue}
          </p>
        )}

        <p className="dashboard-filter-modal__preview-text">
          <strong className="dashboard-filter-modal__preview-text--highlight">
            To:
          </strong>{" "}
          {toPreviewValue}
        </p>

        <Button
          wrapperClassName="d-block"
          className="dashboard-filter-modal__preview-btn btn--primary btn--rv1 btn--sv18 is-width-full"
          onClick={handleUpdateChartFilterDate}
        >
          Proceed
        </Button>
      </div>

      <div className="dashboard-filter-modal__calendar">
        {isYearFilter && (
          <div className="dashboard-filter-modal__from-calendar-wrapper">
            <h4 className="dashboard-filter-modal__from-calendar-title">
              From
            </h4>
            <Calendar
              className="dashboard-filter-modal__from-calendar"
              value={fromDate}
              onChange={setFromDate}
            />
          </div>
        )}

        <div className="dashboard-filter-modal__to-calendar-wrapper">
          <h4 className="dashboard-filter-modal__to-calendar-title">To</h4>
          <Calendar
            className="dashboard-filter-modal__to-calendar"
            value={toDate}
            onChange={setToDate}
          />
        </div>
      </div>
    </Dialog>
  );
};

DashboardFilterModal.defaultProps = {
  className: "",
};
