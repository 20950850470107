// Modules
import React from "react";

// Components
import { Link } from "react-router-dom";

// Style
import "./more-action-dropdown-item.styles.scss";

export const MoreActionDropdownItem = ({
  className,
  children,
  to,
  onClick,
}) => {
  return (
    <li
      className={`more-action-dropdown-item animate-3s ${className}`}
      onClick={onClick}
    >
      {!!to && (
        <Link className="more-action-dropdown-item__link" to={to}>
          {children}
        </Link>
      )}

      {!to && (
        <span className="more-action-dropdown-item__link">{children}</span>
      )}
    </li>
  );
};

MoreActionDropdownItem.defaultProps = {
  className: "",
};
