// Modules
import React from "react";

// Components
import { Form, Input, Button, InputCheckbox } from "../../../";

// Hooks
import { useValidateFormOnMouseEnter, useStaff } from "../../../../hooks";

export const AddStaffForm = ({ className, formik, isEditMode }) => {
  const { isStaffLoading } = useStaff();

  const {
    touched,
    values,
    errors,
    isValid,
    handleChange,
    handleBlur,
    setFieldValue,
    validateForm,
    setTouched,
    handleSubmit,
  } = formik;

  const { triggerFormValidation } = useValidateFormOnMouseEnter(
    validateForm,
    setTouched
  );

  return (
    <Form
      className={`add-staff-form container admin-form ${className}`}
      onSubmit={handleSubmit}
    >
      <Input
        className="add-staff-form__control admin-form__control"
        wrapperClassName="add-staff-form__group admin-form__group"
        labelClassName="add-staff-form__label"
        placeholder="First Name"
        name="firstName"
        id="firstName"
        value={values.firstName}
        error={errors.firstName}
        touched={touched.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Input
        className="add-staff-form__control admin-form__control"
        wrapperClassName="add-staff-form__group admin-form__group"
        labelClassName="add-staff-form__label"
        placeholder="Last Name"
        name="lastName"
        id="lastName"
        value={values.lastName}
        error={errors.lastName}
        touched={touched.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Input
        className="add-staff-form__control admin-form__control"
        wrapperClassName="add-staff-form__group admin-form__group"
        labelClassName="add-staff-form__label"
        placeholder="Email"
        name="email"
        id="email"
        value={values.email}
        error={errors.email}
        touched={touched.email}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Input
        className="add-staff-form__control admin-form__control"
        wrapperClassName="add-staff-form__group admin-form__group"
        labelClassName="add-staff-form__label"
        placeholder="Organization Role"
        name="empRole"
        id="organizationRole"
        value={values.empRole}
        error={errors.empRole}
        touched={touched.empRole}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Input
        type="textarea"
        className="add-staff-form__control admin-form__control"
        wrapperClassName="add-staff-form__group admin-form__group"
        labelClassName="add-staff-form__label"
        placeholder="Role Summary"
        name="roleSummary"
        id="summary"
        value={values.roleSummary}
        error={errors.roleSummary}
        touched={touched.roleSummary}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Input
        type="upload"
        className="add-staff-form__control admin-form__control admin-form__control--upload"
        wrapperClassName="add-staff-form__group add-staff-form__group--upload admin-form__group"
        labelClassName="add-staff-form__label admin-form__label"
        placeholder="Upload"
        label="Upload image"
        name="profileImage"
        id="profileImage"
        value={values.profileImage}
        error={errors.profileImage}
        touched={touched.profileImage}
        onChange={setFieldValue}
        isInline
      />

      <InputCheckbox
        className="add-staff-form__control admin-form__control admin-form__control--checkbox"
        wrapperClassName="add-staff-form__group admin-form__group admin-form__group--checkbox"
        labelClassName="add-staff-form__label admin-form__label admin-form__label--checkbox"
        label="Invite as an admin"
        name="sendAdminInvitation"
        id="sendAdminInvitation"
        checked={values.sendAdminInvitation}
        touched={touched.sendAdminInvitation}
        error={errors.sendAdminInvitation}
        onChange={handleChange}
      />

      <InputCheckbox
        className="add-staff-form__control admin-form__control admin-form__control--checkbox"
        wrapperClassName="add-staff-form__group admin-form__group admin-form__group--checkbox"
        labelClassName="add-staff-form__label admin-form__label admin-form__label--checkbox"
        label="Is featured"
        name="isFeatured"
        id="isFeatured"
        checked={values.isFeatured}
        touched={touched.isFeatured}
        error={errors.isFeatured}
        onChange={handleChange}
      />

      <div className="add-staff-form__btn-group admin-form__btn-group">
        <Button
          className="add-staff-form__btn admin-form__btn btn--primary btn--rv1 btn--sv9"
          type="submit"
          disabled={!isValid || isStaffLoading}
          loading={isStaffLoading}
          loadingHeight="26"
          onMouseEnter={() => triggerFormValidation(isValid)}
        >
          {isEditMode ? "Update" : "SignUp"}
        </Button>
      </div>
    </Form>
  );
};

AddStaffForm.defaultProps = {
  className: "",
};
