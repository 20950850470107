// Redux
import {
  resetStaffErrorMessage,
  setStaffErrorMessage,
  setStaffs,
  setStaffStat,
  staffStartLoading,
  staffStopLoading,
  delStaff,
} from ".";
import { uiSA, authSA } from "..";

// Utils
import { getSuccess, History } from "../../utils";

// Apis
import * as apis from "../../apis";

// Destructure Redux Imports
const { showNotification } = uiSA;
const { logout } = authSA;

/**
 * Get All Staffs And Stat
 */
export const getStaffsAndStat = () => async (dispatch) => {
  try {
    // Reset Error Message
    dispatch(resetStaffErrorMessage());

    // Start Loading
    dispatch(staffStartLoading());

    // Get Staffs And Stat
    const { staffs, stat } = await apis.getStaffsAndStat();

    // Update Staffs
    dispatch(setStaffs(staffs));

    // Update Stat
    dispatch(setStaffStat(stat));
  } catch (err) {
    // Update Error Message
    dispatch(setStaffErrorMessage(err.message.message));
  } finally {
    // Stop Loading
    dispatch(staffStopLoading());
  }
};

/**
 * Get Featured Staffs
 * @param {boolean} throwNotFound If true throw not found error
 */
export const getFeaturedStaffs =
  (throwNotFound = true) =>
  async (dispatch) => {
    try {
      // Reset Error Message
      dispatch(resetStaffErrorMessage());

      // Start Loading
      dispatch(staffStartLoading());

      // Get Featured Staffs
      const featuredStaffs = await apis.getFeaturedStaffs(throwNotFound);

      // Update Staffs
      dispatch(setStaffs(featuredStaffs));
    } catch (err) {
      // Update Error Message
      dispatch(setStaffErrorMessage(err.message.message));
    } finally {
      // Stop Loading
      dispatch(staffStopLoading());
    }
  };

/**
 * Create A New Staff
 * @param {object} staffData Staff data
 */
export const createStaff = (staffData) => async (dispatch) => {
  try {
    // Reset Error Message
    dispatch(resetStaffErrorMessage());

    // Start Loading
    dispatch(staffStartLoading());

    // Create Staff
    const { staffs, stat } = await apis.createStaff(staffData);

    // Update Staffs
    dispatch(setStaffs(staffs));

    // Update Stat
    dispatch(setStaffStat(stat));

    // Show Success Notification On Creating New Staff
    dispatch(showNotification(getSuccess("created")));

    // Navigate To Staffs
    History.push("/admin/staffs");
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  } finally {
    // Stop Loading
    dispatch(staffStopLoading());
  }
};

/**
 * Update A Staff
 * @param {string} staffId Staff id
 * @param {object} staffData Staff data
 */
export const updateStaff = (staffId, staffData) => async (dispatch) => {
  try {
    // Reset Error Message
    dispatch(resetStaffErrorMessage());

    // Start Loading
    dispatch(staffStartLoading());

    // Update Staff
    const { staffs, stat } = await apis.updateStaff(staffId, staffData);

    // Update Staffs
    dispatch(setStaffs(staffs));

    // Update Stat
    dispatch(setStaffStat(stat));

    // Show Success Notification On Updating Staff
    dispatch(showNotification(getSuccess("updated")));

    // Navigate To Staffs
    History.push("/admin/staffs");
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  } finally {
    // Stop Loading
    dispatch(staffStopLoading());
  }
};

/**
 * Delete A Staff
 * @param {string} staffId Staff id
 */
export const deleteStaff = (staffId) => async (dispatch, getState) => {
  try {
    // Get User From State
    const { user } = getState().auth;

    // Delete Staff
    const stat = await apis.deleteStaff(staffId);

    // Delete Staff In Redux Store
    dispatch(delStaff(staffId));

    // Update Stat
    dispatch(setStaffStat(stat));

    // Show Success Notification On Deleting Staff
    dispatch(showNotification(getSuccess("deleted")));

    // If Deleted Staff Is The LoggedIn User, Then Logout The User
    staffId === user.uid && dispatch(logout());
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  }
};
