// Modules
import React from "react";

// Components
import { Fragment } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  Paper,
} from "@mui/material";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { InputCheckbox } from "../";

// Hooks
import { useState } from "react";

// Style
import "./subject-table.styles.scss";

const Row = ({
  subject,
  id,
  priceType,
  isDisabled,
  isActive,
  enableSelection,
  onSelect,
}) => {
  const [collapseOpen, setCollapseOpen] = useState(false);

  /**
   * Toggle Table Collapse Visibility
   */
  const toggleTableCollapseVisibility = () => setCollapseOpen(!collapseOpen);

  return (
    <Fragment>
      <TableRow>
        {enableSelection && (
          <TableCell
            className="subject-table__body-cell"
            align="center"
            padding="checkbox"
          >
            <InputCheckbox
              className="subject-table__checkbox"
              checked={isActive}
              disabled={isDisabled}
              onChange={onSelect.bind(null, id)}
            />
          </TableCell>
        )}
        <TableCell className="subject-table__body-cell" align="center">
          {subject.name}
        </TableCell>
        <TableCell className="subject-table__body-cell" align="center">
          Exam Date: {new Date(subject.examDate.toDate()).toLocaleDateString()}
        </TableCell>
        <TableCell className="subject-table__body-cell" align="center">
          {subject.chapters.length} Chapters
        </TableCell>
        <TableCell className="subject-table__body-cell" align="center">
          {subject.totalDuration}
        </TableCell>
        <TableCell className="subject-table__body-cell" align="center">
          ${subject.price[priceType]}
        </TableCell>
        <TableCell className="subject-table__body-cell" align="center">
          <IconButton
            className="subject-table__body-icon-btn"
            size="medium"
            onClick={toggleTableCollapseVisibility}
          >
            {collapseOpen ? (
              <MdKeyboardArrowUp className="subject-table__body-icon" />
            ) : (
              <MdKeyboardArrowDown className="subject-table__body-icon" />
            )}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
            <Table>
              <TableBody>
                {subject?.chapters.map((chapter) => (
                  <TableRow key={chapter.id}>
                    <TableCell
                      className="subject-table__collapse-cell"
                      align="center"
                    >
                      {chapter.name}
                    </TableCell>
                    <TableCell
                      className="subject-table__collapse-cell"
                      align="center"
                    >
                      {chapter?.totalVideos} Videos &{" "}
                      {chapter?.totalPdf > 1 ? chapter.totalPdf : ""} PDF Guide
                      Work
                    </TableCell>
                    <TableCell
                      className="subject-table__collapse-cell"
                      align="center"
                    >
                      {chapter.totalDuration}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export const SubjectTable = ({
  className,
  subjects,
  disabledSubjects,
  enableSelection,
  selectedSubjects,
  setSelectedSubjects,
  priceType,
}) => {
  const totalSubjects = subjects.length - disabledSubjects.length;
  const selectedSubjectCount = selectedSubjects.length;
  const isAllSubjectSelected =
    totalSubjects > 0 && selectedSubjectCount === totalSubjects;
  const isAtleastASubjectSelected =
    selectedSubjectCount > 0 && selectedSubjectCount < totalSubjects;

  /**
   * Handle Select All Subjects
   * @param {object} event Event
   */
  const handleSelectAllSubject = (event) => {
    // If Checkbox Is Checked Then Add All Subject To Selected Subjects, Without The Disabled Subjects
    if (event.target.checked) {
      const newSelected = subjects
        .map((subject) =>
          disabledSubjects.includes(subject.id) ? undefined : subject.id
        )
        .filter((subjectId) => typeof subjectId !== "undefined");
      return setSelectedSubjects(newSelected);
    }
    // Else Remove All Subject From Selection
    setSelectedSubjects([]);
  };

  /**
   * Handle Select Single Subject
   * @param {string} id Subject Id
   */
  const handleSelectSubject = (id) => {
    // If Subject With The Id Already Selected, Then Remove Id From Selection
    if (selectedSubjects.includes(id)) {
      setSelectedSubjects((prevState) =>
        prevState.filter((subjectId) => subjectId !== id)
      );
    } else {
      // Else, Add Id To Selection
      setSelectedSubjects((prevState) => [...prevState, id]);
    }
  };

  /**
   * Check Whether A Subject Is Active Or Not
   * @param {string} id Subject Id
   */
  const isSubjectActive = (id) => {
    return selectedSubjects.includes(id) || disabledSubjects.includes(id);
  };

  /**
   * Check Whether A Subject Is Disabled Or Not
   * @param {string} id Subject Id
   */
  const isSubjectDisabled = (id) => {
    return disabledSubjects.includes(id);
  };

  return (
    <TableContainer className={`subject-table ${className}`} component={Paper}>
      <Table className="subject-table__container" sx={{ minWidth: 900 }}>
        <TableHead className="subject-table__head">
          <TableRow>
            {enableSelection && (
              <TableCell
                align="center"
                className="subject-table__head-cell"
                padding="checkbox"
              >
                <InputCheckbox
                  className="subject-table__head-checkbox"
                  indeterminate={isAtleastASubjectSelected}
                  checked={isAllSubjectSelected}
                  onChange={handleSelectAllSubject}
                />
              </TableCell>
            )}

            <TableCell align="center" className="subject-table__head-cell">
              Courses
            </TableCell>
            <TableCell className="subject-table__head-cell" align="center">
              Exam Date
            </TableCell>
            <TableCell className="subject-table__head-cell" align="center">
              Course Chapters
            </TableCell>
            <TableCell className="subject-table__head-cell" align="center">
              Completion Time
            </TableCell>
            <TableCell className="subject-table__head-cell" align="center">
              Price
            </TableCell>
            <TableCell className="subject-table__head-cell" align="center">
              View Chapters
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody className="subject-table__body">
          {subjects.map((subject) => (
            <Row
              className="subject-table__body-row"
              key={subject.id}
              id={subject.id}
              subject={subject}
              priceType={priceType}
              enableSelection={enableSelection}
              isActive={isSubjectActive(subject.id)}
              isDisabled={isSubjectDisabled(subject.id)}
              onSelect={handleSelectSubject}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

SubjectTable.defaultProps = {
  className: "",
  subjects: [],
  disabledSubjects: [],
  selectedSubjects: [],
  enableSelection: false,
  priceType: "online",
};
