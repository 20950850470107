// Modules
import React from "react";

// Components
import {
  Levels,
  Button,
  SubjectTable as PricingSubjectTable,
  EmptyContent,
} from "../../";

// Hooks
import { useState, useEffect } from "react";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import { useLevel, useSubject, useSubscription, useAuth } from "../../../hooks";

// Style
import "./pricing-subject-base.styles.scss";

export const PricingSubjectBase = ({ className }) => {
  const { levels, isLevelLoading } = useLevel();
  const {
    subjects,
    isSubjectLoading,
    dispatch,
    subjectErrorMessage,
    getLevelSubjectsAndStat,
  } = useSubject();
  const { user } = useAuth();
  const {
    getStudentLevelPayPerSubjectSubscriptions,
    payPerSubjectSubscriptions,
    isSubscriptionlLoading,
  } = useSubscription();
  const [activeLevelTab, setActiveLevelTab] = useState();
  const [activeLevel, setActiveLevel] = useState();
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [disabledSubjects, setDisabledSubjects] = useState([]);
  const { trainingId } = useParams();
  const [searchParams] = useSearchParams();
  const studyMethod = searchParams.get("studyMethod") || "online";
  const navigate = useNavigate();

  useEffect(() => {
    // If Level Exist And Level Is Not Loading, Update Active Level Tab
    if (levels.length && !isLevelLoading) {
      setActiveLevelTab(levels[0]?.id);
    }
  }, [levels]);

  useEffect(() => {
    // Anytime Active Level Tab Changes Then Update Active Level
    const foundLevel = levels.find((level) => level.id === activeLevelTab);
    foundLevel && setActiveLevel(foundLevel);

    // Get Level Subjects, AnyTime Active Level Tab Changes
    activeLevelTab && dispatch(getLevelSubjectsAndStat(activeLevelTab));

    // Get Student Level PayPerSubject Subjscription, AnyTime Active Level Tab Changes
    activeLevelTab &&
      dispatch(
        getStudentLevelPayPerSubjectSubscriptions(user?.uid, activeLevelTab)
      );

    // Reset Selected Subjects, AnyTime Active Level Tab Changes
    setSelectedSubjects([]);

    // Reset Disabled Subjects, AnyTime Active Level Tab Changes
    setDisabledSubjects([]);
  }, [activeLevelTab]);

  useEffect(() => {
    // If PayPerSubjectSubscriptions Exist And Subscription Is Not Loading, Then Update Disabled Subjects
    if (payPerSubjectSubscriptions.length && !isSubscriptionlLoading) {
      setDisabledSubjects(
        payPerSubjectSubscriptions.map((subscription) =>
          subscription.subjectId.id.trim()
        )
      );
    }
  }, [payPerSubjectSubscriptions]);

  /**
   * Get A Subject By Id
   * @param {string} subjectId Subject Id
   */
  const getSubject = (subjectId) => {
    return subjects.find((subject) => subjectId === subject.id);
  };

  /**
   * Get Enrollment Cart Items
   */
  const getEnrollmentCartItems = () => {
    return selectedSubjects.map((subjectId) => ({
      studyMethod,
      trainingId,
      levelId: activeLevelTab,
      subjectId,
    }));
  };

  /**
   * Get Total Selected Subject Price
   */
  const getTotalSelectedSubjectPrice = () => {
    return selectedSubjects.reduce(
      (sum, subjectId) => sum + getSubject(subjectId).price[studyMethod],
      0
    );
  };

  /**
   * On Enroll Student
   */
  const onEnrollStudent = () => {
    // Get Cart Items
    const cartItems = getEnrollmentCartItems();

    // Perform Checkout
    navigate(
      `/pricing/${trainingId}/subjects/checkout?studyMethod=${studyMethod}`,
      {
        state: {
          cart: {
            items: cartItems,
            paymentType: "oneTime",
            totalPrice: getTotalSelectedSubjectPrice(),
          },
        },
      }
    );
  };

  return (
    <div className={`pricing-subject-base ${className}`}>
      <div className="pricing-subject-base__container container">
        <div className="pricing-subject-base__top">
          <Levels
            className="pricing-subject-base__levels"
            items={levels}
            activeLevel={activeLevelTab}
            setActiveLevel={setActiveLevelTab}
          />

          <div className="pricing-subject-base__checkout">
            <p className="pricing-subject-base__price">
              Total Cost{" "}
              <span className="pricing-subject-base__price-amount">
                ${getTotalSelectedSubjectPrice()}
              </span>
            </p>
            <Button
              className="pricing-subject-base__btn btn--primary btn--rv1 btn--sv15"
              onClick={onEnrollStudent}
              disabled={!selectedSubjects.length}
            >
              Checkout
            </Button>
          </div>
        </div>

        <div className="pricing-subject-base__bottom">
          <h3 className="pricing-subject-base__heading">
            {activeLevel?.name} Subject
          </h3>

          <EmptyContent
            className="pricing-subject-base__empty"
            isLoading={isSubjectLoading || isSubscriptionlLoading}
            errorMessage={subjectErrorMessage}
          >
            <PricingSubjectTable
              className="pricing-subject-base__table-container"
              subjects={subjects}
              enableSelection
              priceType={studyMethod}
              selectedSubjects={selectedSubjects}
              disabledSubjects={disabledSubjects}
              setSelectedSubjects={setSelectedSubjects}
            />
          </EmptyContent>
        </div>
      </div>
    </div>
  );
};

PricingSubjectBase.defaultProps = {
  className: "",
};
