// Modules
import React from "react";

// Hooks
import { useEffect } from "react";
import { useUrlAsTitle, useTraining } from "../../../hooks";

// Components
import { Outlet } from "react-router-dom";
import { TrainingStatsBase, TrainingBase } from "../../../components";

export const Training = () => {
  // Set Dashboard Page Title
  useUrlAsTitle("Trainings");
  const { getTrainingsAndStat, dispatch } = useTraining();

  useEffect(() => {
    // Get Trainings And Stat
    dispatch(getTrainingsAndStat());
  }, []);

  return (
    <div className="training is-height-full">
      <div className="training__container is-height-full">
        <TrainingStatsBase />
        <TrainingBase />
        <Outlet />
      </div>
    </div>
  );
};
