// Hooks
import { useSelector, useDispatch } from "react-redux";

// Redux
import { chapterFileSA } from "../store";

// Destructure Redux Imports
const {
  selectChapterFileErrorMessage,
  selectChapterFiles,
  selectIsChapterFileLoading,
  selectActiveChapterFile,
  selectChapterFileStat,
} = chapterFileSA;

/**
 * ChapterFile Hook
 */
export const useChapterFile = () => {
  const dispatch = useDispatch();
  const chapterFiles = useSelector(selectChapterFiles);
  const isChapterFileLoading = useSelector(selectIsChapterFileLoading);
  const chapterFileErrorMessage = useSelector(selectChapterFileErrorMessage);
  const activeChapterFile = useSelector(selectActiveChapterFile);
  const chapterFileStat = useSelector(selectChapterFileStat);

  return {
    chapterFiles,
    isChapterFileLoading,
    chapterFileErrorMessage,
    activeChapterFile,
    chapterFileStat,
    dispatch,
    ...chapterFileSA,
  };
};
