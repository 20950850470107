// Modules
import React from "react";

// Style
import "./pricing-card.styles.scss";

export const PricingCard = ({
  className,
  price,
  content,
  children,
  hidden,
  ...otherProps
}) => {
  return (
    <div
      className={`pricing-card ${className} ${hidden ? "d-none" : ""}`}
      {...otherProps}
    >
      <p className="pricing-card__price">{price}</p>
      <div className="pricing-card__right">
        <p className="pricing-card__content">{content}</p>
        {children}
      </div>
    </div>
  );
};

PricingCard.defaultProps = {
  className: "",
};
