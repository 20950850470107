// Modules
import React from "react";

// Hooks
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { usePrivateSession } from "../../../hooks";

// Components
import {
  ModalOverlay,
  Modal,
  BookPrivateSessionInfo,
  BookPrivateSessionForm,
  EmptyContent,
} from "../../";

// Schemas
import { addPrivateSessionBookingSchema } from "../../../schemas";

// Style
import "./book-private-session-modal-base.styles.scss";

export const BookPrivateSessionModalBase = () => {
  const [bookingAvailableDatesAndTime, setBookingAvailableDatesAndTime] =
    useState([]);
  const [activeMeetingDate, setActiveMeetingDate] = useState();
  const [isPrivateSessionLoading, setIsPrivateSessionLoading] = useState(true);
  const {
    dispatch,
    getPrivateSessionBookingAvailabilityDatesAndTime,
    privateSession,
    privateSessionErrorMessage,
    resetPrivateSessionErrorMessage,
  } = usePrivateSession();
  const { privateSessionId } = useParams();
  const navigate = useNavigate();

  const privateSessionFormik = useFormik({
    initialValues: {
      meetingTitle: "",
      meetingReason: "",
      meetingDate: null,
      meetingTime: null,
    },
    validationSchema: addPrivateSessionBookingSchema,
    onSubmit(values) {
      // Perform Checkout
      navigate(
        `/student/private-sessions/booking/${privateSessionId}/checkout`,
        {
          state: {
            cart: {
              items: {
                privateSessionId,
                ...values,
                bookedDate: {
                  date: values.meetingDate,
                  times: {
                    start: values.meetingTime.start,
                    end: values.meetingTime.end,
                  },
                },
              },
              paymentType: "support",
              totalPrice: privateSession.price,
            },
          },
        }
      );
    },
  });

  useEffect(() => {
    // Get Private Session Booking Availability Dates And Time, Also Populate Tutor Field
    (async () => {
      // Start Loading
      setIsPrivateSessionLoading(true);

      await dispatch(
        getPrivateSessionBookingAvailabilityDatesAndTime(privateSessionId, true)
      );

      // Stop Loading
      setIsPrivateSessionLoading(false);
    })();

    return () => dispatch(resetPrivateSessionErrorMessage());
  }, []);

  useEffect(() => {
    /**
     * Get Closest Booking Available Date Starting From Today
     * @param {array} availabilityDates Available dates
     */
    const getClosestBookingAvailableDate = (availabilityDates) => {
      const now = Math.floor(Date.today().clearTime().getTime() / 1000);
      return availabilityDates.reduce((closestBookingDate, date) => {
        const delta = Math.abs(now - date.date);
        const closestDelta = Math.abs(now - closestBookingDate.date);
        return delta < closestDelta ? date : closestBookingDate;
      }, availabilityDates[0]);
    };

    // If Private Session Exist
    if (privateSession) {
      const { availabilityDates } = privateSession;

      // Set Booking Available Dates And Time
      setBookingAvailableDatesAndTime(availabilityDates);

      // Get Closest Available Date Start From Today
      const closestAvailableDate =
        getClosestBookingAvailableDate(availabilityDates);

      // Set Initial Meeting Date
      privateSessionFormik.setFieldValue(
        "meetingDate",
        closestAvailableDate.date
      );

      // Update Active Meeting Date
      setActiveMeetingDate(closestAvailableDate);
    } else {
      setBookingAvailableDatesAndTime([]);
    }
  }, [privateSession]);

  useEffect(() => {
    if (activeMeetingDate) {
      // Set Initial Meeting Time
      privateSessionFormik.setFieldValue(
        "meetingTime",
        activeMeetingDate.times[0]
      );
    }
  }, [activeMeetingDate]);

  return (
    <ModalOverlay
      className="book-private-session-modal-base-overlay"
      to="/student/private-sessions"
    >
      <Modal className="book-private-session-modal-base">
        <EmptyContent
          className="book-private-session-modal-base__empty"
          isLoading={isPrivateSessionLoading}
          errorMessage={privateSessionErrorMessage}
        >
          <div className="book-private-session-modal-base__content">
            <BookPrivateSessionInfo
              className="book-private-session-modal-base__info"
              privateSession={privateSession}
            />

            <BookPrivateSessionForm
              className="book-private-session-modal-base__form"
              formik={privateSessionFormik}
              activeMeetingDate={activeMeetingDate}
              setActiveMeetingDate={setActiveMeetingDate}
              bookingAvailableDatesAndTime={bookingAvailableDatesAndTime}
            />
          </div>
        </EmptyContent>
      </Modal>
    </ModalOverlay>
  );
};

BookPrivateSessionModalBase.defaultProps = {
  className: "",
};
