// Modules
import React from "react";

// Hooks
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { useTraining } from "../../../../hooks";

// Components
import { ModalOverlay, Modal, EmptyContent, AddTrainingForm } from "../../../";

// Schemas
import { addTrainingSchema } from "../../../../schemas";

export const AddTrainingModalBase = ({ className }) => {
  const { trainingId } = useParams();
  const {
    trainings,
    isTrainingLoading,
    createTraining,
    updateTraining,
    dispatch,
  } = useTraining();
  const [errorMessage, setErrorMessage] = useState();
  const [training, setTraining] = useState();
  const isEditMode = trainingId !== "1";

  // Add Training Formik
  const addTrainingFormik = useFormik({
    initialValues: {
      title: "",
      summary: "",
      description: "",
    },
    validationSchema: addTrainingSchema,
    async onSubmit(values) {
      // Creating Training Data
      const trainingData = { ...values };

      // Create New Training If isEditMode Is False, Else Update Training
      if (!isEditMode) {
        await dispatch(createTraining(trainingData));
      } else {
        await dispatch(updateTraining(trainingId, trainingData));
      }
    },
  });

  useEffect(() => {
    // Create Error Message
    const errorMessage = "No Training Available With The Provided Id.";

    // Get Training If Training Is Not Loading And Edit Mode Is True
    if (!isTrainingLoading && isEditMode) {
      // Get Training By Training Id
      const training = trainings.find((training) => training.id === trainingId);

      // Set Error Message If Training Is Undefined, Else Update Training
      !training ? setErrorMessage(errorMessage) : setTraining(training);
    }
  }, [trainings]);

  useEffect(() => {
    // If Training Exist And Edit Mode Is True, Update Formik State
    if (training && isEditMode) {
      addTrainingFormik.setValues({
        title: training.title,
        summary: training.summary,
        description: training.description,
      });
    }
  }, [training]);

  return (
    <ModalOverlay
      className={`add-training-modal-base-overlay ${className}`}
      to="/admin/trainings"
    >
      <Modal className="add-training-modal-base">
        <EmptyContent
          className="add-training-modal-base__empty"
          isLoading={!addTrainingFormik.isSubmitting && isTrainingLoading}
          errorMessage={errorMessage}
        >
          <AddTrainingForm
            className="add-training-modal-base__form"
            formik={addTrainingFormik}
            isEditMode={isEditMode}
          />
        </EmptyContent>
      </Modal>
    </ModalOverlay>
  );
};

AddTrainingModalBase.defaultProps = {
  className: "",
};
