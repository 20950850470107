// Modules
import React from "react";
import { getFile } from "easy-file-picker";

// Images
import uploadIcon from "../../../assets/images/upload-icon.svg";

// Style
import "./input-upload.styles.scss";

export const InputUpload = ({
  className,
  id,
  name,
  placeholder,
  acceptedExtensions,
  onChange,
}) => {
  /**
   * Pick File From File Explorer
   */
  const onPickFile = async () => {
    const file = await getFile({
      acceptedExtensions,
    });
    onChange(name, file);
  };

  return (
    <div
      id={id}
      className={`input-upload-box ${className}`}
      onClick={onPickFile}
    >
      <p className="input-upload-box__placeholder">{placeholder}</p>
      <img
        className="input-upload-box__icon"
        src={uploadIcon}
        alt="upload"
        width="20px"
        height="20px"
      />
    </div>
  );
};

InputUpload.defaultProps = {
  className: "",
  id: "",
  acceptedExtensions: ["image/png", "image/jpeg", "image/jpg", "image/webp"],
};
