/**
 * Get Image As Base64
 * @param {File} file File object
 */
export const getImageAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (file instanceof File) {
      // Create New File Reader
      const reader = new FileReader();

      // Read File To Base64
      reader.readAsDataURL(file);

      reader.addEventListener("loadend", (e) => {
        // Resolve With Result
        resolve(e.target.result);
      });

      reader.addEventListener("error", () =>
        // Reject With Custom Error
        reject("Could not read file to base64")
      );
    } else {
      resolve(null);
    }
  });
};
