// Modules
import React from "react";

// Images
import pricingTagMarkIcon from "../../../../../assets/images/pricing-tag-mark-icon.svg";

// Style
import "./pricing-tag.styles.scss";

export const PricingTag = ({ className, content }) => {
  return (
    <div className={`pricing-tag-item ${className}`}>
      <img
        className="pricing-tag-item__icon"
        src={pricingTagMarkIcon}
        alt="pricing tag check"
      />
      {content}
    </div>
  );
};

PricingTag.defaultProps = {
  className: "",
};
