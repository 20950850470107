// Modules
import React from "react";

// Components
import { HomeServicesList } from "../../";

// Style
import "./home-services-base.styles.scss";

// Static Data
import { HOME_SERVICE_ITEMS } from "../../../data";

export const HomeServicesBase = ({ className }) => {
  return (
    <section className={`home-services-base ${className}`}>
      <div className="home-services-base__container container">
        <h2 className="home-services-base__heading primary-heading is-text-accent-300 is-text-center">
          Our <span className="is-text-accent-400">Services</span>
        </h2>

        <HomeServicesList
          className="home-services-base__list"
          items={HOME_SERVICE_ITEMS}
        />
      </div>
    </section>
  );
};

HomeServicesBase.defaultProps = {
  className: "",
};
