// Modules
import React from "react";

// Hooks
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../hooks";

// Components
import { NavLink, Link } from "react-router-dom";
import { MdMenu } from "react-icons/md";
import { Button } from "../";

// Images
import { ReactComponent as FinaccoLogo } from "../../../assets/images/finacco-logo.svg";

// Data
import { NAVIGATION_ITEMS } from "../../../data";

// Style
import "./navbar.styles.scss";

export const Navbar = ({ className }) => {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const [isIntersectingClass, setIsIntersectingClass] = useState("");
  const { isLoggedIn, user } = useAuth();
  const { pathname } = useLocation();

  useEffect(() => {
    const isHomePage = pathname === "/";
    const targetElement = document.querySelector(".home-hero");

    /**
     * Intersection Observer Callback
     * @param {array} entries Threshold entries
     */
    const observerCallback = ([entry]) => {
      setIsIntersectingClass((prevValue) => {
        if (
          (!prevValue && !entry.isIntersecting) ||
          (prevValue && !entry.isIntersecting)
        ) {
          return "navbar--intersecting";
        }

        if (prevValue && entry.isIntersecting) {
          return "navbar--intersecting-return";
        }
      });
    };

    // Create The Observer
    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      threshold: 0,
    });

    // Observe If It Is Home Page
    isHomePage && targetElement && observer.observe(targetElement);

    return () => {
      // Un-Observe/Reset IntersectingClass If Page Is Not Home Page
      if (targetElement) {
        observer.unobserve(targetElement);
        setIsIntersectingClass("");
      }
    };
  }, [pathname]);

  // Navigation Items
  const renderedNavigationItems = NAVIGATION_ITEMS.map(
    ({ to, content, id, enableActive }) => (
      <li key={id} className="navbar__item">
        <NavLink
          to={to}
          className={({ isActive }) =>
            isActive && enableActive
              ? "is-text-primary-color navbar__link animate-3s"
              : "navbar__link animate-3s"
          }
        >
          {content}
        </NavLink>
      </li>
    )
  );

  /**
   * Toggle Navigation Visibility For Mobile Screen
   */
  const toggleNavigationVisibility = () => {
    setIsNavigationOpen(!isNavigationOpen);
  };

  return (
    <nav
      className={`navbar ${
        isNavigationOpen && "navbar--open"
      } ${isIntersectingClass} ${className}`}
    >
      <div className="navbar__container container">
        <div className="navbar__menu">
          <MdMenu
            className="navbar__menu-icon"
            arial-label="toggle navigation visibility"
            onClick={toggleNavigationVisibility}
          />
        </div>

        <div className="navbar__brand">
          <Link className="navbar__brand-link" to="/">
            <FinaccoLogo
              className="navbar__brand-logo"
              aria-label="Finacco Logo"
            />
          </Link>
        </div>

        <ul
          className={`navbar__list animate-3s ${
            isNavigationOpen ? "box-open" : "box-close--md"
          }`}
          onClick={toggleNavigationVisibility}
        >
          {renderedNavigationItems}

          <li className="navbar__item">
            {!isLoggedIn && (
              <div className="navbar__btn-group">
                <Button
                  className="navbar__link-btn btn--dark btn--rv2 btn--sv4"
                  to="/auth/signup"
                >
                  SignUp
                </Button>

                <Button
                  className="navbar__link-btn btn--primary btn--rv2 btn--sv4"
                  to="/auth/login"
                >
                  Log In
                </Button>
              </div>
            )}

            {isLoggedIn && user?.role === 2 && (
              <Button
                className="navbar__link-btn btn btn--primary btn--rv2 btn--sv3"
                to={`/admin/students`}
              >
                Dashboard
              </Button>
            )}

            {isLoggedIn && user?.role === 1 && (
              <Button
                className="navbar__link-btn btn--dark btn--rv2 btn--sv4"
                to="/student/course-catalog"
              >
                My Course
              </Button>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
};

Navbar.defaultProps = {
  className: "",
};
