// Modules
import React from "react";

// Hooks
import { useLevel } from "../../../hooks";

// Utils
import { getTrainingAcronym } from "../../../utils";

// Style
import "./pricing-subject-hero.styles.scss";

export const PricingSubjectHero = ({ className }) => {
  const { levels } = useLevel();
  const training = levels[0]?.training;

  return (
    <div className={`pricing-subject-hero ${className}`}>
      <div className="pricing-subject-hero__container container">
        <h2 className="pricing-subject-hero__heading">
          CHOOSE {getTrainingAcronym(training?.title || "")} COURSE
        </h2>
      </div>
    </div>
  );
};

PricingSubjectHero.defaultProps = {
  className: "",
};
