// Modules
import React from "react";

// Components
import { StatsList, StatsCard } from "../../../";

// Hooks
import { useBooking } from "../../../../hooks";

// Utils
import { formatToCompactNumber } from "../../../../utils";

export const BookingStatsBase = ({ className }) => {
  const {
    bookingStat: {
      totalBookings,
      currentMonthCompletedBookingCount,
      currentMonthPendingBookingCount,
    },
  } = useBooking();

  return (
    <div className={`booking-stats-base ${className}`}>
      <div className="booking-stats-base__container">
        <StatsList className="booking-stats-base__list">
          <StatsCard
            className="booking-stats-base__card"
            title={`${formatToCompactNumber(
              currentMonthPendingBookingCount
            )} Pending`}
            subtitle="This Month"
          />

          <StatsCard
            className="booking-stats-base__card"
            title={`${formatToCompactNumber(
              currentMonthCompletedBookingCount
            )} Completed`}
            subtitle="This Month"
          />

          <StatsCard
            className="booking-stats-base__card"
            title={`${formatToCompactNumber(totalBookings)} Total Booking`}
            subtitle="In Total"
            isSecondary
          />
        </StatsList>
      </div>
    </div>
  );
};

BookingStatsBase.defaultProps = {
  className: "",
};
