// Hooks
import { useSelector, useDispatch } from "react-redux";

// Redux
import { chapterSA } from "../store";

// Destructure Redux Imports
const {
  selectActiveChapter,
  selectChapterErrorMessage,
  selectChapters,
  selectIsChapterLoading,
} = chapterSA;

/**
 * Chapter Hook
 */
export const useChapter = () => {
  const dispatch = useDispatch();
  const activeChapter = useSelector(selectActiveChapter);
  const chapterErrorMessage = useSelector(selectChapterErrorMessage);
  const chapters = useSelector(selectChapters);
  const isChapterLoading = useSelector(selectIsChapterLoading);

  return {
    activeChapter,
    chapterErrorMessage,
    chapters,
    isChapterLoading,
    dispatch,
    ...chapterSA,
  };
};
