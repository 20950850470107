// Modules
import { createSlice } from "@reduxjs/toolkit";

// Common Reducers
import { loadingReducers, errorMessageReducers } from "../common";

// Utils
import { stateSelector } from "../../utils";

// Initial State
const initialState = {
  isLoading: false,
  errorMessage: "",
  transactions: [],
  transactionStat: {
    totalTransactions: 0,
    currentMonthTotalTransactionCount: 0,
    currentMonthTotalSuccessTransactionCount: 0,
  },
  salesStat: {
    currentMonthStat: {
      currentMonthTransactionSales: 0,
      currentWeekTransactionSales: 0,
      currentDayTransactionSales: 0,
    },
    monthStat: {
      currentMonthTransactionSales: 0,
    },
    comparisonStat: {
      startYearStat: 0,
      compareYearStat: 0,
    },
    totalTransactionSales: 0,
  },
  loadingStatName: "",
};

// Slice Name
const sliceName = "transaction";

// Transaction Slice
const transactionSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ...loadingReducers,
    ...errorMessageReducers,
    setLoadingStatName(state, action) {
      state.loadingStatName = action.payload;
    },
    resetLoadingStatName(state) {
      state.loadingStatName = "";
    },
    setTransactions(state, action) {
      state.transactions = action.payload;
    },
    resetTransactions(state) {
      state.transactions = [];
    },
    setTransactionStat(state, action) {
      state.transactionStat = action.payload;
    },
    resetTransactionStat(state) {
      state.transactionStat = {
        totalTransactions: 0,
        currentMonthTotalTransactionCount: 0,
        currentMonthTotalSuccessTransactionCount: 0,
      };
    },
    setSalesStat(state, action) {
      state.salesStat = action.payload;
    },
    resetSalesStat(state) {
      state.salesStat = {
        currentMonthStat: {
          currentMonthTransactionSales: 0,
          currentWeekTransactionSales: 0,
          currentDayTransactionSales: 0,
        },
        monthStat: {
          currentMonthTransactionSales: 0,
        },
        comparisonStat: {
          startYearStat: 0,
          compareYearStat: 0,
        },
        totalTransactionSales: 0,
      };
    },
    delTransaction(state, action) {
      // Get Transaction Id
      const transactionId = action.payload;

      // Get Transactions
      const { transactions } = state;

      // Update Transactions With Transaction That Does Not Contain The Specified Transaction Id
      state.transactions = transactions.filter(
        (transaction) => transaction.id !== transactionId
      );

      // If Transactions Is Empty, Then Set Error Message
      if (!state.transactions.length) {
        state.errorMessage = "No Data Available. Wait For Data Upload";
      }
    },
  },
});

export const {
  resetErrorMessage: resetTransactionErrorMessage,
  setErrorMessage: setTransactionErrorMessage,
  resetTransactions,
  setTransactions,
  startLoading: transactionStartLoading,
  stopLoading: transactionStopLoading,
  resetTransactionStat,
  setTransactionStat,
  delTransaction,
  setSalesStat,
  resetSalesStat,
  setLoadingStatName,
  resetLoadingStatName,
} = transactionSlice.actions;
export * from "./transactionAction";
export const selectTransactions = stateSelector(sliceName, "transactions");
export const selectIsTransactionLoading = stateSelector(sliceName, "isLoading");
export const selectTransactionErrorMessage = stateSelector(
  sliceName,
  "errorMessage"
);
export const selectTransactionStat = stateSelector(
  sliceName,
  "transactionStat"
);
export const selectLoadingStatName = stateSelector(
  sliceName,
  "loadingStatName"
);
export const selectSalesStat = stateSelector(sliceName, "salesStat");
export default transactionSlice.reducer;
