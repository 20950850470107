// Modules
import React from "react";

// Components
import { SubjectCard } from "../../../..";

// Style
import "./subject-list.styles.scss";

export const SubjectList = ({ className, items, onDeleteSubject }) => {
  // Subject Items
  const renderedSubjectItems = items.map((item) => (
    <SubjectCard
      className="subject-list__item"
      key={item.id}
      id={item.id}
      thumbnail={item.thumbnail.url}
      subjectName={item.name}
      subjectAuthor={`${item.tutor.firstName} ${item.tutor.lastName}`}
      subjectPrice={item.price.online}
      numberOfChapter={item.chapters.length}
      to={`/player?subject=${item.id}&edit=true`}
      enableAction
      onDeleteSubject={onDeleteSubject}
    />
  ));

  return (
    <div className={`subject-list ${className}`}>{renderedSubjectItems}</div>
  );
};

SubjectList.defaultProps = {
  className: "",
};
