// Modules
import React from "react";

// Hooks
import { useUrlAsTitle } from "../../../hooks";

// Components
import { StudentProfileBase } from "../../../components";

export const StudentProfile = () => {
  // Set Page Custom Title
  useUrlAsTitle("My Profile");

  return (
    <div className="profile is-height-full">
      <div className="profile__container is-height-full">
        <StudentProfileBase />
      </div>
    </div>
  );
};
