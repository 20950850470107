// Async Messages
const asyncMessages = {
  success: {
    "auth/sendForgotPasswordEmail":
      "Password reset link has been sent to your email account",
    "auth/sendVerificationEmail":
      "Verification link has been sent to your email account",
    "auth/updateProfile": "Profile updated",
    "auth/updateProfileImage": "Avatar uploaded",
    created: "Created",
    updated: "Updated",
    deleted: "Deleted",
    restored: "Restored",
    "feedback/postFeedback": "Feedback/Question submitted",
    "student-enrollment": "Student Enrollment Updated Successfully",
    "newsletter/subscribe": "Subscribed to newsletter successful",
    "newsletter/unsubscribe": "Un-subscribe to newsletter successful",
    "contactUs/postEnquiry": "Enquiry submitted successfully",
  },
  errors: {
    "auth/network-request-failed": "Network error. Please try again",
    unavailable: "Network error. Please try again",
    unhandled: "Unknown error. Please try again",
    "empty-data": "No Data Available. Wait For Data Upload",
    "upload-fail": "Could Not Upload File",
    "upload-delete-fail": "Could Not Delete Uploaded File",
    "delete/empty-data": "Deleted Data Does Not Exist.",
    "update/empty-data": "Updated Data Does Not Exist.",
    "auth/wrong-credential": "No account found with the provided credentials",
    "auth/user-not-found": "User with entered credential do not exist",
    "auth/email-already-in-use": "User with the email already exist",
    "auth/email-already-exists": "User with the email already exist",
    "auth/many-auth-attempt":
      "Too many auth attempt. You are temporarily blocked. Try again in 5 minutes",
    "sendVerificationEmail/many-attempt":
      "Verification link has already been sent to your email.",
    "auth/popup-closed-by-user": "Popup closed. Could not authenticate user",
    "auth/user-disabled":
      "User with the provided credential is disabled. Contact finacco",
    "subscriptions/subscription": "No Active Subscription Available",
    "currencyConverter/currency": "Could not convert currency",
    "permission-denied": "Permission denied. You are not authorized",
    "auth/account-exists-with-different-credential":
      "Could not login. An account already exists with the provider credential",
    "auth/facebook-email":
      "Auth denied, your facebook account does not have an email",
  },
  infos: {
    "auth/autoLogoutOnInvalidDeviceId":
      "Logging out. Seems like you are logged-in on another device",
    "newsletter/subscribe": "You already have an active subscription",
    "newsletter/unsubscribe":
      "You need to have an active subscription to un-subscribe",
  },
};

/**
 * Get A Custom Error Message Based On Error Code
 * @param {string} key Error key
 */
export const getError = (key) => {
  return { type: "error", message: asyncMessages.errors[key] };
};

/**
 * Get A Custom Success Message Based On Success Code
 * @param {string} key Success key
 */
export const getSuccess = (key) => {
  return { type: "success", message: asyncMessages.success[key] };
};

/**
 * Get A Custom Info Message Based On Info Code
 * @param {string} key Info key
 */
export const getInfo = (key) => {
  return { type: "info", message: asyncMessages.infos[key] };
};
