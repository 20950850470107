// Firebase
import {
  getDocs,
  query,
  where,
  orderBy,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {
  firebaseLooper,
  privateSessionCollectionRef,
  userCollectionRef,
  getPrivateSessionAvailabilityDatesAndTime,
} from "../../utils";

// Utils
import { AppError } from "../../utils";

// Apis
import * as apis from "../";

/**
 * Populate Tutor Field In Private Sessions
 * @param {array} privateSessions Private sessions
 */
const populateTutorInPrivateSessions = async (privateSessions) => {
  try {
    // Get Private Sessions With Tutor Populated
    const privateSessionsWitTutor = await Promise.all(
      privateSessions.map(async (privateSession) => ({
        ...privateSession,
        tutorId: privateSession.tutorId.id,
        tutor:
          (await apis.getStaff(privateSession.tutorId.id, false, false)) || {},
      }))
    );

    // Return Private Sessions With Tutor
    return privateSessionsWitTutor;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get All Private Sessions
 * @param {boolean} populateTutorField If true populate the tutor field
 */
export const getPrivateSessions = async (populateTutorField = false) => {
  try {
    // Create Private Session Query
    const privateSessionQuery = query(
      privateSessionCollectionRef,
      where("isActive", "==", true),
      orderBy("createdAt", "desc")
    );

    // Get Private Sessions
    const querySnapshot = await getDocs(privateSessionQuery);

    // If Private Sessions Is Empty, Then Throw Error
    if (querySnapshot.empty) {
      throw new AppError("empty-data");
    }

    // Get Private Sessions From Snapshot
    const privateSessions = firebaseLooper(querySnapshot);

    // If Populate Tutor Field Is True, Then Perform Populating
    const privateSessionsWithTutor = populateTutorField
      ? await populateTutorInPrivateSessions(privateSessions)
      : privateSessions;

    // Return Private Sessions
    return privateSessionsWithTutor;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Create New Private Session
 * @param {object} privateSessionData Private session data
 * @param {string|undefined} privateSessionId Private session id
 */
export const createPrivateSession = async (
  privateSessionData,
  privateSessionId
) => {
  try {
    // Document References
    const tutorRef = doc(userCollectionRef, privateSessionData.tutorId);
    const privateSessionRef = privateSessionId
      ? doc(privateSessionCollectionRef, privateSessionId)
      : doc(privateSessionCollectionRef);

    // Create Private Session
    await setDoc(privateSessionRef, {
      ...privateSessionData,
      tutorId: tutorRef,
      isActive: true,
      createdAt: serverTimestamp(),
    });

    // Get Private Sessions
    const privateSessions = await getPrivateSessions(true);

    // Return Private Sessions
    return privateSessions;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Delete A Private Session
 * @param {string} privateSessionId Private session id
 */
export const deletePrivateSession = async (privateSessionId) => {
  try {
    // Private Session Doc Reference
    const privateSessionRef = doc(
      privateSessionCollectionRef,
      privateSessionId
    );

    // Delete Private Session
    await updateDoc(privateSessionRef, {
      isActive: false,
    });
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Private Session Booking Availability Dates And Time
 * @param {string} privateSessionId Private session id
 * @param {boolean} populateTutorField If true populate the tutor field
 */
export const getPrivateSessionBookingAvailabilityDatesAndTime = async (
  privateSessionId,
  populateTutorField = false
) => {
  try {
    // Get Private Session Booking Availability Dates And Time
    const privateSession = (
      await getPrivateSessionAvailabilityDatesAndTime(privateSessionId)
    ).data;

    // Get Tutor Id
    const tutorId = privateSession.tutorId["_path"]["segments"].pop();

    // Update Tutor Id
    privateSession.tutorId = { id: tutorId };

    // If Populate Tutor Field Is True, Then Perform Populating
    const privateSessionWithTutor = populateTutorField
      ? (await populateTutorInPrivateSessions([privateSession]))[0]
      : privateSession;

    // Return Private Session
    return privateSessionWithTutor;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};
