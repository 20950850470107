// Modules
import React from "react";

// Components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@mui/material";
import { MdOutlineClose } from "react-icons/md";
import { Form, Input, Button } from "../";

// Hooks
import { useEffect } from "react";
import { useFormik } from "formik";
import { useAuth, useValidateFormOnMouseEnter } from "../../../hooks";

// Style
import "./reauthenticate-dialog.styles.scss";

// Schemas
import { authSchema } from "../../../schemas";

export const ReAuthenticateDialog = ({
  open,
  onClose,
  onReAuthenticateSuccess,
}) => {
  const { user, isAuthLoading, reauthenticateUser, dispatch } = useAuth();

  const {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setTouched,
    validateForm,
  } = useFormik({
    validationSchema: authSchema,
    initialValues: {
      authType: "login",
      email: user?.email,
      password: "",
    },
    onSubmit(values) {
      // Re-Authenticate User
      dispatch(reauthenticateUser(values, onReAuthenticateSuccess));
    },
  });

  const { triggerFormValidation } = useValidateFormOnMouseEnter(
    validateForm,
    setTouched
  );

  useEffect(() => {
    // Update User Email In Formik Anytime User Changes
    setFieldValue("email", user?.email);
  }, [user]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="Re-Authenticate">
      <DialogTitle className="reauthenticate-dialog__title">
        Re-Authentication
        <IconButton aria-label="close Re-Authenticate Dialog" onClick={onClose}>
          <MdOutlineClose className="reauthenticate-dialog__close-icon" />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <DialogContentText className="reauthenticate-dialog__text">
          To continue with profile update. Please enter your password.
        </DialogContentText>

        <Form className="reauthenticate-dialog__form">
          <Input
            className="reauthenticate-dialog__control"
            labelClassName="reauthenticate-dialog__label"
            wrapperClassName="reauthenticate-dialog__group"
            type="email"
            label="Email"
            name="email"
            id="rEmail"
            placeholder="Enter your email"
            readonly
            tertiary
            value={values.email}
            error={errors.email}
            touched={touched.email}
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <Input
            className="reauthenticate-dialog__control"
            labelClassName="reauthenticate-dialog__label"
            wrapperClassName="reauthenticate-dialog__group"
            type="password"
            label="Password"
            name="password"
            id="password"
            placeholder="Enter your password"
            tertiary
            value={values.password}
            error={errors.password}
            touched={touched.password}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Form>
      </DialogContent>

      <DialogActions>
        <Button
          className="reauthenticate-dialog__btn btn--primary btn--rv1 btn--sv3"
          disabled={!isValid || isAuthLoading}
          loading={isAuthLoading}
          loadingHeight="25"
          onMouseEnter={triggerFormValidation.bind(null, isValid)}
          onClick={handleSubmit}
        >
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ReAuthenticateDialog.defaultProps = {
  open: false,
};
