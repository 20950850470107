// Modules
import React from "react";

// Hooks
import { useState, useEffect } from "react";
import { useLevel, useSubject } from "../../../hooks";

// Components
import {
  Button,
  Levels,
  SubjectTable as ProfessionalTrainingDetailSubjectTable,
  EmptyContent,
} from "../../";

// Utils
import { scrollIntoView, getTrainingAcronym } from "../../../utils";

// Style
import "./professional-training-detail-base.styles.scss";

export const ProfessionalTrainingDetailBase = ({ className }) => {
  const { levels, dispatch, isLevelLoading } = useLevel();
  const training = levels[0]?.training;
  const [activeLevel, setActiveLevel] = useState("");
  const {
    getLevelSubjectsAndStat,
    subjects,
    isSubjectLoading,
    subjectErrorMessage,
  } = useSubject();

  useEffect(() => {
    // Set Active Level If Levels Exist And Level Is Not isLoading
    if (levels.length && !isLevelLoading) {
      setActiveLevel(levels[0]?.id);
    }
  }, [levels]);

  useEffect(() => {
    // Get Level Subjects
    activeLevel && dispatch(getLevelSubjectsAndStat(activeLevel));
  }, [activeLevel]);

  return (
    <div className={`professional-training-detail-base ${className}`}>
      <div className="professional-training-detail-base__container container">
        <div className="professional-training-detail-base__btn-group">
          <Button
            className="professional-training-detail-base__btn btn--sv5 btn--rv3 btn--gray-2"
            onClick={scrollIntoView.bind(
              null,
              "professional-training-detail-base__heading--overview"
            )}
          >
            Overview
          </Button>

          <Button
            className="professional-training-detail-base__btn btn--sv5 btn--rv3 btn--dark"
            onClick={scrollIntoView.bind(
              null,
              "professional-training-detail-base__heading--study-structure"
            )}
          >
            Study Structure
          </Button>
        </div>

        <div className="professional-training-detail-base__group">
          <h2 className="professional-training-detail-base__heading professional-training-detail-base__heading--overview">
            {getTrainingAcronym(training?.title)} -{" "}
            <span className="is-text-primary-color">Overview</span>
          </h2>

          {/* Overview Content */}
          <div
            className="professional-training-detail-base__overview-content"
            dangerouslySetInnerHTML={{ __html: training?.description }}
          ></div>
        </div>

        <div className="professional-training-detail-base__group">
          <h2 className="professional-training-detail-base__heading professional-training-detail-base__heading--study-structure">
            {getTrainingAcronym(training?.title)} -{" "}
            <span className="is-text-primary-color">Study Structure</span>
          </h2>

          {/* Levels */}
          <Levels
            className="professional-training-detail-base__levels"
            setActiveLevel={setActiveLevel}
            activeLevel={activeLevel}
            items={levels}
          />

          {/* Subject Summary */}
          <EmptyContent
            className="professional-training-detail-base__table-empty"
            isLoading={isSubjectLoading}
            errorMessage={subjectErrorMessage}
          >
            <ProfessionalTrainingDetailSubjectTable
              className="professional-training-detail-base__table-container"
              subjects={subjects}
            />
          </EmptyContent>
        </div>
      </div>
    </div>
  );
};

ProfessionalTrainingDetailBase.defaultProps = {
  className: "",
};
