// Modules
import React from "react";

// Components
import { IoMdVideocam, IoIosPricetag } from "react-icons/io";

// Utils
import { getProfileImage } from "../../../utils";

// Style
import "./book-private-session-info.styles.scss";

export const BookPrivateSessionInfo = ({ className, privateSession }) => {
  return (
    <div className={`book-private-session-info ${className}`}>
      <div className="book-private-session-info__container">
        <div className="book-private-session-info__staff-profile">
          <img
            className="book-private-session-info__staff-photo crop"
            src={getProfileImage(privateSession?.tutor?.avatar?.url, 100, 100)}
            alt={privateSession?.tutor?.firstName}
          />
          <div className="book-private-session-info__staff-summary">
            <p className="book-private-session-info__staff-name">
              {`${privateSession?.tutor?.firstName} ${privateSession?.tutor?.lastName}`}
            </p>
            <p className="book-private-session-info__staff-org-role">
              {privateSession?.tutor?.employment?.empRole}
            </p>
          </div>
        </div>

        <div className="book-private-session-info__group">
          <h4 className="book-private-session-info__heading">
            {privateSession?.name}
          </h4>
          <p className="book-private-session-info__text">
            {privateSession?.description}
          </p>
        </div>

        <div className="book-private-session-info__group">
          <h4 className="book-private-session-info__heading">Details</h4>
          <ul className="book-private-session-info__list">
            <li className="book-private-session-info__item">
              <IoIosPricetag
                className="book-private-session-info__item-icon"
                aria-label="private"
              />
              ${privateSession?.price}
            </li>

            <li className="book-private-session-info__item">
              <IoMdVideocam
                className="book-private-session-info__item-icon"
                aria-label="meeting medium"
              />
              Video Call: With Google Meet
            </li>
          </ul>
        </div>

        <div className="book-private-session-info__group">
          <h4 className="book-private-session-info__heading">Attendees</h4>
          <p className="book-private-session-info__text">
            Each Meeting is designed to be 1 private individual student to
            attend not as a group
          </p>
        </div>
      </div>
    </div>
  );
};

BookPrivateSessionInfo.defaultProps = {
  className: "",
};
