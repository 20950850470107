// Modules
import React from "react";

// Components
import PhoneInput from "react-phone-input-2";

// Style
import "react-phone-input-2/lib/style.css";
import "./phone-number.styles.scss";

export const PhoneNumber = ({
  className,
  wrapperClassName,
  labelClassName,
  label,
  value,
  placeholder,
  name,
  touched,
  error,
  onChange,
  onBlur,
}) => {
  /**
   * Blur Event Handler
   */
  const onBlurHandler = () => {
    onBlur(name, true);
  };

  /**
   * Change Event Handler
   * @param {string} phone Target Value
   * @param {object} data Target data
   */
  const onChangeHandler = (phone, data) => {
    onChange("dialCode", data.dialCode);
    onChange(name, phone);
  };

  return (
    <div className={`form__group ${wrapperClassName}`}>
      {!!label && (
        <label className={`form__label ${labelClassName}`}>{label}</label>
      )}

      <div
        className={`form__phone-group ${
          error && touched ? "form__phone-group--error" : ""
        }`}
      >
        <PhoneInput
          containerClass="form__phone-control-wrapper"
          inputClass={`form__phone-control ${className}`}
          country={"rw"}
          value={value}
          buttonClass="form__phone-dropdown-btn"
          placeholder={placeholder}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
        />
      </div>

      {touched && error && (
        <div className="form__error-wrapper">
          <p className="form__error">{error}</p>
        </div>
      )}
    </div>
  );
};

PhoneNumber.defaultProps = {
  className: "",
  labelClassName: "",
  wrapperClassName: "",
  touched: false,
};
