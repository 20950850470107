// Modules
import axios from "axios";

// Firebase
import { httpsCallable } from "firebase/functions";
import { functions } from "../../utils";

// Utils
import { AppError } from "../../utils";

/**
 * Convert From One Currency To Another
 * @param {string} from Currency from eg USD
 * @param {string} to Currency to eg RWF
 * @param {number} amount Amount to
 */
export const currencyConverter = async (from = "USD", to = "RWF", amount) => {
  try {
    // Convert Currency
    const res = await axios.get(
      `https://api.apilayer.com/exchangerates_data/convert`,
      {
        params: {
          to,
          from,
          amount,
        },
        headers: {
          apikey: process.env.REACT_APP_CURRENCY_CONVERTER_API_KEY,
        },
      }
    );

    // Return The Amount
    return Math.round(res.data.result);
  } catch (err) {
    // Throw Custom Error
    throw new AppError("currencyConverter/currency");
  }
};

/**
 * Create Payment Token
 * @param {object} paymentOptions Payment options
 * @param {object} cart Cart
 */
export const createPaymentToken = async (paymentOptions, cart) => {
  try {
    // Get Payment Data
    const { paymentCurrency, totalPrice } = paymentOptions;
    const conversionPrice =
      paymentCurrency !== "USD"
        ? await currencyConverter(undefined, paymentCurrency, totalPrice)
        : totalPrice;
    cart.conversionPrice = conversionPrice;

    // Create Payment Token
    const onCreatePaymentToken = httpsCallable(
      functions,
      "onCreatePaymentToken"
    );
    const transactionToken = (
      await onCreatePaymentToken({
        paymentOptions,
        cart,
      })
    ).data;

    // Return Transaction Token
    return transactionToken;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};
