// Modules
import React from "react";

// Components
import { SubjectCard } from "../../../..";

// Style
import "./add-student-subjects-list.styles.scss";

export const AddStudentSubjectsList = ({
  className,
  items,
  toggleSubjectSelect,
  selectedSubjects,
}) => {
  // Subject Items
  const renderedSubjectItems = items.map((item) => (
    <SubjectCard
      className="add-student-subjects-list__item"
      key={item.id}
      id={item.id}
      thumbnail={item.thumbnail.url}
      subjectName={item.name}
      subjectAuthor={`${item.tutor.firstName} ${item.tutor.lastName}`}
      subjectPrice={item.price.online}
      numberOfChapter={item.chapters.length}
      enableSelect
      onSubjectSelectChange={toggleSubjectSelect}
      selected={selectedSubjects.includes(item.id)}
    />
  ));

  return (
    <div className={`add-student-subjects-list ${className}`}>
      {renderedSubjectItems}
    </div>
  );
};

AddStudentSubjectsList.defaultProps = {
  className: "",
  items: [],
};
