// Hooks
import { useSelector, useDispatch } from "react-redux";

// Redux
import { transactionSA } from "../store";

// Destructure Redux Imports
const {
  selectIsTransactionLoading,
  selectTransactionErrorMessage,
  selectTransactionStat,
  selectTransactions,
  selectLoadingStatName,
  selectSalesStat,
} = transactionSA;

/**
 * Transaction Hook
 */
export const useTransaction = () => {
  const dispatch = useDispatch();
  const transactions = useSelector(selectTransactions);
  const isTransactionLoading = useSelector(selectIsTransactionLoading);
  const transactionErrorMessage = useSelector(selectTransactionErrorMessage);
  const transactionStat = useSelector(selectTransactionStat);
  const salesStat = useSelector(selectSalesStat);
  const loadingStatName = useSelector(selectLoadingStatName);

  return {
    transactions,
    isTransactionLoading,
    transactionErrorMessage,
    transactionStat,
    salesStat,
    loadingStatName,
    dispatch,
    ...transactionSA,
  };
};
