// Modules
import React from "react";

// Style
import "./recruitment-gateway-base.styles.scss";

export const RecruitmentGatewayBase = ({ className }) => {
  return (
    <section className={`recruitment-gateway-base ${className}`}>
      <div className="recruitment-gateway-base__container container">
        <p className="recruitment-gateway-base__detail">
          Specialized finance and accounting recruitment services. We work hand
          in hand with our clients to develop, drive and deliver customized
          accounting, financial and investment recruitment strategies to build
          their capital and financial strength with the right team at right
          price.
        </p>

        <div className="recruitment-gateway-base__item recruitment-gateway-base__services">
          <h4 className="recruitment-gateway-base__subheading">Our Services</h4>

          <ul className="recruitment-gateway-base__services-list">
            <li className="recruitment-gateway-base__services-item">
              Headhunting and Talent searching
            </li>
            <li className="recruitment-gateway-base__services-item">
              Temporary and interim recruitment
            </li>
            <li className="recruitment-gateway-base__services-item">
              Induction and Transitional plan and execution
            </li>
            <li className="recruitment-gateway-base__services-item">
              Job listings and Advertising
            </li>
          </ul>
        </div>

        <div className="recruitment-gateway-base__item recruitment-gateway-base__job-list">
          <h4 className="recruitment-gateway-base__subheading">
            Job Listing Page
          </h4>
          <p className="recruitment-gateway-base__job-list-content">
            Not available
          </p>
        </div>

        <div className="recruitment-gateway-base__item recruitment-gateway-base__employers">
          <h4 className="recruitment-gateway-base__subheading">Employers</h4>
          <p className="recruitment-gateway-base__employers-content">
            Not available
          </p>
        </div>

        <div className="recruitment-gateway-base__item recruitment-gateway-base__candidate-lab">
          <h4 className="recruitment-gateway-base__subheading">
            Candidate Lab
          </h4>
          <p className="recruitment-gateway-base__candidate-lab-content">
            Kindly send your CV at info@finaccohub.com we will contact you.
          </p>
        </div>
      </div>
    </section>
  );
};

RecruitmentGatewayBase.defaultProps = {
  className: "",
};
