// Modules
import React from "react";

// Components
import { NavLink, Link } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { SlGraduation } from "react-icons/sl";
import { FiSettings, FiLogOut } from "react-icons/fi";
import { IoNotificationsOutline } from "react-icons/io5";
import { MdOutlineHome } from "react-icons/md";

// Hooks
import { useAuth } from "../../../hooks";

// Images
import { ReactComponent as FinaccoLogo } from "../../../assets/images/finacco-logo.svg";

// Style
import "./student-sidenavbar.styles.scss";

export const StudentSideNavbar = ({
  className,
  isSidenavbarOpen,
  setIsSidenavbarOpen,
}) => {
  const { handleLogout } = useAuth();

  /**
   * Close Sidenavbar Visibility
   * @param {Event} e Event
   */
  const closeSidenavbarVisibility = (e) => {
    const { target } = e;

    // Check If The Clicked Element Is Student Sidenavbar
    const isStudentSidenavbarElem =
      target.classList.contains("student-sidenavbar");

    // Check If The Clicked Element Is Navigation Item
    const isNavbarItem =
      target.closest(".student-sidenavbar__nav-item") instanceof Element;

    // If User Clicked On SideNavbar Foreground Or Navigation Item, Then Close Sidenavbar Visibility
    if (isNavbarItem || isStudentSidenavbarElem) {
      setIsSidenavbarOpen(false);
    }
  };

  return (
    <div
      className={`student-sidenavbar ${
        isSidenavbarOpen ? "student-sidenavbar--open" : ""
      } ${className}`}
      onClick={closeSidenavbarVisibility}
    >
      <div className="student-sidenavbar__container">
        <div className="student-sidenavbar__brand">
          <Link className="student-sidenavbar__brand-link" to="/">
            <FinaccoLogo
              className="student-sidenavbar__brand-logo"
              arial-label="Finacco Logo"
            />
          </Link>
        </div>

        <nav className="student-sidenavbar__navbar">
          <ul className="student-sidenavbar__nav-list">
            <div className="student-sidenavbar__nav-top">
              <li className="student-sidenavbar__nav-item">
                <Link className="student-sidenavbar__nav-link" to="/">
                  <MdOutlineHome
                    className="student-sidenavbar__nav-icon student-sidenavbar__nav-icon--home"
                    aria-label="back to home"
                  />
                  Home
                </Link>
              </li>

              {/* <li className="student-sidenavbar__nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "student-sidenavbar__nav-link student-sidenavbar__nav-link--active"
                      : "student-sidenavbar__nav-link"
                  }
                  to="/student/overview"
                >
                  <RxDashboard
                    className="student-sidenavbar__nav-icon"
                    aria-label="student overview"
                  />
                  Overview
                </NavLink>
              </li> */}

              <li className="student-sidenavbar__nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "student-sidenavbar__nav-link student-sidenavbar__nav-link--active"
                      : "student-sidenavbar__nav-link"
                  }
                  to="/student/course-catalog"
                >
                  <SlGraduation
                    className="student-sidenavbar__nav-icon"
                    aria-label="student enrolled courses"
                  />
                  My Courses
                </NavLink>
              </li>

              <li className="student-sidenavbar__nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "student-sidenavbar__nav-link student-sidenavbar__nav-link--active"
                      : "student-sidenavbar__nav-link"
                  }
                  to="/student/private-sessions"
                >
                  <IoNotificationsOutline
                    className="student-sidenavbar__nav-icon"
                    aria-label="book private office hour"
                  />
                  Private Tutor
                </NavLink>
              </li>

              <li className="student-sidenavbar__nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "student-sidenavbar__nav-link student-sidenavbar__nav-link--active"
                      : "student-sidenavbar__nav-link"
                  }
                  to="/student/profile"
                >
                  <FiSettings
                    className="student-sidenavbar__nav-icon"
                    aria-label="student profile"
                  />
                  Profile
                </NavLink>
              </li>
            </div>

            <div className="student-sidenavbar__nav-bottom">
              <li className="student-sidenavbar__nav-item">
                <span
                  className="student-sidenavbar__nav-link student-sidenavbar__nav-link--logout is-cursor-pointer"
                  onClick={handleLogout}
                >
                  <FiLogOut
                    className="student-sidenavbar__nav-icon"
                    aria-label="logout"
                  />
                  Logout
                </span>
              </li>
            </div>
          </ul>
        </nav>
      </div>
    </div>
  );
};

StudentSideNavbar.defaultProps = {
  className: "",
  isSidenavbarOpen: false,
};
