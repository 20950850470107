// Modules
import React from "react";

// Hooks
import { useState, useEffect } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { useChapter } from "../../../../hooks";

// Components
import { ModalOverlay, Modal, EmptyContent, AddChapterForm } from "../../..";

// Schemas
import { addChapterSchema } from "../../../../schemas";

// Style
import "./add-chapter-modal-base.styles.scss";

export const AddChapterModalBase = ({ className }) => {
  const [errorMessage, setErrorMessage] = useState();
  const [chapter, setChapter] = useState();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const { createChapter, updateChapter, dispatch, chapters, isChapterLoading } =
    useChapter();
  const { chapterId } = useParams();
  const subjectId = searchParams.get("subject");
  const isEditMode = chapterId !== "1";

  // Add Chapter Formik
  const addChapterFormik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: addChapterSchema,
    onSubmit(values) {
      // If IsEditMode Is False, Then Create A New Chapter, Else Update Chapter
      if (!isEditMode) {
        dispatch(createChapter(values, subjectId));
      } else {
        dispatch(updateChapter(chapterId, values, subjectId));
      }
    },
  });

  useEffect(() => {
    // Create Error Message
    const errorMessage = "No Chapter Available With The Provided Id.";

    // Get Chapter If Chapter Is Not Loading And Edit Mode Is True
    if (!isChapterLoading && isEditMode) {
      // Get Chapter By Chapter Id
      const chapter = chapters.find((chapter) => chapter.id === chapterId);

      // Set Error Message If Chapter Is Undefined, Else Update Chapter And Reset Error Message
      if (!chapter) {
        setErrorMessage(errorMessage);
      } else {
        setErrorMessage("");
        setChapter(chapter);
      }
    }
  }, [chapters, chapterId]);

  useEffect(() => {
    // If Chapter Exist And Edit Mode Is True, Update Formik State
    if (chapter && isEditMode) {
      const { name } = chapter;
      addChapterFormik.setValues({
        name,
      });
    }
  }, [chapter]);

  const { isSubmitting } = addChapterFormik;

  return (
    <ModalOverlay
      className={`add-chapter-modal-base-overlay ${className}`}
      to={`/player${search}`}
    >
      <Modal className="add-chapter-modal-base">
        <EmptyContent
          className="add-chapter-modal__empty"
          isLoading={isChapterLoading && !isSubmitting}
          errorMessage={errorMessage}
        >
          <AddChapterForm
            className="add-chapter-modal-base__form"
            formik={addChapterFormik}
            isEditMode={isEditMode}
          />
        </EmptyContent>
      </Modal>
    </ModalOverlay>
  );
};

AddChapterModalBase.defaultProps = {
  className: "",
};
