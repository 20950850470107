// Modules
import React from "react";

// Components
import {
  ModalOverlay,
  Modal,
  EmptyContent,
  SubjectList,
  Button,
} from "../../../../";

// Hooks
import { useParams } from "react-router-dom";
import { useSubject } from "../../../../../hooks";

// Style
import "./subject-modal-base.styles.scss";

export const SubjectModalBase = ({ className }) => {
  const { trainingId, levelId } = useParams();
  const {
    subjects,
    subjectErrorMessage,
    isSubjectLoading,
    dispatch,
    deleteSubject,
  } = useSubject();
  const level = subjects[0]?.level;

  /**
   * Delete A Subject
   * @param {string} subjectId Subject Id
   */
  const onDeleteSubject = (subjectId) => {
    dispatch(deleteSubject(subjectId, levelId));
  };

  // Create Subject Button
  const createSubjectBtn = (
    <Button
      className="level-modal-base__btn btn--primary btn--sv8 btn--rv5 admin-layout__content-btn"
      to={`/admin/trainings/${trainingId}/${levelId}/subjects/1`}
    >
      Add Subject
    </Button>
  );

  return (
    <ModalOverlay
      className={`subject-modal-base-overlay ${className}`}
      to={`/admin/trainings/${trainingId}/levels`}
    >
      <Modal className="subject-modal-base">
        <EmptyContent
          className="subject-modal-base__empty"
          errorMessage={subjectErrorMessage}
          isLoading={isSubjectLoading}
          additionalErrorChildren={createSubjectBtn}
        >
          <div className="subject-modal-base__header">
            <h3 className="subject-modal-base__title">
              {level?.name}- Subject Catalog
            </h3>
            {createSubjectBtn}
          </div>

          <div className="subject-modal-base__main">
            <SubjectList
              className="subject-modal-base__list"
              items={subjects}
              onDeleteSubject={onDeleteSubject}
            />
          </div>
        </EmptyContent>
      </Modal>
    </ModalOverlay>
  );
};
