// Modules
import React from "react";

// Components
import { Outlet } from "react-router-dom";
import {
  PricingSubjectHero,
  PricingSubjectBase,
  EmptyContent,
} from "../../../components";

// Hooks
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLevel } from "../../../hooks";

// Style
import "./pricing-subject.styles.scss";

export const PricingSubject = () => {
  const { dispatch, getTrainingLevels, isLevelLoading, levelErrorMessage } =
    useLevel();
  const { trainingId } = useParams();

  useEffect(() => {
    // Get Training Levels And Populate Training Field
    dispatch(getTrainingLevels(trainingId, true));
  }, [trainingId]);

  return (
    <div className="pricing-subject">
      <div className="pricing-subject__container">
        <EmptyContent
          className="pricing-subject__empty"
          isLoading={isLevelLoading}
          errorMessage={levelErrorMessage}
        >
          <PricingSubjectHero />
          <PricingSubjectBase />
          <Outlet />
        </EmptyContent>
      </div>
    </div>
  );
};
