// Redux
import {
  levelStartLoading,
  levelStopLoading,
  setLevels,
  setLevelErrorMessage,
  resetLevelErrorMessage,
  delLevel,
} from ".";
import { uiSA } from "..";

// Utils
import { getSuccess, History } from "../../utils";

// Apis
import * as apis from "../../apis";

// Destructure Redux Imports
const { showNotification } = uiSA;

/**
 * Get All Levels For A Training
 * @param {string} trainingId Training id
 * @param {boolean} populateTrainingField If true populate training field
 */
export const getTrainingLevels =
  (trainingId, populateTrainingField) => async (dispatch) => {
    try {
      // Reset Error Message
      dispatch(resetLevelErrorMessage());

      // Start Loading
      dispatch(levelStartLoading());

      // Get Training Levels
      const levels = await apis.getTrainingLevels(
        trainingId,
        populateTrainingField
      );

      // Update Levels
      dispatch(setLevels(levels));
    } catch (err) {
      // Update Error Message
      dispatch(setLevelErrorMessage(err.message.message));
    } finally {
      // Stop Levels
      dispatch(levelStopLoading());
    }
  };

/**
 * Create New Level
 * @param {object} levelData Level data
 * @param {string} trainingId Training id
 */
export const createLevel = (levelData, trainingId) => async (dispatch) => {
  try {
    // Reset Error Message
    dispatch(resetLevelErrorMessage());

    // Start Loading
    dispatch(levelStartLoading());

    // Create Level
    const levels = await apis.createLevel(levelData, trainingId);

    // Update Levels
    dispatch(setLevels(levels));

    // Show Success Notification On Creating Level
    dispatch(showNotification(getSuccess("created")));

    // Navigate To Training Levels
    History.push(`/admin/trainings/${trainingId}/levels`);
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  } finally {
    // Stop Levels
    dispatch(levelStopLoading());
  }
};

/**
 * Update A Level
 * @param {string} levelId Level id
 * @param {object} levelData Level data
 * @param {string} trainingId Training id
 */
export const updateLevel =
  (levelId, levelData, trainingId) => async (dispatch) => {
    try {
      // Start Loading
      dispatch(levelStartLoading());

      // Update Level
      const levels = await apis.updateLevel(levelId, levelData, trainingId);

      // Update Levels
      dispatch(setLevels(levels));

      // Show Success Notification On Updating Level
      dispatch(showNotification(getSuccess("updated")));

      // Navigate To Training Levels
      History.push(`/admin/trainings/${trainingId}/levels`);
    } catch (err) {
      // Show Error In Notification
      dispatch(showNotification(err.message));
    } finally {
      // Stop Levels
      dispatch(levelStopLoading());
    }
  };

/**
 * Delete A Level
 * @param {string} levelId Level Id
 */
export const deleteLevel = (levelId) => async (dispatch) => {
  try {
    // Delete Level
    await apis.deleteLevel(levelId);

    // Delete Level In Redux Store
    dispatch(delLevel(levelId));

    // Show Success Notification On Deleting Level
    dispatch(showNotification(getSuccess("deleted")));
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  }
};
