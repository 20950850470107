// Modules
import React from "react";

// Hooks
import { useRef } from "react";

// Components
import { InputUpload, InputDatePicker } from "../";

// Style
import "./input.styles.scss";

export const Input = ({
  labelClassName,
  wrapperClassName,
  className,
  label,
  type,
  error,
  touched,
  value,
  id,
  name,
  readonly,
  placeholder,
  dateFormat,
  onChange,
  onBlur,
  secondary,
  tertiary,
  show,
  onSecondaryActionClick,
  isInline,
}) => {
  const inputRef = useRef();

  /**
   * Handle Secondary Action Click
   * @param {object} e Event object
   */
  const handleOnSecondaryActionClick = (e) => {
    // Update Secondary Action State
    onSecondaryActionClick(name);

    // AutoFocus The Input Element
    !show && inputRef.current.focus();
  };

  return (
    <div
      className={`form__group  ${wrapperClassName} ${
        isInline ? "form__group--inline" : ""
      }  ${secondary ? "form__group--secondary" : ""} ${
        tertiary ? "form__group--tertiary" : ""
      }`}
    >
      {!!label && (
        <label htmlFor={id} className={`form__label ${labelClassName}`}>
          {label}
        </label>
      )}

      {type === "textarea" && (
        <textarea
          className={`form__control form__control--textarea ${className} ${
            touched && error ? "form__control--error" : ""
          }`}
          ref={inputRef}
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          readOnly={readonly || !show}
          onChange={onChange}
          onBlur={onBlur}
        ></textarea>
      )}

      {type === "upload" && (
        <InputUpload
          className={`form__control ${className} ${
            touched && error ? "form__control--error" : ""
          }`}
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
        />
      )}

      {type === "date" && (
        <InputDatePicker
          className={`form__control ${className} ${
            touched && error ? "form__control--error" : ""
          }`}
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          dateFormat={dateFormat}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}

      {type !== "textarea" && type !== "upload" && type !== "date" && (
        <input
          className={`form__control ${className} ${
            touched && error ? "form__control--error" : ""
          }`}
          ref={inputRef}
          type={type}
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          readOnly={readonly || !show}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}

      {secondary && (
        <span className="form__action" onClick={handleOnSecondaryActionClick}>
          {!show ? "Show" : "Hide"}
        </span>
      )}

      {touched && error && (
        <div className="form__error-wrapper">
          <p className="form__error">{error}</p>
        </div>
      )}
    </div>
  );
};

Input.defaultProps = {
  className: "",
  labelClassName: "",
  wrapperClassName: "",
  id: "",
  type: "text",
  dateFormat: "M/d/yyyy",
  readonly: false,
  show: true,
  touched: false,
  secondary: false,
  tertiary: false,
  isInline: false,
};
