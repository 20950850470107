// Modules
import { setNestedObjectValues } from "formik";

/**
 * Validate Entire Form On Mouse Enter
 * @param {function} validateForm Formik validate form
 * @param {function} setTouched Formik set touched
 */
export const useValidateFormOnMouseEnter = (validateForm, setTouched) => {
  const triggerFormValidation = (isValid) => {
    if (!isValid) {
      validateForm().then((validationErrors) =>
        setTouched(setNestedObjectValues(validationErrors, true))
      );
    }
  };
  return { triggerFormValidation };
};
