// Modules
import React from "react";

// Components
import { Outlet } from "react-router-dom";
import { BookingStatsBase, BookingBase } from "../../../components";

// Hooks
import { useEffect } from "react";
import { useUrlAsTitle, useBooking } from "../../../hooks";

export const Booking = () => {
  // Set Dashboard Page Title
  useUrlAsTitle("Bookings");
  const { dispatch, getBookingsAndStat } = useBooking();

  useEffect(() => {
    // Get Bookings And Stat, Also Populate Student Field
    dispatch(getBookingsAndStat(true));
  }, []);

  return (
    <div className="booking is-height-full">
      <div className="booking__container container is-height-full">
        <BookingStatsBase />
        <BookingBase />
        <Outlet />
      </div>
    </div>
  );
};
