// Hooks
import { useSelector, useDispatch } from "react-redux";

// Redux
import { subjectSA } from "../store";

// Destructure Redux Imports
const {
  selectSubjects,
  selectIsSubjectLoading,
  selectSubjectErrorMessage,
  selectSubjectStat,
} = subjectSA;

/**
 * Subject Hook
 */
export const useSubject = () => {
  const dispatch = useDispatch();
  const subjects = useSelector(selectSubjects);
  const stat = useSelector(selectSubjectStat);
  const isSubjectLoading = useSelector(selectIsSubjectLoading);
  const subjectErrorMessage = useSelector(selectSubjectErrorMessage);

  return {
    subjects,
    stat,
    isSubjectLoading,
    subjectErrorMessage,
    dispatch,
    ...subjectSA,
  };
};
