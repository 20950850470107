// Modules
import React from "react";

// Components
import { StatusMessage } from "../../../components";

export const PageNotFound = () => {
  return (
    <StatusMessage
      className="page-not-found"
      status="404"
      message="Page Not Found"
      backContent="Back to Home"
      backUrl="/"
    />
  );
};
