// Modules
import React from "react";

// Style
import "./home-stat-item.styles.scss";

export const HomeStatItem = ({ className, stat, title, image }) => {
  return (
    <div className={`home-stat-item ${className}`}>
      <div className="home-stat-item__left">
        <img className="home-stat-item__photo" src={image} alt={title} />
      </div>
      <div className="home-stat-item__right">
        <p className="home-stat-item__stat">{stat}</p>
        <p className="home-stat-item__title">{title}</p>
      </div>
    </div>
  );
};

HomeStatItem.defaultProps = {
  className: "",
};
