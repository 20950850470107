// Modules
import React from "react";

// Components
import { Fragment } from "react";
import { Routes as Switch, Route, Navigate, Outlet } from "react-router-dom";
import { NavigateSetter } from ".";

import { SubscriptionHistory } from "../pages/student/subscription-history/subscription-history.component";

// Layouts
import {
  AuthLayout,
  StudentLayout,
  AdminLayout,
  LandingLayout,
  VideoLayout,
  OutletLayout,
} from "../layouts";

// Pages
import {
  Signup,
  Login,
  ForgotPassword,
  StudentProfile,
  Dashboard,
  Staff,
  AddStaffModal,
  Training,
  AddTrainingModal,
  LevelModal,
  AddLevelModal,
  SubjectModal,
  Pricing,
  PricingSubject,
  AddSubjectModal,
  ProfessionalTraining,
  ProfessionalTrainingDetail,
  Home,
  RecruitmentGateway,
  AccountingSolution,
  AccountingConsultancy,
  CheckoutModal,
  CourseCatalog,
  Transaction,
  Student,
  Video,
  VideoFeedbackModal,
  AddChapterModal,
  TrainingModal,
  AddStudentSubjectsModal,
  StudentProfileModal,
  Booking,
  AddPrivateSessionModal,
  PrivateSessionModal,
  PrivateSession,
  BookPrivateSessionModal,
  PageNotFound,
  StudentOverview,
} from "../pages";

// Hocs
import { preventIfAuthHoc, preventIfNotAuthHoc } from "../hocs";

export const Routes = () => {
  return (
    <Fragment>
      <NavigateSetter />
      <Switch>
        {/* Main Routes */}
        <Route path="/" element={<LandingLayout />}>
          <Route index element={<Home />} />
          <Route
            path="professional-training"
            element={<ProfessionalTraining />}
          />
          <Route
            path="professional-training/:trainingId"
            element={<ProfessionalTrainingDetail />}
          />
          <Route path="recruitment-gateway" element={<RecruitmentGateway />} />
          <Route path="accounting-solution" element={<AccountingSolution />} />
          <Route
            path="accounting-consultancy"
            element={<AccountingConsultancy />}
          />

          <Route path="pricing/:trainingId" element={<Pricing />}>
            <Route path="checkout" element={<CheckoutModal />} />
          </Route>

          <Route
            path="pricing/:trainingId/subjects"
            element={<PricingSubject />}
          >
            <Route path="checkout" element={<CheckoutModal />} />
          </Route>
        </Route>

        {/* Authentication Routes */}
        <Route path="/auth" element={<AuthLayout />}>
          <Route index element={<Navigate to="/auth/login" replace />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
        </Route>

        {/* Student Portal Routes */}
        <Route path="/student" element={<StudentLayout />}>
          <Route index element={<Navigate to="/student/course-catalog" replace />} />
          {/* <Route path="overview" element={<div>Overview</div>} /> */}
          <Route path="course-catalog" element={<CourseCatalog />} />

          <Route path="private-sessions" element={<PrivateSession />}>
            <Route
              path="booking/:privateSessionId"
              element={<BookPrivateSessionModal />}
            />

            <Route
              path="booking/:privateSessionId/checkout"
              element={<CheckoutModal />}
            />
          </Route>

          <Route path="profile" element={<StudentProfile />} />
          <Route
            path="profile/subscription-histories"
            element={<SubscriptionHistory />}
          >
            <Route path="checkout" element={<CheckoutModal />} />
          </Route>

          <Route path="overview" element={<Navigate to="/student/course-catalog" replace />} />
        </Route>

        {/* Admin Routes */}
        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<Navigate to="/admin/dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />

          <Route path="staffs" element={<Staff />}>
            <Route path=":staffId" element={<AddStaffModal />} />
          </Route>

          <Route path="trainings" element={<Training />}>
            <Route path=":trainingId" element={<AddTrainingModal />} />
            <Route path=":trainingId/levels" element={<LevelModal />} />
            <Route
              path=":trainingId/levels/:levelId"
              element={<AddLevelModal />}
            />
            <Route
              path=":trainingId/:levelId/subjects"
              element={<SubjectModal />}
            />
            <Route
              path=":trainingId/:levelId/subjects/:subjectId"
              element={<AddSubjectModal />}
            />
          </Route>

          <Route path="transactions" element={<Transaction />} />

          <Route path="students" element={<Student />}>
            <Route path=":studentId" element={<StudentProfileModal />} />
            <Route path=":studentId/trainings" element={<TrainingModal />} />
            <Route
              path=":studentId/:trainingId/subjects"
              element={<AddStudentSubjectsModal />}
            />
          </Route>

          <Route path="bookings" element={<Booking />}>
            <Route path="private-sessions" element={<PrivateSessionModal />} />
            <Route
              path="private-sessions/:privateSessionId"
              element={<AddPrivateSessionModal />}
            />
          </Route>
        </Route>

        {/* Video Player Routes */}
        <Route path="/player" element={<VideoLayout />}>
          <Route path="" element={<Video />}>
            <Route path="chapter/:chapterId" element={<AddChapterModal />} />
            <Route path="feedback" element={<VideoFeedbackModal />} />
          </Route>
        </Route>

        {/* Status Routes */}
        <Route path="/status" element={<OutletLayout />}>
          <Route
            index
            element={<Navigate to="/status/page-not-found" replace />}
          />
          <Route path="page-not-found" element={<PageNotFound />} />{" "}
        </Route>

        {/* WildCard Route */}
        <Route
          path="*"
          element={<Navigate to="/status/page-not-found" replace />}
        />
      </Switch>
    </Fragment>
  );
};
