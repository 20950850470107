// Modules
import React from "react";

// Components
import { FaGraduationCap } from "react-icons/fa";
import { Button, SubjectStatisticTable, EmptyContent } from "../../";

// Style
import "./student-subject-statistic-table.styles.scss";

// Static Data (StudentSubjectStatistics => CollectionName)
const SUBJECTSTATISTICDATA = [
  {
    id: 1,
    tutor: {
      firstName: "David",
      lastName: "Atebisun",
    },
    subject: {
      name: "Introduction to CFA",
    },
    totalVideos: 7,
    totalPdfs: 4,
    timeSpent: "20h:25m",
    progress: 80,
  },
  {
    id: 2,
    tutor: {
      firstName: "Pelumi",
      lastName: "Samuel",
    },
    subject: {
      name: "Financial Model",
    },
    totalVideos: 3,
    totalPdfs: 2,
    timeSpent: "05h:30m",
    progress: 30,
  },
  {
    id: 3,
    tutor: {
      firstName: "Benedict",
      lastName: "Samuel",
    },
    subject: {
      name: "Financial Projection",
    },
    totalVideos: 10,
    totalPdfs: 5,
    timeSpent: "30h:30m",
    progress: 90,
  },
  {
    id: 4,
    tutor: {
      firstName: "Kaja",
      lastName: "Jones",
    },
    subject: {
      name: "Data Structure",
    },
    totalVideos: 9,
    totalPdfs: 7,
    timeSpent: "100h:30m",
    progress: 100,
  },
];

export const StudentSubjectStatisticTable = ({ className }) => {
  return (
    <div className={`student-subject-statistic-table ${className}`}>
      <div className="student-subject-statistic-table__container">
        <div className="student-subject-statistic-table__header">
          <h2 className="student-subject-statistic-table__heading">
            ENROLLED SUBJECTS
          </h2>

          <Button
            className="student-subject-statistic-table__btn btn--primary btn--sv18"
            to="/student/course-catalog"
          >
            <FaGraduationCap
              className="student-subject-statistic-table__btn-icon"
              aria-label="access course catalog"
            />
            COURSE CATALOG
          </Button>
        </div>

        <div className="student-subject-statistic-table__main">
          <EmptyContent className="student-subject-statistic-table__empty">
            <SubjectStatisticTable
              className="student-subject-statistic-table__stat-table"
              items={SUBJECTSTATISTICDATA}
            />
          </EmptyContent>
        </div>
      </div>
    </div>
  );
};

StudentSubjectStatisticTable.defaultProps = {
  className: "",
};
