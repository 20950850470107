// Modules
import React from "react";

// Hooks
import { useFormik } from "formik";
import { useValidateFormOnMouseEnter, useAuth } from "../../../hooks";

// Components
import { Link } from "react-router-dom";
import { Input, Form, Button } from "../../../components";

// Schemas
import { authSchema } from "../../../schemas";

// Style
import "./forgot-password.styles.scss";

export const ForgotPassword = () => {
  const { isAuthLoading, sendForgotPasswordEmail, dispatch } = useAuth();

  const {
    values,
    touched,
    errors,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
    validateForm,
    setTouched,
  } = useFormik({
    initialValues: {
      authType: "forgotPassword",
      email: "",
    },
    validationSchema: authSchema,
    onSubmit: async (values, formikHelper) => {
      // Send Forgot Password Email
      await dispatch(sendForgotPasswordEmail(values.email));

      // Reset Form State
      formikHelper.resetForm();
    },
  });

  const { triggerFormValidation } = useValidateFormOnMouseEnter(
    validateForm,
    setTouched
  );

  return (
    <div className="forgot-password">
      <div className="forgot-password__container">
        <h1 className="forgot-password__heading auth-layout__heading">
          It’s Alright!
          <br />
          We Have You Covered
        </h1>

        <p className="forgot-password__spacer spacer">
          <span className="spacer__text">Reset Your password</span>
        </p>

        <Form
          className="forgot-password__form auth-layout__form"
          onSubmit={handleSubmit}
        >
          <Input
            className="forgot-password__control"
            labelClassName="forgot-password__label"
            wrapperClassName="forgot-password__group"
            id="email"
            name="email"
            label="Email"
            type="email"
            placeholder="Please enter your email address"
            value={values.email}
            error={errors.email}
            touched={touched.email}
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <div className="forgot-password__form-btn-group auth-layout__form-btn-group">
            <Button
              className="forgot-password__btn auth-layout__form-btn btn--primary btn--rv2 btn--sv2"
              type="submit"
              disabled={isAuthLoading || !isValid}
              loading={isAuthLoading}
              onMouseEnter={() => triggerFormValidation(isValid)}
            >
              Reset
            </Button>
          </div>

          <p className="forgot-password__ref-link auth-layout__ref-link">
            Remember Password?{" "}
            <Link className="is-underline" to="/auth/login">
              Sign In
            </Link>
          </p>
        </Form>
      </div>
    </div>
  );
};
