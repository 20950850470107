// Modules
import { createSlice } from "@reduxjs/toolkit";

// Common Reducers
import { loadingReducers } from "../common";

// Utils
import { stateSelector } from "../../utils";

// Initial State
const initialState = {
  isLoading: false,
};

// Slice Name
const sliceName = "feedback";

// Feedback Slice
const feedbackSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ...loadingReducers,
  },
});

export const {
  startLoading: feedbackStartLoading,
  stopLoading: feedbackStopLoading,
} = feedbackSlice.actions;
export * from "./feedbackAction";
export const selectIsFeedbackLoading = stateSelector(sliceName, "isLoading");
export default feedbackSlice.reducer;
