/**
 * Get Specific Field From An Object
 * @param {string[]} fieldsName Array of field names (Note: Case sensitive)
 * @param {object} object Object to extract fields from
 */
export const getFieldFromObject = (fieldsName, object) => {
  // Convert Object To Array
  const objToArr = Object.entries(object);

  // Filter Array Using FieldsName
  const filteredArray = objToArr.filter(([key]) => fieldsName.includes(key));

  // Return Filtered Array As Object
  return Object.fromEntries(filteredArray);
};
