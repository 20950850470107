// Modules
import React from "react";

// Components
import { Card } from "../../../";

// Style
import "./home-services-list.styles.scss";

export const HomeServicesList = ({ className, items }) => {
  // Home Services Items
  const renderedHomeServicesItems = items.map((item) => (
    <Card
      className="home-services-item"
      key={item.id}
      title={item.title}
      image={item.image}
      imgWidth="100px"
      imgHeight="100px"
      summary={item.description}
      to={item.to}
      actionText="Learn More"
    />
  ));

  return (
    <div className={`home-services-list ${className}`}>
      {renderedHomeServicesItems}
    </div>
  );
};

HomeServicesList.defaultProps = {
  className: "",
  items: [],
};
