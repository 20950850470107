// Modules
import React from "react";

// Components
import { AddPrivateSessionModalBase } from "../../../../components";

// Hooks
import { useEffect } from "react";
import { usePrivateSession } from "../../../../hooks";

export const AddPrivateSessionModal = () => {
  const { dispatch, getPrivateSessions } = usePrivateSession();

  useEffect(() => {
    // Get Private Sessions And Populate The Tutor Field
    dispatch(getPrivateSessions(true));
  }, []);

  return <AddPrivateSessionModalBase />;
};
