/**
 * Check Whether A Date Is Today
 * @param {string|Date} date Date
 */
export const isToday = (date) => {
  return new Date(new Date(date)).isToday();
};

/**
 * Check Whether A Date Is Between The Current Week
 * @param {string|Date} date Date
 */
export const isBetweenCurrentWeek = (date) => {
  const isMonday = new Date().is().monday();
  const lastMonday = Date.last().monday();
  const nextMonday = Date.next().monday();
  const isBetween = new Date(date).between(
    isMonday ? Date.today() : lastMonday,
    nextMonday
  );
  return isBetween;
};

/**
 * Check Whether A Date Is Between The Current Month
 * @param {string|Date} date Date
 */
export const isBetweenCurrentMonth = (date) => {
  const firstDayOfMonth = Date.today().clearTime().moveToFirstDayOfMonth();
  const lastDayOfMonth = Date.today().clearTime().moveToLastDayOfMonth();
  const isBetween = new Date(date).between(firstDayOfMonth, lastDayOfMonth);
  return isBetween;
};

/**
 * Check Whether A Date Is Between The Specified Month
 * @param {string} month Month
 * @param {string|Date} date Date
 */
export const isBetweenMonth = (month, date) => {
  const firstDayOfMonth = Date.parse(month).clearTime().moveToFirstDayOfMonth();
  const lastDayOfMonth = Date.parse(month).clearTime().moveToLastDayOfMonth();
  const isBetween = new Date(date).between(firstDayOfMonth, lastDayOfMonth);
  return isBetween;
};

/**
 * Get Current Day Count
 * @param {array} Data Data to count from
 * @param {string} fieldName Field name to use for counting (Note: Must be field with date value)
 */
export const getCurrentDayCount = (data, fieldName) => {
  return data
    .filter((val) => val[fieldName])
    .reduce((sum, val) => (isToday(val[fieldName]) ? ++sum : sum), 0);
};

/**
 * Get Current Day Sum
 * @param {array} Data Data to count from
 * @param {string} fieldName Field name (Note: Must be field with date value)
 * @param {string} sumField Sum field (Note: Must be field with number value)
 */
export const getCurrentDaySum = (data, fieldName, sumField) => {
  return data
    .filter((val) => val[fieldName])
    .reduce((sum, val) => {
      const sumValue = val[sumField];
      const isBetweenToday = isToday(val[fieldName]);
      return isBetweenToday ? sum + sumValue : sum;
    }, 0);
};

/**
 * Get Current Week Count
 * @param {array} Data Data to count from
 * @param {string} fieldName Field name to use for counting (Note: Must be field with date value)
 */
export const getCurrentWeekCount = (data, fieldName) => {
  return data
    .filter((val) => val[fieldName])
    .reduce((sum, val) => {
      const isBetween = isBetweenCurrentWeek(val[fieldName]);
      return isBetween ? ++sum : sum;
    }, 0);
};

/**
 * Get Current Week Sum
 * @param {array} Data Data to count from
 * @param {string} fieldName Field name (Note: Must be field with date value)
 * @param {string} sumField Sum field (Note: Must be field with number value)
 */
export const getCurrentWeekSum = (data, fieldName, sumField) => {
  return data
    .filter((val) => val[fieldName])
    .reduce((sum, val) => {
      const sumValue = val[sumField];
      const isBetween = isBetweenCurrentWeek(val[fieldName]);
      return isBetween ? sum + sumValue : sum;
    }, 0);
};

/**
 * Get Current Month Count
 * @param {array} Data Data to count from
 * @param {string} fieldName Field name to use for counting (Note: Must be field with date value)
 */
export const getCurrentMonthCount = (data, fieldName) => {
  return data
    .filter((val) => val[fieldName])
    .reduce((sum, val) => {
      const isBetween = isBetweenCurrentMonth(val[fieldName]);
      return isBetween ? ++sum : sum;
    }, 0);
};

/**
 * Get Current Month Sum
 * @param {array} Data Data to count from
 * @param {string} fieldName Field name (Note: Must be field with date value)
 * @param {string} sumField Sum field (Note: Must be field with number value)
 */
export const getCurrentMonthSum = (data, fieldName, sumField) => {
  return data
    .filter((val) => val[fieldName])
    .reduce((sum, val) => {
      const sumValue = val[sumField];
      const isBetween = isBetweenCurrentMonth(val[fieldName]);
      return isBetween ? sum + sumValue : sum;
    }, 0);
};

/**
 * Get All Month Count
 * @param {array} Data Data to count from
 * @param {string} fieldName Field name to use for counting (Note: Must be field with date value)
 */
export const getAllMonthsCount = (data, fieldName) => {
  // All Months Count
  const allMonthsCount = {
    january: 0,
    february: 0,
    march: 0,
    april: 0,
    may: 0,
    june: 0,
    july: 0,
    august: 0,
    september: 0,
    october: 0,
    november: 0,
    december: 0,
  };

  // Get Cleaned Data
  const cleanedData = data.filter((val) => val[fieldName]);

  // Perform Counting For Months
  Object.keys(allMonthsCount).forEach((month) => {
    // Count All Data Related To Month
    const monthCount = cleanedData.reduce((sum, data) => {
      const fieldDate = data[fieldName];
      const isBetween = isBetweenMonth(month, fieldDate);
      return isBetween ? ++sum : sum;
    }, 0);

    // Update Month count
    allMonthsCount[month] = monthCount;
  });

  // Return All Month Count
  return allMonthsCount;
};

/**
 * Get All Month Sum
 * @param {array} Data Data to count from
 * @param {string} fieldName Field name (Note: Must be field with date value)
 * @param {string} sumField Sum field (Note: Must be field with number value)
 */
export const getAllMonthsSum = (data, fieldName, sumField) => {
  // All Months Sum
  const allMonthsSum = {
    january: 0,
    february: 0,
    march: 0,
    april: 0,
    may: 0,
    june: 0,
    july: 0,
    august: 0,
    september: 0,
    october: 0,
    november: 0,
    december: 0,
  };

  // Get Cleaned Data
  const cleanedData = data.filter((val) => val[fieldName]);

  // Perform Summing For Months
  Object.keys(allMonthsSum).forEach((month) => {
    // Sum All Data Related To Month
    const monthSum = cleanedData.reduce((sum, data) => {
      const sumValue = data[sumField];
      const fieldDate = data[fieldName];
      const isBetween = isBetweenMonth(month, fieldDate);
      return isBetween ? sum + sumValue : sum;
    }, 0);

    // Update Month Sum
    allMonthsSum[month] = monthSum;
  });

  // Return All Month Sum
  return allMonthsSum;
};

/**
 * Get Successful Operation Current Month Count
 * @param {array} Data Data to count from
 * @param {string} fieldName Field name to use for counting (Note: Must be field with date value)
 * @param {string} successFieldName Field name to use for filtering success (Note: Must be field with boolean value)
 */
export const getCurrentMonthCountSuccess = (
  data,
  fieldName,
  successFieldName
) => {
  return data
    .filter((val) => val[fieldName] && val[successFieldName])
    .reduce((sum, val) => {
      const isBetween = isBetweenCurrentMonth(val[fieldName]);
      return isBetween ? ++sum : sum;
    }, 0);
};
