// Hooks
import { useSelector, useDispatch } from "react-redux";

// Redux
import { paymentSA } from "../store";

// Destructure Redux Imports
const { selectIsPaymentLoading } = paymentSA;

/**
 * Payment Hook
 */
export const usePayment = () => {
  const dispatch = useDispatch();
  const isPaymentLoading = useSelector(selectIsPaymentLoading);

  return {
    isPaymentLoading,
    dispatch,
    ...paymentSA,
  };
};
