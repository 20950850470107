// Modules
import React from "react";

// Images
import { ReactComponent as CheckSuccess } from "../../../../assets/images/check-success.svg";

// Style
import "./accounting-solution-item.styles.scss";

export const AccountingSolutionItem = ({ className, title, items }) => {
  const renderedItems = items.map((item, i) => (
    <li className="accounting-solution-item__item" key={i}>
      <CheckSuccess
        className="accounting-solution-item__icon"
        aria-label="check"
      />
      {item}
    </li>
  ));

  return (
    <div className={`accounting-solution-item ${className}`}>
      <div
        className={`accounting-solution-item__top ${
          !items.length && "accounting-solution-item__top--pb"
        }`}
      >
        <p className="accounting-solution-item__content">{title}</p>
      </div>

      {!!items.length && (
        <div className="accounting-solution-item__bottom">
          <ul className="accounting-solution-item__list">{renderedItems}</ul>
        </div>
      )}
    </div>
  );
};

AccountingSolutionItem.defaultProps = {
  className: "",
  items: [],
};
