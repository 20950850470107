// Modules
import React from "react";

// Components
import { SubjectCard } from "../../../../..";

// Style
import "./student-paid-subject-list.styles.scss";

export const StudentPaidSubjectList = ({ className, items }) => {
  // Student Subject Card Items
  const renderedStudentSubjectCardItems = items.map((item) => (
    <SubjectCard
      key={item.id}
      className="student-paid-subject-list__item"
      thumbnail={item.thumbnail?.url}
      subjectName={item.name}
      subjectAuthor={`${item.tutor?.firstName} ${item.tutor?.lastName}`}
      numberOfChapter={item.chapters.length}
    />
  ));

  return (
    <div className={`student-paid-subject-list ${className}`}>
      {renderedStudentSubjectCardItems}
    </div>
  );
};

StudentPaidSubjectList.defaultProps = {
  className: "",
  items: [],
};
