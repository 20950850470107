// Modules
import React from "react";

// Components
import { Fragment } from "react";
import { Oval } from "react-loader-spinner";
import { Chart as ChartJS } from "chart.js";

// Style
import "./chart-wrapper.styles.scss";

export const ChartWrapper = ({
  className,
  wrapperClassName,
  isLoading,
  chartData,
  chartOptions,
  chartTitle,
  chartFilterDate,
  setChartFilterDate,
  ChartComponent,
  ChartInfo,
}) => {
  return (
    <div className={`chart-wrapper ${wrapperClassName}`}>
      {isLoading && (
        <Oval
          height={30}
          width={60}
          color="#f9a22f"
          wrapperClass="chart__loading-wrapper"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#f9a22f"
          strokeWidth={6}
          strokeWidthSecondary={6}
        />
      )}

      {/* If Chart Data Does Not Exist Then Show Empty Message */}
      {!chartData && !isLoading && (
        <div className="chart__empty">
          <h6 className="chart__empty-heading">
            We don’t have enough data to show anything useful.
          </h6>
          <p className="chart__empty-content">
            It usually takes about a week to populate this graph.
          </p>
        </div>
      )}

      {chartData && !isLoading && (
        <Fragment>
          {ChartInfo && (
            <div className="chart-wrapper__header">
              <ChartInfo
                chartTitle={chartTitle}
                chartFilterDate={chartFilterDate}
                setChartFilterDate={setChartFilterDate}
              />
            </div>
          )}

          <ChartComponent
            className={`chart ${className}`}
            data={chartData}
            options={chartOptions}
          />
        </Fragment>
      )}
    </div>
  );
};

ChartWrapper.defaultProps = {
  className: "",
  wrapperClassName: "",
  isLoading: false,
};
