// Modules
import React from "react";

// Components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { FiUsers } from "react-icons/fi";
import { StudentTableSubjectPreview, StudentProgressBar } from "../../";

// Style
import "./subject-statistic-table.styles.scss";

export const Row = ({
  subject,
  totalVideos,
  totalPdfs,
  timeSpent,
  progress,
  tutor,
}) => {
  return (
    <TableRow className="subject-statistic-table__body-row">
      <TableCell className="subject-statistic-table__body-cell" align="left">
        {/* <StudentTableSubjectPreview
          className="subject-statistic-table__subject-preview"
          subject={subject}
          totalVideos={totalVideos}
          totalPdfs={totalPdfs}
        /> */}
        Hello Joy
      </TableCell>

      <TableCell className="subject-statistic-table__body-cell" align="left">
        <div className="subject-statistic-table__tutor">
          <FiUsers
            className="subject-statistic-table__icon"
            aria-label="tutor name"
          />
          {`${tutor.firstName} ${tutor.lastName}`}
        </div>
      </TableCell>

      <TableCell className="subject-statistic-table__body-cell" align="left">
        {timeSpent}
      </TableCell>

      <TableCell className="subject-statistic-table__body-cell" align="left">
        {/* <StudentProgressBar
          className="subject-statistic-table__progress-bar"
          progress={progress}
        /> */}
        Hello World
      </TableCell>
    </TableRow>
  );
};

export const SubjectStatisticTable = ({ className, items }) => {
  // Subject Statistic Items
  const renderedSubjectStatisticItems = items.map(
    ({ id, subject, progress, timeSpent, totalVideos, totalPdfs, tutor }) => (
      <Row
        key={id}
        subject={subject}
        tutor={tutor}
        timeSpent={timeSpent}
        totalVideos={totalVideos}
        totalPdfs={totalPdfs}
        progress={progress}
      />
    )
  );

  return (
    <TableContainer className="subject-statistic-table" component={Paper}>
      <Table
        className="subject-statistic-table__table"
        sx={{ minWidth: 700 }}
        aria-label="subject statistic"
      >
        <TableHead className="subject-statistic-table__head">
          <TableRow className="subject-statistic-table__head-row">
            <TableCell
              className="subject-statistic-table__head-cell"
              align="left"
            >
              Course name
            </TableCell>
            <TableCell
              className="subject-statistic-table__head-cell"
              align="left"
            >
              Facilitator
            </TableCell>
            <TableCell
              className="subject-statistic-table__head-cell"
              align="left"
            >
              Time Spent
            </TableCell>
            <TableCell
              className="subject-statistic-table__head-cell"
              align="left"
            >
              Progress
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody className="subject-statistic-table__body">
          {renderedSubjectStatisticItems}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

SubjectStatisticTable.defaultProps = {
  className: "",
};
