/**
 * Capitalize Any Sentence
 * @param {string} words
 * @example capitalize("hello") // Hello
 */
export const capitalize = (words) => {
  const wordArr = words?.split(" ");
  return wordArr
    ?.map((word) => word.replace(word[0], word[0].toUpperCase()))
    .join(" ");
};
