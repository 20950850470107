// Actions
import { contactUsStartLoading, contactUsStopLoading } from ".";
import { uiSA } from "..";

// Apis
import * as apis from "../../apis";

// Utils
import { getSuccess } from "../../utils";

// Destructure Imports
const { showNotification } = uiSA;

/**
 * Submit User Enquiry
 * @param {object} enquiry Enquiry data
 */
export const postEnquiry = (enquiry) => async (dispatch) => {
  try {
    // Start Loading
    dispatch(contactUsStartLoading());

    // Post User Enquiry
    await apis.postEnquiry(enquiry);

    // Show Success Notification On Submitting User Enquiry
    dispatch(showNotification(getSuccess("contactUs/postEnquiry")));
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  } finally {
    // Stop Loading
    dispatch(contactUsStopLoading());
  }
};
