// Modules
import React from "react";

// Components
import { ThreeDots } from "react-loader-spinner";

// Images
import { ReactComponent as FinaccoLogo } from "../../../assets/images/finacco-logo-alt.svg";

// Style
import "./page-loading-styles.scss";

export const PageLoading = ({
  className,
  loadingColor,
  loadingRadius,
  loadingHeight,
  loadingWidth,
}) => {
  return (
    <div className={`page-loading ${className}`}>
      <div className="page-loading__container container">
        {/* Page Loading Logo */}
        <FinaccoLogo
          className="page-loading__logo"
          arial-label="Finacco Logo"
        />

        {/* Page Loading Spinner */}
        <ThreeDots
          width={loadingWidth}
          height={loadingHeight}
          radius={loadingRadius}
          color={loadingColor}
          ariaLabel="Page Loading"
          wrapperClass="page-loading__spinner"
          visible={true}
        />
      </div>
    </div>
  );
};

PageLoading.defaultProps = {
  className: "",
  loadingColor: "#f9a22f",
  loadingWidth: "42",
  loadingHeight: "30",
  loadingRadius: "4",
};
