// Firebase
import {
  getDocs,
  query,
  where,
  addDoc,
  updateDoc,
  doc,
  orderBy,
  documentId,
  serverTimestamp,
} from "firebase/firestore";
import {
  trainingCollectionRef,
  levelCollectionRef,
  firebaseLooper,
} from "../../utils";

// Utils
import { AppError } from "../../utils";

// Apis
import * as apis from "../";

/**
 * Populate Training Field
 * @param {array} levels Levels
 */
const populateTrainingInLevels = async (levels) => {
  try {
    // Populate Training Field
    const levelsWithTraining = await Promise.all(
      levels.map(async (level) => ({
        ...level,
        trainingId: level.trainingId.id,
        training: (await apis.getTraining(level.trainingId.id, false)) || {},
      }))
    );

    // Return Levels With Training
    return levelsWithTraining;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get All Levels For A Training
 * @param {string} trainingId Training id
 * @param {boolean} populateTrainingField If true populate training field
 */
export const getTrainingLevels = async (trainingId, populateTrainingField) => {
  try {
    // Training Doc Reference
    const trainingRef = doc(trainingCollectionRef, trainingId);

    // Create Level Query
    const levelQuery = query(
      levelCollectionRef,
      where("trainingId", "==", trainingRef),
      where("isActive", "==", true),
      orderBy("createdAt")
    );

    // Get Training Levels
    const querySnapshot = await getDocs(levelQuery);

    // If Training Levels Is Empty, Throw Custom Error
    if (querySnapshot.empty) {
      throw new AppError("empty-data");
    }

    // Populate Training Field In Levels If PopulateTrainingField Is True
    let levels = firebaseLooper(querySnapshot);
    levels = populateTrainingField
      ? await populateTrainingInLevels(levels)
      : levels;

    // Return Levels
    return levels;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get A Level
 * @param {string} levelId Level id
 * @param {boolean} throwNotFound If true throw not found error
 */
export const getLevel = async (levelId, throwNotFound = true) => {
  try {
    // Create Level Query
    const levelQuery = query(
      levelCollectionRef,
      where(documentId(), "==", levelId),
      where("isActive", "==", true)
    );

    // Get Level
    const querySnapshot = await getDocs(levelQuery);

    // If Level Is Empty & throwNotFound, Throw Custom Error
    if (querySnapshot.empty && throwNotFound) {
      throw new AppError("empty-data");
    }

    // Return Level
    return firebaseLooper(querySnapshot)[0];
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Create New Level
 * @param {object} levelData Level data
 * @param {string} trainingId Training id
 */
export const createLevel = async (levelData, trainingId) => {
  try {
    // Create Level
    await addDoc(levelCollectionRef, {
      ...levelData,
      isActive: true,
      trainingId: doc(trainingCollectionRef, trainingId),
      createdAt: serverTimestamp(),
    });

    // Get Training Levels
    const levels = await getTrainingLevels(trainingId, true);

    // Return Training Levels
    return levels;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Update A Level
 * @param {string} levelId Level id
 * @param {object} levelData Level data
 * @param {string} trainingId Training id
 */
export const updateLevel = async (levelId, levelData, trainingId) => {
  try {
    // Level Doc Reference
    const levelRef = doc(levelCollectionRef, levelId);

    // Update Level
    await updateDoc(levelRef, levelData);

    // Get Training Levels
    const levels = await getTrainingLevels(trainingId, true);

    // Return Training Levels
    return levels;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Delete A Level
 * @param {string} levelId Level id
 */
export const deleteLevel = async (levelId) => {
  try {
    // Level Doc Reference
    const levelRef = doc(levelCollectionRef, levelId);

    // Delete Level
    await updateDoc(levelRef, {
      isActive: false,
    });
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};
