// Firebase
import { where, query, getDocs, orderBy, documentId } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { userCollectionRef, functions, firebaseLooper } from "../../utils";

// Utils
import { AppError, getCurrentWeekCount } from "../../utils";

/**
 * Get All Staffs
 */
export const getStaffs = async () => {
  try {
    // Create Staff Query
    const staffQuery = query(
      userCollectionRef,
      where("isActive", "==", true),
      where("employment.isStaff", "==", true),
      orderBy("createdAt", "desc")
    );

    // Get Staffs
    const querySnapshot = await getDocs(staffQuery);

    // If Staffs Is Empty, Then Throw A Custom Error
    if (querySnapshot.empty) {
      throw new AppError("empty-data");
    }

    // Return Staffs
    return firebaseLooper(querySnapshot);
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get A Staff
 * @param {string} staffId Staff id
 * @param {boolean} onlyActive If true get only active staff
 * @param {boolean} throwNotFound If true throw not found error
 */
export const getStaff = async (
  staffId,
  onlyActive = true,
  throwNotFound = true
) => {
  try {
    // Create Staff Query
    const staffQuery = onlyActive
      ? query(
          userCollectionRef,
          where(documentId(), "==", staffId),
          where("isActive", "==", true),
          where("employment.isStaff", "==", true)
        )
      : query(
          userCollectionRef,
          where(documentId(), "==", staffId),
          where("employment.isStaff", "==", true)
        );

    // Get Staff
    const querySnapshot = await getDocs(staffQuery);

    // If Staff Is Empty & ThrowNotFound Is True, Then Throw A Custom Error
    if (querySnapshot.empty && throwNotFound) {
      throw new AppError("empty-data");
    }

    // Return Staff
    return firebaseLooper(querySnapshot)[0];
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Featured Staffs
 * @param {boolean} throwNotFound If true throw not found error
 */
export const getFeaturedStaffs = async (throwNotFound = true) => {
  try {
    // Create Staff Query
    const staffQuery = query(
      userCollectionRef,
      where("isActive", "==", true),
      where("employment.isStaff", "==", true),
      where("isFeatured", "==", true),
      orderBy("createdAt")
    );

    // Get Featured Staffs
    const querySnapshot = await getDocs(staffQuery);

    // If Featured Staffs Is Empty & ThrowNotFound Is True, Then Throw A Custom Error
    if (querySnapshot.empty && throwNotFound) {
      throw new AppError("empty-data");
    }

    // Return Featured Staffs
    return firebaseLooper(querySnapshot);
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Staffs Stat
 * @param {array} staffs Staffs
 */
const getStaffsStat = (staffs) => {
  // Get Total Staffs Count
  const totalStaffs = staffs.length;

  // Get Last Active At Current Week Count For Staffs
  const currentWeekLastActiveAtStaffCount = getCurrentWeekCount(
    staffs,
    "lastActiveAt"
  );

  // Return Stat
  return {
    totalStaffs,
    currentWeekLastActiveAtStaffCount,
  };
};

/**
 * Get Staffs And Stat
 */
export const getStaffsAndStat = async () => {
  try {
    // Get Staffs
    const staffs = await getStaffs();

    // Get Staffs Stat
    const stat = getStaffsStat(staffs);

    // Return Staffs And Stat
    return { staffs, stat };
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Create A New Staff
 * @param {object} staffData Staff data
 */
export const createStaff = async (staffData) => {
  try {
    // Create Staff
    const onCreateAdminUser = httpsCallable(functions, "createAdminUser");
    await onCreateAdminUser(staffData);

    // Get Staffs And Stat
    const staffsAndStat = await getStaffsAndStat();

    // Return Staffs And Stat
    return staffsAndStat;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Update A Staff
 * @param {string} staffId Staff id
 * @param {object} staffData Staff data
 */
export const updateStaff = async (staffId, staffData) => {
  try {
    // Update Staff
    const onUpdateAdminUser = httpsCallable(functions, "updateAdminUser");
    await onUpdateAdminUser({ staffData, staffId });

    // Get Staffs And Stat
    const staffsAndStat = await getStaffsAndStat();

    // Return Staffs And Stat
    return staffsAndStat;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Delete A Staff
 * @param {string} staffId Staff id
 */
export const deleteStaff = async (staffId) => {
  try {
    // Delete Staff
    const onDeleteAdminUser = httpsCallable(functions, "deleteAdminUser");
    await onDeleteAdminUser(staffId);

    // Get Staffs And Stat
    const { stat } = await getStaffsAndStat();

    // Return Staff Stat
    return stat;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};
