// Modules
import React from "react";

// Hooks
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useValidateFormOnMouseEnter, useAuth } from "../../../hooks";

// Components
import { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Input,
  Form,
  Button,
  DemoAuthDialogBase,
  SocialLogin,
} from "../../../components";

// Utils
import { getFieldFromObject, wait } from "../../../utils";

// Schemas
import { authSchema } from "../../../schemas";

// Style
import "./login.styles.scss";

export const Login = () => {
  const [isDemoAuthDialogOpen, setIsDemoAuthDialogOpen] = useState(false);
  const { isAuthLoading, loginUser, dispatch } = useAuth();

  const {
    values,
    touched,
    errors,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
    setTouched,
    validateForm,
  } = useFormik({
    initialValues: {
      authType: "login",
      email: "",
      password: "",
    },
    validationSchema: authSchema,
    onSubmit: async (values) => {
      // Create Login Data
      const loginData = getFieldFromObject(["email", "password"], values);

      // Sign In User
      await dispatch(loginUser(loginData));
    },
  });

  const { triggerFormValidation } = useValidateFormOnMouseEnter(
    validateForm,
    setTouched
  );

  useEffect(() => {
    (async () => {
      // Open Demo Auth Dialog After 1 Seconds If React Environment Is Development
      if (process.env.NODE_ENV === "development") {
        await wait(1000);
        setIsDemoAuthDialogOpen(true);
      }
    })();
  }, []);

  /**
   * Handle Close Demo Auth Dialog
   */
  const handleCloseDemoAuthDialog = () => {
    setIsDemoAuthDialogOpen(false);
  };

  return (
    <Fragment>
      {/* Demo Auth Dialog */}
      <DemoAuthDialogBase
        open={isDemoAuthDialogOpen}
        onClose={handleCloseDemoAuthDialog}
      />

      <div className="login">
        <div className="login__container">
          <h1 className="login__heading auth-layout__heading">
            Welcome Back!
            <br />
            We’re Happy to See You
          </h1>

          <p className="login__spacer spacer">
            <span className="spacer__text">Lets Continue</span>
          </p>

          <SocialLogin className="login__social-login" />

          <Form
            className="login__form auth-layout__form"
            onSubmit={handleSubmit}
          >
            <Input
              className="login-form__control"
              labelClassName="login-form__label"
              wrapperClassName="login-form__group"
              id="email"
              name="email"
              label="Email"
              type="email"
              placeholder="Please enter your email address"
              value={values.email}
              error={errors.email}
              touched={touched.email}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <Input
              className="login-form__control"
              labelClassName="login-form__label"
              wrapperClassName="login-form__group"
              type="password"
              id="password"
              name="password"
              label="Password"
              placeholder="Please enter your password"
              value={values.password}
              error={errors.password}
              touched={touched.password}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <div className="login__form-btn-group auth-layout__form-btn-group">
              <Button
                type="submit"
                className="login__btn auth-layout__form-btn btn--primary btn--rv2 btn--sv2"
                disabled={isAuthLoading || !isValid}
                loading={isAuthLoading}
                onMouseEnter={() => triggerFormValidation(isValid)}
              >
                Sign in
              </Button>
            </div>

            <Link
              className="login__ref-link auth-layout__ref-link is-underline"
              to="/auth/forgot-password"
            >
              Forgot Account?
            </Link>

            <p className="login__ref-link login__ref-link--alt auth-layout__ref-link">
              Don’t Have Account?{" "}
              <Link className="is-underline" to="/auth/signup">
                Sign Up
              </Link>
            </p>
          </Form>
        </div>
      </div>
    </Fragment>
  );
};
