// Modules
import { createSlice } from "@reduxjs/toolkit";

// Common Reducers
import { loadingReducers, errorMessageReducers } from "../common";

// Utils
import { stateSelector } from "../../utils";

// Initial State
const initialState = {
  referrerMediums: [],
  referrerMediumsStat: null,
  isLoading: false,
  errorMessage: "",
};

// Static Data
const sliceName = "referrerMedium";

// ReferrerMedium Slice
const referrerMediumSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ...loadingReducers,
    ...errorMessageReducers,
    setReferrerMediums(state, action) {
      state.referrerMediums = action.payload;
    },
    resetReferrerMediums(state) {
      state.referrerMediums = [];
    },
    setReferrerMediumsStat(state, action) {
      state.referrerMediumsStat = action.payload;
    },
    resetReferrerMediumsStat(state) {
      state.referrerMediumsStat = null;
    },
  },
});

export const {
  setReferrerMediums,
  resetReferrerMediums,
  startLoading: referrerMediumStartLoading,
  stopLoading: referrerMediumStopLoading,
  setErrorMessage: setReferrerMediumErrorMessage,
  resetErrorMessage: resetReferrerMediumErrorMessage,
  setReferrerMediumsStat,
  resetReferrerMediumsStat,
} = referrerMediumSlice.actions;
export * from "./referrerMediumAction";
export const selectReferrerMediums = stateSelector(
  sliceName,
  "referrerMediums"
);
export const selectReferrerMediumErrorMessage = stateSelector(
  sliceName,
  "errorMessage"
);
export const selectIsReferrerMediumLoading = stateSelector(
  sliceName,
  "isLoading"
);
export const selectReferrerMediumsStat = stateSelector(
  sliceName,
  "referrerMediumsStat"
);
export default referrerMediumSlice.reducer;
