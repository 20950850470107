// Modules
import React from "react";

// Components
import { Fragment } from "react";
import {
  Button,
  MoreAction,
  MoreActionDropdownItem,
  Dialog as DeleteConfirmationDialog,
} from "../../";

// Hooks
import { useDelete } from "../../../hooks";

// Style
import "./card.styles.scss";

export const Card = ({
  className,
  id,
  image,
  imgWidth,
  imgHeight,
  title,
  summary,
  to,
  actionText,
  enableAction,
  onDelete,
}) => {
  const {
    deleteCloseActionText,
    deleteConfirmActionText,
    isDeleteDialogOpen,
    onHandleCloseDeleteDialog,
    onHandleDeleteConfirm,
    onHandleOpenDeleteDialog,
  } = useDelete(onHandleDelete);

  /**
   * Handle Deletion
   */
  function onHandleDelete() {
    onDelete(id);
  }

  return (
    <Fragment>
      <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        dialogTitle="Confirm Delete Training"
        dialogDescription="You are about to delete training data. Confirm with yes to continue"
        closeActionText={deleteCloseActionText}
        confirmActionText={deleteConfirmActionText}
        onClose={onHandleCloseDeleteDialog}
        onConfirm={onHandleDeleteConfirm}
      />

      <div className={`card ${className}`}>
        <figure className="card__header">
          {!!image && (
            <img
              className="card__photo"
              src={image}
              alt={title}
              width={imgWidth}
              height={imgHeight}
            />
          )}
          <figcaption className="card__title">{title}</figcaption>
        </figure>

        <div className="card__main">
          <p
            className="card__content"
            dangerouslySetInnerHTML={{ __html: summary }}
          ></p>
          <Button className="card__btn btn--primary btn--rv2 btn--sv5" to={to}>
            {actionText}
          </Button>
        </div>

        {enableAction && (
          <MoreAction className="card__action">
            <MoreActionDropdownItem
              className="card__action-dropdown-item"
              to={`/admin/trainings/${id}`}
            >
              Edit
            </MoreActionDropdownItem>

            <MoreActionDropdownItem
              className="card__action-dropdown-item"
              onClick={onHandleOpenDeleteDialog}
            >
              Delete
            </MoreActionDropdownItem>
          </MoreAction>
        )}
      </div>
    </Fragment>
  );
};

Card.defaultProps = {
  className: "",
};
