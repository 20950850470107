// Modules
import React from "react";

// Hooks
import { useFormik } from "formik";
import {
  useReferrerMedium,
  useContactUs,
  useValidateFormOnMouseEnter,
} from "../../../../hooks";

// Components
import { FaCaretDown } from "react-icons/fa";
import { Form, Input, Button, InputSelect } from "../../../";

// Schemas
import { contactUsSchema } from "../../../../schemas";

// Style
import "./contact-form.styles.scss";

export const ContactForm = ({ className }) => {
  const {
    referrerMediumErrorMessage,
    referrerMediums,
    isReferrerMediumLoading,
  } = useReferrerMedium();

  const { isContactUsLoading, dispatch, postEnquiry } = useContactUs();

  const {
    values,
    errors,
    touched,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setTouched,
    validateForm,
  } = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phoneNumber: "",
      message: "",
      referrerMedium: "",
    },
    validationSchema: contactUsSchema,
    async onSubmit(values, formikHelpers) {
      // Submit Enquiry
      await dispatch(postEnquiry(values));

      // Reset Form
      formikHelpers.resetForm();
    },
  });

  const { triggerFormValidation } = useValidateFormOnMouseEnter(
    validateForm,
    setTouched
  );

  /**
   * Trigger Cross Form Validation On Mouse Enter
   */
  const onMouseEnterTriggerFormValidation = () =>
    triggerFormValidation(isValid);

  return (
    <Form
      className={`contact-form ${className}`}
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Input
        className="contact-form__control"
        labelClassName="contact-form__label"
        wrapperClassName="contact-form__group"
        name="fullName"
        id="fullName"
        placeholder="Name"
        value={values.fullName}
        error={errors.fullName}
        touched={touched.fullName}
        onChange={handleChange}
        onBlur={handleBlur}
        tertiary
      />

      <Input
        className="contact-form__control"
        labelClassName="contact-form__label"
        wrapperClassName="contact-form__group"
        type="email"
        name="email"
        id="email"
        placeholder="Email"
        value={values.email}
        error={errors.email}
        touched={touched.email}
        onBlur={handleBlur}
        onChange={handleChange}
        tertiary
      />

      <Input
        className="contact-form__control"
        labelClassName="contact-form__label"
        wrapperClassName="contact-form__group"
        name="phoneNumber"
        id="phoneNumber"
        placeholder="Phone number"
        value={values.phoneNumber}
        error={errors.phoneNumber}
        touched={touched.phoneNumber}
        onBlur={handleBlur}
        onChange={handleChange}
        tertiary
      />

      <Input
        className="contact-form__control contact-form__control--textarea"
        labelClassName="contact-form__label contact-form__label--textarea"
        wrapperClassName="contact-form__group contact-form__group--textarea"
        type="textarea"
        name="message"
        id="message"
        placeholder="Message"
        value={values.message}
        error={errors.message}
        touched={touched.message}
        onChange={handleChange}
        onBlur={handleBlur}
        tertiary
      />

      <InputSelect
        className="contact-form__control contact-form__control--select"
        labelClassName="contact-form__label contact-form__label--select"
        wrapperClassName="contact-form__group contact-form__group--select"
        name="referrerMedium"
        id="referrerMedium"
        placeholder="How did you find us?"
        isLoading={isReferrerMediumLoading}
        fetchError={referrerMediumErrorMessage}
        items={referrerMediums}
        DropdownIconComponent={FaCaretDown}
        error={errors.referrerMedium}
        value={values.referrerMedium}
        touched={touched.referrerMedium}
        onChange={setFieldValue}
        tertiary
      />

      <Button
        wrapperClassName="d-block"
        className="contact-form__btn is-width-full btn--primary btn--sv12"
        type="submit"
        loading={isContactUsLoading}
        loadingHeight="30"
        disabled={isContactUsLoading || !isValid}
        onMouseEnter={onMouseEnterTriggerFormValidation}
      >
        send
      </Button>
    </Form>
  );
};

ContactForm.defaultProps = {
  className: "",
};
