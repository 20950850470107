// Hooks
import { useSelector, useDispatch } from "react-redux";

// Redux
import { feedbackSA } from "../store";

// Destructure Redux Imports
const { selectIsFeedbackLoading } = feedbackSA;

/**
 * Feedback Hook
 */
export const useFeedback = () => {
  const dispatch = useDispatch();
  const isFeedbackLoading = useSelector(selectIsFeedbackLoading);

  return {
    isFeedbackLoading,
    dispatch,
    ...feedbackSA,
  };
};
