// Modules
import React from "react";

// Components
import { Navigate } from "react-router-dom";

// Hooks
import { useLocation } from "react-router-dom";
import { useAuth } from "../../hooks";

/**
 * Prevent Component Render If User Is Not Authenticated And Authorized
 * @param {object} Component React component
 * @param {number} role Prevent unauthorized access based on role number
 */
export const preventIfNotAuthHoc = (Component, role = 1) => {
  const PreventIfNotAuthHoc = (props) => {
    const { user } = useAuth();
    const location = useLocation();
    const hasAccess = user?.role === role;

    // If User Does Not Exist Then Navigate To Login Page
    if (!user)
      return <Navigate to="/auth/login" replace state={{ from: location }} />;

    // If User Exist And Has Required Access Then Render Provided Component
    if (user && hasAccess) return <Component {...props} />;

    // If User Exist And User Does Not Have Required Access But User Role Is 1 Then Navigate To Student Portal
    if (user && !hasAccess && user.role === 1)
      return <Navigate to="/student/course-catalog" replace />;

    // If User Exist And User Does Not Have Required Access But User Role Is 2 Then Navigate To Admin Dashboard
    if (user && !hasAccess && user.role === 2)
      return <Navigate to="/admin/students" replace />;
  };

  return PreventIfNotAuthHoc;
};
