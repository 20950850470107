// Modules
import React from "react";

// Components
import {
  Dialog as AlertDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Button } from "../";

// Style
import "./dialog.styles.scss";

export const Dialog = ({
  open,
  onClose,
  onConfirm,
  dialogTitle,
  dialogDescription,
  closeActionText,
  confirmActionText,
}) => {
  return (
    <AlertDialog
      className="dialog"
      open={open}
      onClose={onClose}
      aria-labelledby={dialogTitle}
      aria-describedby={dialogDescription}
    >
      <DialogTitle className="dialog__title">{dialogTitle}</DialogTitle>

      <DialogContent className="dialog__content">
        <DialogContentText className="dialog__content-text">
          {dialogDescription}
        </DialogContentText>
      </DialogContent>

      <DialogActions className="dialog__actions">
        <Button
          className="dialog__btn btn--tertiary btn--rv1 btn--sv11"
          onClick={onClose}
        >
          {closeActionText}
        </Button>
        <Button
          className="dialog__btn btn--primary btn--rv1 btn--sv11"
          onClick={onConfirm}
        >
          {confirmActionText}
        </Button>
      </DialogActions>
    </AlertDialog>
  );
};

Dialog.defaultProps = {
  open: false,
  confirmActionText: "Yes",
  closeActionText: "No",
};
