// Modules
import React from "react";

// Components
import { IconButton } from "@mui/material";
import { IoIosClose } from "react-icons/io";

// Style
import "./modal.styles.scss";

export const Modal = ({ className, children }) => {
  return (
    <div className={`modal ${className}`}>
      <IconButton className="modal__close-icon-btn">
        <IoIosClose className="modal__close-icon" aria-label="close modal" />
      </IconButton>
      <div className="modal__main">{children}</div>
    </div>
  );
};

Modal.defaultProps = {
  className: "",
};
