// Redux
import {
  studentStartLoading,
  studentStopLoading,
  setStudentErrorMessage,
  resetStudentErrorMessage,
  setEnrolledSubjects,
  setStudentStat,
  setPayPerSubjectSubscriptionEnrolledSubjects,
  setRecurrentSubscriptionEnrolledSubjects,
  setStudents,
  setLoadingStatName,
  delStudent,
  resStudent,
} from ".";
import { uiSA } from "..";

// Apis
import * as apis from "../../apis";

// Utils
import { getSuccess } from "../../utils";

// Destructure Redux Imports
const { showNotification } = uiSA;

/**
 * Get Graph Students Stat
 * @param {number} startYear Start year
 * @param {number} compareYear Compare year
 */
export const getGraphStudentsStat =
  (startYear, compareYear) => async (dispatch, getState) => {
    try {
      // Start Loading
      dispatch(studentStartLoading());

      // Reset Error Message
      dispatch(resetStudentErrorMessage());

      // Update Loading Stat Name
      dispatch(setLoadingStatName("graphStat"));

      // Get Graph Students Stat
      const stat = await apis.getGraphStudentsStat(startYear, compareYear);

      // Get Previous Stat
      const previousStat = getState().student.studentStat;

      // Update Stat
      dispatch(setStudentStat({ ...previousStat, ...stat }));
    } catch (err) {
      // Update Error Message
      dispatch(setStudentErrorMessage(err.message.message));
    } finally {
      // Stop Loading
      dispatch(studentStopLoading());
    }
  };

/**
 * Get Students Stat For Month
 * @param {number|undefined} month Lookup month (Note: month should start from zero)
 */
export const getMonthStudentsStat = (month) => async (dispatch, getState) => {
  try {
    // Start Loading
    dispatch(studentStartLoading());

    // Reset Error Message
    dispatch(resetStudentErrorMessage());

    // Update Loading Stat Name
    dispatch(setLoadingStatName("monthStat"));

    // Get Students Stat For Month
    const stat = await apis.getMonthStudentsStat(month);

    // Get Previous Stat
    const previousStat = getState().student.studentStat;

    // Update Stat
    dispatch(setStudentStat({ ...previousStat, ...stat }));
  } catch (err) {
    // Update Error Message
    dispatch(setStudentErrorMessage(err.message.message));
  } finally {
    // Stop Loading
    dispatch(studentStopLoading());
  }
};

/**
 * Enroll Student In Pay Per Subject Subscription
 * @param {string} studentId Student id
 * @param {array} subjectIds Subject ids
 */
export const enrollStudentPayPerSubjectSubscription =
  (studentId, subjectIds, studyMethod) => async (dispatch) => {
    try {
      // Start Loading
      dispatch(studentStartLoading());

      // Enroll Student In Pay Per Subject Subscription
      const studentPayperSubjectSubscriptionSubjects =
        await apis.enrollStudentPayPerSubjectSubscription(
          studentId,
          subjectIds,
          studyMethod
        );

      // Show Success Notification On Enrolling Student
      dispatch(showNotification(getSuccess("student-enrollment")));

      // Update Students Pay Per Subject Subscription Subjects
      dispatch(
        setPayPerSubjectSubscriptionEnrolledSubjects(
          studentPayperSubjectSubscriptionSubjects
        )
      );
    } catch (err) {
      // Show Error In Notification
      dispatch(showNotification(err.message));
    } finally {
      // Stop Loading
      dispatch(studentStopLoading());
    }
  };

/**
 * Enroll Student In Pay Per Subject Subscription
 */
export const enrollStudentRecurrentSubscription =
  (studentId, levelId, trainingId, type, studyMethod) => async (dispatch) => {
    try {
      // Start Loading
      dispatch(studentStartLoading());

      // Enroll Student In Pay Per Subject Subscription
      const studentRecurrentSubscriptionSubjects =
        await apis.enrollStudentRecurrentSubscription(
          studentId,
          levelId,
          trainingId,
          type,
          studyMethod
        );

      // Show Success Notification On Enrolling Student
      dispatch(showNotification(getSuccess("student-enrollment")));

      // Update Students Pay Per Subject Subscription Subjects
      dispatch(
        setRecurrentSubscriptionEnrolledSubjects(
          studentRecurrentSubscriptionSubjects
        )
      );
    } catch (err) {
      // Show Error In Notification
      dispatch(showNotification(err.message));
    } finally {
      // Stop Loading
      dispatch(studentStopLoading());
    }
  };

/**
 * Un-Enroll Student In Pay Per Subject Subscription
 * @param {string} studentId Student id
 * @param {array} subjectIds Subject ids
 */
export const unEnrollStudentPayPerSubjectSubscription =
  (studentId, subjectIds) => async (dispatch) => {
    try {
      // Start Loading
      dispatch(studentStartLoading());

      // Un-Enroll Student In Pay Per Subject Subscription
      const studentPayperSubjectSubscriptionSubjects =
        await apis.unEnrollStudentPayPerSubjectSubscription(
          studentId,
          subjectIds
        );

      // Show Success Notification On Un-Enrolling Student
      dispatch(showNotification(getSuccess("student-enrollment")));

      // Update Students Pay Per Subject Subscription Subjects
      dispatch(
        setPayPerSubjectSubscriptionEnrolledSubjects(
          studentPayperSubjectSubscriptionSubjects
        )
      );
    } catch (err) {
      // Show Error In Notification
      dispatch(showNotification(err.message));
    } finally {
      // Stop Loading
      dispatch(studentStopLoading());
    }
  };

/**
 * Get Student Pay Per Subject Subscription Enrolled Subjects
 * @param {string} studentId Student id
 */
export const getStudentPayPerSubjectSubscriptionsEnrolledSubjects =
  (studentId) => async (dispatch) => {
    try {
      // Start Loading
      dispatch(studentStartLoading());

      // Get Student Pay Per Subject Subscription Subjects
      const studentPayperSubjectSubscriptionSubjects =
        await apis.getStudentPayPerSubjectSubscriptionsEnrolledSubjects(
          studentId,
          false
        );

      // Update Students Pay Per Subject Subscription Subjects
      dispatch(
        setPayPerSubjectSubscriptionEnrolledSubjects(
          studentPayperSubjectSubscriptionSubjects
        )
      );
    } catch (err) {
      // Show Error In Notification
      dispatch(showNotification(err.message));
    } finally {
      // Stop Loading
      dispatch(studentStopLoading());
    }
  };

/**
 * Get Student Recurrent Subscription Enrolled Subjects
 * @param {string} studentId Student id
 */
export const getStudentRecurrentSubscriptionsEnrolledSubjects =
  (studentId) => async (dispatch) => {
    try {
      // Start Loading
      dispatch(studentStartLoading());

      // Get Student Recurrent Subscription Subjects
      const studentRecurrentSubscriptionSubjects =
        await apis.getStudentRecurrentSubscriptionsEnrolledSubjects(
          studentId,
          false
        );

      // Update Students Recurrent Subscription Subjects
      dispatch(
        setRecurrentSubscriptionEnrolledSubjects(
          studentRecurrentSubscriptionSubjects
        )
      );
    } catch (err) {
      // Show Error In Notification
      dispatch(showNotification(err.message));
    } finally {
      // Stop Loading
      dispatch(studentStopLoading());
    }
  };

/**
 * Get Student Enrolled Subjects
 * @param {string} studentId Student id
 */
export const getStudentEnrolledSubjects = (studentId) => async (dispatch) => {
  try {
    // Reset Error Message
    dispatch(resetStudentErrorMessage());

    // Start Loading
    dispatch(studentStartLoading());

    // Get Student Enrolled Subjects
    const enrolledSubjects = await apis.getStudentEnrolledSubjects(studentId);

    // Update Student Enrolled Subjects
    dispatch(setEnrolledSubjects(enrolledSubjects));
  } catch (err) {
    // Update Error Message
    dispatch(setStudentErrorMessage(err.message.message));
  } finally {
    // Stop Loading
    dispatch(studentStopLoading());
  }
};

/**
 * Get All Students And Stat
 * @param {boolean} onlyActive If true get only active student
 */
export const getStudentsAndStat =
  (onlyActive = true) =>
  async (dispatch, getState) => {
    try {
      // Reset Error Message
      dispatch(resetStudentErrorMessage());

      // Start Loading
      dispatch(studentStartLoading());

      // Get Students And Stat
      const { students, stat } = await apis.getStudentsAndStat(onlyActive);

      // Update Students
      dispatch(setStudents(students));

      // Get Previous Stat
      const previousStat = getState().student.studentStat;

      // Update Stat
      dispatch(setStudentStat({ ...previousStat, ...stat }));
    } catch (err) {
      // Update Error Message
      dispatch(setStudentErrorMessage(err.message.message));
    } finally {
      // Stop Loading
      dispatch(studentStopLoading());
    }
  };

/**
 * Delete A Student
 * @param {string} studentId Student id
 */
export const deleteStudent = (studentId) => async (dispatch, getState) => {
  try {
    // Delete Student
    const stat = await apis.deleteStudent(studentId);

    // Delete Student In Redux Store
    dispatch(delStudent(studentId));

    // Show Success Notification On Deleting Student
    dispatch(showNotification(getSuccess("deleted")));

    // Get Previous Stat
    const previousStat = getState().student.studentStat;

    // Update Stat
    dispatch(setStudentStat({ ...previousStat, ...stat }));
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  }
};

/**
 * Restore A Student
 * @param {string} studentId Student id
 */
export const restoreStudent = (studentId) => async (dispatch, getState) => {
  try {
    // Restore Student
    const stat = await apis.restoreStudent(studentId);

    // Restore Student In Redux Store
    dispatch(resStudent(studentId));

    // Show Success Notification On Restoring Student
    dispatch(showNotification(getSuccess("restored")));

    // Get Previous Stat
    const previousStat = getState().student.studentStat;

    // Update Stat
    dispatch(setStudentStat({ ...previousStat, ...stat }));
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  }
};
