// Modules
import React from "react";

// Components
import { AddSubjectModalBase } from "../../../../../components";

// Hooks
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSubject } from "../../../../../hooks";

export const AddSubjectModal = () => {
  const { levelId } = useParams();
  const { dispatch, getLevelSubjectsAndStat } = useSubject();

  useEffect(() => {
    // Get Level Subjects And Stat
    dispatch(getLevelSubjectsAndStat(levelId));
  }, [levelId]);

  return <AddSubjectModalBase />;
};
