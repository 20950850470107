// Modules
import React from "react";
import { createPortal } from "react-dom";

// Hooks
import { useNavigate, useLocation } from "react-router-dom";

// Style
import "./modal-overlay.styles.scss";

export const ModalOverlay = ({ className, children, to, replace, onClose }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  /**
   * Close Modal Overlay
   * @param {Event} e Event Object
   */
  const closeModalOverlay = (e) => {
    const isModalOverlayEl = e.target.classList.contains("modal-overlay");
    const isModalOverlayContainerEl = e.target.classList.contains(
      "modal-overlay__container"
    );
    const modalCloseBtnEl = e.target.closest(".modal__close-icon-btn");

    // If The Target Is Either ModalCloseBtn Or ModalOverlay Or  ModalOverlayContainer Element Then Close Modal
    if (isModalOverlayEl || modalCloseBtnEl || isModalOverlayContainerEl) {
      // Trigger OnClose Function When Modal Close
      onClose();

      // If Navigation Path Is Specified Then Navigate To Specified Path
      to && navigate(to, { replace: replace });
    }
  };

  return createPortal(
    <div
      className={`modal-overlay ${className} ${
        pathname.includes("admin") ? "modal-overlay--font-2" : ""
      }`}
      onClick={closeModalOverlay}
    >
      <div className="modal-overlay__container container">{children}</div>
    </div>,
    document.getElementById("modal")
  );
};

ModalOverlay.defaultProps = {
  className: "",
  replace: false,
  onClose: () => null,
};
