// Modules
import React from "react";

// Hooks
import { useParams } from "react-router-dom";
import { useDelete } from "../../../../../../hooks";

// Components
import { Fragment } from "react";
import {
  Button,
  MoreAction,
  MoreActionDropdownItem,
  Dialog as DeleteConfirmationDialog,
} from "../../../../../";

// Style
import "./level-card.styles.scss";

export const LevelCard = ({
  className,
  id,
  levelName,
  pricePerMonth,
  pricePerQuarter,
  pricePerYear,
  enableAction,
  onDeleteLevel,
}) => {
  const { trainingId } = useParams();
  const {
    deleteCloseActionText,
    deleteConfirmActionText,
    isDeleteDialogOpen,
    onHandleCloseDeleteDialog,
    onHandleDeleteConfirm,
    onHandleOpenDeleteDialog,
  } = useDelete(onHandleDeleteLevel);

  /**
   * Handle Delete Level
   */
  function onHandleDeleteLevel() {
    onDeleteLevel(id);
  }

  return (
    <Fragment>
      <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        dialogTitle="Confirm Delete Level"
        dialogDescription="You are about to delete level data. Confirm with yes to continue"
        closeActionText={deleteCloseActionText}
        confirmActionText={deleteConfirmActionText}
        onClose={onHandleCloseDeleteDialog}
        onConfirm={onHandleDeleteConfirm}
      />

      <div className={`level-card ${className}`}>
        <div className="level-card__header">
          <p className="level-card__title">{levelName}</p>
        </div>

        <div className="level-card__main">
          <p className="level-card__text">
            Price Per Month{" "}
            <strong className="is-text-bold">${pricePerMonth}</strong>
          </p>
          <p className="level-card__text">
            Price Per Quarter{" "}
            <strong className="is-text-bold">${pricePerQuarter}</strong>
          </p>
          <p className="level-card__text">
            Price Per Year{" "}
            <strong className="is-text-bold">${pricePerYear}</strong>
          </p>
        </div>

        <div className="level-card__footer">
          <Button
            className="level-card__btn btn--primary btn--rv2 btn--sv5"
            to={`/admin/trainings/${trainingId}/${id}/subjects`}
          >
            View
          </Button>
        </div>

        {enableAction && (
          <MoreAction className="level-card__action">
            <MoreActionDropdownItem
              className="level-card__action-dropdown-item"
              to={`/admin/trainings/${trainingId}/levels/${id}`}
            >
              Edit
            </MoreActionDropdownItem>

            <MoreActionDropdownItem
              className="card__action-dropdown-item"
              onClick={onHandleOpenDeleteDialog}
            >
              Delete
            </MoreActionDropdownItem>
          </MoreAction>
        )}
      </div>
    </Fragment>
  );
};

LevelCard.defaultProps = {
  className: "",
};
