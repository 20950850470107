// Modules
import React from "react";

// Hooks
import { useFormik } from "formik";
import { useNewsletter } from "../../../hooks";

// Components
import { Link } from "react-router-dom";
import { Form, Input, Button } from "../";

// Schemas
import { authSchema } from "../../../schemas";

// Images
import { ReactComponent as FinaccoLogo } from "../../../assets/images/finacco-logo.svg";

// Style
import "./footer.styles.scss";

// Static Data
const currentYear = new Date().getFullYear();

export const Footer = ({ className }) => {
  const { dispatch, subscribeToNewsletter, isNewsletterLoading } =
    useNewsletter();

  const {
    values,
    errors,
    isValid,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: authSchema,
    async onSubmit(values, formikHelpers) {
      await dispatch(subscribeToNewsletter(values.email));

      // Reset Form
      formikHelpers.resetForm();
    },
  });

  return (
    <div className={`footer ${className}`}>
      <div className="footer__container container">
        <div className="footer__brand">
          <FinaccoLogo
            className="footer__brand-logo"
            arial-label="Finacco Logo"
          />

          <span className="footer__line footer__brand-line"></span>
          <div className="footer__brand-heading-wrapper">
            <h3 className="footer__brand-heading">
              Online Accounting Study Platform
            </h3>
          </div>
        </div>

        <div className="footer__newsletter">
          <p className="footer__newsletter-heading">
            Subscribe to get our Newsletter
          </p>

          <Form className="footer__newsletter-form" onSubmit={handleSubmit}>
            <Input
              wrapperClassName="footer__newsletter-control-group"
              className="footer__newsletter-control"
              id="newsLetterEmail"
              name="email"
              placeholder="Your Email"
              value={values.email}
              touched={touched.email}
              error={errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <Button
              type="submit"
              className="footer__newsletter-btn btn--sv6 btn--rv4 btn--secondary"
              disabled={!isValid || isNewsletterLoading}
              loading={isNewsletterLoading}
            >
              Subscribe
            </Button>

            {touched && errors.email && (
              <div className="footer__newsletter-control-error-wrapper form__error-wrapper is-width-full">
                <p className="form__error footer__newsletter-control-error  is-text-left">
                  {errors.email}
                </p>
              </div>
            )}
          </Form>
        </div>

        <nav className="footer__nav">
          <ul className="footer__nav-list">
            <li className="footer__nav-item">
              <Link className="footer__nav-link" to="/careers">
                Careers
              </Link>
            </li>

            <span className="footer__nav-line footer__line"></span>
            <li className="footer__nav-item">
              <Link className="footer__nav-link" to="/privacy-policy">
                Privacy Policy
              </Link>
            </li>

            <span className="footer__nav-line footer__line"></span>
            <li className="footer__nav-item">
              <Link className="footer__nav-link" to="/terms-condition">
                Terms & Conditions
              </Link>
            </li>
          </ul>
        </nav>

        <p className="footer__copyright">&copy;{currentYear} Finacco Hub </p>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  className: "",
};
