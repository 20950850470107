// Redux
import {
  setTransactionErrorMessage,
  resetTransactionErrorMessage,
  setTransactions,
  transactionStartLoading,
  transactionStopLoading,
  setTransactionStat,
  delTransaction,
  setSalesStat,
  setLoadingStatName,
} from ".";
import { uiSA } from "..";

// Utils
import { getSuccess } from "../../utils";

// Apis
import * as apis from "../../apis";

// Destructure Redux Imports
const { showNotification } = uiSA;

/**
 * Get Graph Transaction Sales Stat
 * @param {number} startYear Start year
 * @param {number} compareYear Compare year
 */
export const getGraphTransactionSalesStat =
  async (startYear, compareYear) => async (dispatch, getState) => {
    try {
      // Start Loading
      dispatch(transactionStartLoading());

      // Reset Error Message
      dispatch(resetTransactionErrorMessage());

      // Update Loading Stat Name
      dispatch(setLoadingStatName("graphStat"));

      // Get Graph Transaction Sales Stat
      const stat = await apis.getGraphTransactionSalesStat(
        startYear,
        compareYear
      );

      // Get Previous Sales Stat
      const previousSalesStat = getState().transaction.salesStat;

      // Update Sales Stat
      dispatch(setSalesStat({ ...previousSalesStat, ...stat }));
    } catch (err) {
      // Update Error Message
      dispatch(setTransactionErrorMessage(err.message.message));
    } finally {
      // Stop Loading
      dispatch(transactionStopLoading());
    }
  };

/**
 * Get Transaction Sales For Month
 * @param {number|undefined} month Lookup month (Note: month should start from zero)
 */
export const getMonthTransactionSalesStat =
  (month) => async (dispatch, getState) => {
    try {
      // Start Loading
      dispatch(transactionStartLoading());

      // Reset Error Message
      dispatch(resetTransactionErrorMessage());

      // Update Loading Stat Name
      dispatch(setLoadingStatName("monthStat"));

      // Get Transaction Sales Stat For Month
      const stat = await apis.getMonthTransactionSalesStat(month);

      // Get Previous Sales Stat
      const previousSalesStat = getState().transaction.salesStat;

      // Update Sales Stat
      dispatch(setSalesStat({ ...previousSalesStat, ...stat }));
    } catch (err) {
      // Update Error Message
      dispatch(setTransactionErrorMessage(err.message.message));
    } finally {
      // Stop Loading
      dispatch(transactionStopLoading());
    }
  };

/**
 * Get All Transactions And Stat
 * @param {boolean} populateStudentField If true populate student field
 */
export const getTransactionsAndStat =
  (populateStudentField) => async (dispatch) => {
    try {
      // Reset Error Message
      dispatch(resetTransactionErrorMessage());

      // Start Loading
      dispatch(transactionStartLoading());

      // Get Transactions And Stat
      const { transactions, stat } = await apis.getTransactionsAndStat(
        populateStudentField
      );

      // Update Transactions
      dispatch(setTransactions(transactions));

      // Update Stat
      dispatch(setTransactionStat(stat));
    } catch (err) {
      // Update Error Message
      dispatch(setTransactionErrorMessage(err.message.message));
    } finally {
      // Stop Loading
      dispatch(transactionStopLoading());
    }
  };

/**
 * Delete A Transaction
 * @param {string} transactionId Transaction id
 */
export const deleteTransaction = (transactionId) => async (dispatch) => {
  try {
    // Delete Transaction
    const stat = await apis.deleteTransaction(transactionId);

    // Delete Transaction In Redux Store
    dispatch(delTransaction(transactionId));

    // Show Success Notification On Deleting Transaction
    dispatch(showNotification(getSuccess("deleted")));

    // Update Stat
    dispatch(setTransactionStat(stat));
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  }
};
