// Modules
import React from "react";

// Components
import { Fragment } from "react";
import {
  MoreAction,
  MoreActionDropdownItem,
  Dialog as DeleteConfirmationDialog,
} from "../../../";

// Hooks
import { useLocation } from "react-router-dom";
import { useDelete } from "../../../../hooks";

// Style
import "./video-chapter-card.styles.scss";

export const VideoChapterCard = ({
  className,
  chapterId,
  chapterName,
  chapterTotalDuration,
  activeChapter,
  setActiveChapter,
  isEditMode,
  onDeleteChapter,
}) => {
  const { search } = useLocation();
  const {
    deleteCloseActionText,
    deleteConfirmActionText,
    isDeleteDialogOpen,
    onHandleCloseDeleteDialog,
    onHandleDeleteConfirm,
    onHandleOpenDeleteDialog,
  } = useDelete(onHandleDeleteChapter);

  /**
   * Handle Delete Chapter
   */
  function onHandleDeleteChapter() {
    onDeleteChapter(chapterId);
  }

  return (
    <Fragment>
      <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        dialogTitle="Confirm Delete Chapter"
        dialogDescription="You are about to delete chapter data. Confirm with yes to continue"
        closeActionText={deleteCloseActionText}
        confirmActionText={deleteConfirmActionText}
        onClose={onHandleCloseDeleteDialog}
        onConfirm={onHandleDeleteConfirm}
      />

      <div
        className={`video-chapter-card ${
          activeChapter === chapterId && "video-chapter-card--active"
        } ${className}`}
        onClick={setActiveChapter.bind(null, chapterId)}
      >
        <div className="video-chapter-card__container">
          <p
            className={`video-chapter-card__text ${
              !isEditMode && "is-text-center"
            }`}
          >
            {chapterName} {!isEditMode && `(${chapterTotalDuration})`}
          </p>

          {isEditMode && (
            <MoreAction className="video-chapter-card__action">
              <MoreActionDropdownItem
                className="video-chapter-card__action-dropdown-item"
                to={`/player/chapter/${chapterId}${search}`}
              >
                Edit
              </MoreActionDropdownItem>
              <MoreActionDropdownItem
                className="video-chapter-card__action-dropdown-item"
                onClick={onHandleOpenDeleteDialog}
              >
                Delete
              </MoreActionDropdownItem>
            </MoreAction>
          )}
        </div>
      </div>
    </Fragment>
  );
};

VideoChapterCard.defaultProps = {
  className: "",
};
