// Modules
import React from "react";
import "datejs";

// Hooks
import { useState, useEffect } from "react";

// Components
import { Fragment } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Line } from "react-chartjs-2";
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";
import { FcFilledFilter } from "react-icons/fc";
import { ChartWrapper, DashboardFilterModal } from "../../../";

// Style
import "swiper/scss";
import "./chart-info.styles.scss";
import "./dashboard-chart-statistic.styles.scss";

// Static Data
const MONTHLY_CHART_DATA = {
  2022: {
    Jan: 10,
    Feb: 6,
    Mar: 9,
    Apr: 1000.5,
    May: 9,
    Jun: 99,
    Jul: 100,
    Aug: 180,
    Sep: 800,
    Oct: 600,
    Nov: 300,
    Dec: 100,
  },
  2023: {
    Jan: 200,
    Feb: 400,
    Mar: 3,
    Apr: 3.5,
    May: 800,
    Jun: 6,
    Jul: 5,
    Aug: 600,
    Sep: 5,
    Oct: 6,
    Nov: 8,
    Dec: 10,
  },
};

const CHART_OPTIONS = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

const INITIAL_CHART_FILTER_DATE = {
  from: new Date(),
  to: Date.last().year(),
};

export const ChartInfo = ({
  chartTitle,
  chartFilterDate,
  setChartFilterDate,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const fromFilterYear = chartFilterDate.from.toString("yyyy");
  const toFilterYear = chartFilterDate.to.toString("yyyy");

  return (
    <Fragment>
      <DashboardFilterModal
        className="dashboard-chart-statistic__filter-modal"
        open={isFilterOpen}
        isYearFilter
        chartFilterDate={chartFilterDate}
        onClose={setIsFilterOpen.bind(null, false)}
        setChartFilterDate={setChartFilterDate}
      />

      <div className="chart-info">
        <div className="chart-info__left">
          <h6 className="chart-info__title">{chartTitle}</h6>

          <div
            className="chart-info__filter"
            onClick={setIsFilterOpen.bind(null, !isFilterOpen)}
          >
            <FcFilledFilter
              className="chart-info__filter-icon"
              aria-label={`filter ${chartTitle}`}
            />
            <span className="chart-info__filter-text">Filter</span>
          </div>
        </div>

        <div className="chart-info__right">
          <p className="chart-info__legend">
            <span className="chart-info__legend-box" />
            {fromFilterYear}
          </p>

          <p className="chart-info__legend">
            <span className="chart-info__legend-box" />
            {toFilterYear}
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export const DashboardChartStatistic = ({ className }) => {
  const [studentChartData, setStudentChartData] = useState(null);
  const [transactionChartData, setTransactionChartData] = useState(null);
  const [studentChartFilterDate, setStudentChartFilterDate] = useState(
    INITIAL_CHART_FILTER_DATE
  );
  const [transactionChartFilterDate, setTransactionChartFilterDate] = useState(
    INITIAL_CHART_FILTER_DATE
  );
  const [swiperInstance, setSwiperInstance] = useState(null);

  /**
   * Handle Update Plot Type Chart Data
   * @param {string} type Plot type
   * @param {object} chartData Chart filter date
   * @param {object} chartData Chart data
   */
  const handleUpdateChartData = (
    type = "student",
    chartFilterDate,
    chartData
  ) => {
    // If Chart Data Is Empty, Then Return Early
    if (!Object.keys(chartData).length) return;

    // Get Filter Year
    const fromFilterYear = chartFilterDate.from.toString("yyyy");
    const toFilterYear = chartFilterDate.to.toString("yyyy");

    // Compute From Chart Data
    const fromChartData = Object.values(chartData[fromFilterYear]);

    // Compute To Chart Data
    const toChartData = Object.values(chartData[toFilterYear]);

    // Compute Chart Labels
    const chartLabels = Object.keys(chartData[fromFilterYear]).map(
      (chartDataKey) => chartDataKey.toUpperCase()
    );

    // Determine Setter Function
    const setterFunction =
      type === "student" ? setStudentChartData : setTransactionChartData;

    // Update Student Or Transaction Chart Data
    setterFunction({
      labels: chartLabels,
      datasets: [
        {
          label: fromFilterYear,
          data: fromChartData,
          backgroundColor: "#f9a22f",
          borderColor: "#f9a22f",
        },
        {
          label: toFilterYear,
          data: toChartData,
          backgroundColor: "#6FCF97",
          borderColor: "#6FCF97",
        },
      ],
    });
  };

  useEffect(() => {
    // Update Student Chart Data
    handleUpdateChartData(
      undefined,
      studentChartFilterDate,
      MONTHLY_CHART_DATA
    );
  }, [MONTHLY_CHART_DATA]);

  useEffect(() => {
    // Update Transaction Chart Data
    handleUpdateChartData(
      "transaction",
      transactionChartFilterDate,
      MONTHLY_CHART_DATA
    );
  }, [MONTHLY_CHART_DATA]);

  /**
   * Handle Slide To Prev Swiper Item
   */
  const onHandleSlidePrev = () => {
    swiperInstance.slidePrev();
  };

  /**
   * Handle Slide To Next Swiper Item
   */
  const onHandleSlideNext = () => {
    swiperInstance.slideNext();
  };

  return (
    <div className={`dashboard-chart-statistic ${className}`}>
      <MdOutlineKeyboardArrowLeft
        className="dashboard-chart-statistic__slide-icon dashboard-chart-statistic__slide-icon--prev"
        aria-label="slide to previous swiper item"
        onClick={onHandleSlidePrev}
      />

      <MdOutlineKeyboardArrowRight
        className="dashboard-chart-statistic__slide-icon dashboard-chart-statistic__slide-icon--next"
        aria-label="slide to next swiper item"
        onClick={onHandleSlideNext}
      />

      <Swiper
        className="dashboard-chart-statistic__swiper"
        onSwiper={setSwiperInstance}
      >
        <SwiperSlide className="dashboard-chart-statistic__swiper-item">
          <ChartWrapper
            wrapperClassName="dashboard-chart-statistic__chart-wrapper"
            chartData={studentChartData}
            chartOptions={CHART_OPTIONS}
            ChartInfo={ChartInfo}
            chartTitle="Students"
            chartFilterDate={studentChartFilterDate}
            setChartFilterDate={setStudentChartFilterDate}
            ChartComponent={Line}
          />
        </SwiperSlide>

        <SwiperSlide className="dashboard-chart-statistic__swiper-item">
          <ChartWrapper
            wrapperClassName="dashboard-chart-statistic__chart-wrapper"
            chartData={transactionChartData}
            chartOptions={CHART_OPTIONS}
            ChartInfo={ChartInfo}
            chartTitle="Transactions"
            chartFilterDate={transactionChartFilterDate}
            setChartFilterDate={setTransactionChartFilterDate}
            ChartComponent={Line}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

DashboardChartStatistic.defaultProps = {
  className: "",
};
