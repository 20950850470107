// Modules
import React from "react";

// components
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button } from "../..";

// Data
import { HERO_ITEMS } from "../../../data";

// Styles
import "swiper/scss";
import "swiper/scss/autoplay";
import "./hero-item.styles.scss";

export const HomeHero = ({ className }) => {
  // Hero Items
  const renderedHomeItems = HERO_ITEMS.map(({ id, heading, cta, imageUrl }) => (
    <SwiperSlide
      key={id}
      className={`home-hero__item hero-item hero-item--${id}`}
      style={{
        background: `linear-gradient(to bottom, #0000007f, #0000007f), url(${imageUrl})`,
      }}
    >
      <div className="hero-item__container container">
        <h1 className="hero-item__heading">{heading}</h1>
        <p className="hero-item__cta">{cta}</p>
        <Button
          className="hero-item__btn btn--primary btn--rv2 btn--sv4"
          to="/?s=home-services-base"
        >
          Get Started
        </Button>
      </div>
    </SwiperSlide>
  ));

  return (
    <Swiper
      className={`home-hero ${className}`}
      modules={[Autoplay]}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      loop
    >
      {renderedHomeItems}
    </Swiper>
  );
};

HomeHero.defaultProps = {
  className: "",
};
