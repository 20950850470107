// Modules
import React from "react";

// Hooks
import { useEffect } from "react";
import { useAuth } from "../hooks";

// Components
import { Routes } from "../routes";
import { PageLoading, ToastComponent } from "../components";

function App() {
  const {
    checkedAuth,
    initAuth,
    user,
    enforceSingleActiveDevice,
    handleLogout,
    dispatch,
  } = useAuth();

  useEffect(() => {
    // Initialize Authentication On Page Load
    dispatch(initAuth());
  }, []);

  useEffect(() => {
    // Get User Id
    const userId = user?.uid;

    // If User Id Exist, Enforce Single Active Device At A Time For The User
    if (userId) {
      dispatch(enforceSingleActiveDevice(userId, handleLogout));
    }
  }, [user]);

  return (
    <div className="app">
      {/* Page Routing */}
      {checkedAuth && <Routes />}

      {/* Page Loading Spinner */}
      {!checkedAuth && <PageLoading />}

      {/* Toast Notification Container */}
      <ToastComponent />
    </div>
  );
}

export default App;
