// Modules
import React from "react";

// Hooks
import { useEffect } from "react";
import { useTraining } from "../../../../hooks";

// Components
import { Form, Input, InputSelect, InputToggle } from "../../..";

// Utils
import { getTrainingAcronym } from "../../../../utils";

export const ProfileForm = ({
  className,
  onHandleChange,
  onToggleInputEditable,
  formik,
}) => {
  const { values, errors, touched, setFieldValue, handleBlur } = formik;
  const {
    trainings,
    isTrainingLoading,
    trainingErrorMessage,
    getTrainingsAndStat,
    dispatch,
  } = useTraining();

  useEffect(() => {
    // Get All Trainings
    dispatch(getTrainingsAndStat());
  }, []);

  return (
    <Form className={`profile-base__form ${className}`}>
      <Input
        className="profile-base__control"
        wrapperClassName="profile-base__group"
        labelClassName="profile-base__label"
        name="firstName"
        id="firstName"
        label="First Name"
        value={values.firstName.value}
        placeholder="Enter first name"
        secondary
        error={errors.firstName?.value}
        touched={touched.firstName}
        show={values.firstName.editable}
        actionText="Change"
        onSecondaryActionClick={onToggleInputEditable}
        onChange={onHandleChange}
        onBlur={!values.firstName.editable ? () => {} : handleBlur}
      />

      <Input
        className="profile-base__control"
        wrapperClassName="profile-base__group"
        labelClassName="profile-base__label"
        name="lastName"
        id="lastName"
        label="Last Name"
        value={values.lastName.value}
        placeholder="Enter last name"
        secondary
        error={errors.lastName?.value}
        touched={touched.lastName}
        show={values.lastName.editable}
        actionText="Change"
        onSecondaryActionClick={onToggleInputEditable}
        onChange={onHandleChange}
        onBlur={!values.lastName.editable ? () => {} : handleBlur}
      />

      <Input
        className="profile-base__control"
        wrapperClassName="profile-base__group"
        labelClassName="profile-base__label"
        name="email"
        id="email"
        label="Email"
        type="email"
        value={values.email.value}
        placeholder="Enter email address"
        secondary
        error={errors.email?.value}
        touched={touched.email}
        show={values.email.editable}
        actionText="Change"
        onSecondaryActionClick={onToggleInputEditable}
        onChange={onHandleChange}
        onBlur={!values.email.editable ? () => {} : handleBlur}
      />

      <Input
        className="profile-base__control"
        wrapperClassName="profile-base__group"
        labelClassName="profile-base__label"
        name="phoneNumber"
        id="phoneNumber"
        label="Phone Number"
        value={values.phoneNumber.value}
        placeholder="Enter phone number"
        secondary
        error={errors.phoneNumber?.value}
        touched={touched.phoneNumber}
        show={values.phoneNumber.editable}
        actionText="Change"
        onSecondaryActionClick={onToggleInputEditable}
        onChange={onHandleChange}
        onBlur={!values.phoneNumber.editable ? () => {} : handleBlur}
      />

      <Input
        className="profile-base__control"
        wrapperClassName="profile-base__group"
        labelClassName="profile-base__label"
        name="country"
        id="country"
        label="Country"
        value={values.country.value}
        placeholder="Enter your country"
        secondary
        error={errors.country?.value}
        touched={touched.country}
        show={values.country.editable}
        actionText="Change"
        onSecondaryActionClick={onToggleInputEditable}
        onChange={onHandleChange}
        onBlur={!values.country.editable ? () => {} : handleBlur}
      />

      <InputSelect
        className="profile-base__control-select"
        wrapperClassName="profile-base__group-select"
        labelClassName="profile-base__label-select"
        type="select"
        id="program"
        name="program"
        label="Program"
        placeholder="Choose"
        secondary
        value={values.program}
        error={errors.program}
        touched={touched.program}
        isLoading={isTrainingLoading}
        fetchError={trainingErrorMessage}
        items={trainings.map(({ title, id }) => ({
          type: getTrainingAcronym(title),
          id,
        }))}
        onChange={setFieldValue}
      />

      <InputToggle
        className="profile-base__control-toggle"
        wrapperClassName="profile-base__group-toggle"
        labelClassName="profile-base__label-toggle"
        name="isEmployed"
        id="isEmployed"
        label="Are you currently employed?"
        value={values.isEmployed}
        onChange={setFieldValue}
      />
    </Form>
  );
};
