// Modules
import React from "react";

// Hooks
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLevel } from "../../../hooks";

// Components
import {
  ProfessionalTrainingDetailHero,
  ProfessionalTrainingDetailBase,
  EmptyContent,
} from "../../../components";

// Style
import "./professional-traning-detail.styles.scss";

export const ProfessionalTrainingDetail = () => {
  const { getTrainingLevels, isLevelLoading, dispatch, levelErrorMessage } =
    useLevel();
  const { trainingId } = useParams();

  useEffect(() => {
    // Get Training Levels And Also Populate Training Field
    dispatch(getTrainingLevels(trainingId, true));
  }, [trainingId]);

  return (
    <div className="professional-training-detail">
      <EmptyContent
        className="professional-training-detail__empty"
        errorMessage={levelErrorMessage}
        isLoading={isLevelLoading}
      >
        <ProfessionalTrainingDetailHero />
        <ProfessionalTrainingDetailBase />
      </EmptyContent>
    </div>
  );
};
