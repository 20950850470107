// Modules
import { createSlice } from "@reduxjs/toolkit";

// Common Reducers
import { loadingReducers, errorMessageReducers } from "../common";

// Utils
import { stateSelector } from "../../utils";

// Initial State
const initialState = {
  activeChapter: "",
  errorMessage: "",
  isLoading: false,
  chapters: [],
};

// Slice Name
const sliceName = "chapter";

// Chapter Slice
const chapterSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ...loadingReducers,
    ...errorMessageReducers,
    setActiveChapter(state, action) {
      state.activeChapter = action.payload;
    },
    resetActiveChapter(state) {
      state.activeChapter = "";
    },
    setChapters(state, action) {
      state.chapters = action.payload;
    },
    resetChapters(state) {
      state.chapters = [];
    },
  },
});

export const {
  setActiveChapter,
  resetActiveChapter,
  startLoading: chapterStartLoading,
  stopLoading: chapterStopLoading,
  setErrorMessage: setChapterErrorMessage,
  resetErrorMessage: resetChapterErrorMessage,
  setChapters,
  resetChapters,
} = chapterSlice.actions;
export * from "./chapterAction";
export const selectActiveChapter = stateSelector(sliceName, "activeChapter");
export const selectChapters = stateSelector(sliceName, "chapters");
export const selectChapterErrorMessage = stateSelector(
  sliceName,
  "errorMessage"
);
export const selectIsChapterLoading = stateSelector(sliceName, "isLoading");
export default chapterSlice.reducer;
