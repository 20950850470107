// Hooks
import { useSelector, useDispatch } from "react-redux";

// Redux
import { bookingSA } from "../store";

// Destructure Redux Imports
const {
  selectBookingErrorMessage,
  selectBookingStat,
  selectBookings,
  selectIsBookingLoading,
} = bookingSA;

/**
 * Booking Hook
 */
export const useBooking = () => {
  const dispatch = useDispatch();
  const bookings = useSelector(selectBookings);
  const bookingErrorMessage = useSelector(selectBookingErrorMessage);
  const isBookingLoading = useSelector(selectIsBookingLoading);
  const bookingStat = useSelector(selectBookingStat);

  return {
    bookings,
    bookingErrorMessage,
    isBookingLoading,
    bookingStat,
    dispatch,
    ...bookingSA,
  };
};
