// Modules
import React from "react";

// Components
import { IconButton } from "@mui/material";
import { MdDelete } from "react-icons/md";
import { FiDownload } from "react-icons/fi";

// Images
import pdfIcon from "../../../../assets/images/pdf-icon.svg";

// Style
import "./video-playlist-card.styles.scss";

export const VideoPlaylistCard = ({
  className,
  thumbnail,
  playlistName,
  playlistType,
  playlistLink,
  playlistDuration,
  playlistId,
  activePlaylist,
  setActivePlaylist,
  onDeletePlaylistFile,
  playlistPublicId,
  isEditMode,
  enableDragging,
}) => {
  // Check Whether Content Type Is Pdf
  const isPdf = playlistType.includes("pdf");

  /**
   * Handle Set Active Playlist
   */
  const onHandleSetActivePlaylist = () => {
    // If Content Type Is Pdf Then Return Function
    if (isPdf) return;

    // Else Set Active Playlist
    setActivePlaylist(playlistId);
  };

  return (
    <div
      className={`video-playlist-card ${
        activePlaylist === playlistId ? "video-playlist-card--active" : ""
      } ${className}`}
      onClick={onHandleSetActivePlaylist}
    >
      {isPdf && !enableDragging && (
        <a
          className="video-playlist-card__downloadable"
          href={playlistLink}
          target="_blank"
          download={playlistName}
        ></a>
      )}

      <div className="video-playlist-card__left">
        <div className="video-playlist-card__brand">
          <img
            className={`video-playlist-card__thumbnail ${
              isPdf ? "video-playlist-card__thumbnail--pdf" : ""
            }`}
            src={isPdf ? pdfIcon : thumbnail}
            alt={playlistName}
          />
        </div>

        <div className="video-playlist-card__detail">
          <p className="video-playlist-card__content-name" title={playlistName}>
            {playlistName}
          </p>
          <p className="video-playlist-card__content-duration">
            {playlistDuration} {isPdf && "PAGES"}
          </p>
        </div>
      </div>

      <div className="video-playlist-card__right">
        {(isPdf || isEditMode) && (
          <div className="video-playlist-card__end">
            {isPdf && !isEditMode && (
              <IconButton className="video-playlist-card__icon-btn">
                <FiDownload className="video-playlist-card__icon" />
              </IconButton>
            )}

            {isEditMode && (
              <IconButton
                className="video-playlist-card__icon-btn"
                onClick={onDeletePlaylistFile.bind(
                  null,
                  playlistId,
                  playlistPublicId,
                  !isPdf ? "video" : "raw"
                )}
              >
                <MdDelete className="video-playlist-card__icon is-text-danger-color" />
              </IconButton>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

VideoPlaylistCard.defaultProps = {
  className: "",
  isEditMode: false,
};
