// Firebase
import {
  addDoc,
  doc,
  updateDoc,
  query,
  where,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import { newsLetterCollectionRef, firebaseLooper } from "../../utils";

// Utils
import { AppError } from "../../utils";

/**
 * Get A Newsletter
 * @param {string} email User email
 * @param {boolean} throwNotFound If true throw not found error
 */
export const getNewsletter = async (email, throwNotFound = true) => {
  try {
    // Create Newsletter Query
    const newsletterQuery = query(
      newsLetterCollectionRef,
      where("email", "==", email),
      where("isActive", "==", true)
    );

    // Get Newsletter
    const newsletterSnapshot = await getDocs(newsletterQuery);

    // Throw Error If Newsletter Does Not Exist And Throw Not Found Is True
    if (newsletterSnapshot.empty && throwNotFound) {
      throw new AppError("empty-data");
    }

    // Return Newsletter
    return firebaseLooper(newsletterSnapshot)[0];
  } catch (err) {
    // Re-Throw Error
    throw AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Subscribe User To Newsletter
 * @param {string} email User email
 */
export const subscribeToNewsletter = async (email) => {
  try {
    // Check If User Is Already Subscribe To Newsletter
    const newsletter = await getNewsletter(email, false);

    // If User Is Already Subscribed Then Throw A Custom Error
    if (newsletter) {
      throw new AppError("newsletter/subscribe", true);
    }

    // If User Is Not Subscribed To Newsletter, Then Subscribe User To Newsletter
    await addDoc(newsLetterCollectionRef, {
      email,
      isActive: true,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  } catch (err) {
    // Re-Throw Error
    throw AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Un-Subscribe User From Newsletter
 * @param {string} email User email
 */
export const unSubscribeFromNewsletter = async (email) => {
  try {
    // Check If User Is Not Already Subscribed To Newsletter
    const newsletter = await getNewsletter(email, false);

    // If User Is Not Already Subscribed To Newsletter, Then Throw A Custom Error
    if (!newsletter) {
      throw new AppError("newsletter/unsubscribe", true);
    }

    // Newsletter Doc Reference
    const newsletterRef = doc(newsLetterCollectionRef, newsletter.id);

    // If User Is Subscribed To Newsletter, Then Un-Subscribe User From Newsletter
    await updateDoc(newsletterRef, {
      isActive: false,
      updatedAt: serverTimestamp(),
    });
  } catch (err) {
    // Re-Throw Error
    throw AppError.checkNetworkConnectivityAndThrowError(err);
  }
};
