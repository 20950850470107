// Modules
import React from "react";

// Hooks
import { useUrlAsTitle } from "../../../hooks";

// Components
import { Fragment } from "react";
import { DashboardStatsBase, DashboardBase } from "../../../components";

export const Dashboard = () => {
  // Set Dashboard Page Title
  useUrlAsTitle("Dashboard");

  return (
    <Fragment>
      <DashboardStatsBase />
      <DashboardBase />
    </Fragment>
  );
};
