// Modules
import React from "react";

// Components
import { LevelModalBase } from "../../../../../components";

// Hooks
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLevel } from "../../../../../hooks";

export const LevelModal = () => {
  const { trainingId } = useParams();
  const { getTrainingLevels, dispatch } = useLevel();

  useEffect(() => {
    // Get Training Levels And Populate Training
    dispatch(getTrainingLevels(trainingId, true));
  }, [trainingId]);

  return <LevelModalBase />;
};
