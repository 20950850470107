//====================================================
//==================Home Stats Images=================
//====================================================
import statsActiveStudent from "../assets/images/stats-active-student.svg";
import statsConsultingService from "../assets/images/stats-consulting-service.svg";
import statsLearningSupport from "../assets/images/stats-learning-support.svg";

//====================================================
//===============Home Services Images=================
//====================================================
import serviceAccountingSolution from "../assets/images/service-accounting-solution.svg";
import serviceConsultancy from "../assets/images/service-consultancy.svg";
import serviceRecruitment from "../assets/images/service-recruitment.svg";
import serviceTraining from "../assets/images/service-training.svg";

// Navigation Items
export const NAVIGATION_ITEMS = [
  { id: 1, content: "Home", to: "/", enableActive: true },
  {
    id: 2,
    content: "Our Services",
    to: "/?s=home-services-base",
    enableActive: false,
  },
  {
    id: 3,
    content: "Professional Training",
    to: "/professional-training",
    enableActive: true,
  },
  {
    id: 4,
    content: "About Us",
    to: "/?s=home-about-base",
    enableActive: false,
  },
  { id: 5, content: "Contact Us", to: "/?s=contact-base", enableActive: false },
];

// Hero Items
export const HERO_ITEMS = [
  {
    id: 1,
    heading: "Finance and Accounting Hub",
    cta: "CFA and ACCA Training & Financial Consulting Services",
    imageUrl:
      "https://res.cloudinary.com/dmaaitwbb/image/upload/v1681818468/finacco/assets/home-hero_htynza.svg",
  },
  {
    id: 2,
    heading: "Investment & Accounting Consultancy",
    cta: "Providing Premium Accounting & Investment Advice",
    imageUrl:
      "https://res.cloudinary.com/dmaaitwbb/image/upload/v1681818468/finacco/assets/home-hero-2_zhkf9u.svg",
  },
  {
    id: 3,
    heading: "Digital Finance & Accounting Software",
    cta: "We Provide Enterprise Digital solutions",
    imageUrl:
      "https://res.cloudinary.com/dmaaitwbb/image/upload/v1681818470/finacco/assets/home-hero-3_h9skff.svg",
  },
];

// Home Statistic Items
export const HOME_STAT_ITEMS = [
  {
    id: 1,
    image: statsActiveStudent,
    stat: "300+",
    title: "Active Student",
  },
  {
    id: 2,
    image: statsLearningSupport,
    stat: "100%",
    title: "Learning Support",
  },
  {
    id: 3,
    image: statsConsultingService,
    stat: "100%",
    title: "Financial Consulting Service",
  },
];

// Home Service Items
export const HOME_SERVICE_ITEMS = [
  {
    id: 1,
    image: serviceTraining,
    title: "Professional Designated Training",
    description:
      "Offering quality professional designation  training to our students currently training <strong>(ACCA, CFA & CERTIFICATE IN ESG INVESTING)</strong>",
    to: "/professional-training",
  },
  {
    id: 2,
    image: serviceAccountingSolution,
    title: "Digital Finance & Accounting Solution",
    description: "We digitize your finance and accounting department",
    to: "/accounting-solution",
  },
  {
    id: 3,
    image: serviceRecruitment,
    title: "Recruitment Gateway",
    description:
      "Linking trained professionals to appropriate  job opportunities via our recruitment  gateway",
    to: "/recruitment-gateway",
  },
  {
    id: 4,
    image: serviceConsultancy,
    title: "Investment & Accounting Consultancy",
    description:
      "Providing tailor-made financial and  investment consultancy services",
    to: "/accounting-consultancy",
  },
];

// SponsorshipType Items
export const SPONSORSHIP_TYPE_ITEMS = [
  {
    id: 1,
    type: "Partial Sponsorship",
  },
  {
    id: 2,
    type: "Full Sponsorship",
  },
];

// Visual Management Items
export const VISUAL_MANAGEMENT_ITEMS = [
  {
    id: 1,
    title: "Analytics",
  },
  {
    id: 2,
    title: "Process Managment",
  },
  {
    id: 3,
    title: "Books & Record",
  },
  {
    id: 4,
    title: "Audit",
  },
];

// Transaction Management Items
export const TRANSACTION_MANAGEMENT_ITEMS = [
  {
    id: 1,
    title: "Supplier management",
    items: ["Contracts", "Requisition", "Invoice", "Compliance"],
  },
  {
    id: 2,
    title: "Customer Managment",
    items: ["Receivables", "Debt Collections", "Invoicing", "Compliance"],
  },
  {
    id: 3,
    title: "Accounting Portal",
    items: [
      "Reconciliation",
      "Journals",
      "Variance & reporting",
      "Inventory & properties",
    ],
  },
  {
    id: 4,
    title: "Report Portal",
    items: [
      "Budgeting",
      "Forecasting",
      "Management reporting",
      "Financial reporting",
    ],
  },
];

// Other Solution Items
export const OTHER_SOLUTION_ITEMS = [
  {
    id: 1,
    title: "Intelligent Process Automation",
  },
  {
    id: 2,
    title: "Integrations",
  },
  {
    id: 3,
    title: "Record Systems",
  },
  {
    id: 4,
    title: "Accounting Systems",
  },
];

// Pricing Tag Items
export const PRICING_TAG_ITEMS = [
  "Access To Study Videos",
  "Access To Practice Video",
  "Access To Formula Sheet",
  "Access To Lecture PDF Notes",
];

// Subscription Types
export const subscriptionTypes = [
  {
    id: 1,
    type: "Monthly",
  },
  {
    id: 2,
    type: "Quarterly",
  },
  {
    id: 3,
    type: "Yearly",
  },
];

// Payment Methods
export const paymentMethods = [
  {
    id: 1,
    type: "Card",
  },
  {
    id: 2,
    type: "Mobile Money",
  },
  {
    id: 3,
    type: "Wire Transfer",
  },
];

// Supported Currencies
export const supportedCurrencies = [
  {
    id: 1,
    type: "USD",
  },
  {
    id: 2,
    type: "RWF",
  },
];
