// Modules
import isOnline from "is-online";

// Utils
import { getError, getInfo } from "../asyncMessage/asyncMessage.utils";

/**
 * @class
 * Handle Application Error
 */
export class AppError extends Error {
  /**
   * @constructor
   * @param {string|AppError} err Error code or Error
   * @param {boolean} useInfo If true use info notification type
   */
  constructor(err, useInfo) {
    super();
    this.err = err;
    this.useInfo = useInfo;
    this.getCode();
    this.getMessage();
    this.useUnhandledError();
    AppError.captureStackTrace(this, constructor);
  }

  /**
   * Get Error Code
   */
  getCode() {
    const { err } = this;
    if (typeof err === "object") {
      this.isServer = !!err.details?.isServer || !!err.isServer;
      this.code = err.code;
      this.useInfo = err.useInfo;
    } else {
      this.code = err;
    }
  }

  /**
   * Get Error Message Base On Error Code
   */
  getMessage() {
    const { isServer, err } = this;
    if (isServer && err instanceof AppError) {
      this.message = err.message;
    } else if (isServer && !(err instanceof AppError)) {
      this.message = { type: "error", message: err.details.message };
    } else {
      this.message = !this.useInfo ? getError(this.code) : getInfo(this.code);
    }
  }

  /**
   * Check For Internet Connection Issue
   */
  async checkInternetConnectivity() {
    const isNetworkOnline = await isOnline();
    if (!isNetworkOnline) {
      this.message = {
        type: "error",
        message: "You Are Offline. Connect To Internet",
      };
    }
    return this;
  }

  /**
   * Apply UnHandled Error
   */
  useUnhandledError() {
    // If Message Is Undefined Then Use Unhandled Error Code
    if (!this.message.message) {
      this.code = "unhandled";
      this.getMessage();
    }
  }

  /**
   * Check For Network Connectivity And Handle Throw Error
   * @param {string|AppError} err Error code or Error
   * @param {boolean} useInfo If true use info notification type
   */
  static async checkNetworkConnectivityAndThrowError(err, useInfo) {
    return await new AppError(err, useInfo).checkInternetConnectivity();
  }
}
