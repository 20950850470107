// Modules
import React from "react";

// Components
import { Outlet } from "react-router-dom";
import { PrivateSessionBase } from "../../../components";

// Hooks
import { useEffect } from "react";
import { usePrivateSession, useUrlAsTitle } from "../../../hooks";

export const PrivateSession = () => {
  const { dispatch, getPrivateSessions } = usePrivateSession();
  // Set Page Custom Title
  useUrlAsTitle("Book For Private Tutor");

  useEffect(() => {
    // Get Private Sessions And Populate Tutor Field
    dispatch(getPrivateSessions(true));
  }, []);

  return (
    <div className="private-session is-height-full">
      <div className="private-session__container is-height-full">
        <PrivateSessionBase />
        <Outlet />
      </div>
    </div>
  );
};
