// Modules
import React from "react";

// Style
import "./stats-list.styles.scss";

export const StatsList = ({ className, children }) => {
  return <div className={`stats-list ${className}`}>{children}</div>;
};

StatsList.defaultProps = {
  className: "",
};
