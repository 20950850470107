// Modules
import { createSlice } from "@reduxjs/toolkit";

// Common Reducers
import { loadingReducers } from "../common";

// Utils
import { stateSelector } from "../../utils";

// Initial State
const initialState = {
  isLoading: false,
  lastPlayedVideo: null,
};

// Slice Name
const sliceName = "videoStreamStat";

// Video Stream Stat Slice
const videoStreamStatSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ...loadingReducers,
    setLastPlayedVideo(state, action) {
      state.lastPlayedVideo = action.payload;
    },
    resetLastPlayedVideo(state, action) {
      state.lastPlayedVideo = null;
    },
  },
});

export const {
  startLoading: videoStreamStatStartLoading,
  stopLoading: videoStreamStatStopLoading,
  setLastPlayedVideo,
  resetLastPlayedVideo,
} = videoStreamStatSlice.actions;
export * from "./videoStreamStatAction";
export const selectIsVideoStreamStatLoading = stateSelector(
  sliceName,
  "isLoading"
);
export const selectLastPlayedVideo = stateSelector(
  sliceName,
  "lastPlayedVideo"
);
export default videoStreamStatSlice.reducer;
