// Modules
import { configureStore } from "@reduxjs/toolkit";

// Reducers
import uiSliceReducer from "./uiSlice";
import authSliceReducer from "./authSlice";
import referrerMediumSliceReducer from "./referrerMediumSlice";
import staffSliceReducer from "./staffSlice";
import trainingSliceReducer from "./trainingSlice";
import levelSliceReducer from "./levelSlice";
import subjectSliceReducer from "./subjectSlice";
import subscriptionSliceReducer from "./subscriptionSlice";
import paymentSliceReducer from "./paymentSlice";
import studentSliceReducer from "./studentSlice";
import transactionSliceReducer from "./transactionSlice";
import chapterSliceReducer from "./chapterSlice";
import chapterFileSliceReducer from "./chapterFileSlice";
import feedbackSliceReducer from "./feedbackSlice";
import videoViewsReducer from "./videoViewsSlice";
import videoStreamStatReducer from "./videoStreamStatSlice";
import privateSessionReducer from "./privateSessionSlice";
import bookingReducer from "./bookingSlice";
import newsletterSliceReducer from "./newsletterSlice";
import contactUsSliceReducer from "./contactUsSlice";

// Store
const store = configureStore({
  reducer: {
    ui: uiSliceReducer,
    auth: authSliceReducer,
    referrerMedium: referrerMediumSliceReducer,
    staff: staffSliceReducer,
    student: studentSliceReducer,
    transaction: transactionSliceReducer,
    newsletter: newsletterSliceReducer,
    contactUs: contactUsSliceReducer,
    training: trainingSliceReducer,
    level: levelSliceReducer,
    subscription: subscriptionSliceReducer,
    payment: paymentSliceReducer,
    subject: subjectSliceReducer,
    feedback: feedbackSliceReducer,
    chapter: chapterSliceReducer,
    chapterFile: chapterFileSliceReducer,
    videoViews: videoViewsReducer,
    videoStreamStat: videoStreamStatReducer,
    privateSession: privateSessionReducer,
    booking: bookingReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
