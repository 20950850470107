// Modules
import React from "react";

// Components
import { VideoChapterCard } from "../../";

// Style
import "./video-chapter-list.styles.scss";

export const VideoChapterList = ({
  className,
  items,
  isEditMode,
  activeChapter,
  onDeleteChapter,
  setActiveChapter,
}) => {
  // Video Chapter Card Items
  const renderedVideoChapterCardItems = items.map(
    ({ id, name, totalDuration }) => (
      <VideoChapterCard
        className="video-chapter-list__item"
        key={id}
        chapterId={id}
        chapterName={name}
        chapterTotalDuration={totalDuration}
        activeChapter={activeChapter}
        isEditMode={isEditMode}
        setActiveChapter={setActiveChapter}
        onDeleteChapter={onDeleteChapter}
      />
    )
  );

  return (
    <div className={`video-chapter-list ${className}`}>
      {renderedVideoChapterCardItems}
    </div>
  );
};

VideoChapterList.defaultProps = {
  className: "",
  items: [],
};
