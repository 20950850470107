// Modules
import React from "react";

// Hooks
import { useRef } from "react";

// Style
import "./input-toggle.styles.scss";

export const InputToggle = ({
  wrapperClassName,
  labelClassName,
  className,
  name,
  label,
  id,
  value,
  trueText,
  falseText,
  onChange,
}) => {
  const checkboxRef = useRef();

  /**
   * Toggle Input Box
   * @param {object} e Event object
   */
  const onToggleBox = (e) => {
    // Get Target Element
    const target = e.target || e;

    // Update Input Toggle State
    onChange(name, target.checked);
  };

  /**
   * Toggle Checkbox Selection
   */
  const toggleCheckboxState = () => {
    // Get Checkbox Element
    const checkboxEl = checkboxRef.current;

    // Toggle Checkbox Checked/Value Property
    const newChecked = !checkboxEl.checked;
    checkboxEl.checked = newChecked;
    checkboxEl.value = newChecked;

    // Update Input Toggle State
    onToggleBox(checkboxEl);
  };

  return (
    <div className={`input-toggle ${wrapperClassName}`}>
      <input
        type="checkbox"
        className={`input-toggle__control ${className}`}
        id={id}
        name={name}
        value={value}
        checked={value}
        ref={checkboxRef}
        hidden
        onChange={onToggleBox}
      />

      <label className={`input-toggle__label ${labelClassName}`} htmlFor={id}>
        {label}
      </label>

      <div className="toggle-box">
        <p className="toggle-box__false">{falseText}</p>
        <div
          className={`toggle-box__btn-wrapper ${
            value ? "toggle-box__btn-wrapper--active" : ""
          }`}
          onClick={toggleCheckboxState}
        >
          <div className="toggle-box__btn"></div>
        </div>
        <p className="toggle-box__true">{trueText}</p>
      </div>
    </div>
  );
};

InputToggle.defaultProps = {
  labelClassName: "",
  wrapperClassName: "",
  className: "",
  falseText: "No",
  trueText: "Yes",
};
