// Redux
import {
  setActiveChapter,
  setChapterErrorMessage,
  setChapters,
  chapterStartLoading,
  chapterStopLoading,
  resetChapterErrorMessage,
} from "./";
import { uiSA } from "../";

// Utils
import { getSuccess, History } from "../../utils";

// Apis
import * as apis from "../../apis";

// Destructure Redux Imports
const { showNotification } = uiSA;

/**
 * Get Subject Chapters
 * @param {string} subjectId Subject id
 */
export const getSubjectChapters = (subjectId) => async (dispatch) => {
  try {
    // Reset Error Message
    dispatch(resetChapterErrorMessage());

    // Start Loading
    dispatch(chapterStartLoading());

    // Get Subject Chapters
    const chapters = await apis.getSubjectChapters(subjectId);

    // Update Chapters
    dispatch(setChapters(chapters));

    // Update Active Chapters
    dispatch(setActiveChapter(chapters[0]?.id));
  } catch (err) {
    // Update Error Message
    dispatch(setChapterErrorMessage(err.message.message));
  } finally {
    // Stop Loading
    dispatch(chapterStopLoading());
  }
};

/**
 * Create New Chapter
 * @param {object} chapterData Chapter data
 * @param {string} subjectId Subject id
 */
export const createChapter = (chapterData, subjectId) => async (dispatch) => {
  try {
    // Reset Error Message
    dispatch(resetChapterErrorMessage());

    // Start Loading
    dispatch(chapterStartLoading());

    // Create Chapter
    const { chapterId, chapters } = await apis.createChapter(
      chapterData,
      subjectId
    );

    // Show Success Notification On Creating Chapter
    dispatch(showNotification(getSuccess("created")));

    // Update Chapters
    dispatch(setChapters(chapters));

    // Update Active Chapters
    dispatch(setActiveChapter(chapterId));

    // Navigate To Player
    History.push(`/player${History.location?.search}`);
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  } finally {
    // Stop Loading
    dispatch(chapterStopLoading());
  }
};

/**
 * Update A Chapter
 * @param {string} chapterId Chapter id
 * @param {object} chapterData Chapter data
 * @param {string} subjectId Subject id
 */
export const updateChapter =
  (chapterId, chapterData, subjectId) => async (dispatch) => {
    try {
      // Start Loading
      dispatch(chapterStartLoading());

      // Update Chapter
      const chapters = await apis.updateChapter(
        chapterId,
        chapterData,
        subjectId
      );

      // Show Success Notification On Updating Chapter
      dispatch(showNotification(getSuccess("updated")));

      // Update Chapters
      dispatch(setChapters(chapters));

      // Update Active Chapters
      dispatch(setActiveChapter(chapterId));

      // Navigate To Player
      History.push(`/player${History.location?.search}`);
    } catch (err) {
      // Show Error In Notification
      dispatch(showNotification(err.message));
    } finally {
      // Stop Loading
      dispatch(chapterStopLoading());
    }
  };

/**
 * Delete A Chapter
 * @param {string} chapterId Chapter id
 * @param {string} subjectId Subject id
 */
export const deleteChapter = (chapterId, subjectId) => async (dispatch) => {
  try {
    // Delete Chapter
    const chapters = await apis.deleteChapter(chapterId, subjectId);

    // Show Success Notification On Deleting Chapter
    dispatch(showNotification(getSuccess("deleted")));

    // Update Chapters
    dispatch(setChapters(chapters));

    // Update Active Chapters
    dispatch(setActiveChapter(chapters[0]?.id));
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  }
};
