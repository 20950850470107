// Redux
import { newsLetterStartLoading, newsLetterStopLoading } from ".";
import { uiSA } from "..";

// Apis
import * as apis from "../../apis";

// Utils
import { getSuccess } from "../../utils";

// Destructure Redux Imports
const { showNotification } = uiSA;

/**
 * Subscribe User To Newsletter
 * @param {string} email User email
 */
export const subscribeToNewsletter = (email) => async (dispatch) => {
  try {
    // Start Loading
    dispatch(newsLetterStartLoading());

    // Subscribe User To Newsletter
    await apis.subscribeToNewsletter(email);

    // Show Success Notification On Subscribing User To Newsletter
    dispatch(showNotification(getSuccess("newsletter/subscribe")));
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  } finally {
    // Stop Loading
    dispatch(newsLetterStopLoading());
  }
};

/**
 * Un-Subscribe User From Newsletter
 * @param {string} email User email
 */
export const unSubscribeFromNewsletter = (email) => async (dispatch) => {
  try {
    // Start Loading
    dispatch(newsLetterStartLoading());

    // Un-Subscribe User From Newsletter
    await apis.unSubscribeFromNewsletter(email);

    // Show Success Notification On Un-Subscribing User From Newsletter
    dispatch(showNotification(getSuccess("newsletter/unsubscribe")));
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  } finally {
    // Stop Loading
    dispatch(newsLetterStopLoading());
  }
};
