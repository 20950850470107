// Modules
import React from "react";

// Components
import { AccountingSolutionItem } from "../../";

// Style
import "./accounting-solution-list.styles.scss";

export const AccountingSolutionList = ({ className, items }) => {
  // Accounting Solution Items
  const renderedAccountingSolutionItems = items.map((item) => (
    <AccountingSolutionItem
      key={item.id}
      className="accounting-solution-list__item"
      title={item.title}
      items={item.items}
    />
  ));

  return (
    <div className={`accounting-solution-list ${className}`}>
      {renderedAccountingSolutionItems}
    </div>
  );
};

AccountingSolutionList.defaultProps = {
  className: "",
  items: [],
};
