/**
 * Human Readable Date
 * @param {string} locale Locale
 * @param {Date} date Date
 */
export const humanReadableDate = (locale = "en-GB", date = new Date()) => {
  return new Date(date).toLocaleString(locale, {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};
