// Redux
import { feedbackStartLoading, feedbackStopLoading } from ".";
import { uiSA } from "../";

// Apis
import * as apis from "../../apis";

// Utils
import { getSuccess } from "../../utils";

// Destructure Redux Imports
const { showNotification } = uiSA;

/**
 * Create New Feedback
 * @param {object} feedbackData Feedback data
 * @param {string} studentId Student id
 * @param {string} chapterId Chapter id
 * @param {string} chapterFileId Chapter file id
 */
export const createFeedback =
  (feedbackData, studentId, chapterId, chapterFileId) => async (dispatch) => {
    try {
      // Start Loading
      dispatch(feedbackStartLoading());

      // Create Feedback
      await apis.createFeedback(
        feedbackData,
        studentId,
        chapterId,
        chapterFileId
      );

      // Show Success Notification On Creating Feedback
      dispatch(showNotification(getSuccess("feedback/postFeedback")));
    } catch (err) {
      // Show Error In Notification
      dispatch(showNotification(err));
    } finally {
      // Stop Loading
      dispatch(feedbackStopLoading());
    }
  };
