// Modules
import React from "react";

// Hooks
import { useEffect } from "react";
import { useTraining } from "../../../hooks";

// Components
import { ProfessionalTrainingCourseList, EmptyContent } from "../../";

// Style
import "./professional-training-course-base.styles.scss";

export const ProfessionalTrainingCourseBase = ({ className }) => {
  const {
    getTrainingsAndStat,
    isTrainingLoading,
    trainingErrorMessage,
    trainings,
    dispatch,
  } = useTraining();

  useEffect(() => {
    // Get Trainings
    dispatch(getTrainingsAndStat());
  }, []);

  return (
    <div className={`professional-training-course ${className}`}>
      <div className="professional-training-course__container container">
        <EmptyContent
          isLoading={isTrainingLoading}
          errorMessage={trainingErrorMessage}
        >
          <ProfessionalTrainingCourseList
            className="professional-training-course__list"
            items={trainings}
          />
        </EmptyContent>
      </div>
    </div>
  );
};

ProfessionalTrainingCourseBase.defaultProps = {
  className: "",
};
