// Modules
import React from "react";

// Components
import { Fragment } from "react";
import {
  MoreAction,
  MoreActionDropdownItem,
  Dialog as DeleteConfirmationDialog,
} from "../../../../";

// Hooks
import { useDelete } from "../../../../../hooks";

// Utils
import { getProfileImage } from "../../../../../utils";

// Style
import "./staff-card.styles.scss";

export const StaffCard = ({
  className,
  id,
  profileImage,
  fullName,
  organizationRole,
  profileSummary,
  enableAction,
  onDeleteStaff,
}) => {
  const {
    deleteCloseActionText,
    deleteConfirmActionText,
    isDeleteDialogOpen,
    onHandleCloseDeleteDialog,
    onHandleDeleteConfirm,
    onHandleOpenDeleteDialog,
  } = useDelete(onHandleDeleteStaff);

  /**
   * Handle Staff Deletion
   */
  function onHandleDeleteStaff() {
    onDeleteStaff(id);
  }

  return (
    <Fragment>
      <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        dialogTitle="Confirm Delete Staff"
        dialogDescription="You are about to delete staff data. Confirm with yes to continue"
        closeActionText={deleteCloseActionText}
        confirmActionText={deleteConfirmActionText}
        onClose={onHandleCloseDeleteDialog}
        onConfirm={onHandleDeleteConfirm}
      />

      <figure className={`staff-card ${className}`}>
        <img
          className="staff-card__photo crop"
          src={getProfileImage(profileImage, 100, 100)}
          alt={fullName}
        />

        <figcaption className="staff-card__caption">
          <p className="staff-card__name">{fullName}</p>
          <p className="staff-card__organizationRole">{organizationRole}</p>
        </figcaption>

        <div className="staff-card__main">
          <p className="staff-card__summary">{profileSummary}</p>
        </div>

        {enableAction && (
          <MoreAction className="staff-card__action">
            <MoreActionDropdownItem
              className="staff-card__action-dropdown-item"
              to={`/admin/staffs/${id}`}
            >
              Edit
            </MoreActionDropdownItem>

            <MoreActionDropdownItem
              className="staff-card__action-dropdown-item"
              onClick={onHandleOpenDeleteDialog}
            >
              Delete
            </MoreActionDropdownItem>
          </MoreAction>
        )}
      </figure>
    </Fragment>
  );
};

StaffCard.defaultProps = {
  className: "",
  enableAction: false,
};
