// Modules
import React from "react";

// Components
import { BsBox } from "react-icons/bs";

// Style
import "./stats-card.styles.scss";

export const StatsCard = ({ className, title, subtitle, isSecondary }) => {
  return (
    <div className={`stats-card ${className}`}>
      <div className="stats-card__container container">
        <p className="stats-card__content">
          <strong className="stats-card__title d-block">{title}</strong>
          <span className="stats-card__subtitle">{subtitle}</span>
        </p>

        <div
          className={`stats-card__icon-wrapper ${
            isSecondary ? "stats-card__icon-wrapper--secondary" : ""
          }`}
        >
          <BsBox className="stats-card__icon" aria-label="statistic" />
        </div>
      </div>
    </div>
  );
};

StatsCard.defaultProps = {
  className: "",
  isSecondary: false,
};
