// Redux
import {
  setTrainings,
  setTrainingErrorMessage,
  resetTrainingErrorMessage,
  trainingStartLoading,
  trainingStopLoading,
  setTrainingStat,
  delTraining,
} from ".";
import { uiSA } from "../";

// Apis
import * as apis from "../../apis";

// Utils
import { getSuccess, History } from "../../utils";

// Destructure Redux Imports
const { showNotification } = uiSA;

/**
 * Get All Trainings And Stats
 */
export const getTrainingsAndStat = () => async (dispatch) => {
  try {
    // Reset Error Message
    dispatch(resetTrainingErrorMessage());

    // Start Loading
    dispatch(trainingStartLoading());

    // Get Trainings And Stat
    const { trainings, stat } = await apis.getTrainingsAndStat();

    // Update Trainings
    dispatch(setTrainings(trainings));

    // Update Stat
    dispatch(setTrainingStat(stat));
  } catch (err) {
    // Update Error Message
    dispatch(setTrainingErrorMessage(err.message.message));
  } finally {
    // Stop Loading
    dispatch(trainingStopLoading());
  }
};

/**
 * Delete A Training
 * @param {string} trainingId Training Id
 */
export const deleteTraining = (trainingId) => async (dispatch) => {
  try {
    // Delete Training
    const stat = await apis.deleteTraining(trainingId);

    // Delete Training In Redux Store
    dispatch(delTraining(trainingId));

    // Show Success Notification On Deleting Training
    dispatch(showNotification(getSuccess("deleted")));

    // Update Stat
    dispatch(setTrainingStat(stat));
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  }
};

/**
 * Create New Training
 * @param {object} trainingData Training data
 */
export const createTraining = (trainingData) => async (dispatch) => {
  try {
    // Reset Error Message
    dispatch(resetTrainingErrorMessage());

    // Start Loading
    dispatch(trainingStartLoading());

    // Create Training
    const { trainings, stat } = await apis.createTraining(trainingData);

    // Update Trainings
    dispatch(setTrainings(trainings));

    // Update Stat
    dispatch(setTrainingStat(stat));

    // Show Success Notification On Creating Training
    dispatch(showNotification(getSuccess("created")));

    // Navigate To Trainings
    History.push("/admin/trainings");
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  } finally {
    // Stop Loading
    dispatch(trainingStopLoading());
  }
};

/**
 * Update A Training
 * @param {string} trainingId Training id
 * @param {object} trainingData Training data
 */
export const updateTraining =
  (trainingId, trainingData) => async (dispatch) => {
    try {
      // Start Loading
      dispatch(trainingStartLoading());

      // Update Training
      const { trainings, stat } = await apis.updateTraining(
        trainingId,
        trainingData
      );

      // Update Trainings
      dispatch(setTrainings(trainings));

      // Update Stat
      dispatch(setTrainingStat(stat));

      // Show Success Notification On Upadating Training
      dispatch(showNotification(getSuccess("updated")));

      // Navigate To Trainings
      History.push("/admin/trainings");
    } catch (err) {
      // Show Error In Notification
      dispatch(showNotification(err.message));
    } finally {
      // Stop Loading
      dispatch(trainingStopLoading());
    }
  };
