// Firebase
import { query, where, Timestamp, getDocs, doc } from "firebase/firestore";
import {
  subscriptionCollectionRef,
  payPerSubjectSubscriptionCollectionRef,
  firebaseLooper,
  levelCollectionRef,
  userCollectionRef,
} from "../../utils";

// Apis
import * as apis from "../";

// Utils
import { AppError } from "../../utils";

/**
 * Get Student Pay Per Subject Subscriptions
 * @param {string} studentId Student Id
 * @param {boolean} onlyActive If true get only active pay per subject subscriptions
 * @param {boolean} throwNotFound If true throw not found error
 */
export const getStudentPayPerSubjectSubscriptions = async (
  studentId,
  onlyActive = true,
  throwNotFound = true
) => {
  try {
    // Student Doc Reference
    const studentRef = doc(userCollectionRef, studentId);

    // Create Pay Per Subject Subscription Query
    const payPerSubjectSubscriptionQuery = onlyActive
      ? query(
          payPerSubjectSubscriptionCollectionRef,
          where("studentId", "==", studentRef),
          where("subscriptionEndAt", ">=", Timestamp.fromDate(new Date()))
        )
      : query(
          payPerSubjectSubscriptionCollectionRef,
          where("studentId", "==", studentRef)
        );

    // Get Student Pay Per Subject Subscriptions
    const querySnapshot = await getDocs(payPerSubjectSubscriptionQuery);

    // Throw Custom Error If PayPerSubject Subscription Does Not Exist & Throw Not Found Is True
    if (querySnapshot.empty && throwNotFound) {
      throw new AppError("subscriptions/subscription");
    }

    // Return Student PayPerSubject Subscriptions
    return firebaseLooper(querySnapshot);
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Student Level Pay Per Subject Subscriptions
 * @param {string} studentId Student Id
 * @param {string} levelId Level Id
 * @param {boolean} onlyActive If true get only active pay per subject subscriptions
 * @param {boolean} throwNotFound If true throw not found error
 */
export const getStudentLevelPayPerSubjectSubscriptions = async (
  studentId,
  levelId,
  onlyActive = true,
  throwNotFound = true
) => {
  try {
    // Document References
    const studentRef = doc(userCollectionRef, studentId);
    const levelRef = doc(levelCollectionRef, levelId);

    // Create Pay Per Subject Subscription Query For Level
    const payPerSubjectSubscriptionQuery = onlyActive
      ? query(
          payPerSubjectSubscriptionCollectionRef,
          where("studentId", "==", studentRef),
          where("levelId", "==", levelRef),
          where("subscriptionEndAt", ">=", Timestamp.fromDate(new Date()))
        )
      : query(
          payPerSubjectSubscriptionCollectionRef,
          where("studentId", "==", studentRef),
          where("levelId", "==", levelRef)
        );

    // Get Level Pay Per Subject Subscriptions
    const querySnapshot = await getDocs(payPerSubjectSubscriptionQuery);

    // Throw Custom Error If PayPerSubject Subscription Does Not Exist & Throw Not Found Is True
    if (querySnapshot.empty && throwNotFound) {
      throw new AppError("subscriptions/subscription");
    }

    // Return Level PayPerSubject Subscriptions
    return firebaseLooper(querySnapshot);
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Student Recurrent Subscriptions
 * @param {string} studentId Student Id
 * @param {boolean} onlyActive If true get only active recurrent subscriptions
 * @param {boolean} throwNotFound If true throw not found error
 */
export const getStudentRecurrentSubscriptions = async (
  studentId,
  onlyActive = true,
  throwNotFound = true
) => {
  try {
    // Student Document Reference
    const studentRef = doc(userCollectionRef, studentId);

    // Create Subscription Query
    const subscriptionQuery = onlyActive
      ? query(
          subscriptionCollectionRef,
          where("studentId", "==", studentRef),
          where("subscriptionEndAt", ">=", Timestamp.fromDate(new Date()))
        )
      : query(subscriptionCollectionRef, where("studentId", "==", studentRef));

    // Get Student Recurrent Subscriptions
    const querySnapshot = await getDocs(subscriptionQuery);

    // Throw Custom Error If Recurrent Subscription Does Not Exist & Throw Not Found Is True
    if (querySnapshot.empty && throwNotFound) {
      throw new AppError("subscriptions/subscription");
    }

    // Return Student Recurrent Subscriptions
    return firebaseLooper(querySnapshot);
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Student Level Recurrent Subscriptions
 * @param {string} studentId Student Id
 * @param {string} levelId Level Id
 * @param {boolean} onlyActive If true get only active recurrent subscriptions
 * @param {boolean} throwNotFound If true throw not found error
 */
export const getStudentLevelRecurrentSubscriptions = async (
  studentId,
  levelId,
  onlyActive = true,
  throwNotFound = true
) => {
  try {
    // Document References
    const studentRef = doc(userCollectionRef, studentId);
    const levelRef = doc(levelCollectionRef, levelId);

    // Create Subscription Query
    const subscriptionQuery = onlyActive
      ? query(
          subscriptionCollectionRef,
          where("studentId", "==", studentRef),
          where("levelId", "==", levelRef),
          where("subscriptionEndAt", ">=", Timestamp.fromDate(new Date()))
        )
      : query(
          subscriptionCollectionRef,
          where("studentId", "==", studentRef),
          where("levelId", "==", levelRef)
        );

    // Get Level Recurrent Subscriptions
    const querySnapshot = await getDocs(subscriptionQuery);

    // Throw Custom Error If Recurrent Subscription Does Not Exist & Throw Not Found Is True
    if (querySnapshot.empty && throwNotFound) {
      throw new AppError("subscriptions/subscription");
    }

    // Return Level Recurrent Subscriptions
    return firebaseLooper(querySnapshot);
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Populate Level Field In Recurrent Subscriptions
 * @param {array} recurrentSubscriptions Recurrent subscriptions
 */
const populateLevelInRecurrentSubscriptions = async (
  recurrentSubscriptions
) => {
  try {
    // Get Recurrent Subscriptions With Level Populated
    const recurrrentSubscriptionsWithLevel = await Promise.all(
      recurrentSubscriptions.map(async (recurrentSubscription) => ({
        ...recurrentSubscription,
        levelId: recurrentSubscription.levelId.id,
        level:
          (await apis.getLevel(recurrentSubscription.levelId.id, false)) || {},
      }))
    );

    // Return Recurrent Subscriptions With Level
    return recurrrentSubscriptionsWithLevel;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Populate Training Field In Recurrent Subscriptions
 * @param {array} recurrentSubscriptions Recurrent subscriptions
 */
const populateTrainingInRecurrentSubscriptions = async (
  recurrentSubscriptions
) => {
  try {
    // Get Recurrent Subscriptions With Training Populated
    const recurrentSubscriptionsWithTraining = await Promise.all(
      recurrentSubscriptions.map(async (recurrentSubscription) => ({
        ...recurrentSubscription,
        trainingId: recurrentSubscription.trainingId.id,
        training:
          (await apis.getTraining(
            recurrentSubscription.trainingId.id,
            false
          )) || {},
      }))
    );

    // Return Recurrent Subscriptions With Training
    return recurrentSubscriptionsWithTraining;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Student Subscription Histories
 * @param {string} studentId Student id
 * @param {boolean} onlyActiveSubscription If true get only active recurrent subscription
 * @param {boolean} onlyActive If true get subscription that has not been deleted
 */
export const getStudentSubscriptionHistories = async (
  studentId,
  onlyActiveSubscription = true,
  onlyActive = true
) => {
  try {
    // Student Document Reference
    const studentRef = doc(userCollectionRef, studentId);

    // Create Subscription Query
    const subscriptionQuery = onlyActiveSubscription
      ? query(
          subscriptionCollectionRef,
          where("studentId", "==", studentRef),
          where("isActive", "==", onlyActive),
          where("subscriptionEndAt", ">=", Timestamp.fromDate(new Date()))
        )
      : query(
          subscriptionCollectionRef,
          where("studentId", "==", studentRef),
          where("isActive", "==", onlyActive)
        );

    // Get Student Recurrent Subscriptions
    const subscriptionSnapshot = await getDocs(subscriptionQuery);

    // If User Does Not Have Any Subscription, Throw Subscription Error
    if (subscriptionSnapshot.empty) {
      throw new AppError("subscriptions/subscription");
    }

    // Get Recurrent Subscriptions From Subscription Snapshot
    let subscriptionHistories = firebaseLooper(subscriptionSnapshot);

    // Populate Level Field In Recurrent Subscriptions
    subscriptionHistories = await populateLevelInRecurrentSubscriptions(
      subscriptionHistories
    );

    // Populate Training Field In Recurrent Subscriptions
    subscriptionHistories = await populateTrainingInRecurrentSubscriptions(
      subscriptionHistories
    );

    // Return Student Subscription Histories
    return subscriptionHistories;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};
