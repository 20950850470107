// Modules
import { createSlice } from "@reduxjs/toolkit";

// Common Reducers
import { loadingReducers } from "../common";

// Utils
import { stateSelector } from "../../utils";

// Initial State
const initialState = {
  isLoading: false,
};

// Slice Name
const sliceName = "payment";

// Payment Slice
const paymentSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: { ...loadingReducers },
});

export const {
  startLoading: paymentStartLoading,
  stopLoading: paymentStopLoading,
} = paymentSlice.actions;
export * from "./paymentAction";
export const selectIsPaymentLoading = stateSelector(sliceName, "isLoading");
export default paymentSlice.reducer;
