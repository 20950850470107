/**
 * Scroll To A Section On A Page
 * @param {string} sectionName Section classname on page
 * @example
 * scrollIntoView("home-services")
 */
export const scrollIntoView = (sectionName) => {
  const sectionElm = document.querySelector(`.${sectionName}`);
  sectionElm.scrollIntoView({
    behavior: "smooth",
  });
};
