// Modules
import React from "react";

// Components
import { StudentPrivateSessionList, EmptyContent } from "../..";

// Hooks
import { usePrivateSession, useUi } from "../../../hooks";

// Style
import "./private-session-base.styles.scss";

export const PrivateSessionBase = ({ className }) => {
  const {
    privateSessions,
    privateSessionErrorMessage,
    isPrivateSessionLoading,
  } = usePrivateSession();
  const { pageTitle } = useUi();

  return (
    <div className={`private-session-base ${className} is-height-full`}>
      <div className="private-session-base__container is-height-full">
        <EmptyContent
          className="private-session-base__empty"
          isLoading={isPrivateSessionLoading}
          errorMessage={privateSessionErrorMessage}
        >
          <h2 className="private-session-base__heading student-base-heading student-base-heading--media-sensitive is-text-secondary-color">
            {pageTitle}
          </h2>

          <StudentPrivateSessionList
            className="private-session-base__list"
            items={privateSessions}
          />
        </EmptyContent>
      </div>
    </div>
  );
};

PrivateSessionBase.defaultProps = {
  className: "",
};
