// Modules
import React from "react";

// Hooks
import { useEffect } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";

// Utils
import { scrollIntoView } from "../../utils";

// Components
import { Fragment } from "react";
import {
  HomeHero,
  HomeStatBase,
  HomeServicesBase,
  HomeAboutBase,
  HomeFinaccoBenefitBase,
  HomeTestimonialBase,
  ContactBase,
  TeamBase,
} from "../../components";

export const Home = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { search } = useLocation();

  useEffect(() => {
    // Get Name Of Section To Scroll To
    const scrollTo = searchParams.get("s");

    if (!scrollTo) return;

    // Scroll To Section In Page
    scrollIntoView(scrollTo);

    // Reset Url
    navigate("/");
  }, [search]);

  return (
    <Fragment>
      <HomeHero />
      <HomeStatBase />
      <HomeServicesBase />
      <HomeAboutBase />
      <HomeFinaccoBenefitBase />
      <HomeTestimonialBase />
      <TeamBase />
      <ContactBase />
    </Fragment>
  );
};
