// Modules
import React from "react";

// Hooks
import { useEffect } from "react";
import { useStaff } from "../../../hooks";

// Components
import { TeamList } from "../../";

// Style
import "./team-base.styles.scss";

export const TeamBase = ({ className }) => {
  const { getFeaturedStaffs, dispatch, staffs } = useStaff();

  useEffect(() => {
    // Get Featured Staffs
    dispatch(getFeaturedStaffs(false));
  }, []);

  // If Staffs Is Empty Then Render Nothing
  if (!staffs.length) return null;

  return (
    <div className={`team-base ${className}`}>
      <div className="team-base__container container">
        <h2 className="team-base__heading primary-heading is-text-accent-300 is-text-center">
          Our <span className="is-text-accent-400">Team</span>
        </h2>

        <TeamList className="team-base__list" items={staffs} />
      </div>
    </div>
  );
};

TeamBase.defaultProps = {
  className: "",
};
