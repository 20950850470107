// Modules
import React from "react";

// Hooks
import { useStaff } from "../../../../hooks";

// Utils
import { formatToCompactNumber } from "../../../../utils";

// Components
import { StatsList, StatsCard } from "../../../";

export const StaffStatsBase = ({ className }) => {
  const {
    staffStat: { totalStaffs, currentWeekLastActiveAtStaffCount },
  } = useStaff();

  return (
    <div className={`staff-stats-base ${className}`}>
      <div className="staff-stats-base__container">
        <StatsList className="staff-stats-base__list">
          <StatsCard
            className="staff-stats-base__card"
            title={`${formatToCompactNumber(totalStaffs)} Staffs`}
            subtitle="In Total"
          />

          <StatsCard
            className="staff-stats-base__card"
            title={`${formatToCompactNumber(
              currentWeekLastActiveAtStaffCount
            )} Active`}
            subtitle="This week"
            isSecondary
          />
        </StatsList>
      </div>
    </div>
  );
};

StaffStatsBase.defaultProps = {
  className: "",
};
