// Modules
import React from "react";

// Components
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

// Style
import "./button.styles.scss";

export const Button = ({
  className,
  wrapperClassName,
  children,
  to,
  type,
  loading,
  loadingColor,
  loadingWrapperClassName,
  loadingWidth,
  loadingHeight,
  loadingRadius,
  disabled,
  onClick,
  onMouseEnter,
}) => {
  const renderedLoadingSpinner = (
    <ThreeDots
      height={loadingHeight}
      width={loadingWidth}
      radius={loadingRadius}
      color={loadingColor}
      ariaLabel="content loading"
      wrapperClass={`btn__loading ${loadingWrapperClassName}`}
      visible={loading}
    />
  );

  if (to) {
    return (
      <Link className={`btn ${className}`} to={to}>
        {loading ? renderedLoadingSpinner : children}
      </Link>
    );
  }

  return (
    <div
      className={`btn-wrapper ${wrapperClassName}`}
      onMouseEnter={onMouseEnter}
    >
      <button
        type={type}
        className={`btn ${className}`}
        disabled={disabled}
        onClick={onClick}
      >
        {loading ? renderedLoadingSpinner : children}
      </button>
    </div>
  );
};

Button.defaultProps = {
  className: "",
  loadingWrapperClassName: "",
  wrapperClassName: "",
  to: "",
  type: "button",
  disabled: false,
  loading: false,
  loadingColor: "#ffffff",
  loadingWidth: "50",
  loadingHeight: "35",
  loadingRadius: "9",
};
