// Modules
import { createUpload as _createUpload } from "@mux/upchunk";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase/firebaseConfig.utils";
import { toast } from "react-toastify";

/**
 * Get Mux upload url
 */
const getUploadUrl = async () => {
  const uploadUrl = await httpsCallable(functions, "getMuxUploadUrl")();
  return uploadUrl.data;
};

/**
 * Mux create upload promisify
 * @param {File} file
 * @param {string} fileName
 */
export const muxCreateUploadPro = (file, fileName) => {
  return new Promise(async (resolve, reject) => {
    const uploadUrlData = await getUploadUrl();

    const upload = _createUpload({
      endpoint: uploadUrlData.url,
      file,
      chunkSize: 2048,
      attempts: 15,
      useLargeFileWorkaround: true,
    });

    upload.on("progress", (progress) => {
      if (!toast.isActive(uploadUrlData.id)) {
        toast.success(`${fileName}:${Math.trunc(progress.detail)}%`, {
          toastId: uploadUrlData.id,
          autoClose: false,
        });
      } else {
        toast.update(uploadUrlData.id, {
          type: "success",
          render: `${fileName}:${Math.trunc(progress.detail)}%`,
        });
      }
    });

    upload.on("error", (err) => {
      console.log(err, err.detail);
      toast.dismiss(uploadUrlData.id);
      toast.error(err.detail);
      reject(err);
    });

    upload.on("success", async () => {
      toast.dismiss(uploadUrlData.id);

      const assetData = await httpsCallable(
        functions,
        "getMuxAssetData"
      )(uploadUrlData.id);

      resolve(assetData.data);
    });
  });
};

/**
 * Mux Delete Upload
 * @param {string} assetId
 */
export const muxDeleteUpload = async (assetId) => {
  await httpsCallable(functions, "deleteMuxAssetData")(assetId);
};
