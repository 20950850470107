// Modules
import { createSlice } from "@reduxjs/toolkit";

// Common Reducers
import { errorMessageReducers, loadingReducers } from "../common";

// Utils
import { stateSelector } from "../../utils";

// Initial State
const initialState = {
  trainings: [],
  trainingStat: {
    totalTrainings: 0,
  },
  errorMessage: "",
  isLoading: false,
};

// Slice Name
const sliceName = "training";

// Training Slice
const trainingSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ...errorMessageReducers,
    ...loadingReducers,
    setTrainings(state, action) {
      state.trainings = action.payload;
    },
    resetTrainings(state) {
      state.trainings = [];
    },
    setTrainingStat(state, action) {
      state.trainingStat = action.payload;
    },
    resetTrainingStat(state) {
      state.trainingStat = {
        totalTrainings: 0,
      };
    },
    delTraining(state, action) {
      // Get Training Id
      const trainingId = action.payload;

      // Get Trainings
      const { trainings } = state;

      // Update Trainings With Training That Does Not Contain The Specified Training Id
      state.trainings = trainings.filter(
        (training) => training.id !== trainingId
      );
    },
  },
});

export const {
  setTrainings,
  resetTrainings,
  setErrorMessage: setTrainingErrorMessage,
  resetErrorMessage: resetTrainingErrorMessage,
  startLoading: trainingStartLoading,
  stopLoading: trainingStopLoading,
  setTrainingStat,
  resetTrainingStat,
  delTraining,
} = trainingSlice.actions;
export * from "./trainingAction";
export const selectTrainings = stateSelector(sliceName, "trainings");
export const selectTrainingErrorMessage = stateSelector(
  sliceName,
  "errorMessage"
);
export const selectIsTrainingLoading = stateSelector(sliceName, "isLoading");
export const selectTrainingStat = stateSelector(sliceName, "trainingStat");
export default trainingSlice.reducer;
