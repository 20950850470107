// Hooks
import { useSelector, useDispatch } from "react-redux";

// Redux
import { uiSA } from "../store";

// Destructure Redux Imports
const { selectNotification, selectPageTitle } = uiSA;

/**
 * Ui Hook
 */
export const useUi = () => {
  const dispatch = useDispatch();
  const notification = useSelector(selectNotification);
  const pageTitle = useSelector(selectPageTitle);

  return {
    notification,
    pageTitle,
    dispatch,
    ...uiSA,
  };
};
