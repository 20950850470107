// Modules
import React from "react";

// Components
import { TeamItem } from "../../";

// Style
import "./team-list.styles.scss";

export const TeamList = ({ className, items }) => {
  const renderedTeamItems = items.map(
    ({ uid, firstName, lastName, employment, avatar }) => (
      <TeamItem
        key={uid}
        className="team-list__item"
        fullName={`${firstName} ${lastName}`}
        avatar={avatar?.url}
        orgRole={employment.empRole}
      />
    )
  );

  return <div className={`team-list ${className}`}>{renderedTeamItems}</div>;
};

TeamList.defaultProps = {
  className: "",
};
