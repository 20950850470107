// Modules
import React from "react";

// Components
import { PrivateSessionCard } from "../../../../";

// Style
import "./private-session-list.styles.scss";

export const PrivateSessionList = ({
  className,
  items,
  onDeletePrivateSession,
}) => {
  // Rendered Private Session Items
  const renderedPrivateSessionItems = items.map((privateSession) => (
    <PrivateSessionCard
      className="private-session-list__item"
      key={privateSession.id}
      sessionId={privateSession.id}
      sessionDescription={privateSession.description}
      sessionName={privateSession.name}
      onDeletePrivateSession={onDeletePrivateSession}
      enableAction
    />
  ));

  return (
    <div className={`private-session-list ${className}`}>
      {renderedPrivateSessionItems}
    </div>
  );
};

PrivateSessionList.defaultProps = {
  className: "",
};
