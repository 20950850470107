// Modules
import React from "react";

// Components
import { AccountingSolutionGroup, AccountingSolutionList } from "..";

// Data
import {
  TRANSACTION_MANAGEMENT_ITEMS,
  VISUAL_MANAGEMENT_ITEMS,
  OTHER_SOLUTION_ITEMS,
} from "../../../data";

// Style
import "./accounting-solution-base.styles.scss";

export const AccountingSolutionBase = ({ className }) => {
  return (
    <section className={`accounting-solution-base ${className}`}>
      <div className="accounting-solution-base__container container">
        <AccountingSolutionGroup
          className="accounting-solution-base__group"
          title="Visual Management"
        >
          <AccountingSolutionList
            className="accounting-solution-base__list"
            items={VISUAL_MANAGEMENT_ITEMS}
          />
        </AccountingSolutionGroup>

        <AccountingSolutionGroup
          className="accounting-solution-base__group"
          title="Transaction Managment"
        >
          <AccountingSolutionList
            className="accounting-solution-base__list"
            items={TRANSACTION_MANAGEMENT_ITEMS}
          />
        </AccountingSolutionGroup>

        <AccountingSolutionGroup
          className="accounting-solution-base__group"
          title="Others Solutions"
        >
          <AccountingSolutionList
            className="accounting-solution-base__list"
            items={OTHER_SOLUTION_ITEMS}
          />
        </AccountingSolutionGroup>
      </div>
    </section>
  );
};

AccountingSolutionBase.defaultProps = {
  className: "",
};
