// Modules
import React from "react";

// Components
import { LazyLoadImage } from "react-lazy-load-image-component";

// Images
import homeAbout from "../../../assets/images/home-about.svg";
import homeAboutLazy from "../../../assets/images/home-about-lazy.jpg";

// Style
import "./home-about-base.styles.scss";

export const HomeAboutBase = ({ className }) => {
  return (
    <div className={`home-about-base ${className}`}>
      <div className="home-about-base__container container">
        <h2 className="home-about-base__heading primary-heading is-text-center is-text-secondary-color">
          What is <span className="is-text-primary-color">FinAcco Hub?</span>
        </h2>

        <p className="home-about-base__description container">
          FinAcco Hub is an accounting & financial consulting institute for
          training accounting professionals and empowering organizations with
          the best talent and services. We pride ourself in professional ethical
          standards, client satisfaction, innovation, and high-quality solutions
          for our clients.
        </p>

        <div className="home-about-base__group">
          <div className="home-about-base__left">
            <LazyLoadImage
              className="home-about-base__photo"
              src={homeAbout}
              placeholderSrc={homeAboutLazy}
              alt="About Finacco Purpose and Goal"
            />
          </div>

          <div className="home-about-base__right">
            <div className="home-about-base__right-top">
              <h3 className="home-about-base__right-heading">OUR PURPOSE</h3>
              <p className="home-about-base__right-text">
                Our purpose is to create an opportunity to rise for our
                stakeholders
              </p>
            </div>

            <div className="home-about-base__right-bottom">
              <h3 className="home-about-base__right-heading">OUR GOALS</h3>
              <ul className="home-about-base__right-list">
                <li className="home-about-base__right-item">
                  To offer credible and high-quality service to our partners.
                </li>
                <li className="home-about-base__right-item">
                  To contribute to social, governance and financial inclusion by
                  providing reliable and capable resources to drive the
                  country's development agenda
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeAboutBase.defaultProps = {
  className: "",
};
