// Modules
import React from "react";

// Hooks
import { useValidateFormOnMouseEnter, useFeedback } from "../../../hooks";

// Components
import { Form, Input, Button } from "../../";

// Style
import "./video-feedback-modal-form.styles.scss";

export const VideoFeedbackModalForm = ({ className, formik }) => {
  const { isFeedbackLoading } = useFeedback();

  const {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    handleSubmit,
    handleChange,
    validateForm,
    setTouched,
  } = formik;

  const { triggerFormValidation } = useValidateFormOnMouseEnter(
    validateForm,
    setTouched
  );

  return (
    <Form
      className={`video-feedback-modal-form container ${className}`}
      onSubmit={handleSubmit}
    >
      <Input
        labelClassName="video-feedback-modal-form__label"
        className="video-feedback-modal-form__control"
        wrapperClassName="video-feedback-modal-form__group"
        id="chapterName"
        name="chapterName"
        placeholder="Chapter Name"
        tertiary
        readonly
        error={errors.chapterName}
        touched={touched.chapterName}
        value={values.chapterName}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Input
        labelClassName="video-feedback-modal-form__label"
        className="video-feedback-modal-form__control"
        wrapperClassName="video-feedback-modal-form__group"
        id="chapterFileName"
        name="chapterFileName"
        placeholder="Video Name"
        tertiary
        readonly
        error={errors.chapterFileName}
        touched={touched.chapterFileName}
        value={values.chapterFileName}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Input
        labelClassName="video-feedback-modal-form__label"
        className="video-feedback-modal-form__control"
        wrapperClassName="video-feedback-modal-form__group"
        id="title"
        name="title"
        placeholder="Question/feedback Title"
        tertiary
        error={errors.title}
        touched={touched.title}
        value={values.title}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Input
        labelClassName="video-feedback-modal-form__label"
        className="video-feedback-modal-form__control"
        wrapperClassName="video-feedback-modal-form__group"
        id="description"
        name="description"
        type="textarea"
        placeholder="Describe your Questions or Feedback"
        tertiary
        error={errors.description}
        touched={touched.description}
        value={values.description}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Button
        type="submit"
        wrapperClassName="d-block"
        className="video-feedback-modal-form__btn btn--primary btn--rv1 btn--sv12 is-width-full"
        loading={isFeedbackLoading}
        loadingHeight="20"
        disabled={!isValid || isFeedbackLoading}
        onMouseEnter={triggerFormValidation.bind(null, isValid)}
      >
        Submit
      </Button>
    </Form>
  );
};

VideoFeedbackModalForm.defaultProps = {
  className: "",
};
