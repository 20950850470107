// Firebase
import {
  addDoc,
  updateDoc,
  doc,
  getDocs,
  query,
  where,
  serverTimestamp,
  orderBy,
  documentId,
} from "firebase/firestore";
import { firebaseLooper, trainingCollectionRef } from "../../utils";

// Utils
import { AppError } from "../../utils";

/**
 * Get All Trainings
 */
export const getTrainings = async () => {
  try {
    // Create Training Query
    const trainingQuery = query(
      trainingCollectionRef,
      where("isActive", "==", true),
      orderBy("createdAt", "desc")
    );

    // Get Trainings
    const querySnapshot = await getDocs(trainingQuery);

    // If Trainings Does Not Exist, Throw Custom Error
    if (querySnapshot.empty) {
      throw await AppError.checkNetworkConnectivityAndThrowError("empty-data");
    }

    // Return Trainings
    return firebaseLooper(querySnapshot);
  } catch (err) {
    // Re-Throw Error
    throw await AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Get A Training
 * @param {string} trainingId Training Id
 * @param {boolean} throwNotFound If true throw not found error
 */
export const getTraining = async (trainingId, throwNotFound = true) => {
  try {
    // Create Training Query
    const trainingQuery = query(
      trainingCollectionRef,
      where(documentId(), "==", trainingId),
      where("isActive", "==", true)
    );

    // Get Training
    const querySnapshot = await getDocs(trainingQuery);

    // If Training Does Not Exist & ThrowNotFound Is True, Throw Custom Error
    if (querySnapshot.empty && throwNotFound) {
      throw await AppError.checkNetworkConnectivityAndThrowError("empty-data");
    }

    // Return Training
    return firebaseLooper(querySnapshot)[0];
  } catch (err) {
    // Re-Throw Error
    throw await AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Get Trainings Stat
 * @param {array} trainings Trainings
 */
export const getTrainingsStat = (trainings) => {
  // Get Total Trainings Count
  const totalTrainings = trainings.length;

  // Return Stat
  return {
    totalTrainings,
  };
};

/**
 * Get All Trainings And Stat
 */
export const getTrainingsAndStat = async () => {
  try {
    // Get Trainings
    const trainings = await getTrainings();

    // Get Trainings Stat
    const stat = getTrainingsStat(trainings);

    // Return Trainings And Stat
    return { trainings, stat };
  } catch (err) {
    // Re-Throw Error
    throw await AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Create New Training
 * @param {object} trainingData Training data
 */
export const createTraining = async (trainingData) => {
  try {
    // Create Training
    await addDoc(trainingCollectionRef, {
      ...trainingData,
      isActive: true,
      createdAt: serverTimestamp(),
    });

    // Get Trainings And Stat
    const trainingsStat = await getTrainingsAndStat();

    // Return Trainings And Stat
    return trainingsStat;
  } catch (err) {
    // Re-Throw Error
    throw await AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Update Training
 * @param {string} trainingId Training id
 * @param {object} trainingData Training data
 */
export const updateTraining = async (trainingId, trainingData) => {
  try {
    // Training Doc Reference
    const trainingRef = doc(trainingCollectionRef, trainingId);

    // Update Training
    await updateDoc(trainingRef, trainingData);

    // Get Trainings And Stat
    const trainingsStat = await getTrainingsAndStat();

    // Return TrainingsStat
    return trainingsStat;
  } catch (err) {
    // Re-Throw Error
    throw await AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Delete A Training
 * @param {string} trainingId Training Id
 */
export const deleteTraining = async (trainingId) => {
  try {
    // Training Doc Reference
    const trainingRef = doc(trainingCollectionRef, trainingId);

    // Delete Training
    await updateDoc(trainingRef, {
      isActive: false,
    });

    // Get Trainings Stat
    const { stat } = await getTrainingsAndStat();

    // Return Training Stat
    return stat;
  } catch (err) {
    // Re-Throw Error
    throw await AppError.checkNetworkConnectivityAndThrowError(err);
  }
};
