// Modules
import { createSlice } from "@reduxjs/toolkit";

// Common Reducers
import { loadingReducers, errorMessageReducers } from "../common";

// Utils
import { stateSelector } from "../../utils";

// Initial State
const initialState = {
  isLoading: false,
  errorMessage: "",
  videoViewsStat: {
    currentMonthStat: {
      currentMonthViewsCount: 0,
      currentDayViewsCount: 0,
      currentWeekViewsCount: 0,
    },
    monthStat: {
      currentMonthViewsCount: 0,
    },
  },
};

// Slice Name
const sliceName = "videoViews";

// Video Views Slice
const videoViewsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ...loadingReducers,
    ...errorMessageReducers,
    setVideoViewsStat(state, action) {
      state.videoViewsStat = action.payload;
    },
    resetVideoViewsStat(state) {
      state.videoViewsStat = {
        currentMonthStat: {
          currentMonthViewsCount: 0,
          currentDayViewsCount: 0,
          currentWeekViewsCount: 0,
        },
        monthStat: {
          currentMonthViewsCount: 0,
        },
      };
    },
  },
});

export const {
  startLoading: videoViewsStartLoading,
  stopLoading: videoViewsStopLoading,
  setVideoViewsStat,
  resetVideoViewsStat,
  setErrorMessage: setVideoViewsErrorMessage,
  resetErrorMessage: resetVideoViewsErrorMessage,
} = videoViewsSlice.actions;
export * from "./videoViewsAction";
export const selectIsVideoViewsloading = stateSelector(sliceName, "isLoading");
export const selectVideoViewsStat = stateSelector(sliceName, "videoViewsStat");
export const selectVideoViewsErrorMessage = stateSelector(
  sliceName,
  "errorMessage"
);
export default videoViewsSlice.reducer;
