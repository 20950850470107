// Modules
import React from "react";

// Hooks
import { useAuth } from "../../../hooks";

// Components
import { Button, MessageBanner } from "../../shared";

// Style
import "./not-verified.styles.scss";

export const NotVerified = ({ className }) => {
  const { user, sendVerificationEmail, isAuthLoading, dispatch } = useAuth();

  /**
   * Resend User Verification Email Link
   */
  const handleResendVerificationEmail = () => {
    dispatch(sendVerificationEmail());
  };

  return (
    <MessageBanner
      className={`not-verified ${className}`}
      message={`Hi ${user?.firstName}, We’ll Love to Verify your Email address`}
      show={!user?.isVerified}
    >
      <Button
        className="not-verified__btn btn--rv2 btn--sv11 btn--color-secondary"
        loading={isAuthLoading}
        loadingHeight="17"
        disabled={isAuthLoading}
        onClick={handleResendVerificationEmail}
      >
        Resend Verification Email
      </Button>
    </MessageBanner>
  );
};

NotVerified.defaultProps = {
  className: "",
};
