// Modules
import { createSlice } from "@reduxjs/toolkit";

// Common Reducers
import { loadingReducers, errorMessageReducers } from "../common";

// Utils
import { stateSelector } from "../../utils";

// Initial State
const initialState = {
  isLoading: false,
  errorMessage: "",
  chapterFiles: [],
  activeChapterFile: "",
  chapterFileStat: {
    totalVideos: 0,
  },
};

// Slice Name
const sliceName = "chapterFile";

// Chapter File Slice
const chapterFileSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ...loadingReducers,
    ...errorMessageReducers,
    setChapterFiles(state, action) {
      state.chapterFiles = action.payload;
    },
    resetChapterFiles(state) {
      state.chapterFiles = [];
    },
    setActiveChapterFile(state, action) {
      state.activeChapterFile = action.payload;
    },
    resetActiveChapterFile(state) {
      state.activeChapterFile = "";
    },
    setChapterFileStat(state, action) {
      state.chapterFileStat = action.payload;
    },
    resetChapterFileStat(state) {
      state.chapterFileStat = {
        totalVideos: 0,
      };
    },
    delChapterFile(state, action) {
      // Get ChapterFile Id
      const chapterFileId = action.payload;

      // Get ChapterFiles
      const { chapterFiles } = state;

      // Update ChapterFiles With ChapterFile That Does Not Contain The Specified ChapterFile Id
      state.chapterFiles = chapterFiles.filter(
        (chapterFile) => chapterFile.id !== chapterFileId
      );

      // If ChapterFiles Is Empty, Then Set Error Message
      if (!state.chapterFiles.length) {
        state.errorMessage = "No Data Available. Wait For Data Upload";
      }
    },
  },
});

export const {
  setChapterFiles,
  resetChapterFiles,
  startLoading: chapterFileStartLoading,
  stopLoading: chapterFileStopLoading,
  setErrorMessage: setChapterFileErrorMessage,
  resetErrorMessage: resetChapterFileErrorMessage,
  setActiveChapterFile,
  resetActiveChapterFile,
  delChapterFile,
  setChapterFileStat,
  resetChapterFileStat,
} = chapterFileSlice.actions;
export * from "./chapterFileAction";
export const selectChapterFiles = stateSelector(sliceName, "chapterFiles");
export const selectIsChapterFileLoading = stateSelector(sliceName, "isLoading");
export const selectActiveChapterFile = stateSelector(
  sliceName,
  "activeChapterFile"
);
export const selectChapterFileErrorMessage = stateSelector(
  sliceName,
  "errorMessage"
);
export const selectChapterFileStat = stateSelector(
  sliceName,
  "chapterFileStat"
);
export default chapterFileSlice.reducer;
