// Modules
import React from "react";

// Components
import { StatsList, StatsCard } from "../../../";

export const DashboardStatsBase = ({ className }) => {
  return (
    <div className={`dashboard-stats-base ${className}`}>
      <div className="dashboard-stats-base__container">
        <StatsList className="dashboard-stats-base__list">
          <StatsCard
            className="dashboard-stats-base__card"
            title="$23k Sales"
            subtitle="Sales this Month"
          />

          <StatsCard
            className="dashboard-stats-base__card"
            title="230 Students"
            subtitle="Students this Month"
          />

          <StatsCard
            className="dashboard-stats-base__card"
            title="7k Videos"
            subtitle="In Total"
            isSecondary
          />
        </StatsList>
      </div>
    </div>
  );
};

DashboardStatsBase.defaultProps = {
  className: "",
};
