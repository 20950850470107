// Modules
import React from "react";

// Components
import {
  ModalOverlay,
  Modal,
  EmptyContent,
  AddPrivateSessionForm,
} from "../../../";

// Hooks
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { usePrivateSession } from "../../../../hooks";

// Schema
import { addPrivateSessionSchema } from "../../../../schemas";

// Style
import "./add-private-session-modal-base.styles.scss";

export const AddPrivateSessionModalBase = ({ className }) => {
  const [privateSession, setPrivateSession] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const {
    createPrivateSession,
    isPrivateSessionLoading,
    dispatch,
    privateSessions,
  } = usePrivateSession();
  const { privateSessionId } = useParams();
  const isEditMode = privateSessionId !== "1";

  // Add Private Session Formik
  const addPrivateSessionFormik = useFormik({
    initialValues: {
      name: "",
      description: "",
      price: "",
      tutorId: "",
      availabilityDates: [],
    },
    validationSchema: addPrivateSessionSchema,
    onSubmit(values) {
      // Create/Update Private Session
      dispatch(
        createPrivateSession(values, isEditMode ? privateSessionId : undefined)
      );
    },
  });

  useEffect(() => {
    // Create Error Message
    const errorMessage = "No Private Session Available With The Provided Id.";

    // Get PrivateSession If PrivateSession Is Not Loading And Edit Mode Is True
    if (!isPrivateSessionLoading && isEditMode) {
      // Get PrivateSession By PrivateSession Id
      const privateSession = privateSessions.find(
        (privateSession) => privateSession.id === privateSessionId
      );

      // Set Error Message If PrivateSession Is Undefined, Else Update PrivateSession And Reset Error Message
      if (!privateSession) {
        setErrorMessage(errorMessage);
      } else {
        setErrorMessage("");
        setPrivateSession(privateSession);
      }
    }
  }, [privateSessions, privateSessionId]);

  useEffect(() => {
    // If PrivateSession Exist And Edit Mode Is True, Update Formik State
    if (privateSession && isEditMode) {
      const { name, description, price, tutorId, availabilityDates } =
        privateSession;
      addPrivateSessionFormik.setValues({
        name,
        description,
        price,
        tutorId,
        availabilityDates: structuredClone(availabilityDates),
      });
    }
  }, [privateSession]);

  // Destructure Add Private Session Formik
  const { isSubmitting } = addPrivateSessionFormik;

  return (
    <ModalOverlay
      className={`add-private-session-modal-base-overlay ${className}`}
      to="/admin/bookings/private-sessions"
    >
      <Modal className="add-private-session-modal-base">
        <EmptyContent
          className="add-private-session-modal-base__empty"
          isLoading={!isSubmitting && isPrivateSessionLoading}
          errorMessage={errorMessage}
        >
          <AddPrivateSessionForm
            className="add-private-session-modal-base__form"
            formik={addPrivateSessionFormik}
            isEditMode={isEditMode}
          />
        </EmptyContent>
      </Modal>
    </ModalOverlay>
  );
};

AddPrivateSessionModalBase.defaultProps = {
  className: "",
};
