// Modules
import React from "react";

export const Form = ({ className, children, ...formProps }) => {
  return (
    <form className={`form ${className}`} {...formProps}>
      {children}
    </form>
  );
};

Form.defaultProps = {
  className: "",
};
