// Redux
import {
  setPrivateSessions,
  setPrivateSession,
  privateSessionStartLoading,
  privateSessionStopLoading,
  setPrivateSessionErrorMessage,
  resetPrivateSessionErrorMessage,
  delPrivateSession,
} from "./";
import { uiSA } from "..";

// Apis
import * as apis from "../../apis";

// Utils
import { getSuccess, History } from "../../utils";

// Destructure Redux Imports
const { showNotification } = uiSA;

/**
 * Get All Private Sessions
 * @param {boolean} populateTutorField If true populate the tutor field
 */
export const getPrivateSessions =
  (populateTutorField = false) =>
  async (dispatch) => {
    try {
      // Reset Error Message
      dispatch(resetPrivateSessionErrorMessage());

      // Start Loading
      dispatch(privateSessionStartLoading());

      // Get Private Sessions
      const privateSessions = await apis.getPrivateSessions(populateTutorField);

      // Update PrivateSessions
      dispatch(setPrivateSessions(privateSessions));
    } catch (err) {
      // Update Error Message
      dispatch(setPrivateSessionErrorMessage(err.message.message));
    } finally {
      // Stop Loading
      dispatch(privateSessionStopLoading());
    }
  };

/**
 * Create New Private Session
 * @param {object} privateSessionData Private session data
 * @param {string|undefined} privateSessionId Private session id
 */
export const createPrivateSession =
  (privateSessionData, privateSessionId) => async (dispatch) => {
    try {
      // Reset Error Message
      dispatch(resetPrivateSessionErrorMessage());

      // Start Loading
      dispatch(privateSessionStartLoading());

      // Create Private Session
      const privateSessions = await apis.createPrivateSession(
        privateSessionData,
        privateSessionId
      );

      // Update PrivateSessions
      dispatch(setPrivateSessions(privateSessions));

      // Show Success Notification On Creating/Updating Private Session
      privateSessionId
        ? dispatch(showNotification(getSuccess("updated")))
        : dispatch(showNotification(getSuccess("created")));

      // Navigate To PrivateSessions Page
      History.push("/admin/bookings/private-sessions", { replace: true });
    } catch (err) {
      // Show Error In Notification
      dispatch(showNotification(err.message));
    } finally {
      // Stop Loading
      dispatch(privateSessionStopLoading());
    }
  };

/**
 * Delete A Private Session
 * @param {string} privateSessionId Private session id
 */
export const deletePrivateSession = (privateSessionId) => async (dispatch) => {
  try {
    // Delete Private Session
    await apis.deletePrivateSession(privateSessionId);

    // Delete PrivateSession In Redux Store
    dispatch(delPrivateSession(privateSessionId));

    // Show Success Notification On Deleting Private Session
    dispatch(showNotification(getSuccess("deleted")));
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  }
};

/**
 * Get Private Session Booking Availability Dates And Time
 * @param {string} privateSessionId Private session id
 * @param {boolean} populateTutorField If true populate the tutor field
 */
export const getPrivateSessionBookingAvailabilityDatesAndTime =
  (privateSessionId, populateTutorField = false) =>
  async (dispatch) => {
    try {
      // Reset Error Message
      dispatch(resetPrivateSessionErrorMessage());

      // Get Private Session
      const privateSession =
        await apis.getPrivateSessionBookingAvailabilityDatesAndTime(
          privateSessionId,
          populateTutorField
        );

      // Update Private Session
      dispatch(setPrivateSession(privateSession));
    } catch (err) {
      // Update Error Message
      dispatch(setPrivateSessionErrorMessage(err.message.message));
    }
  };
