// Modules
import ReactDataGrid from "@inovua/reactdatagrid-community";

// React Data Grid Custom Filters
export const tableCustomFilters = Object.assign(
  {},
  ReactDataGrid.defaultProps.filterTypes,
  {
    service: {
      name: "service",
      operators: [
        {
          name: "transactionService",
          fn: ({ value, filterValue }) => {
            return value.serviceText
              .toLowerCase()
              .trim()
              .includes(filterValue.toLowerCase().trim());
          },
        },
      ],
    },
    fullName: {
      name: "fullName",
      operators: [
        {
          name: "fullName",
          fn: ({ data: { student, firstName, lastName }, filterValue }) => {
            const _firstName = firstName || student.firstName;
            const _lastName = lastName || student.lastName;
            return `${_firstName} ${_lastName}`
              .toLowerCase()
              .trim()
              .includes(filterValue.toLowerCase().trim());
          },
        },
      ],
    },
    email: {
      name: "email",
      operators: [
        {
          name: "email",
          fn: ({ data: { student }, filterValue }) => {
            return student.email
              .toLowerCase()
              .trim()
              .includes(filterValue.toLowerCase().trim());
          },
        },
      ],
    },
  }
);
