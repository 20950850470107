// Modules
import React from "react";

// Hooks
import { useSearchParams, useLocation } from "react-router-dom";
import { useChapter } from "../../../hooks";

// Components
import { VideoChapterList, Button, EmptyContent } from "../../";

// Style
import "./video-chapter-base.styles.scss";

export const VideoChapterBase = ({ className }) => {
  const [searchParams] = useSearchParams();
  const { search } = useLocation();
  const {
    chapters,
    dispatch,
    deleteChapter,
    activeChapter,
    setActiveChapter,
    chapterErrorMessage,
    isChapterLoading,
  } = useChapter();
  const isEditMode = searchParams.get("edit") === "true";
  const subjectId = searchParams.get("subject");

  /**
   * Handle Delete A Chapter
   * @param {string} chapterId Chapter id
   */
  const onHandleDeleteChapter = (chapterId) => {
    dispatch(deleteChapter(chapterId, subjectId));
  };

  /**
   * Handle Set Active Chapter
   * @param {string} chapterId Chapter id
   */
  const onHandleSetActiveChapter = (chapterId) => {
    dispatch(setActiveChapter(chapterId));
  };

  return (
    <div className={`video-chapter-base ${className}`}>
      <div className="video-chapter-base__header">
        <div className="video-chapter-base__left">
          <h2
            className={`video-layout__heading student-base-heading is-text-secondary-color ${
              !isEditMode && "is-text-center"
            }`}
          >
            Course Chapter Content
          </h2>
        </div>

        {isEditMode && (
          <div className="video-chapter-base__right">
            <Button
              className="video-chapter-base__btn btn--primary btn--rv1 btn--sv4"
              to={`/player/chapter/1${search}`}
            >
              Add Chapter
            </Button>
          </div>
        )}
      </div>

      <div className="video-chapter-base__main">
        <EmptyContent
          className="video-chapter-base__empty"
          isLoading={isChapterLoading}
          errorMessage={chapterErrorMessage}
        >
          <VideoChapterList
            className="video-chapter-base__list"
            items={chapters}
            isEditMode={isEditMode}
            activeChapter={activeChapter}
            onDeleteChapter={onHandleDeleteChapter}
            setActiveChapter={onHandleSetActiveChapter}
          />
        </EmptyContent>
      </div>
    </div>
  );
};

VideoChapterBase.defaultProps = {
  className: "",
};
