// Hooks
import { useSelector, useDispatch } from "react-redux";

// Redux
import { privateSessionSA } from "../store";

// Destructure Redux Imports
const {
  selectIsPrivateSessionLoading,
  selectPrivateSessionErrorMessage,
  selectPrivateSessions,
  selectPrivateSession,
} = privateSessionSA;

/**
 * PrivateSession Hook
 */
export const usePrivateSession = () => {
  const dispatch = useDispatch();
  const privateSessions = useSelector(selectPrivateSessions);
  const privateSession = useSelector(selectPrivateSession);
  const isPrivateSessionLoading = useSelector(selectIsPrivateSessionLoading);
  const privateSessionErrorMessage = useSelector(
    selectPrivateSessionErrorMessage
  );

  return {
    privateSessions,
    privateSession,
    isPrivateSessionLoading,
    privateSessionErrorMessage,
    dispatch,
    ...privateSessionSA,
  };
};
