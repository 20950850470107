// Modules
import { createSlice } from "@reduxjs/toolkit";

// Common Reducers
import { loadingReducers, errorMessageReducers } from "../common";

// Utils
import { stateSelector } from "../../utils";

// Initial State
const initialState = {
  isLoading: false,
  errorMessage: "",
  bookings: [],
  bookingStat: {
    totalBookings: 0,
    currentMonthCompletedBookingCount: 0,
    currentMonthPendingBookingCount: 0,
  },
};

// Slice Name
const sliceName = "booking";

// Booking Slice
const bookingSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ...loadingReducers,
    ...errorMessageReducers,
    setBookings(state, action) {
      state.bookings = action.payload;
    },
    resetBookings(state) {
      state.bookings = [];
    },
    setBookingStat(state, action) {
      state.bookingStat = action.payload;
    },
    resetBookingStat(state) {
      state.bookingStat = {
        totalBookings: 0,
        currentMonthCompletedBookingCount: 0,
        currentMonthPendingBookingCount: 0,
      };
    },
    updBookingStatus() {},
    delBooking() {},
  },
});

export const {
  startLoading: bookingStartLoading,
  stopLoading: bookingStopLoading,
  resetErrorMessage: resetBookingErrorMessage,
  setErrorMessage: setBookingErrorMessage,
  updBookingStatus,
  delBooking,
  resetBookingStat,
  setBookingStat,
  setBookings,
  resetBookings,
} = bookingSlice.actions;
export * from "./bookingAction";
export const selectBookings = stateSelector(sliceName, "bookings");
export const selectBookingStat = stateSelector(sliceName, "bookingStat");
export const selectIsBookingLoading = stateSelector(sliceName, "isLoading");
export const selectBookingErrorMessage = stateSelector(
  sliceName,
  "errorMessage"
);
export default bookingSlice.reducer;
