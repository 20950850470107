// Firebase
import {
  serverTimestamp,
  addDoc,
  updateDoc,
  getDocs,
  doc,
  query,
  where,
  orderBy,
  Timestamp,
  documentId,
  getCountFromServer,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import {
  firebaseLooper,
  functions,
  levelCollectionRef,
  subjectCollectionRef,
  trainingCollectionRef,
  userCollectionRef,
} from "../../utils";

// Utils
import { AppError } from "../../utils";

// Apis
import * as apis from "../../apis";

/**
 * Populate Level Field In Subjects
 * @param {array} subjects Subjects
 */
const populateLevelInSubjects = async (subjects) => {
  try {
    // Get Subjects With Level Populated
    const subjectsWithLevel = await Promise.all(
      subjects.map(async (subject) => ({
        ...subject,
        level: (await apis.getLevel(subject.levelId.id, false)) || {},
        levelId: subject.levelId.id,
      }))
    );

    // Return Subjects With Level Populated
    return subjectsWithLevel;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Subject With Tutor
 * @param {object} subject Subject
 */
const getSubjectWithTutor = async (subject) => {
  try {
    // Get Subject With Tutor Populated
    const subjectWithTutor = {
      ...subject,
      tutor: (await apis.getStaff(subject.tutorId.id, false, false)) || {},
      tutorId: subject.tutorId.id,
    };

    // Return Subject With Tutor Populated
    return subjectWithTutor;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Subject With Chapters
 * @param {object} subject subject
 */
const getSubjectWithChapters = async (subject) => {
  try {
    // Get Subject With Chapters
    const subjectWithChapters = {
      ...subject,
      chapters: await apis.getSubjectChapters(subject.id, false),
    };

    // Return Subject With Chapters
    return subjectWithChapters;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Level Subjects
 * @param {string} levelId Level id
 * @param {boolean} populateLevelField If true populate the level field In Subject
 * @param {boolean} throwNotFound If true throw not found error
 */
export const getLevelSubjects = async (
  levelId,
  populateLevelField,
  throwNotFound = true
) => {
  try {
    // Level Doc Reference
    const levelRef = doc(levelCollectionRef, levelId);

    // Create Subject Query
    const subjectQuery = query(
      subjectCollectionRef,
      where("levelId", "==", levelRef),
      where("isActive", "==", true),
      orderBy("createdAt", "desc")
    );

    // Get Subjects
    const querySnapshot = await getDocs(subjectQuery);

    // If Subjects Is Empty & ThrowNotFound Is True, Throw Custom Error
    if (querySnapshot.empty && throwNotFound) {
      throw new AppError("empty-data");
    }

    // Subjects From Snapshot
    let subjects = firebaseLooper(querySnapshot);

    // Get Subjects With Chapters
    const subjectsWithChapters = await Promise.all(
      subjects.map(async (subject) => await getSubjectWithChapters(subject))
    );

    // Get Subjects With Tutor
    const subjectsWithTutor = await Promise.all(
      subjectsWithChapters.map(
        async (subject) => await getSubjectWithTutor(subject)
      )
    );

    // Populate Level Field If PopulateLevelField Is True
    subjects = populateLevelField
      ? await populateLevelInSubjects(subjectsWithTutor)
      : subjectsWithTutor;

    // Return Subjects
    return subjects;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Training Subjects
 * @param {string} trainingId Training id
 * @param {boolean} throwNotFound If true throw not found error
 */
export const getTrainingSubjects = async (trainingId, throwNotFound = true) => {
  try {
    // Training Doc Reference
    const trainingRef = doc(trainingCollectionRef, trainingId);

    // Create Subject Query
    const subjectQuery = query(
      subjectCollectionRef,
      where("trainingId", "==", trainingRef),
      where("isActive", "==", true),
      orderBy("createdAt", "desc")
    );

    // Get Subjects
    const querySnapshot = await getDocs(subjectQuery);

    // If Subjects Is Empty & ThrowNotFound Is True, Throw Custom Error
    if (querySnapshot.empty && throwNotFound) {
      throw new AppError("empty-data");
    }

    // Subjects From Snapshot
    const subjects = firebaseLooper(querySnapshot);

    // Get Subjects With Chapters
    const subjectsWithChapters = await Promise.all(
      subjects.map(async (subject) => await getSubjectWithChapters(subject))
    );

    // Get Subjects With Tutor
    const subjectsWithTutor = await Promise.all(
      subjectsWithChapters.map(
        async (subject) => await getSubjectWithTutor(subject)
      )
    );

    // Return Subjects
    return subjectsWithTutor;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Subjects Stat
 * @param {object} subjects Subjects
 */
const getSubjectsStat = (subjects) => {
  // Get Total Subjects Count
  const totalSubjects = subjects.length;

  // Return Stat
  return { totalSubjects };
};

/**
 * Get Total Subject Stat
 */
export const getTotalSubjectStat = async () => {
  try {
    // Count Subject Collection
    const querySnapshot = await getCountFromServer(subjectCollectionRef);
    const totalSubjects = querySnapshot.data().count;

    // Return Total Subjects
    return { totalSubjects };
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Level Subjects And Stat
 * @param {string} levelId Level id
 * @param {boolean} populateLevelField If true populate the level field
 */
export const getLevelSubjectsAndStat = async (levelId, populateLevelField) => {
  try {
    // Get Subjects
    const subjects = await getLevelSubjects(levelId, populateLevelField);

    // Get Subjects Stat
    const stat = getSubjectsStat(subjects);

    // Return Subjects And Stat
    return { subjects, stat };
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Training Subjects And Stat
 * @param {string} trainingId Training id
 */
export const getTrainingSubjectsAndStat = async (trainingId) => {
  try {
    // Get Subjects
    const subjects = await getTrainingSubjects(trainingId);

    // Get Subjects Stat
    const stat = getSubjectsStat(subjects);

    // Return Subjects And Stat
    return { subjects, stat };
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get A Subject
 * @param {string} subjectId Subject id
 * @param {boolean} throwNotFound If true throw not found error
 */
export const getSubject = async (subjectId, throwNotFound = true) => {
  try {
    // Create Subject Query
    const subjectQuery = query(
      subjectCollectionRef,
      where(documentId(), "==", subjectId),
      where("isActive", "==", true)
    );

    // Get Subject
    const querySnapshot = await getDocs(subjectQuery);

    // If Subject Does Not Exist & ThrowNotFound Is True, Throw A Custom Error
    if (querySnapshot.empty && throwNotFound) {
      throw new AppError("empty-data");
    }

    // Get Subject From Snapshot
    const subject = firebaseLooper(querySnapshot)[0];

    // Get Subject With Chapters Included
    const subjectWithChapters = await getSubjectWithChapters(subject);

    // Get Subject With Tutor Included
    const subjectWithTutor = await getSubjectWithTutor(subjectWithChapters);

    // Return Subject With Chapters
    return subjectWithTutor;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Delete A Subject
 * @param {string} subjectId Subject id
 * @param {string} levelId Level id
 */
export const deleteSubject = async (subjectId, levelId) => {
  try {
    // Subject Doc Reference
    const subjectRef = doc(subjectCollectionRef, subjectId);

    // Delete Subject
    await updateDoc(subjectRef, { isActive: false });

    // Get Level Subjects Stat
    const { stat } = await getLevelSubjectsAndStat(levelId, true);

    // Return Level Subjects Stat
    return stat;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Upload Subject Thumbnail
 * @param {string} subjectId Subject id
 * @param {string|object} thumbnail Thumbnail image
 */
const uploadSubjectThumbnail = async (subjectId, thumbnail) => {
  try {
    // Upload Subject Thumbnail
    const onUploadSubjectThumbnail = httpsCallable(
      functions,
      "onUploadSubjectThumbnail"
    );
    const {
      data: { url },
    } = await onUploadSubjectThumbnail({
      thumbnail,
      subjectId,
    });

    // Return Thumbnail Url
    return url;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Create New Subject
 * @param {object} subjectData Subject data
 * @param {string} trainingId Training id
 * @param {string} levelId Level id
 */
export const createSubject = async (subjectData, trainingId, levelId) => {
  try {
    // Get Data
    const { thumbnail, examDate, tutorId } = subjectData;

    // Create Subject
    const newSubject = await addDoc(subjectCollectionRef, {
      ...subjectData,
      levelId: doc(levelCollectionRef, levelId),
      trainingId: doc(trainingCollectionRef, trainingId),
      createdAt: serverTimestamp(),
      tutorId: doc(userCollectionRef, tutorId),
      isActive: true,
      examDate: Timestamp.fromDate(examDate),
    });

    // Upload Subject Thumbnail
    await uploadSubjectThumbnail(newSubject.id, thumbnail);

    // Get Level Subjects And Stat
    const subjectsStat = await getLevelSubjectsAndStat(levelId, true);

    // Return Level Subjects And Stat
    return subjectsStat;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Update A Subject
 * @param {string} subjectId Subject id
 * @param {object} subjectData Subject data
 * @param {string} trainingId Training id
 * @param {string} levelId Level id
 */
export const updateSubject = async (
  subjectId,
  subjectData,
  trainingId,
  levelId
) => {
  try {
    // Subject Doc Reference
    const subjectRef = doc(subjectCollectionRef, subjectId);

    // Get Data
    const { thumbnail, examDate, tutorId } = subjectData;

    // Update Subject
    await updateDoc(subjectRef, {
      ...subjectData,
      levelId: doc(levelCollectionRef, levelId),
      trainingId: doc(trainingCollectionRef, trainingId),
      tutorId: doc(userCollectionRef, tutorId),
      examDate: Timestamp.fromDate(examDate),
    });

    // Upload Subject Thumbnail
    thumbnail && (await uploadSubjectThumbnail(subjectId, thumbnail));

    // Get Level Subjects And Stat
    const subjectsStat = await getLevelSubjectsAndStat(levelId, true);

    // Return Level Subjects And Stat
    return subjectsStat;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};
