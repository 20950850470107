// Modules
import React from "react";

// Hooks
import { useStaff } from "../../../../hooks";

// Components
import { Button, StaffList, EmptyContent } from "../../../";

export const StaffsBase = ({ className }) => {
  const { staffs, deleteStaff, dispatch, isStaffLoading, staffErrorMessage } =
    useStaff();

  /**
   * Handle Staff Deletion
   * @param {string} staffId Staff id
   */
  const onHandleDeleteStaff = (staffId) => {
    dispatch(deleteStaff(staffId));
  };

  // Create Staff Button
  const createStaffBtn = (
    <Button
      className="staffs-base__btn btn--primary btn--sv8 btn--rv5 admin-layout__content-btn"
      to="/admin/staffs/1"
    >
      Add Staff
    </Button>
  );

  return (
    <div className={`staffs-base ${className} is-height-full`}>
      <div className="staffs-base__container is-height-full">
        <EmptyContent
          className="staffs-base__empty"
          isLoading={isStaffLoading}
          errorMessage={staffErrorMessage}
          additionalErrorChildren={createStaffBtn}
        >
          <div className="staffs-base__header admin-layout__content-header">
            {createStaffBtn}
          </div>

          <div className="staffs-base__main admin-layout__content-main">
            <StaffList
              className="staffs-base__staff-list"
              items={staffs}
              onDeleteStaff={onHandleDeleteStaff}
            />
          </div>
        </EmptyContent>
      </div>
    </div>
  );
};

StaffsBase.defaultProps = {
  className: "",
};
