// Modules
import React from "react";

// Hooks
import { useValidateFormOnMouseEnter } from "../../../hooks";

// Components
import {
  Form,
  Button,
  Input,
  BookPrivateSessionDate,
  BookPrivateSessionTimestampList,
} from "../../";

// Style
import "./book-private-session-form.styles.scss";

export const BookPrivateSessionForm = ({
  className,
  formik,
  activeMeetingDate,
  setActiveMeetingDate,
  bookingAvailableDatesAndTime,
}) => {
  const {
    values,
    errors,
    isValid,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    validateForm,
    setTouched,
  } = formik;

  const { triggerFormValidation } = useValidateFormOnMouseEnter(
    validateForm,
    setTouched
  );

  return (
    <Form
      className={`book-private-session-form ${className}`}
      onSubmit={handleSubmit}
    >
      <BookPrivateSessionDate
        className="book-private-session-form__date"
        bookingAvailableDatesAndTime={bookingAvailableDatesAndTime}
        formik={formik}
        setActiveMeetingDate={setActiveMeetingDate}
      />

      <BookPrivateSessionTimestampList
        className="book-private-session-form__timestamp-list"
        formik={formik}
        activeMeetingDate={activeMeetingDate}
      />

      <Input
        className="book-private-session-form__control admin-form__control"
        labelClassName="book-private-session-form__label"
        wrapperClassName="book-private-session-form__group"
        id="meetingTitle"
        name="meetingTitle"
        placeholder="Meeting Title"
        value={values.meetingTitle}
        error={errors.meetingTitle}
        touched={touched.meetingTitle}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Input
        className="book-private-session-form__control admin-form__control"
        labelClassName="book-private-session-form__label"
        wrapperClassName="book-private-session-form__group"
        type="textarea"
        id="meetingReason"
        name="meetingReason"
        placeholder="Reason for Meeting"
        value={values.meetingReason}
        error={errors.meetingReason}
        touched={touched.meetingReason}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Button
        type="submit"
        wrapperClassName="d-block"
        className="book-private-session-form__btn btn--primary btn--rv1 btn--sv12 is-width-full"
        disabled={!isValid}
        onMouseEnter={triggerFormValidation.bind(null, isValid)}
      >
        Proceed to Payment
      </Button>
    </Form>
  );
};

BookPrivateSessionForm.defaultProps = {
  className: "",
};
