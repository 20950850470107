// Modules
import React from "react";

// Hooks
import { useEffect } from "react";
import { useReferrerMedium } from "../../../hooks";

// Components
import { ContactForm, ContactAddress } from "../../";

// Style
import "./contact-base.styles.scss";

export const ContactBase = ({ className }) => {
  const { dispatch, getAllReferrerMediums } = useReferrerMedium();

  useEffect(() => {
    // Get All ReferrerMediums
    dispatch(getAllReferrerMediums());
  }, []);

  return (
    <section className={`contact-base ${className}`}>
      <div className="contact-base__container container">
        <div className="contact-base__left">
          <h2 className="contact-base__heading primary-heading is-text-secondary-color">
            Get in <span className="is-text-primary-color">Touch</span>
          </h2>

          <p className="contact-base__description">
            We are open to conversation feel free to reach out to us
          </p>

          <ContactForm className="contact-base__form" />
        </div>

        <div className="contact-base__right">
          <ContactAddress className="contact-base__address" />
        </div>
      </div>
    </section>
  );
};

ContactBase.defaultProps = {
  className: "",
};
