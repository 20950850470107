// Modules
import React from "react";

// Components
import { PrivateSessionModalBase } from "../../../../components";

// Hooks
import { useEffect } from "react";
import { usePrivateSession } from "../../../../hooks";

export const PrivateSessionModal = () => {
  const { dispatch, getPrivateSessions } = usePrivateSession();

  useEffect(() => {
    // Get Private Sessions
    dispatch(getPrivateSessions());
  }, []);

  return <PrivateSessionModalBase />;
};
