// Modules
import React from "react";

// Components
import { Form, Input, Button } from "../../..";

// Hooks
import { useChapter } from "../../../../hooks";

// Style
import "./add-chapter-form.styles.scss";

export const AddChapterForm = ({ className, formik, isEditMode }) => {
  const { isChapterLoading } = useChapter();
  const {
    values,
    errors,
    isValid,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;

  return (
    <Form className={`add-chapter-form ${className}`} onSubmit={handleSubmit}>
      <Input
        className="add-chapter-form__control"
        labelClassName="add-chapter-form__label"
        wrapperClassName="add-chapter-form__group"
        placeholder="Chapter Name"
        name="name"
        id="name"
        tertiary
        value={values.name}
        error={errors.name}
        touched={touched.name}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <Button
        type="submit"
        wrapperClassName="d-block"
        className="add-chapter-form__btn is-width-full btn--primary btn--rv1 btn--sv12"
        loadingHeight="24"
        disabled={!isValid || isChapterLoading}
        loading={isChapterLoading}
      >
        {isEditMode ? "Update" : "Publish"}
      </Button>
    </Form>
  );
};

AddChapterForm.defaultProps = {
  className: "",
};
