// Modules
import { createSlice } from "@reduxjs/toolkit";

// Common Reducers
import { loadingReducers } from "../common";

// Utils
import { stateSelector } from "../../utils";

// Initial State
const initialState = {
  isLoggedIn: false,
  user: null,
  oAuthLoadingName: "",
  isLoading: false,
  checkedAuth: false,
};

// Slice Name
const sliceName = "auth";

// Auth Slice
const authSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ...loadingReducers,
    setIsLoggedIn(state, action) {
      state.isLoggedIn = action.payload;
    },
    resetIsLoggedIn(state) {
      state.isLoggedIn = false;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    resetUser(state) {
      state.user = null;
    },
    setOAuthLoadingName(state, action) {
      state.oAuthLoadingName = action.payload;
    },
    resetOAuthLoadingName(state) {
      state.oAuthLoadingName = "";
    },
    setCheckedAuth(state, action) {
      state.checkedAuth = action.payload;
    },
    resetCheckedAuth(state) {
      state.checkedAuth = false;
    },
  },
});

export const {
  startLoading: authStartLoading,
  stopLoading: authStopLoading,
  setIsLoggedIn,
  setUser,
  setOAuthLoadingName,
  resetOAuthLoadingName,
  resetIsLoggedIn,
  resetUser,
  setCheckedAuth,
  resetCheckedAuth,
} = authSlice.actions;
export * from "./authAction";
export const selectOAuthLoadingName = stateSelector(
  sliceName,
  "oAuthLoadingName"
);
export const selectUser = stateSelector(sliceName, "user");
export const selectIsLoggedIn = stateSelector(sliceName, "isLoggedIn");
export const selectCheckedAuth = stateSelector(sliceName, "checkedAuth");
export const selectIsAuthLoading = stateSelector(sliceName, "isLoading");
export default authSlice.reducer;
