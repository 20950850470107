// Modules
import React from "react";
import { getFile } from "easy-file-picker";

// Hooks
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  useValidateFormOnMouseEnter,
  useAuth,
  useDelete,
} from "../../../hooks";

// Components
import { Fragment } from "react";
import { HiOutlinePencil } from "react-icons/hi";
import {
  ProfileEmploymentForm,
  ProfileForm,
  Button,
  Dialog as ConfirmEmploymentDeleteDialog,
  ReAuthenticateDialog,
} from "../..";

// Utils
import {
  getProfileImage,
  getFieldFromObject,
  getImageAsBase64,
} from "../../../utils";

// Schemas
import { profileUpdateSchema } from "../../../schemas";

// Style
import "./student-profile-base.styles.scss";

// Static Data
let isFirstTime = false;

export const StudentProfileBase = ({ className }) => {
  const {
    user,
    isAuthLoading,
    updateProfileImage,
    updateProfile,
    signInWithOAuth,
    dispatch,
  } = useAuth();
  const [avatar, setAvatar] = useState();
  const [isReauthenticateDialogOpen, setIsReauthenticateDialogOpen] =
    useState(false);
  const {
    deleteCloseActionText,
    deleteConfirmActionText,
    isDeleteDialogOpen,
    onHandleCloseDeleteDialog,
    onHandleDeleteConfirm,
    onHandleOpenDeleteDialog,
  } = useDelete(onDeleteUserEmployment, onHandleEmploymentDeleteDialogClose);

  const profileFormik = useFormik({
    validationSchema: profileUpdateSchema,
    initialValues: {
      firstName: {
        value: user?.firstName || "",
        editable: false,
      },
      lastName: {
        value: user?.lastName || "",
        editable: false,
      },
      email: {
        value: user?.email || "",
        editable: false,
      },
      phoneNumber: {
        value: user?.phoneNumber || "",
        editable: false,
      },
      country: {
        value: user?.country || "",
        editable: false,
      },
      program: user?.program || "",
      isEmployed: user?.isEmployed || false,
      empName: {
        value: user?.employment?.empName || "",
        editable: false,
      },
      empEmail: {
        value: user?.employment?.empEmail || "",
        editable: false,
      },
      empRole: {
        value: user?.employment?.empRole || "",
        editable: false,
      },
      empCompany: {
        value: user?.employment?.empCompany || "",
        editable: false,
      },
      officeAddress: {
        value: user?.employment?.officeAddress || "",
        editable: false,
      },
      roleSummary: {
        value: user?.employment?.roleSummary || "",
        editable: false,
      },
      sponsorshipType: user?.employment?.sponsorshipType || "",
      isSponsored: user?.employment?.isSponsored || false,
    },
    onSubmit(values) {
      // Check Whether Email Changed
      const emailChanged = values.email.value !== user.email;

      // If Email Changed, Re-Authenticate With User Sign-In Medium
      if (emailChanged) {
        return handleReAuthentication();
      }

      // If Email Changed Is False Then Update Profile
      onHandleUpdateProfile(values, emailChanged);
    },
  });

  const {
    validateForm,
    setTouched,
    values,
    handleSubmit,
    isValid,
    setFieldValue,
    setValues,
    setErrors,
    errors,
    touched,
  } = profileFormik;

  const { triggerFormValidation } = useValidateFormOnMouseEnter(
    validateForm,
    setTouched
  );

  useEffect(() => {
    // If User Employment Detail Exist And  User Turned Off IsEmployed On User Profile And It Not First Dialog Rendering, Then Show Employment Delete Confirmation Dialog
    if (user?.isEmployed && !values.isEmployed && !isFirstTime) {
      onHandleOpenDeleteDialog();
      isFirstTime = true;
    }
  }, [values.isEmployed]);

  useEffect(() => {
    // Update User Avatar
    setAvatar(getProfileImage(user?.avatar?.url, 150, 150));
  }, [user]);

  useEffect(() => {
    /**
     * Reset Fields Editable
     * @param {Event} event Event
     */
    const resetFieldsEditable = (event) => {
      const isFormGroupElem = event.target.closest(".form__group");

      // If IsFormGroupElem Is True Then Return Function, Else Reset Fields Editable
      if (isFormGroupElem) return;

      const resetValue = {
        ...values,
        country: { ...values.country, editable: false },
        phoneNumber: { ...values.phoneNumber, editable: false },
        email: { ...values.email, editable: false },
        lastName: { ...values.lastName, editable: false },
        firstName: { ...values.firstName, editable: false },
        empEmail: { ...values.empEmail, editable: false },
        empCompany: { ...values.empCompany, editable: false },
        empRole: { ...values.empRole, editable: false },
        empName: { ...values.empName, editable: false },
        officeAddress: { ...values.officeAddress, editable: false },
        roleSummary: { ...values.roleSummary, editable: false },
      };

      setValues(resetValue);
    };

    // On Document Body Clicked Reset Fields Editable
    document.body.addEventListener("click", resetFieldsEditable);

    // Remove Event From Body When Component Un-Mount
    return () => {
      document.body.removeEventListener("click", resetFieldsEditable);
    };
  }, [values]);

  /**
   * Get Formik Value
   * @param {object} values Formik values
   */
  function getValue(values) {
    // Convert Values To Array
    const valuesEntries = Object.entries(values);

    // Tranform ValueEntries And Convert Back To Object
    return Object.fromEntries(
      valuesEntries.map(([key, entry]) => {
        return [key, typeof entry === "object" ? entry.value : entry];
      })
    );
  }

  /**
   * Handle Input Change
   * @param {object} e Event object
   */
  const onHandleChange = (e) => {
    // Get Target Name And Value
    const { name, value } = e.target;

    // Update Formik Value
    setFieldValue(name, {
      ...values[name],
      value: value,
    });
  };

  /**
   * Toggle Input Editable Value
   * @param {string} targetName Event target name
   */
  const onToggleInputEditable = function (targetName) {
    // Negate Target Editable
    const newEditable = !values[targetName].editable;

    // Update Editable Value
    setFieldValue(targetName, {
      ...values[targetName],
      editable: newEditable,
    });
  };

  /**
   * Handle Profile Update
   * @param {object} profileValues Formik values
   * @param {boolean} emailChanged
   */
  const onHandleUpdateProfile = async (
    profileValues = values,
    emailChanged = true
  ) => {
    // Close Re-Authenticate Dialog
    setIsReauthenticateDialogOpen(false);

    // Get Value
    const values = getValue(profileValues);

    // Get Profile Value
    const profileValue = getFieldFromObject(
      [
        "isEmployed",
        "country",
        "phoneNumber",
        "email",
        "lastName",
        "firstName",
        "program",
      ],
      values
    );

    // Get Employment Value
    const employmentValue = {
      employment: getFieldFromObject(
        [
          "empName",
          "empEmail",
          "empRole",
          "empCompany",
          "roleSummary",
          "officeAddress",
          "sponsorshipType",
          "isSponsored",
        ],
        values
      ),
    };

    // Update Value
    const updateValue = { ...profileValue, ...employmentValue };

    // Update User Profile
    await dispatch(updateProfile(user.uid, updateValue, emailChanged));
  };

  /**
   * Upload User Profile Photo
   */
  const handleUploadUserPhoto = async () => {
    // Open User Exlorer To Get Avatar
    const file = await getFile({
      acceptedExtensions: [".png", ".jpeg", ".jpg", "svg"],
    });

    // Set File In State
    setAvatar(getProfileImage(file, 150, 150));

    // Read File To Base64
    const base64File = await getImageAsBase64(file);

    // Upload/Update User Profile Image
    base64File && dispatch(updateProfileImage(base64File));
  };

  /**
   * Handle Employment Delete Dialog Close
   */
  function onHandleEmploymentDeleteDialogClose() {
    // Reset IsEmployed Property
    setFieldValue("isEmployed", true);

    // Reset IsFirstTime
    isFirstTime = false;
  }

  /**
   * Reset Employment Form State
   */
  const resetEmploymentFormState = () => {
    // Reset Errors
    setErrors({
      ...errors,
      empEmail: { value: "" },
      empCompany: { value: "" },
      empRole: { value: "" },
      empName: { value: "" },
      officeAddress: { value: "" },
      roleSummary: { value: "" },
      sponsorshipType: "",
    });

    // Reset Touched
    setTouched(
      {
        ...touched,
        empEmail: false,
        empCompany: false,
        empRole: false,
        empName: false,
        officeAddress: false,
        roleSummary: false,
        sponsorshipType: false,
      },
      false
    );
  };

  /**
   * Reset Employment Form State
   */
  const resetEmploymentFormValue = () => {
    // Reset Value
    const resetValue = {
      ...values,
      isEmployed: false,
      empEmail: { value: "", editable: false },
      empCompany: { value: "", editable: false },
      empRole: { value: "", editable: false },
      empName: { value: "", editable: false },
      isSponsored: false,
      officeAddress: { value: "", editable: false },
      roleSummary: { value: "", editable: false },
      sponsorshipType: "",
    };

    // Reset Employment Form Value
    setValues(resetValue);
  };

  /**
   * Delete User Employment Data
   */
  function onDeleteUserEmployment() {
    // Reset Employment Form Value
    resetEmploymentFormValue();

    // Reset Employment Form Error
    resetEmploymentFormState();

    // Update Profile
    handleSubmit();
  }

  /**
   * Close Re-Authenticate Dialog
   */
  const onHandleCloseReAuthenticateDialog = () => {
    // Close Dialog
    setIsReauthenticateDialogOpen(false);

    // Reset Email Back To Initial State
    setFieldValue("email", { value: user.email, editable: false });
  };

  /**
   * Handle Re-Authenticating User
   */
  const handleReAuthentication = async () => {
    switch (user.signupMedium) {
      // If User Signup Medium Is Google Or Facebook Then Re-Authenticate With OAuth
      case "google":
      case "facebook":
        await dispatch(
          signInWithOAuth(
            user.signupMedium,
            onHandleUpdateProfile,
            onHandleCloseReAuthenticateDialog
          )
        );
        break;
      // Default ReAuthenticate With Email And Password Login
      default:
        setIsReauthenticateDialogOpen(true);
    }
  };

  return (
    <Fragment>
      <ConfirmEmploymentDeleteDialog
        open={isDeleteDialogOpen}
        dialogTitle="Confirm Delete Employment Details"
        dialogDescription="You are about to delete your employment data. Confirm with yes to continue"
        closeActionText={deleteCloseActionText}
        confirmActionText={deleteConfirmActionText}
        onClose={onHandleCloseDeleteDialog}
        onConfirm={onHandleDeleteConfirm}
      />

      <ReAuthenticateDialog
        open={isReauthenticateDialogOpen}
        onClose={onHandleCloseReAuthenticateDialog}
        onReAuthenticateSuccess={onHandleUpdateProfile}
      />

      <div className={`profile-base ${className}`}>
        <div className="profile-base__container">
          <div className="profile-base__group">
            <div className="profile-base__group-header">
              <h2 className="profile-base__heading student-base-heading is-text-secondary-color is-underline">
                Basic Profile
              </h2>

              <div className="profile-base__profile-wrapper">
                <div
                  className="profile-base__profile-img-wrapper crop-sm"
                  onClick={handleUploadUserPhoto}
                >
                  <img
                    className="profile-base__profile-img crop-sm"
                    src={avatar}
                    alt={`${user?.firstName} Profile`}
                  />
                  <HiOutlinePencil
                    className="profile-base__profile-img-icon"
                    aria-label="change profile image"
                  />
                </div>

                <p className="profile-base__profile-studentId">
                  Student ID: {user?.studentId}
                </p>
              </div>

              <div className="profile-base__actions">
                <Button
                  className="profile-base__action-btn profile-base__action-btn--subscription btn--rv1 btn--sv4 is-text-light-color bg-dark-color"
                  to="/student/profile/subscription-histories"
                >
                  Subscription History
                </Button>

                <Button
                  className="profile-base__action-btn btn--primary btn--rv1 btn--sv4"
                  disabled={!isValid || isAuthLoading}
                  loading={isAuthLoading}
                  onClick={handleSubmit}
                  onMouseEnter={triggerFormValidation.bind(null, isValid)}
                >
                  Update
                </Button>
              </div>
            </div>

            <ProfileForm
              className="profile-base__form--profile"
              onHandleChange={onHandleChange}
              onToggleInputEditable={onToggleInputEditable}
              formik={profileFormik}
            />
          </div>

          <div
            className={`profile-base__group profile-base__group--employment ${
              values.isEmployed
                ? "profile-base__group--employment-active"
                : "d-none"
            } `}
          >
            <div className="profile-base__group-header">
              <h2 className="profile-base__heading student-base-heading is-text-secondary-color is-underline">
                Employment Info
              </h2>
            </div>

            <ProfileEmploymentForm
              className="profile-base__form--employment"
              onHandleChange={onHandleChange}
              onToggleInputEditable={onToggleInputEditable}
              formik={profileFormik}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

StudentProfileBase.defaultProps = {
  className: "",
};
