// Modules
import React from "react";

// Components
import { BookingDateList, Button } from "../../../../";

// Style
import "./booking-date.styles.scss";

export const BookingDate = ({
  className,
  labelClassName,
  label,
  name,
  availabilityDates,
  error,
  touched,
  onAvailabilityDatesChange,
}) => {
  /**
   * Handle Add Date
   */
  const onHandleAddDate = () => {
    // Update State
    onAvailabilityDatesChange(name, [
      ...availabilityDates,
      {
        date: Date.now(),
        times: [],
      },
    ]);
  };

  return (
    <div className={`booking-date ${className}`}>
      <div className="booking-date__container">
        <label className={`booking-date__label ${labelClassName}`}>
          {label}
        </label>

        <BookingDateList
          className="booking-date__list"
          name={name}
          availabilityDates={availabilityDates}
          onAvailabilityDatesChange={onAvailabilityDatesChange}
        />

        {touched && error && (
          <div className="form__error-wrapper booking-date__error-wrapper">
            <p className="form__error booking-date__error">{error}</p>
          </div>
        )}

        <Button
          className="booking-date__btn btn--primary btn--sv8 btn--rv5"
          onClick={onHandleAddDate}
        >
          Add Date
        </Button>
      </div>
    </div>
  );
};

BookingDate.defaultProps = {
  className: "",
  labelClassName: "",
  availabilityDates: [],
};
