// Modules
import React from "react";

// Components
import { TransactionStatsBase, TransactionBase } from "../../../components";

// Hooks
import { useEffect } from "react";
import { useTransaction, useUrlAsTitle } from "../../../hooks";

export const Transaction = () => {
  // Set Dashboard Page Title
  useUrlAsTitle();
  const { dispatch, getTransactionsAndStat } = useTransaction();

  useEffect(() => {
    // Get All Transactions And Stat, Also Populate Student Field
    dispatch(getTransactionsAndStat(true));
  }, []);

  return (
    <div className="transaction is-height-full">
      <div className="transaction__container is-height-full">
        <TransactionStatsBase />
        <TransactionBase />
      </div>
    </div>
  );
};
