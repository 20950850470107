// Modules
import React from "react";

// Components
import { Card as ProfessionalTrainingCourseCard } from "../../../";

// Images
import cardImage from "../../../../assets/images/service-recruitment.svg";

// Style
import "./professional-training-course-list.styles.scss";

export const ProfessionalTrainingCourseList = ({ className, items }) => {
  // Professional Training Course Items
  const renderedProfessionalTrainingCourseItems = items.map((item) => (
    <ProfessionalTrainingCourseCard
      key={item.id}
      className="professional-training-course-list__item"
      title={item.title}
      summary={item.summary}
      to={`/professional-training/${item.id}`}
      image={cardImage}
      imgWidth="100px"
      imgHeight="100px"
      actionText="Get Started"
    />
  ));

  return (
    <div className={`professional-training-course-list ${className}`}>
      {renderedProfessionalTrainingCourseItems}
    </div>
  );
};

ProfessionalTrainingCourseList.defaultProps = {
  className: "",
  items: [],
};
