// Modules
import React from "react";

// Components
import { StatsList, StatsCard } from "../../../";

// Utils
import { formatToCompactNumber } from "../../../../utils";

// Hooks
import { useTransaction } from "../../../../hooks";

export const TransactionStatsBase = ({ className }) => {
  const {
    transactionStat: {
      currentMonthTotalTransactionCount,
      currentMonthTotalSuccessTransactionCount,
      totalTransactions,
    },
  } = useTransaction();

  return (
    <div className={`transaction-stats-base ${className}`}>
      <div className="transaction-stats-base__container">
        <StatsList className="transaction-stats-base__list">
          <StatsCard
            className="transaction-stats-base__card"
            title={`${formatToCompactNumber(
              currentMonthTotalTransactionCount
            )} Orders`}
            subtitle="This Month"
          />

          <StatsCard
            className="transaction-stats-base__card"
            title={`${formatToCompactNumber(
              currentMonthTotalSuccessTransactionCount
            )} Success`}
            subtitle="This Month"
          />

          <StatsCard
            className="transaction-stats-base__card"
            title={`${formatToCompactNumber(totalTransactions)} Orders`}
            subtitle="In Total"
            isSecondary
          />
        </StatsList>
      </div>
    </div>
  );
};

TransactionStatsBase.defaultProps = {
  className: "",
};
