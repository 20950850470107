// Hooks
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useUi } from "./";

// Utils
import { capitalize } from "../utils";

/**
 * Use Last Part Of Route Path As Title
 * @param {string} customTitle Custom title (If set replaces url as title)
 */
export const useUrlAsTitle = (customTitle) => {
  const { pathname } = useLocation();
  const { pageTitle, setPageTitle, dispatch } = useUi();

  useEffect(() => {
    let urlTitle = customTitle;

    // If Custom Title Is Not Set Then Use Url As Title
    if (!customTitle) {
      urlTitle = capitalize(pathname.split("/").pop());
    }

    // Update Page Title In store
    dispatch(setPageTitle(urlTitle));
  }, [customTitle]);

  // Return PageTitle
  return { pageTitle };
};
