// Modules
import React from "react";

// Hooks
import { useState, useEffect } from "react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { useLevel } from "../../../hooks";

// Components
import {
  Levels,
  PricingTagList,
  PricingCard,
  Button,
  EmptyContent,
} from "../../";

// Utils
import { capitalize, getTrainingAcronym } from "../../../utils";

// Data
import { PRICING_TAG_ITEMS } from "../../../data";

// Style
import "./pricing-base.styles.scss";

export const PricingBase = ({ className }) => {
  const {
    getTrainingLevels,
    levels,
    isLevelLoading,
    levelErrorMessage,
    dispatch,
  } = useLevel();
  const { trainingId } = useParams();
  const [activeLevel, setActiveLevel] = useState();
  const [activeLevelTab, setActiveLevelTab] = useState();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const studyMethod = searchParams.get("studyMethod") || "online";
  const training = activeLevel?.training;

  // Static Data
  const pricePerMonth = activeLevel?.pricePerMonth?.[studyMethod];
  const pricePerQuarter = activeLevel?.pricePerQuarter?.[studyMethod];
  const pricePerYear = activeLevel?.pricePerYear?.[studyMethod];

  useEffect(() => {
    // Get Training Levels And Also Populate Training Field
    dispatch(getTrainingLevels(trainingId, true));
  }, [trainingId]);

  useEffect(() => {
    // Update Active Level Tab, If Level Is Not Loading And Levels Exist
    if (!isLevelLoading && levels.length) {
      setActiveLevelTab(levels[0]?.id);
    }
  }, [levels]);

  useEffect(() => {
    // Update Active Level, Anytime Active Level Tab Changes
    const foundLevel = levels.find((level) => level.id === activeLevelTab);
    setActiveLevel(foundLevel);
  }, [activeLevelTab]);

  /**
   * Get Subscription Price
   * @param {string} subscriptionType Subscription type
   */
  const getSubscriptionPrice = (subscriptionType) => {
    switch (subscriptionType) {
      case "monthly":
        return activeLevel.pricePerMonth[studyMethod];
      case "quarterly":
        return activeLevel.pricePerQuarter[studyMethod];
      case "yearly":
        return activeLevel.pricePerYear[studyMethod];
    }
  };

  /**
   * On Enroll Student
   * @param {string} subscriptionType Subscription type
   */
  const onEnrollStudent = (subscriptionType) => {
    // Perform Checkout
    navigate(`/pricing/${trainingId}/checkout?studyMethod=${studyMethod}`, {
      state: {
        cart: {
          items: [
            {
              studyMethod,
              trainingId,
              levelId: activeLevelTab,
            },
          ],
          paymentType: subscriptionType,
          totalPrice: getSubscriptionPrice(subscriptionType),
        },
      },
    });
  };

  return (
    <div className={`pricing-base ${className}`}>
      <EmptyContent
        className="pricing-base__empty"
        isLoading={isLevelLoading}
        errorMessage={levelErrorMessage}
      >
        <div className="pricing-base__container container">
          <div className="pricing-base__header">
            <h1 className="pricing-base__heading">
              {getTrainingAcronym(training?.title || "")} - Plans and pricing
            </h1>
            <p className="pricing-base__sub-heading">
              Choose the best plan for your business.
            </p>

            <Levels
              className="pricing-base__levels"
              activeLevel={activeLevelTab}
              setActiveLevel={setActiveLevelTab}
              items={levels}
            />
          </div>

          <div className="pricing-base__list container">
            <h2 className="pricing-base__list-heading">
              {activeLevel?.name} Pricing{" "}
              <span className="pricing-base__study-method">
                ({capitalize(studyMethod)} Class)
              </span>
            </h2>

            <div className="pricing-base__list-group">
              <div className="pricing-base__list-left">
                <PricingTagList
                  className="pricing-base__list-tag"
                  items={PRICING_TAG_ITEMS}
                />
              </div>

              <div className="pricing-base__list-right">
                <PricingCard
                  className="pricing-base__pricing-item"
                  content="Pay Per Subject"
                  price="N/A"
                >
                  <Button
                    className="pricing-card__btn btn--primary btn--rv3 btn--sv5"
                    to={`/pricing/${trainingId}/subjects?studyMethod=${studyMethod}`}
                  >
                    Choose Course
                  </Button>
                </PricingCard>

                <PricingCard
                  className="pricing-base__pricing-item"
                  content="Pay per Level Per Month"
                  price={pricePerMonth ? `$${pricePerMonth}` : "Free"}
                  hidden={pricePerMonth === -1}
                >
                  <Button
                    className="pricing-card__btn btn--primary btn--rv3 btn--sv5"
                    onClick={onEnrollStudent.bind(null, "monthly")}
                  >
                    Get Started
                  </Button>
                </PricingCard>

                <PricingCard
                  className="pricing-base__pricing-item"
                  content="Pay per Level Per Quarter"
                  price={pricePerQuarter ? `$${pricePerQuarter}` : "Free"}
                  hidden={pricePerQuarter === -1}
                >
                  <Button
                    className="pricing-card__btn btn--primary btn--rv3 btn--sv5"
                    onClick={onEnrollStudent.bind(null, "quarterly")}
                  >
                    Get Started
                  </Button>
                </PricingCard>

                <PricingCard
                  className="pricing-base__pricing-item"
                  content="Pay per Level Per Year"
                  price={pricePerYear ? `$${pricePerYear}` : "Free"}
                  hidden={pricePerYear === -1}
                >
                  <Button
                    className="pricing-card__btn btn--primary btn--rv3 btn--sv5"
                    onClick={onEnrollStudent.bind(null, "yearly")}
                  >
                    Get Started
                  </Button>
                </PricingCard>

                <PricingCard
                  className="pricing-base__pricing-item"
                  content="Book For Support"
                  price="N/A"
                />
              </div>
            </div>
          </div>
        </div>
      </EmptyContent>
    </div>
  );
};

PricingBase.defaultProps = {
  className: "",
};
