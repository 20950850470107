// Modules
import React from "react";

// Components
import { Button, TrainingList, EmptyContent } from "../../../";

// Hooks
import { useTraining } from "../../../../hooks";

export const TrainingBase = ({ className }) => {
  const {
    trainings,
    dispatch,
    deleteTraining,
    trainingErrorMessage,
    isTrainingLoading,
  } = useTraining();

  /**
   * Delete A Training
   * @param {string} trainingId Training Id
   */
  const onDeleteTraining = (trainingId) => {
    dispatch(deleteTraining(trainingId));
  };

  // Create Training Button
  const createTrainingBtn = (
    <Button
      className="training-base__btn btn--primary btn--sv8 btn--rv5 admin-layout__content-btn"
      to="/admin/trainings/1"
    >
      Add Training
    </Button>
  );

  return (
    <div className={`training-base ${className} is-height-full`}>
      <div className="training-base__container is-height-full">
        <EmptyContent
          className="training-base__empty"
          isLoading={isTrainingLoading}
          errorMessage={trainingErrorMessage}
          additionalErrorChildren={createTrainingBtn}
        >
          <div className="training-base__header admin-layout__content-header">
            {createTrainingBtn}
          </div>

          <div className="training-base__main admin-layout__content-main">
            <TrainingList
              className="training-base__list"
              items={trainings}
              onDeleteTraining={onDeleteTraining}
              enableAction
            />
          </div>
        </EmptyContent>
      </div>
    </div>
  );
};

TrainingBase.defaultProps = {
  className: "",
};
