// Hooks
import { useSelector, useDispatch } from "react-redux";

// Redux
import { staffSA } from "../store";

// Destructure Redux Imports
const {
  selectStaffErrorMessage,
  selectIsStaffLoading,
  selectStaffs,
  selectStaffStat,
} = staffSA;

// Staff Hook
export const useStaff = () => {
  const dispatch = useDispatch();
  const isStaffLoading = useSelector(selectIsStaffLoading);
  const staffErrorMessage = useSelector(selectStaffErrorMessage);
  const staffs = useSelector(selectStaffs);
  const staffStat = useSelector(selectStaffStat);

  return {
    isStaffLoading,
    staffErrorMessage,
    staffs,
    staffStat,
    dispatch,
    ...staffSA,
  };
};
