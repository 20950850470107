// Static Data
const defaultImage = `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_NAME}/image/upload/v1680957596/finacco/users/default_pu23db_qspzk6.jpg`;

/**
 * Add Additional Payload To Image Url
 * @param {string} url Image url
 * @param {string|number} width Image width
 * @param {string|number} height Image height
 */
const addPayloadToProfileImageUrl = (url, width, height) => {
  // Convert Url To Arr
  const urlArr = url.split("/");

  // Add Additional Payload To Url
  urlArr[
    urlArr.indexOf("upload") + 1
  ] = `w_${width},h_${height},c_thumb,g_face,r_max`;

  // Return Url With Additional Payload
  return urlArr.join("/");
};

/**
 * Get Profile Image
 * @param {string|null|object} profileImage Profile image file
 * @param {string|number} width Image width
 * @param {string|number} height Image height
 */
export const getProfileImage = (profileImage = defaultImage, width, height) => {
  // If Their's Profile Image And TypeOf Profile Image Is String Then Return The Profile Image
  if (profileImage && typeof profileImage === "string")
    return addPayloadToProfileImageUrl(profileImage, width, height);

  // If Their's Profile Image And TypeOf Profile Image Is An Instance Of File Object. Then Create Url From The File Object And Return The Url
  if (profileImage && profileImage instanceof File)
    return URL.createObjectURL(profileImage);
};
