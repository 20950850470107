// Modules
import React from "react";

// Components
import { StaffCard } from "../../../";

// Style
import "./staff-list.styles.scss";

export const StaffList = ({ className, items, onDeleteStaff }) => {
  // Staff Items
  const renderedStaffItems = items.map((item) => (
    <StaffCard
      className="staff-list__item"
      key={item.id}
      id={item.id}
      profileImage={item.avatar?.url}
      fullName={`${item.firstName} ${item.lastName}`}
      organizationRole={item.employment?.empRole}
      profileSummary={item.employment?.roleSummary}
      onDeleteStaff={onDeleteStaff}
      enableAction
    />
  ));

  return <div className={`staff-list ${className}`}>{renderedStaffItems}</div>;
};

StaffList.defaultProps = {
  className: "",
  items: [],
};
