// Modules
import React from "react";

// Components
import { Fragment } from "react";
import {
  AccountingSolutionHero,
  AccountingSolutionBase,
} from "../../components";

export const AccountingSolution = () => {
  return (
    <Fragment>
      <AccountingSolutionHero />
      <AccountingSolutionBase />
    </Fragment>
  );
};
