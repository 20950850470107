// Modules
import React from "react";

// Hooks
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useStaff } from "../../../../hooks";

// Components
import { ModalOverlay, Modal, AddStaffForm, EmptyContent } from "../../../";

// Utils
import {
  getProfileImage,
  getImageAsBase64,
  getFieldFromObject,
} from "../../../../utils";

// Schemas
import { addStaffSchema } from "../../../../schemas";

// Style
import "./add-staff-modal-base.styles.scss";

export const AddStaffModalBase = ({ className }) => {
  const [errorMessage, setErrorMessage] = useState();
  const [staff, setStaff] = useState(null);
  const { staffId } = useParams();
  const { dispatch, updateStaff, createStaff, staffs, isStaffLoading } =
    useStaff();
  const isEditMode = staffId !== "1";

  // Add Staff Formik
  const addStaffFormik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      empRole: "",
      email: "",
      roleSummary: "",
      profileImage: undefined,
      sendAdminInvitation: false,
      isFeatured: false,
    },
    validationSchema: addStaffSchema,
    async onSubmit(values) {
      // Get Profile Image As Base64
      const profileImage = await getImageAsBase64(values.profileImage);

      // Create Staff Data
      const staffData = {
        ...(profileImage && { profileImage }),
        ...getFieldFromObject(
          ["firstName", "lastName", "email", "sendAdminInvitation"],
          values
        ),
        employment: {
          ...getFieldFromObject(["empRole", "roleSummary"], values),
        },
      };

      // Create New Staff If isEditMode Is False, Else Update Staff
      if (!isEditMode) {
        await dispatch(createStaff(staffData));
      } else {
        await dispatch(updateStaff(staffId, staffData));
      }
    },
  });
  let { profileImage, firstName } = addStaffFormik.values;

  useEffect(() => {
    // Create Error Message
    const errorMessage = "No Staff Available With The Provided Id.";

    // Get Staff If Staff Is Not Loading And Edit Mode Is True
    if (!isStaffLoading && isEditMode) {
      // Get Staff By Staff Id
      const staff = staffs.find((staff) => staff.id === staffId);

      // Set Error Message If Staff Is Undefined, Else Update Staff And Reset Error Message
      if (!staff) {
        setErrorMessage(errorMessage);
      } else {
        setErrorMessage("");
        setStaff(staff);
      }
    }
  }, [staffs, staffId]);

  useEffect(() => {
    // If Staff Exist And Edit Mode Is True, Update Formik State
    if (staff && isEditMode) {
      addStaffFormik.setValues({
        firstName: staff.firstName,
        lastName: staff.lastName,
        email: staff.email,
        empRole: staff.employment.empRole,
        roleSummary: staff.employment.roleSummary,
        profileImage: staff.avatar?.url,
        isFeatured: staff.isFeatured || false,
      });
    }
  }, [staff]);

  return (
    <ModalOverlay
      className={`add-staff-modal-base-overlay ${className}`}
      to="/admin/staffs"
    >
      <Modal className="add-staff-modal-base">
        <EmptyContent
          className="add-staff-modal-base__empty"
          isLoading={!addStaffFormik.isSubmitting && isStaffLoading}
          errorMessage={errorMessage}
        >
          <img
            className="add-staff-modal-base__photo crop"
            src={getProfileImage(profileImage, 100, 100)}
            alt={firstName}
          />

          <AddStaffForm
            className="add-staff-modal-base__form"
            isEditMode={isEditMode}
            formik={addStaffFormik}
          />
        </EmptyContent>
      </Modal>
    </ModalOverlay>
  );
};

AddStaffModalBase.defaultProps = {
  className: "",
};
