// Modules
import React from "react";

// Components
import { Card as TrainingCard } from "../../../../";

// Style
import "./training-list.styles.scss";

export const TrainingList = ({
  className,
  items,
  onDeleteTraining,
  enableAction,
  useAltPath,
  studentId,
}) => {
  // Training Items
  const renderedTrainingItems = items.map((item) => (
    <TrainingCard
      className="training-list__item"
      key={item.id}
      id={item.id}
      title={item.title}
      summary={item.summary}
      to={
        !useAltPath
          ? `/admin/trainings/${item.id}/levels`
          : `/admin/students/${studentId}/${item.id}/subjects`
      }
      actionText="View"
      onDelete={onDeleteTraining}
      enableAction={enableAction}
    />
  ));

  return (
    <div className={`training-list ${className}`}>{renderedTrainingItems}</div>
  );
};

TrainingList.defaultProps = {
  className: "",
  enableAction: false,
  useAltPath: false,
};
