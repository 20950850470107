// Redux
import {
  setSubjectErrorMessage,
  setSubjects,
  resetSubjectErrorMessage,
  subjectStartLoading,
  subjectStopLoading,
  setSubjectStat,
  delSubject,
} from ".";
import { uiSA } from "../";

// Utils
import { getSuccess, History } from "../../utils";

// Apis
import * as apis from "../../apis";

// Destructure Redux Imports
const { showNotification } = uiSA;

/**
 * Get Level Subjects And Stat
 * @param {string} levelId Level id
 * @param {boolean} populateLevelField If true populate the level field
 */
export const getLevelSubjectsAndStat =
  (levelId, populateLevelField) => async (dispatch) => {
    try {
      // Reset Error Message
      dispatch(resetSubjectErrorMessage());

      // Start Loading
      dispatch(subjectStartLoading());

      // Get Level Subjects And Stat
      const { subjects, stat } = await apis.getLevelSubjectsAndStat(
        levelId,
        populateLevelField
      );

      // Update Subjects
      dispatch(setSubjects(subjects));

      // Update Stat
      dispatch(setSubjectStat(stat));
    } catch (err) {
      // Update Error Message
      dispatch(setSubjectErrorMessage(err.message.message));
    } finally {
      // Stop Loading
      dispatch(subjectStopLoading());
    }
  };

/**
 * Get Training Subjects And Stat
 * @param {string} trainingId Training id
 */
export const getTrainingSubjectsAndStat = (trainingId) => async (dispatch) => {
  try {
    // Reset Error Message
    dispatch(resetSubjectErrorMessage());

    // Start Loading
    dispatch(subjectStartLoading());

    // Get Training Subjects And Stat
    const { subjects, stat } = await apis.getTrainingSubjectsAndStat(
      trainingId
    );

    // Update Subjects
    dispatch(setSubjects(subjects));

    // Update Stat
    dispatch(setSubjectStat(stat));
  } catch (err) {
    // Update Error Message
    dispatch(setSubjectErrorMessage(err.message.message));
  } finally {
    // Stop Loading
    dispatch(subjectStopLoading());
  }
};

/**
 * Get Total Subject Stat
 */
export const getTotalSubjectStat = () => async (dispatch) => {
  try {
    // Reset Error Message
    dispatch(resetSubjectErrorMessage());

    // Start Loading
    dispatch(subjectStartLoading());

    // Get Total Subject Stat
    const stat = await apis.getTotalSubjectStat();

    // Update Stat
    dispatch(setSubjectStat(stat));
  } catch (err) {
    // Update Error Message
    dispatch(setSubjectErrorMessage(err.message.message));
  } finally {
    // Stop Loading
    dispatch(subjectStopLoading());
  }
};

/**
 * Create New Subject
 * @param {object} subjectData Subject data
 * @param {string} trainingId Training id
 * @param {string} levelId Level id
 */
export const createSubject =
  (subjectData, trainingId, levelId) => async (dispatch) => {
    try {
      // Reset Error Message
      dispatch(resetSubjectErrorMessage());

      // Start Loading
      dispatch(subjectStartLoading());

      // Create Subject
      const { subjects, stat } = await apis.createSubject(
        subjectData,
        trainingId,
        levelId
      );

      // Show Success Notification On Creating Subject
      dispatch(showNotification(getSuccess("created")));

      // Update Subjects
      dispatch(setSubjects(subjects));

      // Update Stat
      dispatch(setSubjectStat(stat));

      // Navigate To Subjects
      History.push(`/admin/trainings/${trainingId}/${levelId}/subjects`);
    } catch (err) {
      // Show Error In Notification
      dispatch(showNotification(err.message));
    } finally {
      // Stop Loading
      dispatch(subjectStopLoading());
    }
  };

/**
 * Update A Subject
 * @param {string} subjectId Subject id
 * @param {object} subjectData Subject data
 * @param {string} trainingId Training id
 * @param {string} levelId Level id
 */
export const updateSubject =
  (subjectId, subjectData, trainingId, levelId) => async (dispatch) => {
    try {
      // Start Loading
      dispatch(subjectStartLoading());

      // Update Subject
      const { subjects, stat } = await apis.updateSubject(
        subjectId,
        subjectData,
        trainingId,
        levelId
      );

      // Show Success Notification On Updating Subject
      dispatch(showNotification(getSuccess("updated")));

      // Update Subjects
      dispatch(setSubjects(subjects));

      // Update Stat
      dispatch(setSubjectStat(stat));

      // Navigate To Subjects
      History.push(`/admin/trainings/${trainingId}/${levelId}/subjects`);
    } catch (err) {
      // Show Error In Notification
      dispatch(showNotification(err.message));
    } finally {
      // Stop Loading
      dispatch(subjectStopLoading());
    }
  };

/**
 * Delete A Subject
 * @param {string} subjectId Subject id
 * @param {string} levelId Level id
 */
export const deleteSubject = (subjectId, levelId) => async (dispatch) => {
  try {
    // Delete Subject
    const stat = await apis.deleteSubject(subjectId, levelId);

    // Delete Subject In Redux Store
    dispatch(delSubject(subjectId));

    // Show Success Notification On Deleting Subject
    dispatch(showNotification(getSuccess("deleted")));

    // Update Stat
    dispatch(setSubjectStat(stat));
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  }
};
