// Modules
import React from "react";

// Components
import { AiOutlineCalendar } from "react-icons/ai";

// Style
import "./primary-timestamp.styles.scss";

export const PrimaryTimestamp = ({ className, isActive, value, onClick }) => {
  const { start: startTime, end: endTime } = value;

  /**
   * Format Time To Human Readable Format
   * @param {Date|string|number} date Date
   */
  const formatTime = (date) => {
    return new Date(date).toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  return (
    <div
      className={`primary-timestamp ${className} ${
        isActive ? "primary-timestamp--active" : ""
      }`}
      onClick={onClick.bind(null, value)}
    >
      <AiOutlineCalendar className="primary-timestamp__icon" />
      <p className="primary-timestamp__time">
        <span className="primary-timestamp__start-time">
          {formatTime(startTime)}
        </span>{" "}
        -{" "}
        <span className="primary-timestamp__end-time">
          {formatTime(endTime)}
        </span>
      </p>
    </div>
  );
};

PrimaryTimestamp.defaultProps = {
  className: "",
};
