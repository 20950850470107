// Loading Reducers
export const loadingReducers = {
  startLoading(state) {
    state.isLoading = true;
  },
  stopLoading(state) {
    state.isLoading = false;
  },
};

// Error Reducers
export const errorMessageReducers = {
  setErrorMessage(state, action) {
    state.errorMessage = action.payload;
  },
  resetErrorMessage(state) {
    state.errorMessage = "";
  },
};
