// Modules
import React from "react";

// Components
import {
  MdOutlinePhoneInTalk,
  MdOutlineMailOutline,
  MdOutlineLocationOn,
} from "react-icons/md";
import { AiOutlineClockCircle } from "react-icons/ai";

// Style
import "./contact-address.styles.scss";

export const ContactAddress = ({ className }) => {
  return (
    <div className={`contact-address ${className}`}>
      <div className="contact-address__item">
        <MdOutlinePhoneInTalk
          className="contact-address__item-icon"
          aria-label="contact us on phone"
        />

        <div className="contact-address__item-right">
          <p className="contact-address__item-title">PHONE</p>
          <a className="contact-address__item-content" href="tel:+250789309155">
            +250789309155
          </a>
        </div>
      </div>

      <div className="contact-address__item">
        <MdOutlineMailOutline
          className="contact-address__item-icon"
          arial-label="contact us on email"
        />

        <div className="contact-address__item-right">
          <p className="contact-address__item-title">EMAIL</p>
          <a
            className="contact-address__item-content"
            href="mailto:finacco2021@gmail.com"
          >
            finacco2021@gmail.com
          </a>
        </div>
      </div>

      <div className="contact-address__item">
        <MdOutlineLocationOn
          className="contact-address__item-icon"
          aria-label="our location"
        />

        <div className="contact-address__item-right">
          <p className="contact-address__item-title">ADDRESS</p>
          <address className="contact-address__item-content">
            KK 15, Silver Back Mall, 3rd Floor, Kigali Rwanda
          </address>
        </div>
      </div>

      <div className="contact-address__item">
        <AiOutlineClockCircle
          className="contact-address__item-icon"
          aria-label="our opening hours"
        />

        <div className="contact-address__item-right">
          <p className="contact-address__item-title">OPENING HOURS</p>
          <address className="contact-address__item-content">
            Mon - Fri 9am - 7pm
          </address>
        </div>
      </div>
    </div>
  );
};

ContactAddress.defaultProps = {
  className: "",
};
