// Modules
import React from "react";

export const VideoAnalytics = ({ vPlayer, vPlayerInitialize }) => {
  return null;
};

VideoAnalytics.defaultProps = {
  vPlayerInitialize: false,
};
