// Modules
import React from "react";

// Components
import { SubjectCard } from "../../../";

// Style
import "./course-catalog-list.styles.scss";

export const CourseCatalogList = ({ className, items }) => {
  // Subject Items
  const renderedSubjectItems = items.map(
    ({ id, thumbnail, name, tutor, chapters }) => (
      <SubjectCard
        className="course-catalog-list__item"
        key={id}
        thumbnail={thumbnail?.url}
        subjectName={name}
        subjectAuthor={`${tutor?.firstName} ${tutor?.lastName}`}
        numberOfChapter={chapters.length}
        to={`/player?subject=${id}`}
      />
    )
  );

  return (
    <div className={`course-catalog-list ${className}`}>
      {renderedSubjectItems}
    </div>
  );
};

CourseCatalogList.defaultProps = {
  className: "",
  items: [],
};
