// Modules
import React from "react";

// Components
import { Fragment } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Button } from "../../";

// Images
import emptyContent from "../../../assets/images/empty-content.svg";

// Style
import "./empty-content.styles.scss";

export const EmptyContent = ({
  className,
  additionalErrorChildren,
  children,
  isLoading,
  errorMessage,
}) => {
  /**
   * Handle Reload Window
   */
  const onHandleReload = () => {
    window.location.reload();
  };

  return (
    <Fragment>
      <div
        className={`empty-content ${className} ${
          isLoading || errorMessage ? "" : "d-none"
        }`}
      >
        <div className="empty-content__container container">
          {/* Loading Spinner */}
          {isLoading && !errorMessage && (
            <Fragment>
              <span className="empty-content__loading-spinner-spacer" />
              <RotatingLines
                ariaLabel="content loading"
                strokeColor="#f9a22f"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
              <p className="empty-content__text is-text-thick">Loading...</p>
            </Fragment>
          )}

          {/* Error Message */}
          {!isLoading && errorMessage && (
            <Fragment>
              <img
                className="empty-content__photo"
                src={emptyContent}
                alt="no result"
              />
              <p className="empty-content__text">{errorMessage}</p>

              {additionalErrorChildren ? (
                additionalErrorChildren
              ) : (
                <Button
                  className="empty-content__btn btn--primary btn--rv1 btn--sv4"
                  onClick={onHandleReload}
                >
                  Reload
                </Button>
              )}
            </Fragment>
          )}
        </div>
      </div>

      {/* Render Children If IsLoading And ErrorMessage Is False */}
      <div hidden={isLoading || errorMessage}>{children}</div>
    </Fragment>
  );
};

EmptyContent.defaultProps = {
  className: "",
  isLoading: false,
};
