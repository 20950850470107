// Modules
import React from "react";

// Components
import { Button as LevelItem } from "..";

// Style
import "./levels.styles.scss";

export const Levels = ({ className, setActiveLevel, activeLevel, items }) => {
  /**
   * Update Active Level
   * @param {string} levelId Level Id
   */
  const onUpdateActiveLevel = (levelId) => {
    setActiveLevel(levelId);
  };

  // Level Item
  const renderedLevelItem = items.map((item) => (
    <LevelItem
      key={item.id}
      wrapperClassName="levels__item-wrapper"
      className={`levels__item btn--sv14 btn--rv1 ${
        activeLevel === item.id ? "levels__item--active" : ""
      }`}
      onClick={onUpdateActiveLevel.bind(null, item.id)}
    >
      {item.name}
    </LevelItem>
  ));

  return (
    <div className={`levels ${className}`}>
      <div
        className="levels__container"
        style={{
          gridTemplateColumns: `repeat(${
            items.length > 4 ? 4 : items.length
          }, 1fr)`,
        }}
      >
        {renderedLevelItem}
      </div>
    </div>
  );
};

Levels.defaultProps = {
  className: "",
  items: [],
};
