// Modules
import React from "react";

// Components
import {
  ModalOverlay,
  Modal,
  EmptyContent,
  Levels,
  InputCheckbox,
  Button,
  AddStudentSubjectsList,
} from "../../../";

// Hooks
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  useLevel,
  useSubject,
  useSubscription,
  useStudent,
} from "../../../../hooks";

// Style
import "./add-student-subjects-modal-base.styles.scss";

export const AddStudentSubjectsModalBase = ({ className }) => {
  const [activeLevel, setActiveLevel] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [
    payPerSubjectSubscriptionsSubjectIds,
    setPayPerSubjectSubscriptionsSubjectIds,
  ] = useState([]);
  const {
    dispatch,
    levels,
    getTrainingLevels,
    isLevelLoading,
    levelErrorMessage,
  } = useLevel();
  const {
    getLevelSubjectsAndStat,
    isSubjectLoading,
    subjectErrorMessage,
    subjects,
  } = useSubject();
  const {
    getStudentLevelPayPerSubjectSubscriptions,
    payPerSubjectSubscriptions,
    isSubscriptionlLoading,
  } = useSubscription();
  const {
    enrollStudentPayPerSubjectSubscription,
    unEnrollStudentPayPerSubjectSubscription,
  } = useStudent();
  const { trainingId, studentId } = useParams();

  useEffect(() => {
    // Get Training levels
    dispatch(getTrainingLevels(trainingId));
  }, [trainingId]);

  useEffect(() => {
    if (activeLevel) {
      // Get Student Level Pay Per Subject Subscriptions
      dispatch(
        getStudentLevelPayPerSubjectSubscriptions(
          studentId,
          activeLevel,
          true,
          false
        )
      );

      // Get Level Subjects
      dispatch(getLevelSubjectsAndStat(activeLevel));
    }
  }, [activeLevel, studentId]);

  useEffect(() => {
    // Update Active Level. If Level Exist
    if (levels.length) {
      setActiveLevel(levels[0].id);
    }
  }, [levels]);

  useEffect(() => {
    // Get Pay Per Subject Subscriptions Subject Ids
    const payPerSubjectSubscriptionsSubjectIds = payPerSubjectSubscriptions.map(
      (payPerSubjectSubscription) => payPerSubjectSubscription.subjectId?.id
    );

    // Update Pay Per Subject Subscriptions Subject Ids In State
    setPayPerSubjectSubscriptionsSubjectIds(
      payPerSubjectSubscriptionsSubjectIds
    );

    // Update SelectedSubjects With PayPerSubjectSubscription Subject Ids
    setSelectedSubjects(payPerSubjectSubscriptionsSubjectIds);
  }, [payPerSubjectSubscriptions]);

  useEffect(() => {
    // If Select All Is True, Then Select All Subjects
    if (selectAll) {
      setSelectedSubjects(subjects.map((subject) => subject.id));
    } else {
      // Else Reset Selected Subjects
      setSelectedSubjects([]);
    }
  }, [selectAll]);

  /**
   * Toggle Select All Value
   */
  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
  };

  /**
   * Toggle Subject Selection
   * @param {string} subjectId Subject id
   */
  const onToggleSubjectSelect = (subjectId) => {
    if (!isSubmitting) {
      setSelectedSubjects((prevState) => {
        // If Previous Selected Subject State Has The Subject Id, Then Remove It
        if (prevState.includes(subjectId)) {
          return prevState.filter(
            (prevStateSubjectId) => prevStateSubjectId !== subjectId
          );
        }

        if (!prevState.includes(subjectId)) {
          return [...prevState, subjectId];
        }
      });
    }
  };

  /**
   * Update Student Pay Per Subject Enrollment
   */
  const updateStudentEnrollment = async () => {
    // Start IsSubmitting
    setIsSubmitting(true);

    // Get Added Subjects
    const addedSubjects = selectedSubjects.filter(
      (selectedSubject) =>
        !payPerSubjectSubscriptionsSubjectIds.includes(selectedSubject)
    );

    // Get Removed Subjects
    const removedSubjects = payPerSubjectSubscriptionsSubjectIds.filter(
      (payPerSubjectSubscriptionSubjectId) =>
        !selectedSubjects.includes(payPerSubjectSubscriptionSubjectId)
    );

    // If Added Subjects Is Not Empty, Then Enroll Student Into Subjects
    if (addedSubjects.length) {
      await dispatch(
        enrollStudentPayPerSubjectSubscription(studentId, addedSubjects)
      );
    }

    // If Removed Subjects Is Not Empty, Then Un-Enroll Student From Subjects
    if (removedSubjects.length) {
      await dispatch(
        unEnrollStudentPayPerSubjectSubscription(studentId, removedSubjects)
      );
    }

    // Get Student Level Pay Per Subject Subscriptions
    await dispatch(
      getStudentLevelPayPerSubjectSubscriptions(
        studentId,
        activeLevel,
        true,
        false
      )
    );

    // Stop IsSubmitting
    setIsSubmitting(false);
  };

  return (
    <ModalOverlay
      className={`add-student-subjects-modal-base-overlay ${className}`}
      to={`/admin/students/${studentId}/trainings`}
    >
      <Modal className="add-student-subjects-modal-base">
        <EmptyContent
          className="add-student-subjects-modal-base__empty"
          isLoading={
            isLevelLoading ||
            isSubjectLoading ||
            (isSubscriptionlLoading && !isSubmitting)
          }
          errorMessage={levelErrorMessage || subjectErrorMessage}
        >
          <div className="add-student-subjects-modal-base__header">
            <h3 className="add-student-subjects-modal-base__title">
              Add Subject to Student
            </h3>

            <div className="add-student-subjects-modal-base__levels">
              <Levels
                className="add-student-subjects-modal-base__levels-list"
                activeLevel={activeLevel}
                setActiveLevel={setActiveLevel}
                items={levels}
              />
            </div>

            <div className="add-student-subjects-modal-base__actions">
              <InputCheckbox
                wrapperClassName="add-student-subjects-modal-base__checkbox-wrapper"
                className="add-student-subjects-modal-base__checkbox"
                labelClassName="add-student-subjects-modal-base__checkbox-label"
                label="Select All"
                checked={selectAll}
                onChange={toggleSelectAll}
              />

              <Button
                className="add-student-subjects-modal-base__btn btn--primary btn--sv8 btn--rv5 admin-layout__content-btn"
                onClick={updateStudentEnrollment}
                loading={isSubmitting}
                loadingHeight="20"
                disabled={isSubmitting}
              >
                Update Student Enrollment
              </Button>
            </div>
          </div>

          <div className="add-student-subjects-modal-base__main">
            <AddStudentSubjectsList
              className="add-student-subjects-modal-base__list"
              items={subjects}
              selectedSubjects={selectedSubjects}
              toggleSubjectSelect={onToggleSubjectSelect}
            />
          </div>
        </EmptyContent>
      </Modal>
    </ModalOverlay>
  );
};

AddStudentSubjectsModalBase.defaultProps = {
  className: "",
};
