// Modules
import React from "react";

// Hooks
import { useEffect, useState, useRef } from "react";
import { useChapterFile, useChapter, useAuth } from "../../../../hooks";

// Components
import { Fragment } from "react";
import MuxPlayer from "@mux/mux-player-react";

// Style
import "./video-player.styles.scss";

export const VideoPlayer = ({ className }) => {
  const videoElm = useRef();
  const [activePlaylistFile, setActivePlaylistFile] = useState(null);
  const [activePlaylistIndex, setActivePlaylistIndex] = useState(0);
  const [playlist, setPlaylist] = useState([]);
  const {
    activeChapterFile: activeChapterFileId,
    chapterFiles,
    setActiveChapterFile: setActiveChapterFileId,
    dispatch,
    isChapterFileLoading,
  } = useChapterFile();
  const { activeChapter } = useChapter();
  const { user } = useAuth();

  // Handle Video Ended
  const handleVideoEnded = () => {
    const nextPlaylistFile = playlist[activePlaylistIndex + 1];

    if (nextPlaylistFile) {
      dispatch(setActiveChapterFileId(nextPlaylistFile.id));
    }
  };

  useEffect(() => {
    if (activeChapter && !isChapterFileLoading) {
      // Get Playlist Items
      const playlistItems = chapterFiles
        .filter(
          (chapterFile) =>
            chapterFile.chapterId?.["_path"]?.["segments"]?.[1] ===
              activeChapter && !chapterFile.contentType.includes("pdf")
        )
        .map((chapterFile) => ({
          playbackId: chapterFile.file.playback_ids[0].id,
          video: {
            id: chapterFile.file.id,
            title: chapterFile.name,
            userId: user.id,
          },
          id: chapterFile.id,
        }));

      // Update Playlist
      setPlaylist(playlistItems);

      // Set Default Active Chapter File Id
      dispatch(setActiveChapterFileId(playlistItems[0]?.id));
    }
  }, [activeChapter, chapterFiles]);

  useEffect(() => {
    if (activeChapterFileId) {
      // Get Active Playlist File
      const activePlaylistFile = playlist.find(
        (playlist) => playlist.id === activeChapterFileId
      );

      // Get Active Playlist Index
      const activePlaylistIndex = playlist.findIndex(
        (playlist) => playlist.id === activeChapterFileId
      );

      // Update Active Playlist File
      setActivePlaylistFile(activePlaylistFile);

      // Update Active Playlist Index
      setActivePlaylistIndex(activePlaylistIndex);
    }
  }, [activeChapterFileId, playlist]);

  return (
    <Fragment>
      <div className={`video-player ${className}`}>
        <div className="video-player__container">
          <div id="video-player__video">
            <MuxPlayer
              ref={videoElm}
              playbackId={activePlaylistFile?.playbackId}
              accentColor="rgba(0, 0, 0, 0.3)"
              primaryColor="#db8226"
              metadata={{
                video_id: activePlaylistFile?.video?.id,
                video_title: activePlaylistFile?.video?.title,
                viewer_user_id: activePlaylistFile?.video?.userId,
              }}
              onEnded={handleVideoEnded}
            />
          </div>

          <p className="video-player__name">
            {activePlaylistFile?.video?.title}
          </p>
        </div>
      </div>
    </Fragment>
  );
};

VideoPlayer.defaultProps = {
  className: "",
};
