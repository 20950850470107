// Hooks
import { useSelector, useDispatch } from "react-redux";

// Redux
import { authSA } from "../store";

// Destructure Redux Imports
const {
  selectCheckedAuth,
  selectIsAuthLoading,
  selectUser,
  selectOAuthLoadingName,
  selectIsLoggedIn,
  signInWithOAuth,
  logout,
} = authSA;

// Authentication Hook
export const useAuth = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isAuthLoading = useSelector(selectIsAuthLoading);
  const user = useSelector(selectUser);
  const oAuthLoadingName = useSelector(selectOAuthLoadingName);
  const checkedAuth = useSelector(selectCheckedAuth);

  /**
   * Sign-In With OAuth Strategy
   * @param {string} providerName OAuth provider name
   * @example
   * handleSignInWithOAuth("google")
   * handleSignInWithOAuth("facebook")
   */
  const handleSignInWithOAuth = async (providerName) => {
    await dispatch(signInWithOAuth(providerName));
  };

  /**
   * Logout currently Authenticated User
   */
  const handleLogout = async () => {
    await dispatch(logout());
  };

  return {
    isLoggedIn,
    isAuthLoading,
    user,
    oAuthLoadingName,
    checkedAuth,
    handleSignInWithOAuth,
    handleLogout,
    dispatch,
    ...authSA,
  };
};
