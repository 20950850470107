// Modules
import React from "react";

// Hooks
import { useNavigate, useLocation } from "react-router-dom";

// Utils
import { History } from "../utils";

export const NavigateSetter = () => {
  History.navigate = useNavigate();
  History.location = useLocation();
  return null;
};
