// Modules
import React from "react";

// Components
import { LazyLoadImage } from "react-lazy-load-image-component";

// Images
import homeFinaccoBenefit from "../../../assets/images/home-finacco-benefit.svg";
import homeFinaccoBenefitLazy from "../../../assets/images/home-finacco-benefit-lazy.jpg";

// Style
import "./home-finacco-benefit-base.styles.scss";

export const HomeFinaccoBenefitBase = ({ className }) => {
  return (
    <div className={`home-finacco-benefit-base ${className}`}>
      <div className="home-finacco-benefit-base__container container">
        <div className="home-finacco-benefit-base__left">
          <h1 className="home-finacco-benefit-base__heading primary-heading is-text-secondary-color">
            Everything you can do in a physical classroom,{" "}
            <span className="is-text-primary-color">
              you can do with FinAcco
            </span>
          </h1>

          <p className="home-finacco-benefit-base__content">
            We offer classes with highly flexible schedules that accommodate
            both part time and full time students. Our blended approach allows
            you to attend class physically or from the comfort of your house
          </p>
        </div>

        <div className="home-finacco-benefit-base__right">
          <LazyLoadImage
            className="home-finacco-benefit-base__photo"
            placeholderSrc={homeFinaccoBenefitLazy}
            src={homeFinaccoBenefit}
            alt="Benefit of using finacco"
          />
        </div>
      </div>
    </div>
  );
};

HomeFinaccoBenefitBase.defaultProps = {
  className: "",
};
