// Modules
import { createSlice } from "@reduxjs/toolkit";

// Common Reducers
import { loadingReducers, errorMessageReducers } from "../common";

// Utils
import { stateSelector } from "../../utils";

// Initial State
const initialState = {
  isLoading: false,
  errorMessage: "",
  privateSessions: [],
  privateSession: null,
};

// Slice Name
const sliceName = "privateSession";

// Private Session Slice
const privateSessionSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ...loadingReducers,
    ...errorMessageReducers,
    setPrivateSessions(state, action) {
      state.privateSessions = action.payload;
    },
    resetPrivateSessions(state) {
      state.privateSessions = [];
    },
    setPrivateSession(state, action) {
      state.privateSession = action.payload;
    },
    resetPrivateSession(state) {
      state.privateSession = null;
    },
    delPrivateSession(state, action) {
      // Get Private Session Id
      const privateSessionId = action.payload;

      // Get Private Sessions
      const { privateSessions } = state;

      // Update PrivateSessions With PrivateSession That Does Not Contain The Specified PrivateSession Id
      state.privateSessions = privateSessions.filter(
        (privateSession) => privateSession.id !== privateSessionId
      );

      // If PrivateSessions Is Empty, Then Set Error Message
      if (!state.privateSessions.length) {
        state.errorMessage = "No Data Available. Wait For Data Upload";
      }
    },
  },
});

export const {
  startLoading: privateSessionStartLoading,
  stopLoading: privateSessionStopLoading,
  resetErrorMessage: resetPrivateSessionErrorMessage,
  setErrorMessage: setPrivateSessionErrorMessage,
  setPrivateSessions,
  resetPrivateSessions,
  delPrivateSession,
  setPrivateSession,
  resetPrivateSession,
} = privateSessionSlice.actions;
export * from "./privateSessionAction";
export const selectIsPrivateSessionLoading = stateSelector(
  sliceName,
  "isLoading"
);
export const selectPrivateSessionErrorMessage = stateSelector(
  sliceName,
  "errorMessage"
);
export const selectPrivateSessions = stateSelector(
  sliceName,
  "privateSessions"
);
export const selectPrivateSession = stateSelector(sliceName, "privateSession");
export default privateSessionSlice.reducer;
