// Modules
import React from "react";

// Components
import { Button } from "../..";

// Style
import "./accounting-solution-hero.styles.scss";

export const AccountingSolutionHero = ({ className }) => {
  return (
    <div className={`accounting-solution-hero ${className} secondary-hero`}>
      <div className="accounting-solution-hero__container container secondary-hero__container">
        <h1 className="accounting-solution-hero__heading primary-heading secondary-hero__heading is-text-accent-300">
          Digital Finance &{" "}
          <span className="is-text-accent-400">Accounting Solutions</span>
        </h1>

        <p className="accounting-solution-hero__description secondary-hero__description">
          We digitize your finance and accounting department
        </p>

        <Button
          to="/?s=contact-base"
          className="accounting-solution-hero__btn secondary-hero__btn btn--primary btn--rv3 btn--sv6"
        >
          Contact Us
        </Button>
      </div>
    </div>
  );
};

AccountingSolutionHero.defaultProps = {
  className: "",
};
