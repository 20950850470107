// Modules
import React from "react";

// Components
import { ModalOverlay, Modal, EmptyContent, TrainingList } from "../../..";

// Hooks
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTraining } from "../../../../hooks";

// Style
import "./training-modal-base.styles.scss";

export const TrainingModalBase = ({ className }) => {
  const { studentId } = useParams();
  const {
    dispatch,
    getTrainingsAndStat,
    isTrainingLoading,
    trainingErrorMessage,
    trainings,
  } = useTraining();

  useEffect(() => {
    // Get Trainings And Stat
    dispatch(getTrainingsAndStat());
  }, []);

  return (
    <ModalOverlay
      className={`training-modal-base-overlay ${className}`}
      to={`/admin/students/${studentId}`}
    >
      <Modal className="training-modal-base">
        <EmptyContent
          className="training-modal-base__empty"
          isLoading={isTrainingLoading}
          errorMessage={trainingErrorMessage}
        >
          <div className="training-modal-base__header">
            <h3 className="training-modal-base__title">Choose Exam Type</h3>
          </div>

          <div className="training-modal-base__main">
            <TrainingList
              className="training-modal-base__list"
              items={trainings}
              useAltPath
              studentId={studentId}
            />
          </div>
        </EmptyContent>
      </Modal>
    </ModalOverlay>
  );
};

TrainingModalBase.defaultProps = {
  className: "",
};
