// Modules
import React from "react";

// Components
import { PrivateSessionCard } from "../../../";

// Style
import "./private-session-list.styles.scss";

export const StudentPrivateSessionList = ({ className, items }) => {
  // Private Session Card Items
  const renderedPrivateSessionItems = items.map((item) => (
    <PrivateSessionCard
      key={item.id}
      className="private-session-list__item"
      sessionId={item.id}
      sessionName={item.name}
      sessionPrice={item.price}
      sessionDescription={item.description}
    />
  ));

  return (
    <div className={`private-session-list ${className}`}>
      {renderedPrivateSessionItems}
    </div>
  );
};

StudentPrivateSessionList.defaultProps = {
  className: "",
  items: [],
};
