// Modules
import React from "react";

export const ProfessionalTrainingHero = ({ className }) => {
  return (
    <div className={`professional-training-hero secondary-hero ${className}`}>
      <div className="professional-training-hero__container container secondary-hero__container">
        <h1 className="professional-training-hero__heading primary-heading secondary-hero__heading is-text-accent-300">
          Professional{" "}
          <span className="is-text-accent-400">Designation Training</span>
        </h1>
        <p className="professional-training-hero__description secondary-hero__description">
          Offering quality professional designation training to our students
          currently training{" "}
          <strong>(ACCA, CFA & CERTIFICATE IN ESG INVESTING)</strong>
        </p>
      </div>
    </div>
  );
};

ProfessionalTrainingHero.defaultProps = {
  className: "",
};
