// Firebase
import { getDocs, where, query, Timestamp } from "firebase/firestore";
import {
  referrerMediumCollectionRef,
  userCollectionRef,
  firebaseLooper,
} from "../../utils";

// Utils
import { AppError } from "../../utils";

/**
 * Get Referrer Mediums Stat For Month
 * @param {number|undefined} month Lookup month (Note: month should start from zero)
 */
export const getMonthReferrerMediumsStat = async (month) => {
  /**
   * Get All Student That Signup Using Specified Referrer Medium For A Month
   * @param {string} referrerMedium Referrer medium
   * @param {number} month Month
   */
  const getAllStudentReferrerMediumForMonth = async (referrerMedium, month) => {
    // Get Begining Of Month Date
    const beginningOfMonthDate = new Date(
      new Date().setMonth(month)
    ).moveToFirstDayOfMonth();

    // Get End Of Month Date
    const endOfMonthDate = new Date(
      new Date().setMonth(month)
    ).moveToLastDayOfMonth();

    // Create Student Query
    const studentQuery = query(
      userCollectionRef,
      where("role", "==", 1),
      where("referrerMedium", "==", referrerMedium),
      where("createdAt", ">=", Timestamp.fromDate(beginningOfMonthDate)),
      where("createdAt", "<=", Timestamp.fromDate(endOfMonthDate))
    );

    // Get Students
    const querySnapshot = await getDocs(studentQuery);

    // Return Students
    return firebaseLooper(querySnapshot);
  };

  /**
   * Get Referrer Mediums Stat
   * @param {array} referrerMediums Referrer mediums
   * @param {number} month Month
   */
  const getReferrerMediumsStat = async (referrerMediums, month) => {
    return Object.fromEntries(
      await Promise.all(
        referrerMediums.map(async (referrerMedium) => {
          const students = await getAllStudentReferrerMediumForMonth(
            referrerMedium,
            month
          );

          // Return New Entry For Month Referrer Mediums Stat
          return [referrerMedium, students.length];
        })
      )
    );
  };

  try {
    // Get Referrer Mediums Stat For Specified Month
    let monthReferrerMediumStat;

    // Get Referrer Mediums Stat For Current Month
    let currentMonthReferrerMediumStat;

    // Get All Referrer Mediums
    const referrerMediums = await getAllReferrerMediums(false);

    // Get Referrer Mediums For Specified Month, If Month Exist
    if (month) {
      monthReferrerMediumStat = await getReferrerMediumsStat(referrerMediums);
    }

    // Get Referrer Mediums Stat For Current Month
    currentMonthReferrerMediumStat = await getReferrerMediumsStat(
      referrerMediums
    );

    // Return Referrer Mediums Stat
    return {
      currentMonthStat: {
        ...currentMonthReferrerMediumStat,
      },
      monthStat: {
        ...monthReferrerMediumStat,
      },
      totalReferrerMediums: referrerMediums.length,
    };
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get All Referrer Mediums
 * @param {boolean} throwNotFound If true throw not found error
 */
export const getAllReferrerMediums = async (throwNotFound = true) => {
  try {
    // Get All Referrer Mediums
    const querySnapshot = await getDocs(referrerMediumCollectionRef);

    // If Referrer Mediums Is Empty & Throw Not Found Is True, Then Throw A Custom Error
    if (querySnapshot.empty && throwNotFound) {
      throw new AppError("empty-data");
    }

    // Return ReferrerMediums
    return firebaseLooper(querySnapshot);
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};
