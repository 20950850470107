// Hooks
import { useSelector, useDispatch } from "react-redux";

// Redux
import { subscriptionSA } from "../store";

// Destructure Redux Imports
const {
  selectIsSubscriptionLoading,
  selectPayPerSubjectSubscriptions,
  selectRecurrentSubscriptions,
  selectSubscriptionErrorMessage,
  selectSubscriptionHistories,
} = subscriptionSA;

/**
 * Subscription Hook
 */
export const useSubscription = () => {
  const dispatch = useDispatch();
  const recurrentSubscriptions = useSelector(selectRecurrentSubscriptions);
  const payPerSubjectSubscriptions = useSelector(
    selectPayPerSubjectSubscriptions
  );
  const isSubscriptionlLoading = useSelector(selectIsSubscriptionLoading);
  const subscriptionErrorMessage = useSelector(selectSubscriptionErrorMessage);
  const subscriptionHistories = useSelector(selectSubscriptionHistories);

  return {
    recurrentSubscriptions,
    subscriptionHistories,
    isSubscriptionlLoading,
    subscriptionErrorMessage,
    payPerSubjectSubscriptions,
    dispatch,
    ...subscriptionSA,
  };
};
