// Modules
import React from "react";

// Components
import { Form, Input, InputSelect, Button, BookingDate } from "../../../../";

// Hooks
import { useState, useEffect } from "react";
import {
  useStaff,
  useValidateFormOnMouseEnter,
  usePrivateSession,
} from "../../../../../hooks";

export const AddPrivateSessionForm = ({ className, formik, isEditMode }) => {
  const [transformedStaffs, setTransformedStaffs] = useState([]);
  const {
    isStaffLoading,
    staffErrorMessage,
    staffs,
    dispatch,
    getStaffsAndStat,
  } = useStaff();
  const { isPrivateSessionLoading } = usePrivateSession();

  const {
    isValid,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    validateForm,
    setTouched,
  } = formik;

  const { triggerFormValidation } = useValidateFormOnMouseEnter(
    validateForm,
    setTouched
  );

  useEffect(() => {
    // Get All Staffs And Stat
    dispatch(getStaffsAndStat());
  }, []);

  useEffect(() => {
    // Transform Staffs If Staffs Exist
    if (staffs.length) {
      setTransformedStaffs(
        staffs.map((staff) => ({
          id: staff.id,
          type: `${staff.firstName} ${staff.lastName}`,
        }))
      );
    }
  }, [staffs]);

  return (
    <Form
      className={`add-private-session-form is-text-center container ${className}`}
      onSubmit={handleSubmit}
    >
      <Input
        className="add-private-session-form__control admin-form__control"
        wrapperClassName="add-private-session-form__group admin-form__group"
        labelClassName="add-private-session-form__label admin-form__label"
        id="name"
        name="name"
        placeholder="Event Name"
        value={values.name}
        error={errors.name}
        touched={touched.name}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <Input
        className="add-private-session-form__control admin-form__control"
        wrapperClassName="add-private-session-form__group admin-form__group"
        labelClassName="add-private-session-form__label admin-form__label"
        type="textarea"
        id="description"
        name="description"
        placeholder="Event Description"
        value={values.description}
        error={errors.description}
        touched={touched.description}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <Input
        className="add-private-session-form__control admin-form__control"
        wrapperClassName="add-private-session-form__group admin-form__group"
        labelClassName="add-private-session-form__label admin-form__label"
        id="price"
        name="price"
        type="number"
        label="Price Per Support"
        placeholder="Price"
        value={values.price}
        error={errors.price}
        touched={touched.price}
        onBlur={handleBlur}
        onChange={handleChange}
        isInline
      />

      <InputSelect
        className="add-private-session-form__control admin-form__control admin-form__control--select"
        wrapperClassName="add-private-session-form__group admin-form__group admin-form__group--select"
        labelClassName="add-private-session-form__label admin-form__label admin-form__label--select"
        placeholder="Tutor Name"
        name="tutorId"
        id="tutorName"
        useKey
        items={transformedStaffs}
        isLoading={isStaffLoading}
        fetchError={staffErrorMessage}
        value={values.tutorId}
        error={errors.tutorId}
        touched={touched.tutorId}
        onChange={setFieldValue}
      />

      <BookingDate
        className="add-private-session-form__booking-date admin-form__booking-date"
        labelClassName="add-private-session-form__booking-date-label admin-form__label admin-form__label--booking-date"
        label="Event Date/Time"
        name="availabilityDates"
        availabilityDates={values.availabilityDates}
        error={errors.availabilityDates}
        touched={touched.availabilityDates}
        onAvailabilityDatesChange={setFieldValue}
      />

      <div className="add-private-session-form__btn-group admin-form__btn-group">
        <Button
          className="add-private-session-form__btn admin-form__btn btn--primary btn--rv1 btn--sv9"
          type="submit"
          disabled={!isValid || isPrivateSessionLoading}
          loading={isPrivateSessionLoading}
          loadingHeight="26"
          onMouseEnter={() => triggerFormValidation(isValid)}
        >
          {isEditMode ? "Update" : "Publish"}
        </Button>
      </div>
    </Form>
  );
};

AddPrivateSessionForm.defaultProps = {
  className: "",
};
