// Modules
import { createSlice } from "@reduxjs/toolkit";

// Common Reducers
import { loadingReducers, errorMessageReducers } from "../common";

// Utils
import { stateSelector } from "../../utils";

// Initial State
const initialState = {
  students: [],
  studentStat: {
    currentMonthStat: {
      currentWeekLastActiveAtStudentCount: 0,
      currentMonthTotalStudentCount: 0,
      currentWeekTotalStudentCount: 0,
      currentDayTotalStudentCount: 0,
    },
    monthStat: {
      currentMonthTotalStudentCount: 0,
    },
    comparisonStat: {
      startYearStat: 0,
      compareYearStat: 0,
    },
    totalStudents: 0,
  },
  isLoading: false,
  loadingStatName: "",
  errorMessage: "",
  enrolledSubjects: [],
  recurrentSubscriptionEnrolledSubjects: [],
  payPerSubjectSubscriptionEnrolledSubjects: [],
};

// Slice Name
const sliceName = "student";

// Student Slice
const studentSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ...loadingReducers,
    ...errorMessageReducers,
    setLoadingStatName(state, action) {
      state.loadingStatName = action.payload;
    },
    resetLoadingStatName(state) {
      state.loadingStatName = "";
    },
    setStudents(state, action) {
      state.students = action.payload;
    },
    resetStudents(state) {
      state.students = [];
    },
    setEnrolledSubjects(state, action) {
      state.enrolledSubjects = action.payload;
    },
    resetEnrolledSubjects(state) {
      state.enrolledSubjects = [];
    },
    setStudentStat(state, action) {
      state.studentStat = action.payload;
    },
    resetStudentStat(state) {
      state.studentStat = {
        currentMonthStat: {
          currentWeekLastActiveAtStudentCount: 0,
          currentMonthTotalStudentCount: 0,
          currentWeekTotalStudentCount: 0,
          currentDayTotalStudentCount: 0,
        },
        monthStat: {
          currentMonthTotalStudentCount: 0,
        },
        comparisonStat: {
          startYearStat: 0,
          compareYearStat: 0,
        },
        totalStudents: 0,
      };
    },
    setRecurrentSubscriptionEnrolledSubjects(state, action) {
      state.recurrentSubscriptionEnrolledSubjects = action.payload;
    },
    resetRecurrentSubscriptionEnrolledSubjects(state) {
      state.recurrentSubscriptionEnrolledSubjects = [];
    },
    setPayPerSubjectSubscriptionEnrolledSubjects(state, action) {
      state.payPerSubjectSubscriptionEnrolledSubjects = action.payload;
    },
    resetPayPerSubjectSubscriptionEnrolledSubjects(state) {
      state.payPerSubjectSubscriptionEnrolledSubjects = [];
    },
    delStudent(state, action) {
      // Get Student Id
      const studentId = action.payload;

      // Get Students
      const { students } = state;

      // Update Students With The Specified Student Active Status Disabled
      state.students = students.map((student) =>
        student.id === studentId ? { ...student, isActive: false } : student
      );
    },
    resStudent(state, action) {
      // Get Student Id
      const studentId = action.payload;

      // Get Students
      const { students } = state;

      // Update Students With The Specified Student Active Status Restored
      state.students = students.map((student) =>
        student.id === studentId ? { ...student, isActive: true } : student
      );
    },
  },
});

export const {
  resetEnrolledSubjects,
  setEnrolledSubjects,
  setErrorMessage: setStudentErrorMessage,
  resetErrorMessage: resetStudentErrorMessage,
  setStudents,
  resetStudents,
  startLoading: studentStartLoading,
  stopLoading: studentStopLoading,
  setStudentStat,
  resetStudentStat,
  delStudent,
  setPayPerSubjectSubscriptionEnrolledSubjects,
  resetPayPerSubjectSubscriptionEnrolledSubjects,
  setRecurrentSubscriptionEnrolledSubjects,
  resetRecurrentSubscriptionEnrolledSubjects,
  setLoadingStatName,
  resetLoadingStatName,
  resStudent,
} = studentSlice.actions;
export * from "./studentAction";
export const selectStudents = stateSelector(sliceName, "students");
export const selectStudentStat = stateSelector(sliceName, "studentStat");
export const selectEnrolledSubjects = stateSelector(
  sliceName,
  "enrolledSubjects"
);
export const selectStudentErrorMessage = stateSelector(
  sliceName,
  "errorMessage"
);
export const selectIsStudentLoading = stateSelector(sliceName, "isLoading");
export const selectLoadingStatName = stateSelector(
  sliceName,
  "loadingStatName"
);
export const selectRecurrentSubscriptionEnrolledSubjects = stateSelector(
  sliceName,
  "recurrentSubscriptionEnrolledSubjects"
);
export const selectPayPerSubjectSubscriptionEnrolledSubjects = stateSelector(
  sliceName,
  "payPerSubjectSubscriptionEnrolledSubjects"
);
export default studentSlice.reducer;
