// Modules
import React from "react";

// Components
import { Outlet, Link } from "react-router-dom";

// Hocs
import { preventIfAuthHoc } from "../../hocs";

// Images
import { ReactComponent as FinaccoLogo } from "../../assets/images/finacco-logo.svg";
import authPhoto from "../../assets/images/auth-photo.svg";

// Style
import "./auth-layout.styles.scss";

const _AuthLayout = () => {
  return (
    <div className="auth-layout">
      <div className="auth-layout__container">
        <div className="auth-layout__left">
          <img
            className="auth-layout__photo d-block img-fluid"
            src={authPhoto}
            alt="Finacco Online Course"
          />
        </div>

        <div className="auth-layout__right">
          <div className="auth-layout__right-container container">
            <div className="auth-layout__brand">
              <Link className="auth-layout__brand-link" to="/">
                <FinaccoLogo
                  className="auth-layout__brand-logo"
                  arial-label="Finacco Logo"
                />
              </Link>
            </div>

            <div className="auth-layout__main">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Prevent User From Accessing Auth Layout If User Is Already Authenticated
export const AuthLayout = preventIfAuthHoc(_AuthLayout);
