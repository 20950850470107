// Modules
import { createSlice } from "@reduxjs/toolkit";

// Common Reducers
import { errorMessageReducers, loadingReducers } from "../common";

// Utils
import { stateSelector } from "../../utils";

// Initial State
const initialState = {
  subjects: [],
  subjectStat: {
    totalSubjects: 0,
  },
  isLoading: false,
  errorMessage: "",
};

// Slice Name
const sliceName = "subject";

// Subject Slice
const subjectSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ...loadingReducers,
    ...errorMessageReducers,
    setSubjects(state, actions) {
      state.subjects = actions.payload;
    },
    resetSubjects(state) {
      state.subjects = [];
    },
    setSubjectStat(state, actions) {
      state.subjectStat = actions.payload;
    },
    resetSubjectStat(state) {
      state.subjectStat = { totalSubjects: 0 };
    },
    delSubject(state, action) {
      // Get Subject Id
      const subjectId = action.payload;

      // Get Subjects
      const { subjects } = state;

      // Update Subjects With Subject That Does Not Contain The Specified Subject Id
      state.subjects = subjects.filter((subject) => subject.id !== subjectId);

      // If Subjects Is Empty, Then Set Error Message
      if (!state.subjects.length) {
        state.errorMessage = "No Data Available. Wait For Data Upload";
      }
    },
  },
});

export const {
  setSubjects,
  resetSubjects,
  setErrorMessage: setSubjectErrorMessage,
  resetErrorMessage: resetSubjectErrorMessage,
  startLoading: subjectStartLoading,
  stopLoading: subjectStopLoading,
  setSubjectStat,
  resetSubjectStat,
  delSubject,
} = subjectSlice.actions;
export * from "./subjectAction";
export const selectSubjects = stateSelector(sliceName, "subjects");
export const selectSubjectStat = stateSelector(sliceName, "subjectStat");
export const selectIsSubjectLoading = stateSelector(sliceName, "isLoading");
export const selectSubjectErrorMessage = stateSelector(
  sliceName,
  "errorMessage"
);
export default subjectSlice.reducer;
