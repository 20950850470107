// Modules
import React from "react";

// Hooks
import { useStudent, useUi } from "../../../hooks";

// Components
import { CourseCatalogList, EmptyContent, Button } from "../../";

// Style
import "./course-catalog-base.styles.scss";

export const CourseCatalogBase = ({ className }) => {
  const { enrolledSubjects, isStudentLoading, studentErrorMessage } =
    useStudent();
  const { pageTitle } = useUi();

  // Create Enroll Button
  const enrollBtn = (
    <Button
      className="course-catalog-base__btn btn--primary btn--sv8 btn--rv5"
      to="/professional-training"
    >
      Enroll Now
    </Button>
  );

  return (
    <div className={`course-catalog-base ${className} is-height-full`}>
      <div className="course-catalog-base__container is-height-full">
        <EmptyContent
          className="course-catalog-base__empty"
          additionalErrorChildren={enrollBtn}
          isLoading={isStudentLoading}
          errorMessage={studentErrorMessage}
        >
          <h2 className="course-catalog-base__heading student-base-heading student-base-heading--media-sensitive is-text-secondary-color">
            {pageTitle}
          </h2>

          <CourseCatalogList
            className="course-catalog-base__list"
            items={enrolledSubjects}
          />
        </EmptyContent>
      </div>
    </div>
  );
};

CourseCatalogBase.defaultProps = {
  className: "",
};
