// Modules
import React from "react";

// Components
import { Fragment } from "react";
import { Navigate } from "react-router-dom";

// Hooks
import { useLocation } from "react-router-dom";
import { useAuth } from "../../hooks";

/**
 * Prevent Component Render If User Is Authenticated
 * @param {function} Component React component
 */
export const preventIfAuthHoc = (Component) => {
  const PreventIfAuthHoc = (props) => {
    const location = useLocation();
    const { user } = useAuth();
    const { state } = location;

    return (
      <Fragment>
        {/* If User Is Null Then Navigate To Provided Component */}
        {!user && <Component {...props} />}

        {/* If User Is Available And User Role Is 1 Then Navigate To Student Portal */}
        {!state?.from && user?.role === 1 && (
          <Navigate to="/student/course-catalog" replace />
        )}

        {/* If User Is Available And User Role Is 2 Then Navigate To Admin Dashboard */}
        {!state?.from && user?.role == 2 && (
          <Navigate to="/admin/students" replace />
        )}

        {/* If State Has The From Property, Then Navigate To Specified Path */}
        {!!state?.from && user && <Navigate to={state.from.pathname} replace />}
      </Fragment>
    );
  };

  return PreventIfAuthHoc;
};
