// Modules
import React from "react";

// Components
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { TiUser } from "react-icons/ti";
import {
  MoreAction,
  MoreActionDropdownItem,
  Dialog as DeleteConfirmationDialog,
  InputCheckbox,
} from "../";

// Hooks
import { useParams } from "react-router-dom";
import { useDelete } from "../../../hooks";

// Style
import "./subject-card.styles.scss";

export const SubjectCard = ({
  className,
  thumbnail,
  subjectName,
  subjectAuthor,
  subjectPrice,
  numberOfChapter,
  to,
  id,
  selected,
  enableAction,
  enableSelect,
  onDeleteSubject,
  onSubjectSelectChange,
}) => {
  const { trainingId, levelId } = useParams();
  const {
    deleteCloseActionText,
    deleteConfirmActionText,
    isDeleteDialogOpen,
    onHandleCloseDeleteDialog,
    onHandleDeleteConfirm,
    onHandleOpenDeleteDialog,
  } = useDelete(onHandleSubjectDelete);

  /**
   * Handle Subject Delete
   */
  function onHandleSubjectDelete() {
    onDeleteSubject(id);
  }

  /**
   * Handle Subject Select
   */
  function onHandleSubjectSelect() {
    onSubjectSelectChange(id);
  }

  return (
    <Fragment>
      <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        dialogTitle="Confirm Delete Subject"
        dialogDescription="You are about to delete subject data. Confirm with yes to continue"
        closeActionText={deleteCloseActionText}
        confirmActionText={deleteConfirmActionText}
        onClose={onHandleCloseDeleteDialog}
        onConfirm={onHandleDeleteConfirm}
      />

      <div className={`subject-card ${className}`}>
        {!!to && <Link className="subject-card__link" to={to} />}
        <figure className="subject-card__header">
          <img
            className="subject-card__photo"
            src={thumbnail}
            alt={subjectName}
          />

          <figcaption className="subject-card__caption">
            <p className="subject-card__name">{subjectName}</p>
            {!!subjectPrice && (
              <p className="subject-card__price">${subjectPrice}</p>
            )}
          </figcaption>
        </figure>

        <div className="subject-card__main">
          <p className="subject-card__author">
            <TiUser className="subject-card__author-icon" />
            {subjectAuthor}
          </p>

          <p className="subject-card__stats">
            <span className="subject-card__stats-chapter">
              {numberOfChapter} lessons
            </span>
          </p>
        </div>

        {enableSelect && (
          <InputCheckbox
            wrapperClassName="subject-card__checkbox-wrapper"
            className="subject-card__checkbox-control"
            checked={selected}
            onChange={onHandleSubjectSelect}
          />
        )}

        {enableAction && (
          <MoreAction className="subject-card__action">
            <MoreActionDropdownItem
              className="subject-card__action-item"
              to={`/admin/trainings/${trainingId}/${levelId}/subjects/${id}`}
            >
              Edit
            </MoreActionDropdownItem>

            <MoreActionDropdownItem
              className="subject-card__action-item"
              onClick={onHandleOpenDeleteDialog}
            >
              Delete
            </MoreActionDropdownItem>
          </MoreAction>
        )}
      </div>
    </Fragment>
  );
};

SubjectCard.defaultProps = {
  className: "",
  selected: false,
};
