// Modules
import React from "react";

// Components
import { Button } from "../../";

// Hooks
import { useAuth } from "../../../hooks";

// Images
import facebookIcon from "../../../assets/images/facebook-icon.svg";
import googleIcon from "../../../assets/images/google-icon.svg";

// Style
import "./social-login.styles.scss";

export const SocialLogin = ({ className }) => {
  const { handleSignInWithOAuth, isAuthLoading, oAuthLoadingName } = useAuth();

  return (
    <div className={`social-login ${className}`}>
      <Button
        wrapperClassName="social-login__btn-wrapper d-block is-width-full"
        className="social-login__btn btn--sv1 btn--rv1 is-width-full"
        loading={oAuthLoadingName === "google"}
        loadingHeight="24"
        loadingColor="#f9a22f"
        disabled={isAuthLoading}
        onClick={handleSignInWithOAuth.bind(null, "google")}
      >
        <img
          className="social-login__icon"
          src={googleIcon}
          alt="google icon"
          width="20.12px"
          height="21.95px"
        />
        Google
      </Button>

      <Button
        wrapperClassName="social-login__btn-wrapper d-block is-width-full"
        className="social-login__btn btn--sv1 btn--rv1 is-width-full"
        loading={oAuthLoadingName === "facebook"}
        loadingHeight="24"
        loadingColor="#f9a22f"
        disabled={isAuthLoading}
        onClick={handleSignInWithOAuth.bind(null, "facebook")}
      >
        <img
          className="social-login__icon"
          src={facebookIcon}
          alt="facebook icon"
          width="20.12px"
          height="20.22px"
        />
        Facebook
      </Button>
    </div>
  );
};

SocialLogin.defaultProps = {
  className: "",
};
