// Modules
import React from "react";

// Hooks
import { usePrivateSession } from "../../../../hooks";

// Components
import {
  PrivateSessionList,
  ModalOverlay,
  Modal,
  Button,
  EmptyContent,
} from "../../../";

// Style
import "./private-session-modal-base.styles.scss";

export const PrivateSessionModalBase = ({ className }) => {
  const {
    dispatch,
    deletePrivateSession,
    isPrivateSessionLoading,
    privateSessionErrorMessage,
    privateSessions,
  } = usePrivateSession();

  /**
   * Delete A Private Session
   * @param {string} privateSessionId Private session id
   */
  const onHandleDeletePrivateSession = (privateSessionId) => {
    dispatch(deletePrivateSession(privateSessionId));
  };

  // Create PrivateSession Button
  const createPrivateSessionBtn = (
    <Button
      className="private-session-modal-base__btn btn--primary btn--sv8 btn--rv5 admin-layout__content-btn"
      to={`/admin/bookings/private-sessions/1`}
    >
      Create Private Session
    </Button>
  );

  return (
    <ModalOverlay
      className={`private-session-modal-base-overlay ${className}`}
      to="/admin/bookings"
    >
      <Modal className="private-session-modal-base">
        <EmptyContent
          className="private-session-modal-base__empty"
          isLoading={isPrivateSessionLoading}
          errorMessage={privateSessionErrorMessage}
          additionalErrorChildren={createPrivateSessionBtn}
        >
          <div className="private-session-modal-base__header">
            <h3 className="private-session-modal-base__title">
              Private Sessions
            </h3>

            {createPrivateSessionBtn}
          </div>

          <div className="private-session-modal-base__main">
            <PrivateSessionList
              className="private-session-modal-base__list"
              items={privateSessions}
              onDeletePrivateSession={onHandleDeletePrivateSession}
            />
          </div>
        </EmptyContent>
      </Modal>
    </ModalOverlay>
  );
};

PrivateSessionModalBase.defaultProps = {
  className: "",
};
