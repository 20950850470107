// Modules
import React from "react";

// Components
import {
  EmptyContent,
  Modal,
  ModalOverlay,
  StudentProfile,
  StudentPaidSubjectBase,
} from "../../..";

// Hooks
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStudent } from "../../../../hooks";

// Style
import "./student-profile-modal-base.styles.scss";

export const StudentProfileModalBase = ({ className }) => {
  const [student, setStudent] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const { isStudentLoading, students } = useStudent();
  const { studentId } = useParams();

  useEffect(() => {
    // Create Error Message
    const errorMessage = "No Student Available With The Provided Id.";

    // If Student Is Not Loading, Then Get Student With The Specified Id
    if (!isStudentLoading) {
      // Get Student
      const student = students.find((student) => student.id === studentId);

      // If Student Exist, Then Reset Error Message And Update Student In State
      if (student) {
        setErrorMessage("");
        setStudent(student);
      }

      // If Student Does Not Exist Then, Set Error Message
      if (!student) {
        setErrorMessage(errorMessage);
      }
    }
  }, [studentId, students]);

  return (
    <ModalOverlay
      className={`student-profile-modal-base-overlay ${className}`}
      to="/admin/students"
    >
      <Modal className="student-profile-modal-base">
        <EmptyContent
          className="student-profile-modal-base__empty"
          isLoading={isStudentLoading}
          errorMessage={errorMessage}
        >
          <div className="student-profile-modal-base__header">
            <h3 className="student-profile-modal-base__title">
              {student.firstName} {student.lastName} Profile Details
            </h3>
          </div>

          <div className="student-profile-modal-base__main">
            <StudentProfile
              className="student-profile-modal-base__profile"
              student={student}
            />

            <StudentPaidSubjectBase className="student-profile-modal-base__paid-subject-base" />
          </div>
        </EmptyContent>
      </Modal>
    </ModalOverlay>
  );
};

StudentProfileModalBase.defaultProps = {
  className: "",
};
