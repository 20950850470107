// Modules
import React from "react";

// Hooks
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { useSubject } from "../../../../../hooks";

// Components
import { ModalOverlay, Modal, EmptyContent, AddSubjectForm } from "../../../..";

// Utils
import { getFieldFromObject, getImageAsBase64 } from "../../../../../utils";

// Schemas
import { addSubjectSchema } from "../../../../../schemas";

// Style
import "./add-subject-modal-base.styles.scss";

export const AddSubjectModalBase = ({ className }) => {
  const { trainingId, levelId, subjectId } = useParams();
  const [errorMessage, setErrorMessage] = useState();
  const [subject, setSubject] = useState();
  const { dispatch, createSubject, updateSubject, isSubjectLoading, subjects } =
    useSubject();
  const isEditMode = subjectId !== "1";

  // Add Subject Formik
  const addSubjectFormik = useFormik({
    validationSchema: addSubjectSchema,
    initialValues: {
      thumbnailUrl: "https://placehold.jp/100?text=placeholder",
      thumbnail: "",
      name: "",
      tutorId: "",
      examDate: new Date(),
      onlinePrice: "",
      offlinePrice: "",
    },
    async onSubmit(values) {
      // Get Thumbnail Image As Base64
      const thumbnail = await getImageAsBase64(values.thumbnail);

      // Create Subject Data
      const subjectData = {
        ...getFieldFromObject(["name", "examDate", "tutorId"], values),
        price: {
          online: +values.onlinePrice,
          offline: +values.offlinePrice,
        },
        ...(thumbnail && { thumbnail }),
      };

      // If IsEditMode Is False, Then Create Subject, Else Update Subject
      if (!isEditMode) {
        await dispatch(createSubject(subjectData, trainingId, levelId));
      } else {
        await dispatch(
          updateSubject(subjectId, subjectData, trainingId, levelId)
        );
      }
    },
  });
  const { thumbnail, name, thumbnailUrl } = addSubjectFormik.values;

  useEffect(() => {
    // Create Error Message
    const errorMessage = "No Subject Available With The Provided Id.";

    // Get Subject If Subject Is Not Loading And Edit Mode Is True
    if (!isSubjectLoading && isEditMode) {
      // Get Subject By Subject Id
      const subject = subjects.find((subject) => subject.id === subjectId);

      // Set Error Message If Subject Is Undefined, Else Update Subject And Reset Error Message
      if (!subject) {
        setErrorMessage(errorMessage);
      } else {
        setErrorMessage("");
        setSubject(subject);
      }
    }
  }, [subjects, subjectId]);

  useEffect(() => {
    // If Subject Exist And Edit Mode Is True, Update Formik State
    if (subject && isEditMode) {
      const { name, thumbnail, tutorId, examDate, price } = subject;
      addSubjectFormik.setValues({
        thumbnailUrl: thumbnail?.url,
        thumbnail: thumbnail?.url,
        name,
        tutorId,
        examDate: new Date(examDate.toDate()),
        onlinePrice: price.online,
        offlinePrice: price.offline,
      });
    }
  }, [subject]);

  useEffect(() => {
    // If Thumbnail Is File Object Then Create Object Url
    thumbnail instanceof File &&
      addSubjectFormik.setFieldValue(
        "thumbnailUrl",
        URL.createObjectURL(thumbnail)
      );
  }, [thumbnail]);

  return (
    <ModalOverlay
      className={`add-subject-modal-base-overlay ${className}`}
      to={`/admin/trainings/${trainingId}/${levelId}/subjects`}
    >
      <Modal className="add-subject-modal-base">
        <EmptyContent
          className="add-subject-modal-base__empty"
          errorMessage={errorMessage}
          isLoading={isSubjectLoading && !addSubjectFormik.isSubmitting}
        >
          <img
            className="add-subject-modal__photo crop"
            src={thumbnailUrl}
            alt={name}
          />

          <AddSubjectForm
            className="add-modal-base__form"
            formik={addSubjectFormik}
            isEditMode={isEditMode}
          />
        </EmptyContent>
      </Modal>
    </ModalOverlay>
  );
};

AddSubjectModalBase.defaultProps = {
  className: "",
};
