// Hooks
import { useSelector, useDispatch } from "react-redux";

// Redux
import { contactUsSA } from "../store";

// Destructure Redux Imports
const { selectIsContactUsLoading } = contactUsSA;

// ContactUs Hook
export const useContactUs = () => {
  const dispatch = useDispatch();
  const isContactUsLoading = useSelector(selectIsContactUsLoading);

  return {
    isContactUsLoading,
    dispatch,
    ...contactUsSA,
  };
};
