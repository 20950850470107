// Modules
import React from "react";

// Components
import { LevelCard } from "../../../../";

// Style
import "./level-list.styles.scss";

export const LevelList = ({ className, items, onDeleteLevel }) => {
  // Level Items
  const renderedLevelItems = items.map((item) => (
    <LevelCard
      className="level-list__item"
      key={item.id}
      id={item.id}
      levelName={item.name}
      pricePerMonth={item.pricePerMonth.online}
      pricePerQuarter={item.pricePerQuarter.online}
      pricePerYear={item.pricePerYear.online}
      enableAction
      onDeleteLevel={onDeleteLevel}
    />
  ));

  return <div className={`level-list ${className}`}>{renderedLevelItems}</div>;
};

LevelList.defaultProps = {
  className: "",
};
