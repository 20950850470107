// Modules
import React from "react";

// Components
import { SubscriptionHistoryCard } from "../../..";

// Style
import "./subscription-history-list.styles.scss";

export const SubscriptionHistoryList = ({ className, items }) => {
  // Subscription History Items
  const renderedSubscriptionHistoryItems = items.map(
    ({
      id,
      updatedAt,
      subscriptionEndAt,
      amount,
      level,
      training,
      subscriptionType,
      studyMethod,
    }) => (
      <SubscriptionHistoryCard
        className="subscription-history-list__item"
        key={id}
        paidAt={updatedAt}
        renewalDate={subscriptionEndAt}
        subscriptionType={subscriptionType}
        amountPaid={amount}
        level={level}
        training={training}
        studyMethod={studyMethod}
      />
    )
  );

  return (
    <div className={`subscription-history-list ${className}`}>
      {renderedSubscriptionHistoryItems}
    </div>
  );
};

SubscriptionHistoryList.defaultProps = {
  className: "",
  items: [],
};
