// Modules
import React from "react";

// Components
import { LazyLoadImage } from "react-lazy-load-image-component";

// Images
import homeTestimonialImage from "../../../assets/images/home-testimonial-img.svg";
import homeTestimonialImageLazy from "../../../assets/images/home-testimonial-img-lazy.jpg";

// Style
import "./home-testimonial-base.styles.scss";

export const HomeTestimonialBase = ({ className }) => {
  return (
    <div className={`home-testimonial-base ${className}`}>
      <div className="home-testimonial-base__container container">
        <h2 className="home-testimonial-base__heading primary-heading is-text-secondary-color">
          What They Say?
        </h2>

        <figure className="home-testimonial-base__main">
          <LazyLoadImage
            className="home-testimonial-base__img"
            src={homeTestimonialImage}
            placeholderSrc={homeTestimonialImageLazy}
            wrapperClassName="home-testimonial-base__img--lazy"
            alt="Kaja Megane Testimonial"
          />

          <figcaption className="home-testimonial-base__content">
            <p className="home-testimonial-base__author-name">Kaja Megane</p>
            <q className="home-testimonial-base__author-testimonial">
              For me, joining The Finacco Hub to pursue my ACCA journey was one
              of the best decisions I took because I never regretted it with the
              high quality tutoring and the conducisive learning environment
              they provided me with
            </q>
            <span className="home-testimonial-base__author-testimonial-end">
              Thank you!
            </span>
          </figcaption>
        </figure>
      </div>
    </div>
  );
};

HomeTestimonialBase.defaultProps = {
  className: "",
};
