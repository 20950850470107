// Modules
import React from "react";

// Style
import "./paper.styles.scss";

export const Paper = ({ className, title, children }) => {
  return (
    <div className={`paper ${className} container`}>
      {!!title && <h3 className="paper__title">{title}</h3>}
      <div className="paper__container">{children}</div>
    </div>
  );
};

Paper.defaultProps = {
  className: "",
};
