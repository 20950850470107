// Modules
import React from "react";

// Components
import { Button } from "../..";

// Style
import "./accounting-consultancy-hero.styles.scss";

export const AccountingConsultancyHero = ({ className }) => {
  return (
    <div className={`accounting-consultancy-hero secondary-hero ${className}`}>
      <div className="accounting-consultancy-hero__container secondary-hero__container container">
        <h1 className="accounting-consultancy-hero__heading primary-heading secondary-hero__heading is-text-accent-300 container">
          Investment &{" "}
          <span className="is-text-accent-400">Accounting Consultancy</span>
        </h1>

        <p className="accounting-consultancy-hero__description secondary-hero__description">
          With The FinAcco Hub you can outsource your whole Financial
          Department!
        </p>

        <Button
          className="accounting-consultancy-hero__btn secondary-hero__btn btn--primary btn--rv3 btn--sv6"
          to="/?s=contact-base"
        >
          Contact Us
        </Button>
      </div>
    </div>
  );
};

AccountingConsultancyHero.defaultProps = {
  className: "",
};
