// Modules
import React from "react";

// Hooks
import { useState, useEffect } from "react";

// Components
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Oval } from "react-loader-spinner";

// Style
import "./input-select.styles.scss";

export const InputSelect = ({
  className,
  wrapperClassName,
  labelClassName,
  name,
  value,
  placeholder,
  items,
  error,
  touched,
  label,
  useKey,
  secondary,
  tertiary,
  DropdownIconComponent,
  isLoading,
  loadingColor,
  fetchError,
  onChange,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    // Close Select Dropdown If User Click Outside Of select Dropdown
    const closeSelectDropdown = (e) => {
      const target = e.target;
      const selectGroupElm = target?.closest(".form__select-group");
      if (selectGroupElm) return;
      setIsDropdownOpen(false);
    };

    // Add Event Listener To Body
    document.body.addEventListener("click", closeSelectDropdown);

    // Remove Event Listener On Component UnMount
    return () => {
      document.body.removeEventListener("click", closeSelectDropdown);
    };
  }, []);

  /**
   * Toggle Dropdown Visibility
   */
  const toggleDropdownVisibility = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  /**
   * Handle Select Input Change In Value
   * @param {object} e Event object
   */
  const onHandleSelectChange = (e) => {
    const targetClasses = e.target.classList;
    if (!targetClasses.contains("form__select-dropdown-item")) return;
    const value = useKey ? e.target.dataset.key : e.target.textContent;
    onChange(name, value);
  };

  /**
   * Handle Click On Select Form Group
   * @param {object} e Event object
   */
  const onHandleSelectGroupClick = (e) => {
    toggleDropdownVisibility();
    onHandleSelectChange(e);
  };

  /**
   * Get Input Value
   * @param {string} value Input value
   */
  const getValue = (value) => {
    if (!useKey) {
      return value;
    }
    return items.find((item) => item.id === value)?.type;
  };

  // Rendered Select Options
  const renderedSelectOptions = items.map((item) => (
    <li
      className={`form__select-dropdown-item ${
        value === item.type || value === item.id
          ? "form__select-dropdown-item--selected"
          : ""
      }`}
      data-key={item.id}
      key={item.id}
    >
      {item.type}
    </li>
  ));

  // Rendered Loading Spinner
  const renderedLoadingSpinner = (
    <Oval
      height={32}
      width={32}
      color={loadingColor}
      wrapperClass="form__select-loading"
      visible={isLoading}
      ariaLabel="select data loading"
      secondaryColor="#"
      strokeWidth={4}
      strokeWidthSecondary={2}
    />
  );

  return (
    <div
      className={`form__group form__group--select-input ${wrapperClassName} ${
        tertiary ? "form__group--tertiary" : ""
      } ${secondary ? "form__group--secondary" : ""}`}
    >
      {!!label && (
        <label className={`form__label ${labelClassName}`}>{label}</label>
      )}

      <div
        className={`form__select-group ${className} ${
          error && touched ? "form__select-group--error" : ""
        }`}
        onClick={onHandleSelectGroupClick}
      >
        <p
          className={`form__select-selected ${
            value ? "is-text-dark-color" : ""
          }`}
        >
          {getValue(value) || placeholder}
        </p>

        <span className="form__select-dropdown-icon-wrapper">
          <DropdownIconComponent
            className={`form__select-dropdown-icon ${
              isDropdownOpen && "form__select-dropdown-icon--active"
            }`}
            aria-label={`${name} dropdown visibility icon`}
          />
        </span>

        <div
          className={`form__select-dropdown ${
            isDropdownOpen ? "form__select-dropdown--active" : ""
          }`}
        >
          {isLoading && renderedLoadingSpinner}

          {!isLoading && !!fetchError && (
            <p className="form__select-fetch-error">{fetchError}</p>
          )}

          {!isLoading && !fetchError && (
            <ul className="form__select-dropdown-list">
              {renderedSelectOptions}
            </ul>
          )}
        </div>
      </div>

      {touched && error && (
        <div className="form__error-wrapper">
          <p className="form__error">{error}</p>
        </div>
      )}
    </div>
  );
};

InputSelect.defaultProps = {
  className: "",
  labelClassName: "",
  wrapperClassName: "",
  touched: false,
  tertiary: false,
  secondary: false,
  isLoading: false,
  useKey: false,
  loadingColor: "#f9a22f",
  items: [],
  DropdownIconComponent: MdOutlineKeyboardArrowDown,
};
