// Modules
import React from "react";

// Hooks
import { useState, useEffect } from "react";

// Components
import Calendar from "react-calendar";
import { IconButton } from "@mui/material";
import { MdDelete, MdCalendarToday } from "react-icons/md";

// Style
import "react-calendar/dist/Calendar.css";
import "./booking-date-card.styles.scss";

export const BookingDateCard = ({
  className,
  dateIndex,
  value,
  name,
  availabilityDates,
  onAvailabilityDatesChange,
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  // Get Date As Object
  const dateObj = new Date(value);

  // Human Readable Date
  const humanReadableDate = dateObj.toString("dd-MM-yyyy");

  useEffect(() => {
    /**
     * Close Calendar Visibility
     * @param {Event} event Event
     */
    const closeCalendarVisibility = (event) => {
      // Get Booking Date Card Element From The Target
      const bookingDateCardEl = event.target.closest(".booking-date-card");

      // If Booking Date Card Element Exist, Then Return Function
      if (bookingDateCardEl) return;

      // Else Close Calendar Visibility
      setIsCalendarOpen(false);
    };

    // Attach Event Listener To The Body Element
    document.body.addEventListener("click", closeCalendarVisibility);

    // Remove Event Listener When Page Un-Mount
    return () =>
      document.body.removeEventListener("click", closeCalendarVisibility);
  }, []);

  /**
   * Handle Delete Date Using Date Index
   */
  const onHandleDeleteDate = () => {
    // Remove Date From availabilityDates
    const newDates = availabilityDates.filter(
      (_, index) => index !== dateIndex
    );

    // Update State
    onAvailabilityDatesChange(name, newDates);
  };

  /**
   * Handle Date Change
   * @param {Date} dateValue Date value
   */
  const onHandleDateChange = (dateValue) => {
    // Get Date Object
    const date = availabilityDates[dateIndex];

    // Update Date Value
    date.date = dateValue.getTime();

    // Update State
    onAvailabilityDatesChange(name, availabilityDates);

    // Close Calender Visibility
    setIsCalendarOpen(false);
  };

  /**
   * Handle Toggle Calendar Visibility
   */
  const toggleCalendarVisibility = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  return (
    <div className={`booking-date-card ${className}`}>
      <div className="booking-date-card__container">
        <div className="booking-date-card__left">
          <div
            className="booking-date-card__card"
            onClick={toggleCalendarVisibility}
          >
            <MdCalendarToday className="booking-date-card__calendar-icon" />
            <span className="booking-date-card__date-text">
              {humanReadableDate}
            </span>
          </div>

          <div className="booking-date-card__calendar-dropdown">
            {isCalendarOpen && (
              <Calendar
                className="booking-date-card__calendar"
                value={dateObj}
                onChange={onHandleDateChange}
              />
            )}
          </div>
        </div>

        <div className="booking-date-card__right">
          <IconButton
            className="booking-date-card__icon-btn"
            arial-label="delete date"
            onClick={onHandleDeleteDate}
          >
            <MdDelete
              className="booking-date-card__delete-icon"
              title="Delete Date"
            />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

BookingDateCard.defaultProps = {
  className: "",
  availabilityDates: [],
};
