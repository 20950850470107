// Modules
import React from "react";

// Hooks
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useLevel } from "../../../../../hooks";

// Components
import { ModalOverlay, Modal, EmptyContent, AddLevelForm } from "../../../../";

// Schemas
import { addLevelSchema } from "../../../../../schemas";

// Style
import "./add-level-modal-base.styles.scss";

export const AddLevelModalBase = ({ className }) => {
  const { trainingId, levelId } = useParams();
  const [level, setLevel] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const { isLevelLoading, levels, createLevel, updateLevel, dispatch } =
    useLevel();
  const isEditMode = levelId !== "1";

  const addLevelFormik = useFormik({
    validationSchema: addLevelSchema,
    initialValues: {
      name: "",
      pricePerMonthOnline: "",
      pricePerMonthOffline: "",
      pricePerQuarterOnline: "",
      pricePerQuarterOffline: "",
      pricePerYearOnline: "",
      pricePerYearOffline: "",
    },
    async onSubmit(values) {
      // Create Level Data
      const {
        pricePerMonthOnline,
        pricePerMonthOffline,
        pricePerQuarterOffline,
        pricePerQuarterOnline,
        pricePerYearOffline,
        pricePerYearOnline,
        name,
      } = values;

      const levelData = {
        name,
        pricePerMonth: {
          online: +pricePerMonthOnline,
          offline: +pricePerMonthOffline,
        },
        pricePerQuarter: {
          online: +pricePerQuarterOnline,
          offline: +pricePerQuarterOffline,
        },
        pricePerYear: {
          online: +pricePerYearOnline,
          offline: +pricePerYearOffline,
        },
      };

      // If IsEditMode Is False, Then Create A New Level, Else Update Level
      if (!isEditMode) {
        await dispatch(createLevel(levelData, trainingId));
      } else {
        await dispatch(updateLevel(levelId, levelData, trainingId));
      }
    },
  });

  useEffect(() => {
    // Create Error Message
    const errorMessage = "No Level Available With The Provided Id.";

    // Get Level If Level Is Not Loading And Edit Mode Is True
    if (!isLevelLoading && isEditMode) {
      // Get Level By Level Id
      const level = levels.find((level) => level.id === levelId);

      // Set Error Message If Level Is Undefined, Else Update Level And Reset Error Message
      if (!level) {
        setErrorMessage(errorMessage);
      } else {
        setErrorMessage("");
        setLevel(level);
      }
    }
  }, [levels, levelId]);

  useEffect(() => {
    // If Level Exist And Edit Mode Is True, Update Formik State
    if (level && isEditMode) {
      const { name, pricePerMonth, pricePerQuarter, pricePerYear } = level;
      addLevelFormik.setValues({
        name,
        pricePerMonthOnline: pricePerMonth.online,
        pricePerMonthOffline: pricePerMonth.offline,
        pricePerQuarterOnline: pricePerQuarter.online,
        pricePerQuarterOffline: pricePerQuarter.offline,
        pricePerYearOnline: pricePerYear.online,
        pricePerYearOffline: pricePerYear.offline,
      });
    }
  }, [level]);

  return (
    <ModalOverlay
      className={`add-level-modal-base-overlay ${className}`}
      to={`/admin/trainings/${trainingId}/levels`}
    >
      <Modal className="add-level-modal-base">
        <EmptyContent
          className="add-level-modal-base__empty"
          isLoading={!addLevelFormik.isSubmitting && isLevelLoading}
          errorMessage={errorMessage}
        >
          <AddLevelForm
            className="add-level-modal-base__form"
            formik={addLevelFormik}
            isEditMode={isEditMode}
          />
        </EmptyContent>
      </Modal>
    </ModalOverlay>
  );
};

AddLevelModalBase.defaultProps = {
  className: "",
};
