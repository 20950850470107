// Modules
import React from "react";

// Components
import { IconButton } from "@mui/material";
import { MdAdd } from "react-icons/md";
import { BookingDateCard, BookingTimeCard } from "../../../../../";

// Style
import "./booking-date-list.styles.scss";

export const BookingDateList = ({
  className,
  name,
  availabilityDates,
  onAvailabilityDatesChange,
}) => {
  /**
   * Handle Add Time To A Date
   * @param {number} dateIndex Date index
   */
  const onHandleAddDateTime = (dateIndex) => {
    // Get Date Object
    const date = availabilityDates[dateIndex];

    // Get Current Time
    const currentTime = new Date().clearTime().getTime();

    // Add Time To The Date
    date.times.push({ start: currentTime, end: currentTime });

    // Update State
    onAvailabilityDatesChange(name, availabilityDates);
  };

  // Date Card Items
  const renderedDateCardItems = availabilityDates.map(
    (availabilityDate, index) => {
      // Time Card Items
      const renderedTimeCardItems = availabilityDate.times.map(
        (time, timeIndex) => (
          <BookingTimeCard
            className="booking-date-list__time-card"
            key={timeIndex}
            dateIndex={index}
            timeIndex={timeIndex}
            name={name}
            value={time}
            availabilityDates={availabilityDates}
            onAvailabilityDatesChange={onAvailabilityDatesChange}
          />
        )
      );

      return (
        <div className="booking-date-list__item" key={index}>
          <BookingDateCard
            className="booking-date-list__date-card"
            dateIndex={index}
            name={name}
            value={availabilityDate.date}
            availabilityDates={availabilityDates}
            onAvailabilityDatesChange={onAvailabilityDatesChange}
          />

          {renderedTimeCardItems}

          <div className="booking-date-list__btn-right">
            <div className="booking-date-list__btn-group">
              <IconButton
                className="booking-date-list__icon-btn"
                arial-label="add date time"
                onClick={onHandleAddDateTime.bind(null, index)}
              >
                <MdAdd className="booking-date-list__icon" title="add time" />
              </IconButton>
              <span className="booking-date-list__btn-text">Add Time</span>
            </div>
          </div>
        </div>
      );
    }
  );

  return (
    <div className={`booking-date-list ${className}`}>
      {renderedDateCardItems}
    </div>
  );
};

BookingDateList.defaultProps = {
  className: "",
  availabilityDates: [],
};
