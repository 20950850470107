// Modules
import { createSlice } from "@reduxjs/toolkit";

// Common Reducers
import { loadingReducers, errorMessageReducers } from "../common";

// Utils
import { stateSelector } from "../../utils";

// Initial State
const initialState = {
  isLoading: false,
  errorMessage: "",
  recurrentSubscriptions: [],
  payPerSubjectSubscriptions: [],
  subscriptionHistories: [],
};

// Slice Name
const sliceName = "subscription";

// Subscription Slice
const subscriptionSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ...loadingReducers,
    ...errorMessageReducers,
    setRecurrentSubscriptions(state, action) {
      state.recurrentSubscriptions = action.payload;
    },
    setPayPerSubjectSubscriptions(state, action) {
      state.payPerSubjectSubscriptions = action.payload;
    },
    setSubscriptionHistories(state, action) {
      state.subscriptionHistories = action.payload;
    },
    resetRecurrentSubscriptions(state) {
      state.recurrentSubscriptions = [];
    },
    resetPayPerSubjectSubscriptions(state) {
      state.payPerSubjectSubscriptions = [];
    },
    resetSubscriptionHistories(state) {
      state.subscriptionHistories = [];
    },
  },
});

export const {
  setPayPerSubjectSubscriptions,
  setRecurrentSubscriptions,
  resetPayPerSubjectSubscriptions,
  resetRecurrentSubscriptions,
  startLoading: subscriptionStartLoading,
  stopLoading: subscriptionStopLoading,
  setErrorMessage: setSubscriptionErrorMessage,
  resetErrorMessage: resetSubscriptionErrorMessage,
  setSubscriptionHistories,
  resetSubscriptionHistories,
} = subscriptionSlice.actions;
export * from "./subscriptionAction";
export const selectRecurrentSubscriptions = stateSelector(
  sliceName,
  "recurrentSubscriptions"
);
export const selectPayPerSubjectSubscriptions = stateSelector(
  sliceName,
  "payPerSubjectSubscriptions"
);
export const selectIsSubscriptionLoading = stateSelector(
  sliceName,
  "isLoading"
);
export const selectSubscriptionErrorMessage = stateSelector(
  sliceName,
  "errorMessage"
);
export const selectSubscriptionHistories = stateSelector(
  sliceName,
  "subscriptionHistories"
);
export default subscriptionSlice.reducer;
