// Modules
import React from "react";

// Images
import onlineIcon from "../../../assets/images/online-icon.svg";

// Hooks
import { useNavigate } from "react-router-dom";
import { useAuth, useUi } from "../../../hooks";

// Utils
import { getProfileImage } from "../../../utils";

// Style
import "./admin-navbar.styles.scss";

export const AdminNavbar = ({ className }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { pageTitle } = useUi();

  return (
    <div className={`admin-navbar ${className}`}>
      <div className="admin-navbar__container">
        <div className="admin-navbar__left">
          <p className="admin-navbar__heading">{pageTitle}</p>
          <p className="admin-navbar__subheading">Statistics</p>
        </div>

        <div
          className="admin-navbar__right is-cursor-pointer"
          onClick={navigate.bind(null, "/admin/staffs")}
        >
          <p className="admin-navbar__profile">
            <strong className="admin-navbar__profile-name d-block">
              {user?.firstName}
            </strong>
            <span className="admin-navbar__profile-role">Admin</span>
          </p>

          <div className="admin-navbar__profile-img-wrapper">
            <img
              className="admin-navbar__profile-img"
              src={getProfileImage(user?.avatar?.url, 53, 53)}
              alt={user?.firstName}
            />

            <img
              className="admin-navbar__profile-img-online"
              src={onlineIcon}
              alt="online"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

AdminNavbar.defaultProps = {
  className: "",
};
