// Modules
import React from "react";

// Hooks
import { useLevel } from "../../../hooks";

// Components
import { Button } from "../../";

// Style
import "./professional-training-detail-hero.styles.scss";

export const ProfessionalTrainingDetailHero = ({ className }) => {
  const { levels } = useLevel();
  const training = levels[0]?.training;

  return (
    <div
      className={`professional-training-detail-hero secondary-hero ${className}`}
    >
      <div className="professional-training-detail-hero__container container secondary-hero__container">
        <h1 className="professional-training-detail-hero__heading primary-heading secondary-hero__heading is-text-accent-300">
          {training?.title}
        </h1>
        <p className="professional-training-detail-hero__description secondary-hero__description">
          {training?.summary}
        </p>
        <div className="professional-training-detail__btn-group secondary-hero__btn-group">
          <Button
            className="professional-training-detail__btn secondary-hero__btn btn--primary btn--rv3 btn--sv6"
            to={`/pricing/${training?.id}?studyMethod=online`}
          >
            Apply for Online Class
          </Button>
          <Button
            className="professional-training-detail__btn secondary-hero__btn btn--primary btn--rv3 btn--sv6"
            to={`/pricing/${training?.id}?studyMethod=offline`}
          >
            Apply for physical Class
          </Button>
        </div>
      </div>
    </div>
  );
};

ProfessionalTrainingDetailHero.defaultProps = {
  className: "",
};
