// Modules
import React from "react";

// Components
import { Fragment } from "react";
import {
  Button,
  Dialog as DeleteConfirmationDialog,
  MoreAction,
  MoreActionDropdownItem,
} from "../..";

// Hooks
import { useDelete, useUi } from "../../../hooks";

// Style
import "./private-session-card.styles.scss";

export const PrivateSessionCard = ({
  className,
  sessionId,
  sessionName,
  sessionPrice,
  sessionDescription,
  enableAction,
  onDeletePrivateSession,
}) => {
  const {
    deleteCloseActionText,
    deleteConfirmActionText,
    isDeleteDialogOpen,
    onHandleCloseDeleteDialog,
    onHandleDeleteConfirm,
    onHandleOpenDeleteDialog,
  } = useDelete(onHandlePrivateSessionDelete);
  const { showNotification, dispatch } = useUi();

  /**
   * Handle Delete Private Session
   */
  function onHandlePrivateSessionDelete() {
    onDeletePrivateSession(sessionId);
  }

  return (
    <Fragment>
      <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        dialogTitle="Confirm Delete Private Session"
        dialogDescription="You are about to delete private session data. Confirm with yes to continue"
        closeActionText={deleteCloseActionText}
        confirmActionText={deleteConfirmActionText}
        onClose={onHandleCloseDeleteDialog}
        onConfirm={onHandleDeleteConfirm}
      />

      <div className={`private-session-card ${className}`}>
        <div className="private-session-card__header">
          <p className="private-session-card__name">{sessionName}</p>

          {!!sessionPrice && (
            <p className="private-session-card__price">${sessionPrice}/hr</p>
          )}
        </div>

        <div className="private-session-card__main">
          <p className="private-session-card__description">
            {sessionDescription}
          </p>

          {!!sessionId && !enableAction && (
            <Button
              className="private-session-card__btn btn--primary btn--sv12 btn--rv1"
              to={`/student/private-sessions/booking/${sessionId}`}
            >
              Book Now
            </Button>
          )}
        </div>

        {enableAction && (
          <MoreAction className="private-session-card__action">
            <MoreActionDropdownItem
              className="private-session-card__action-item"
              to={`/admin/bookings/private-sessions/${sessionId}`}
            >
              Edit
            </MoreActionDropdownItem>

            <MoreActionDropdownItem
              className="private-session-card__action-item"
              onClick={onHandleOpenDeleteDialog}
            >
              Delete
            </MoreActionDropdownItem>
          </MoreAction>
        )}
      </div>
    </Fragment>
  );
};

PrivateSessionCard.defaultProps = {
  className: "",
};
