// Modules
import generateUniqueId from "generate-unique-id";
import { toast } from "react-toastify";

// Firebase
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  signInWithPopup,
  updateEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import {
  doc,
  setDoc,
  getDoc,
  Timestamp,
  updateDoc,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";
import {
  auth,
  userCollectionRef,
  dataFromSnapshot,
  googleProvider,
  facebookProvider,
  sendUserEmail,
  uploadUserAvatar,
} from "../../utils";

// Apis
import * as apis from "../";

// Utils
import { AppError } from "../../utils";

/**
 * Handle Login Error
 * @param {object} err Error
 */
const handleLoginError = async (err) => {
  switch (err.code) {
    case "auth/wrong-password":
    case "auth/user-not-found":
      // Throw Custom Error
      throw new AppError("auth/wrong-credential");
    case "auth/too-many-requests":
      // Throw Custom Error
      throw new AppError("auth/many-auth-attempt");
    default:
      // Re-Throw Error
      throw await AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Get Common Signup Data
 * @param {object} user User
 */
const getCommonSignupData = async (user) => {
  try {
    // Generate Student Id
    const studentId = generateUniqueId({ length: 8 }).toUpperCase();

    // Get User Ip Info
    const userIpInfo = await apis.getUserIp();

    // Create Common Signup Data
    const commonSignupData = {
      uid: user.uid,
      createdAt: Timestamp.fromDate(new Date(user.metadata.creationTime)),
      updatedAt: Timestamp.fromDate(new Date(user.metadata.creationTime)),
      role: 1,
      studentId,
      isActive: true,
      country: userIpInfo?.location?.country?.name || "unknown",
      ipAddress: userIpInfo?.ip || "unknown",
      city: userIpInfo?.location?.city || "unknown",
      password: null,
      passwordConfirm: null,
    };

    // Return CommonSignupData
    return commonSignupData;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get User Profile
 * @param {string} uid User id
 */
export const getUserProfile = async (uid) => {
  try {
    // User Doc Reference
    const userRef = doc(userCollectionRef, uid);

    // Get Profile
    const querySnapshot = await getDoc(userRef);

    // Throw Error If User Does Not Exist
    if (!querySnapshot.exists())
      throw await AppError.checkNetworkConnectivityAndThrowError(
        "auth/wrong-credential"
      );

    // Get User Profile
    const userProfile = dataFromSnapshot(querySnapshot);

    // Throw Error If User Exist And IsActive Is False
    if (!userProfile.isActive) throw new AppError("auth/user-disabled");

    // Return User Profile
    return userProfile;
  } catch (err) {
    // Re-Throw Error
    throw await AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Send User A Verification Link To Email
 */
export const sendVerificationEmail = async () => {
  try {
    // Send Verification Email To User
    const userProfile = (await sendUserEmail(null, "accountVerification")).data;

    // Return User Profile
    return userProfile;
  } catch (err) {
    if (err.code === "functions/unknown") {
      throw new AppError("sendVerificationEmail/many-attempt");
    }
    // Re-Throw Error
    throw await AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Create A New User
 * @param {object} userData User data
 */
export const createUser = async (userData) => {
  try {
    // Get Id, IsVerified And Email From User Data
    const { uid, isVerified } = userData;

    // User Doc Reference
    const userRef = doc(userCollectionRef, uid);

    // Create The New User
    await setDoc(userRef, userData);

    // Perform User Initial Auth Setup
    const userProfile = await performUserInitSetup(uid);

    // If User Is Not Verified, Then Send User Verification Email
    !isVerified && (await sendVerificationEmail());

    // Send Welcome Email To User
    await sendUserEmail(null, "welcome");

    // Return User Profile
    return userProfile;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Login User With Email And Password
 * @param {object} userData Login form data
 */
export const loginUser = async ({ email, password }) => {
  try {
    // Login User
    const { user } = await signInWithEmailAndPassword(auth, email, password);

    // Get User Profile
    const userProfile = await getUserProfile(user.uid);

    // Return User Profile
    return userProfile;
  } catch (err) {
    // Re-Throw Error Based On Error Code
    await handleLoginError(err);
  }
};

/**
 * Sign-In User With Google OAuth Provider
 */
export const signInWithGoogle = async () => {
  try {
    // SignUp/SignIn User With Google Provider Using Popup
    const userCredential = await signInWithPopup(auth, googleProvider);
    const { user } = userCredential;

    // Get User Additional Info
    const { profile, isNewUser } = getAdditionalUserInfo(userCredential);

    // Get User Profile
    let userProfile;

    // If User Is New Then Add User To Cloud Firestore
    if (isNewUser) {
      // Get Common Signup Data
      const commonSignupData = await getCommonSignupData(user);

      // Create Signup Data
      const signupData = {
        firstName: profile.given_name,
        lastName: profile.family_name,
        email: profile.email,
        signupMedium: "google",
        isVerified: true,
        ...commonSignupData,
      };

      // Create User
      userProfile = await createUser(signupData);
    }

    // Get User Profile
    userProfile = !userProfile ? await getUserProfile(user.uid) : userProfile;

    // Return User Profile And IsNewUser
    return { userProfile, isNewUser };
  } catch (err) {
    // Re-Throw Error
    throw await AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Sign-In User With Facebook OAuth Provider
 */
export const signInWithFacebook = async () => {
  try {
    // SignUp/SignIn User With Facebook Provider Using Popup
    const userCredential = await signInWithPopup(auth, facebookProvider);
    const { user } = userCredential;

    // Get User Info
    const { profile, isNewUser } = getAdditionalUserInfo(userCredential);

    // Throw Error If User Does Not Have Email On Their Facebook Account
    if (!profile.email) {
      throw new AppError("auth/facebook-email");
    }

    // Get User Profile
    let userProfile;

    // If User Is New Then Add User To Cloud Firestore
    if (isNewUser) {
      // Get Common Signup Data
      const commonSignupData = await getCommonSignupData(user);

      // Create Signup Data
      const signupData = {
        firstName: profile.first_name,
        lastName: profile.last_name,
        email: profile.email,
        signupMedium: "facebook",
        isVerified: user.emailVerified,
        ...commonSignupData,
      };

      // Create User
      userProfile = await createUser(signupData);
    }

    // Get User Profile
    userProfile = !userProfile ? await getUserProfile(user.uid) : userProfile;

    // Return User Profile And IsNewUser
    return { userProfile, isNewUser };
  } catch (err) {
    // Re-Throw Error
    throw await AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Signup User With Email And Password
 * @param {object} userData User data
 */
export const signUpUser = async ({ email, password, ...restUserData }) => {
  try {
    // Signup User
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    // Get Common Signup Data
    const commonSignupData = await getCommonSignupData(user);

    // Create Signup Data
    const signupData = {
      email,
      password,
      ...restUserData,
      signupMedium: "password",
      isVerified: false,
      ...commonSignupData,
    };

    // Create User In Firestore
    const userProfile = await createUser(signupData);

    // Return User Profile
    return userProfile;
  } catch (err) {
    // Re-Throw Error
    throw await AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Send User A Forgot Password Link To Email
 * @param {string} email User email
 */
export const sendForgotPasswordEmail = async (email) => {
  try {
    // Send Password Reset Email To User
    await sendUserEmail(email, "passwordReset");
  } catch (err) {
    // Re-Throw Error
    throw await AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Perform User Authentication Initial Setup
 * @param {string} uid User id
 */
export const performUserInitSetup = async (uid) => {
  // Update User Last Login Device Id
  await updateUserLastLoginDeviceId(uid);

  // Update Last Active
  await updateUserLastActive(uid);

  // Perform Auth Syncing With User In Firestore
  const userProfile = await syncAuthStateToUser();

  // Return User Profile
  return userProfile;
};

/**
 * Initialize Authentication
 */
export const initAuth = () => {
  return new Promise((resolve) => {
    /**
     * Auth State Changed Callback
     * @param {object|null} user Auth user
     */
    const authCallback = async (user) => {
      // If User Does Not Exist
      if (!user) {
        // Resolve Promise With Null
        return resolve(null);
      }

      // Perform User Initial Auth Setup
      const userProfile = await performUserInitSetup(user.uid);

      // Resolve Promise With User Profile
      resolve(userProfile);
    };

    // Handle Auth State Change
    onAuthStateChanged(auth, authCallback);
  });
};

/**
 * Logout Authenticated User
 */
export const logoutUser = async () => {
  try {
    // Logout User
    await signOut(auth);
  } catch (err) {
    // Re-Throw Error
    throw await AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Update User Profile
 * @param {string} uid User id
 * @param {object} newProfile New profile data
 * @param {boolean} emailChanged If true perform email update and verification
 */
export const updateProfile = async (uid, newProfile, emailChanged) => {
  try {
    // User Doc Reference
    const userRef = doc(userCollectionRef, uid);

    // Update User Profile
    await updateDoc(userRef, { ...newProfile, updatedAt: serverTimestamp() });

    // If User Email Changed Then Update Auth Email
    if (emailChanged) {
      // Update Email
      await updateEmail(auth.currentUser, newProfile.email);

      // Send User Verification Email
      await sendVerificationEmail();
    }

    // Get User Profile
    const userProfile = await getUserProfile(uid);

    // Return User Profile
    return userProfile;
  } catch (err) {
    // Re-Throw Error
    throw await AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Update User Profile Image
 * @param {object|string} avatar Avatar url(base64)
 */
export const updateProfileImage = async (avatar) => {
  try {
    // Upload User Avatar
    const userProfile = (await uploadUserAvatar(avatar)).data;

    // Return User Profile
    return userProfile;
  } catch (err) {
    // Re-Throw Error
    throw await AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Re-Authenticate User
 * @param {object} userData User auth data
 */
export const reauthenticateUser = async ({ email, password }) => {
  try {
    // Create New Login Credential
    const credential = EmailAuthProvider.credential(email, password);

    // Re-Authenticate User
    const { user } = await reauthenticateWithCredential(
      auth.currentUser,
      credential
    );

    // Get User Profile
    const userProfile = await getUserProfile(user.uid);

    // Return User Profile
    return userProfile;
  } catch (err) {
    // Re-Throw Error Based On Error Code
    await handleLoginError(err);
  }
};

/**
 * Sync Auth State To User In Firestore
 */
export const syncAuthStateToUser = async () => {
  try {
    // Get Auth User
    const authUser = auth.currentUser;

    // Get User Profile
    let userProfile = await getUserProfile(authUser.uid);

    // Check If User Profile Email, IsVerified, And LastLogin Is Different From Auth Snapshot, Then Update User With New State
    let newState = {};

    // Add Email To State If Email Is Different From Auth Email
    if (authUser.email !== userProfile.email) {
      newState = { ...newState, email: authUser.email };
    }

    // Add EmailVerified To State If EmailVerified Is Different From Auth EmailVerified
    if (authUser.emailVerified !== userProfile.isVerified) {
      newState = { ...newState, isVerified: authUser.emailVerified };
    }

    // Add LastLogin To State If LastLogin Is Different From Auth LastLogin
    if (
      new Date(authUser.metadata.lastSignInTime).toISOString() !==
      userProfile.lastLogin
    ) {
      newState = {
        ...newState,
        lastLogin: Timestamp.fromDate(
          new Date(authUser.metadata.lastSignInTime)
        ),
      };
    }

    // If NewState Has Properties Then Update User Profile Else Return User Profile
    userProfile = Object.keys(newState).length
      ? await updateProfile(authUser.uid, newState)
      : userProfile;

    // Return User Profile
    return userProfile;
  } catch (err) {
    // Re-Throw Error
    throw await AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Update User Last Active At
 * @param {string} userId User id
 */
export const updateUserLastActive = async (userId) => {
  try {
    // Create LastActive Data
    const lastActiveData = {
      lastActiveAt: serverTimestamp(),
    };

    // Update User Last Active
    const userProfile = await updateProfile(userId, lastActiveData);

    // Return User Profile
    return userProfile;
  } catch (err) {
    // Re-Throw Error
    throw await AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Update Last Login Device Id For A User
 * @param {string} userId User id
 */
export const updateUserLastLoginDeviceId = async (userId) => {
  try {
    // Get User Device Id
    let deviceId = localStorage.getItem("deviceId");

    // If User Does Not Have A Device Id
    if (!deviceId) {
      // Create A New Device Id
      deviceId = generateUniqueId({ length: 45, useNumbers: true });

      // Store It In The Localstorage
      localStorage.setItem("deviceId", deviceId);
    }

    // Update User Last Login Device Id
    const userProfile = await updateProfile(userId, {
      lastLoginDeviceId: deviceId,
    });

    // Return User Profile
    return userProfile;
  } catch (err) {
    // Re-Throw Error
    throw await AppError.checkNetworkConnectivityAndThrowError(err);
  }
};

/**
 * Enforce Single Device Active At A Time For A User
 * @param {string} userId User id
 * @param {function} signOut Signout function
 */
export const enforceSingleActiveDevice = (userId, signOut) => {
  // User Document Reference
  const userRef = doc(userCollectionRef, userId);

  return new Promise((resolve) => {
    const userSubscription = onSnapshot(userRef, (userSnapshot) => {
      // Get User Device Id
      const deviceId = localStorage.getItem("deviceId");

      // Get User Last Login Device Id
      const lastLoginDeviceId =
        dataFromSnapshot(userSnapshot).lastLoginDeviceId;

      // If User Device Id Does Not Match Last Login Device Id
      if (deviceId !== lastLoginDeviceId) {
        // Logout User
        signOut();

        // Toast Notification On Logging Out
        toast.info(
          new AppError("auth/autoLogoutOnInvalidDeviceId", true).message.message
        );
      }

      // Resolve Promise With User Subscription
      resolve(userSubscription);
    });
  });
};
