// Hooks
import { useSelector, useDispatch } from "react-redux";

// Redux
import { levelSA } from "../store";

// Destructure Redux Imports
const { selectIsLevelLoading, selectLevels, selectLevelErrorMessage } = levelSA;

/**
 * Level Hook
 */
export const useLevel = () => {
  const dispatch = useDispatch();
  const levels = useSelector(selectLevels);
  const isLevelLoading = useSelector(selectIsLevelLoading);
  const levelErrorMessage = useSelector(selectLevelErrorMessage);

  return {
    levels,
    isLevelLoading,
    levelErrorMessage,
    dispatch,
    ...levelSA,
  };
};
