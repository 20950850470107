// Firebase
import {
  query,
  getDocs,
  where,
  documentId,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
  serverTimestamp,
  Timestamp,
  getCountFromServer,
} from "firebase/firestore";
import {
  firebaseLooper,
  userCollectionRef,
  subjectCollectionRef,
  payPerSubjectSubscriptionCollectionRef,
  subscriptionCollectionRef,
  levelCollectionRef,
  trainingCollectionRef,
} from "../../utils";

// Utils
import {
  AppError,
  getCurrentMonthCount,
  getCurrentWeekCount,
  getFieldFromObject,
  getAllMonthsCount,
  getCurrentDayCount,
} from "../../utils";

// Apis
import * as apis from "../";

/**
 * Enroll Student In Pay Per Subject Subscription
 * @param {string} studentId Student id
 * @param {array} subjectIds Subject ids
 */
export const enrollStudentPayPerSubjectSubscription = async (
  studentId,
  subjectIds,
  studyMethod
) => {
  try {
    // Student Doc Reference
    const studentRef = doc(userCollectionRef, studentId);

    // Create Pay Per Subject Subscriptions Data
    const payPerSubjectSubscriptionsData = await Promise.all(
      subjectIds.map(async (subjectId) => {
        // Get Subject
        const subject = await apis.getSubject(subjectId);

        // Subject Doc Reference
        const subjectRef = doc(subjectCollectionRef, subject.id);

        // Return Subscription Data
        return {
          subscriptionType: "oneTime",
          studyMethod: studyMethod || "online",
          levelId: subject.levelId,
          trainingId: subject.trainingId,
          subjectId: subjectRef,
          studentId: studentRef,
          subscriptionEndAt: subject.examDate,
          createdAt: serverTimestamp(),
        };
      })
    );

    // Enroll Student
    await Promise.all(
      payPerSubjectSubscriptionsData.map(
        async (payPerSubjectSubscriptionData) => {
          await addDoc(
            payPerSubjectSubscriptionCollectionRef,
            payPerSubjectSubscriptionData
          );
        }
      )
    );

    // Get Student Pay Per Subject Subscription Subjects
    const studentActivePayPerSubjectSubscriptionSubjects =
      await getStudentPayPerSubjectSubscriptionsEnrolledSubjects(
        studentId,
        false
      );

    // Return Student Pay Per Subject Subscription Subjects
    return studentActivePayPerSubjectSubscriptionSubjects;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Enroll Student In Pay Per Subject Subscription
 * @param {string} studentId Student id
 * @param {array} subjectIds Subject ids
 */
export const enrollStudentRecurrentSubscription = async (
  studentId,
  levelId,
  trainingId,
  type,
  studyMethod
) => {
  try {
    // Student Doc Reference
    const studentRef = doc(userCollectionRef, studentId);
    const levelRef = doc(levelCollectionRef, levelId);
    const trainingRef = doc(trainingCollectionRef, trainingId);

    await addDoc(subscriptionCollectionRef, {
      levelId: levelRef,
      trainingId: trainingRef,
      cancellationToken: "transactionToken",
      subscriptionEndAt: Timestamp.fromDate(new Date("10/10/2025")),
      studentId: studentRef,
      isActive: true,
      createdAt: serverTimestamp(),
      subscriptionType: type,
      studyMethod: studyMethod || "online",
    });

    // Get Student Pay Per Subject Subscription Subjects
    const studentActivePayPerSubjectSubscriptionSubjects =
      await getStudentRecurrentSubscriptionsEnrolledSubjects(studentId, false);

    // Return Student Pay Per Subject Subscription Subjects
    return studentActivePayPerSubjectSubscriptionSubjects;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Un-Enroll Student In Pay Per Subject Subscription
 * @param {string} studentId Student id
 * @param {array} subjectIds Subject ids
 */
export const unEnrollStudentPayPerSubjectSubscription = async (
  studentId,
  subjectIds
) => {
  try {
    // Student Doc Reference
    const studentRef = doc(userCollectionRef, studentId);

    // Un-Eroll Student
    await Promise.all(
      subjectIds.map(async (subjectId) => {
        // Subject Doc Reference
        const subjectRef = doc(subjectCollectionRef, subjectId);

        // Create Pay Per Subject Subscription Query
        const payPerSubjectSubscriptionQuery = query(
          payPerSubjectSubscriptionCollectionRef,
          where("studentId", "==", studentRef),
          where("subjectId", "==", subjectRef)
        );

        // Get Student Pay Per Subject Subscription For Subject
        const querySnapshot = await getDocs(payPerSubjectSubscriptionQuery);

        // Delete Student Pay Per Subject Subscription For Subject
        await Promise.all(
          querySnapshot.docs.map(async (snapshot) => {
            await deleteDoc(snapshot.ref);
          })
        );
      })
    );

    // Get Student Pay Per Subject Subscription Subjects
    const studentActivePayPerSubjectSubscriptionSubjects =
      await getStudentPayPerSubjectSubscriptionsEnrolledSubjects(
        studentId,
        false
      );

    // Return Student Pay Per Subject Subscription Subjects
    return studentActivePayPerSubjectSubscriptionSubjects;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Student Pay Per Subject Subscription Enrolled Subjects
 * @param {string} studentId Student id
 * @param {boolean} throwNotFound If true throw not found error
 */
export const getStudentPayPerSubjectSubscriptionsEnrolledSubjects = async (
  studentId,
  throwNotFound = true
) => {
  try {
    // Get Student Pay Per Subject Subscriptions
    const studentPayPerSubjectSubscriptions =
      await apis.getStudentPayPerSubjectSubscriptions(
        studentId,
        true,
        throwNotFound
      );

    // Get Student Enrolled Subject Using Pay Per Subject Subscriptions
    let studentEnrolledSubjects = await Promise.all(
      studentPayPerSubjectSubscriptions.map(
        async (studentPayPerSubjectSubscription) => {
          try {
            // Get Subject
            const subject = await apis.getSubject(
              studentPayPerSubjectSubscription.subjectId.id,
              true
            );

            // Return Subject
            return subject;
          } catch (_) {
            return undefined;
          }
        }
      )
    );

    // Clean Student Enrolled Subjects
    studentEnrolledSubjects = studentEnrolledSubjects.filter(
      (studentEnrolledSubject) => typeof studentEnrolledSubject !== "undefined"
    );

    // Return Student Enrolled Subjects
    return studentEnrolledSubjects;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Student Recurrent Subscription Enrolled Subjects
 * @param {string} studentId Student id
 * @param {boolean} throwNotFound If true throw not found error
 */
export const getStudentRecurrentSubscriptionsEnrolledSubjects = async (
  studentId,
  throwNotFound = true
) => {
  try {
    // Get Student Recurrent Subscriptions
    const studentRecurrentSubscriptions =
      await apis.getStudentRecurrentSubscriptions(
        studentId,
        true,
        throwNotFound
      );

    // Get Student Enrolled Subject Using Recurrent Subscriptions
    let studentEnrolledSubjects = await Promise.all(
      studentRecurrentSubscriptions.map(
        async (studentRecurrentSubscription) => {
          // Get Level Subjects
          const levelSubjects = await apis.getLevelSubjects(
            studentRecurrentSubscription.levelId.id,
            false,
            false
          );

          // Return Level Subjects
          return levelSubjects;
        }
      )
    );

    // Clean Student Enrolled Subjects
    studentEnrolledSubjects = studentEnrolledSubjects.flat(1);

    // Return Student Enrolled Subjects
    return studentEnrolledSubjects;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Student Enrolled Subjects
 * @param {string} studentId Student id
 * @param {boolean} throwSubscriptionError Throw subscription error
 */
export const getStudentEnrolledSubjects = async (
  studentId,
  throwSubscriptionError = true
) => {
  try {
    // Get Recurrrent Subscription Subjects
    const recurrentSubscriptionSubjects =
      await getStudentRecurrentSubscriptionsEnrolledSubjects(studentId, false);

    // Get Pay Per Subject Subscription Subjects
    const payPerSubjectSubscriptionSubjects =
      await getStudentPayPerSubjectSubscriptionsEnrolledSubjects(
        studentId,
        false
      );

    // If Student Do Not Have Recurrent Subscriptions And Pay Per Subject Subscriptions And Throw Subscription Error Is True, Throw Custom Error
    if (
      !recurrentSubscriptionSubjects.length &&
      !payPerSubjectSubscriptionSubjects.length &&
      throwSubscriptionError
    ) {
      throw new AppError("subscriptions/subscription");
    }

    // Combined Student Subjects Together
    const combinedSubjects = [
      recurrentSubscriptionSubjects,
      payPerSubjectSubscriptionSubjects,
    ].flat(1);

    // Unique Student Subject Ids
    const uniqueIds = [
      ...new Set(combinedSubjects.map((subject) => subject.id)),
    ];

    // Unique Student Subjects
    const uniqueSubjects = combinedSubjects.filter((subject) => {
      if (uniqueIds.includes(subject.id)) {
        uniqueIds.splice(uniqueIds.indexOf(subject.id), 1);
        return true;
      }
      return false;
    });

    // Return Unique Subjects
    return uniqueSubjects;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get A Student
 * @param {string} studentId Student id
 * @param {boolean} onlyActive If true get only active student
 * @param {boolean} throwNotFound If true throw not found error
 */
export const getStudent = async (
  studentId,
  onlyActive = true,
  throwNotFound = true
) => {
  try {
    // Create Student Query
    const studentQuery = onlyActive
      ? query(
          userCollectionRef,
          where("role", "==", 1),
          where(documentId(), "==", studentId),
          where("isActive", "==", onlyActive)
        )
      : query(
          userCollectionRef,
          where(documentId(), "==", studentId),
          where("role", "==", 1)
        );

    // Get Student
    const querySnapshot = await getDocs(studentQuery);

    // If Student Does Not Exist & ThrowNotFound Is True, Then Throw Custom Error
    if (querySnapshot.empty && throwNotFound) {
      throw new AppError("empty-data");
    }

    // Return Student
    return firebaseLooper(querySnapshot)[0];
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Students And Enrolled Subjects Count
 * @param {array} students Students
 */
const getStudentsAndEnrolledSubjectsCount = async (students) => {
  try {
    // Get Students With Enrolled Subjects Count
    const studentsWithEnrolledSubjectsCount = await Promise.all(
      students.map(async (student) => ({
        ...student,
        enrolledSubjectsCount: (
          await getStudentEnrolledSubjects(student.id, false)
        ).length,
      }))
    );

    // Return Student With Enrolled Subjects Count
    return studentsWithEnrolledSubjectsCount;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get All Students
 * @param {boolean} onlyActive If true get only active student
 */
export const getStudents = async (onlyActive = true) => {
  try {
    // Create Student Query
    const studentQuery = onlyActive
      ? query(
          userCollectionRef,
          where("role", "==", 1),
          where("isActive", "==", onlyActive),
          orderBy("createdAt", "desc")
        )
      : query(
          userCollectionRef,
          where("role", "==", 1),
          orderBy("createdAt", "desc")
        );

    // Get Students
    const querySnapshot = await getDocs(studentQuery);

    // If Students Does Not Exist, Throw Custom Error
    if (querySnapshot.empty) {
      throw new AppError("empty-data");
    }

    // Include Students Enrolled Subjects Count
    let students = firebaseLooper(querySnapshot);

    const studentsWithEnrolledSubjectCount =
      await getStudentsAndEnrolledSubjectsCount(students);

    // Return Students With Enrolled Subject Count
    return studentsWithEnrolledSubjectCount;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Students Stat
 * @param {array} students Students
 */
export const getStudentsStat = (students) => {
  // Get Total Students Count
  const totalStudents = students.length;

  // Get Total Students Count For Current Month
  let currentMonthTotalStudentCount = getCurrentMonthCount(
    students,
    "createdAt"
  );

  // Get Total Students Count For Current Week
  let currentWeekTotalStudentCount = getCurrentWeekCount(students, "createdAt");

  // Get Total Students Count For Current Day
  let currentDayTotalStudentCount = getCurrentDayCount(students, "createdAt");

  // Get Last Active At Current Week Count For Students
  let currentWeekLastActiveAtStudentCount = getCurrentWeekCount(
    students,
    "lastActiveAt"
  );

  // Return Stat
  return {
    totalStudents,
    currentWeekLastActiveAtStudentCount,
    currentMonthTotalStudentCount,
    currentWeekTotalStudentCount,
    currentDayTotalStudentCount,
  };
};

/**
 * Get Students Stat For Month
 * @param {number|undefined} month Lookup month (Note: month should start from zero)
 */
export const getMonthStudentsStat = async (month) => {
  /**
   * Get Students For Month
   * @param {number} month Month
   */
  const getStudentsForMonth = async (month) => {
    // Get Begining Of Month Date
    const beginningOfMonthDate = new Date(
      new Date().setMonth(month)
    ).moveToFirstDayOfMonth();

    // Get End Of Month Date
    const endOfMonthDate = new Date(
      new Date().setMonth(month)
    ).moveToLastDayOfMonth();

    // Create Student Query
    const studentQuery = query(
      userCollectionRef,
      where("role", "==", 1),
      where("createdAt", ">=", Timestamp.fromDate(beginningOfMonthDate)),
      where("createdAt", "<=", Timestamp.fromDate(endOfMonthDate))
    );

    // Get Students
    const querySnapshot = await getDocs(studentQuery);

    // Return Students
    return firebaseLooper(querySnapshot);
  };

  try {
    // Get Month Student Stat
    let monthStudentStat;

    // Get Current Month Student Stat
    let currentMonthStudentStat;

    // Get Students For Specified Month If Month Exist
    if (month) {
      const students = await getStudentsForMonth(month);

      // Get Month Student Stat
      monthStudentStat = { currentMonthTotalStudentCount: students.length };
    }

    // Get Current Month Students
    const students = await getStudentsForMonth(Date.today().getMonth());

    // Get Current Month Student Stat
    currentMonthStudentStat = getStudentsStat(students);

    // Get Total Students Stat
    const querySnapshot = await getCountFromServer(userCollectionRef);
    const totalStudents = querySnapshot.data().count;

    // Return Stat
    return {
      totalStudents,
      currentMonthStat: getFieldFromObject(
        [
          "currentWeekLastActiveAtStudentCount",
          "currentMonthTotalStudentCount",
          "currentWeekTotalStudentCount",
          "currentDayTotalStudentCount",
        ],
        currentMonthStudentStat
      ),
      monthStat: getFieldFromObject(
        ["currentMonthTotalStudentCount"],
        monthStudentStat
      ),
    };
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Graph Students Stat
 * @param {number} startYear Start year
 * @param {number} compareYear Compare year
 */
export const getGraphStudentsStat = async (startYear, compareYear) => {
  /**
   * Get Students For Year
   * @param {number} year Year
   */
  const getStudentsForYear = async (year) => {
    // Get Begining Of Year Date
    const beginningOfYearDate = new Date(year, 0, 1);

    // Get End Of Year Date
    const endOfYearDate = new Date(year, 11, 31);

    // Create Student Query
    const studentQuery = query(
      userCollectionRef,
      where("role", "==", 1),
      where("createdAt", ">=", Timestamp.fromDate(beginningOfYearDate)),
      where("createdAt", "<=", Timestamp.fromDate(endOfYearDate))
    );

    // Get Students
    const querySnapshot = await getDocs(studentQuery);

    // Return Students
    return firebaseLooper(querySnapshot);
  };

  try {
    // Get Start Year Students
    const startYearStudents = await getStudentsForYear(startYear);

    // Get Compare Year Students
    const compareYearStudents = await getStudentsForYear(compareYear);

    // Get Start Year Students Stat
    const startYearStudentsStat = getAllMonthsCount(
      startYearStudents,
      "createdAt"
    );

    // Get Compare Year Students Stat
    const compareYearStudentsStat = getAllMonthsCount(
      compareYearStudents,
      "createdAt"
    );

    // Return Start Year And Compare Year Students Stat
    return {
      comparisonStat: {
        startYearStat: startYearStudentsStat,
        compareYearStat: compareYearStudentsStat,
      },
    };
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get All Students And Stat
 * @param {boolean} onlyActive If true get only active student
 */
export const getStudentsAndStat = async (onlyActive = true) => {
  try {
    // Get Students
    const students = await getStudents(onlyActive);

    // Get Students Stat
    const rawStat = getStudentsStat(students);
    const stat = {
      totalStudents: rawStat.totalStudents,
      currentMonthStat: getFieldFromObject(
        [
          "currentWeekLastActiveAtStudentCount",
          "currentMonthTotalStudentCount",
          "currentWeekTotalStudentCount",
          "currentDayTotalStudentCount",
        ],
        rawStat
      ),
    };

    // Return Students And Stat
    return { students, stat };
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Delete A Student
 * @param {string} studentId Student Id
 */
export const deleteStudent = async (studentId) => {
  try {
    // Student Doc Reference
    const studentRef = doc(userCollectionRef, studentId);

    // Get Students
    await updateDoc(studentRef, { isActive: false });

    // Get Students Stat
    const { stat } = getStudentsAndStat(false);

    // Return Student Stat
    return stat;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Restore A Student
 * @param {string} studentId Student Id
 */
export const restoreStudent = async (studentId) => {
  try {
    // Student Doc Reference
    const studentRef = doc(userCollectionRef, studentId);

    // Get Students
    await updateDoc(studentRef, { isActive: true });

    // Get Students Stat
    const { stat } = getStudentsAndStat(false);

    // Return Student Stat
    return stat;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};
