// Modules
import React from "react";

// Hooks
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  useFeedback,
  useChapter,
  useChapterFile,
  useAuth,
} from "../../../hooks";

// Components
import { ModalOverlay, Modal, VideoFeedbackModalForm } from "../../";

// Utils
import { getFieldFromObject } from "../../../utils";

// Style
import "./video-feedback-modal-base.styles.scss";

export const VideoFeedbackModalBase = ({ className }) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { dispatch, createFeedback } = useFeedback();
  const { activeChapter, chapters, isChapterLoading } = useChapter();
  const { activeChapterFile, chapterFiles, isChapterFileLoading } =
    useChapterFile();
  const { user } = useAuth();

  // Video Feedback Formik
  const videoFeedbackFormik = useFormik({
    initialValues: {
      title: "",
      description: "",
      chapterName: "",
      chapterFileName: "",
    },
    async onSubmit(values) {
      // Create Feedback Data
      const feedbackData = getFieldFromObject(["title", "description"], values);

      // Create New Feedback
      await dispatch(
        createFeedback(feedbackData, user.uid, activeChapter, activeChapterFile)
      );

      // Navigate To Video Player
      navigate(`/player${search}`, { replace: true });
    },
  });

  useEffect(() => {
    // If Chapter Is Not Loading, Then Get Active Chapter Data, Also Set Chapter Name In Formik
    if (!isChapterLoading) {
      // Get Active Chapter Data
      const activeChapterData = chapters.find(
        (chapter) => chapter.id === activeChapter
      );

      // Update Chapter Name In Formik
      videoFeedbackFormik.setFieldValue("chapterName", activeChapterData.name);
    }

    // If Chapter File Is Not Loading, Then Get Active Chapter File Data, Also Set Chapter File Name In Formik
    if (!isChapterFileLoading) {
      // Get Active Chapter File Data
      const activeChapterFileData = chapterFiles.find(
        (chapterFile) => chapterFile.id === activeChapterFile
      );

      // Update Chapter File Name In Formik
      videoFeedbackFormik.setFieldValue(
        "chapterFileName",
        activeChapterFileData.name
      );
    }
  }, [activeChapter, activeChapterFile]);

  return (
    <ModalOverlay
      className={`video-feedback-modal-base-overlay ${className}`}
      to={`/player${search}`}
    >
      <Modal className="video-feedback-modal-base">
        <VideoFeedbackModalForm
          className="video-feedback-modal-base__form"
          formik={videoFeedbackFormik}
        />
      </Modal>
    </ModalOverlay>
  );
};

VideoFeedbackModalBase.defaultProps = {
  className: "",
};
