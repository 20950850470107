// Modules
import React from "react";

// Components
import { EmptyContent, Button } from "../../../";

// Hooks
import { useBooking } from "../../../../hooks";

export const BookingBase = ({ className }) => {
  const { bookings, isBookingLoading, bookingErrorMessage } = useBooking();

  return (
    <div className={`booking-base ${className} is-height-full`}>
      <div className="booking-base__container is-height-full">
        <div className="booking-base__header admin-layout__content-header">
          <Button
            className="booking-base__btn btn--primary btn--sv8 btn--rv5 admin-layout__content-btn"
            to="/admin/bookings/private-sessions"
          >
            Private Sessions
          </Button>
        </div>

        <div className="booking-base__main admin-layout__content-main">
          <EmptyContent
            className="booking-base__empty"
            isLoading={isBookingLoading}
            errorMessage={bookingErrorMessage}
          >
            {/* <BookingTable className="booking-base__table" /> */}
          </EmptyContent>
        </div>
      </div>
    </div>
  );
};

BookingBase.defaultProps = {
  className: "",
};
