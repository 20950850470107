// Modules
import React from "react";

// Components
import { InputSelect, Form, Button } from "../../shared";

// Hooks
import { useValidateFormOnMouseEnter, usePayment } from "../../../hooks";

// Data
import { paymentMethods, supportedCurrencies } from "../../../data";

// Style
import "./checkout-form.styles.scss";

export const CheckoutForm = ({ className, formik }) => {
  const cardPaymentCurrencies = supportedCurrencies;
  const mobileMoneyPaymentCurrencies = supportedCurrencies.filter(
    (currency) => currency.type !== "USD"
  );

  const { isPaymentLoading } = usePayment();

  const {
    values,
    errors,
    isValid,
    touched,
    handleSubmit,
    setFieldValue,
    setTouched,
    validateForm,
  } = formik;

  const { triggerFormValidation } = useValidateFormOnMouseEnter(
    validateForm,
    setTouched
  );

  return (
    <Form className={`checkout-form ${className}`} onSubmit={handleSubmit}>
      <InputSelect
        className="checkout-form__control"
        wrapperClassName="checkout-form__group"
        labelClassName="checkout-form__label"
        name="paymentMethod"
        label="Payment Method"
        placeholder="Enter Payment Method"
        value={values.paymentMethod}
        error={errors.paymentMethod}
        touched={touched.paymentMethod}
        items={paymentMethods}
        onChange={setFieldValue}
      />

      {values.paymentMethod === "Card" && (
        <InputSelect
          className="checkout-form__control"
          wrapperClassName="checkout-form__group"
          labelClassName="checkout-form__label"
          name="cardPaymentCurrency"
          label="Payment Currency"
          placeholder="Enter Payment Currency"
          value={values.cardPaymentCurrency}
          error={errors.cardPaymentCurrency}
          touched={touched.cardPaymentCurrency}
          items={cardPaymentCurrencies}
          onChange={setFieldValue}
        />
      )}

      {values.paymentMethod === "Mobile Money" && (
        <InputSelect
          className="checkout-form__control"
          wrapperClassName="checkout-form__group"
          labelClassName="checkout-form__label"
          name="mobileMoneyPaymentCurrency"
          label="Payment Currency"
          placeholder="Enter Payment Currency"
          value={values.mobileMoneyPaymentCurrency}
          error={errors.mobileMoneyPaymentCurrency}
          touched={touched.mobileMoneyPaymentCurrency}
          items={mobileMoneyPaymentCurrencies}
          onChange={setFieldValue}
        />
      )}

      <Button
        type="submit"
        wrapperClassName="d-block"
        className="checkout-form__btn btn--primary btn--sv5 btn--rv1 is-width-full"
        disabled={!isValid || isPaymentLoading}
        loading={isPaymentLoading}
        loadingHeight="26"
        onMouseEnter={() => triggerFormValidation(isValid)}
      >
        Pay
      </Button>
    </Form>
  );
};

CheckoutForm.defaultProps = {
  className: "",
};
