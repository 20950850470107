// Modules
import React from "react";

// Components
import ReactQuill from "react-quill";

// Style
import "react-quill/dist/quill.snow.css";
import "./editor.styles.scss";

// Static Data
const editorModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

export const Editor = ({
  wrapperClassName,
  className,
  name,
  value,
  touched,
  error,
  placeholder,
  onChange,
  onBlur,
}) => {
  /**
   * Handle Editor Value Change
   */
  const handleEditorChange = (value) => {
    onChange(name, value);
  };

  /**
   * Handle Editor Blur
   */
  const handleEditorBlur = () => {
    onBlur(name, true);
  };

  return (
    <div className={`form__group form__group--editor ${wrapperClassName}`}>
      <ReactQuill
        className={`form__control form__control--editor ${className} ${
          touched && error ? "form__control--error" : ""
        }`}
        theme="snow"
        value={value}
        placeholder={placeholder}
        modules={editorModules}
        onChange={handleEditorChange}
        onBlur={handleEditorBlur}
      />

      {touched && error && (
        <div className="form__error-wrapper">
          <p className="form__error">{error}</p>
        </div>
      )}
    </div>
  );
};

Editor.defaultProps = {
  className: "",
  wrapperClassName: "",
};
