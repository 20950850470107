// Modules
import React from "react";

// Components
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Button } from "../../";

// Hooks
import { useAuth } from "../../../hooks";

// Style
import "./demo-auth-dialog-base.styles.scss";

export const DemoAuthDialogBase = ({ className, open, onClose }) => {
  const { dispatch, loginUser, isAuthLoading } = useAuth();

  /**
   * Handle Login As Student Or Admin
   * @param {string} type Type of authorization
   */
  const handleLogin = async (type) => {
    // Create Login Data
    const loginData = {
      email:
        type === "admin"
          ? process.env.REACT_APP_ADMINDEMOEMAIL
          : process.env.REACT_APP_STUDENTDEMOEMAIL,
      password:
        type === "admin"
          ? process.env.REACT_APP_ADMINDEMOPASSWORD
          : process.env.REACT_APP_STUDENTDEMOPASSWORD,
    };

    // Demo Sign In User
    await dispatch(loginUser(loginData));

    // Close Demo Auth Dialog
    onClose();
  };

  return (
    <Dialog
      className={`demo-auth-dialog-base ${className}`}
      open={open}
      onClose={onClose}
      aria-labelledby="Quick Login"
      aria-describedby="Login as a student or admin"
    >
      <DialogTitle className="demo-auth-dialog-base__title">
        Quick Login
      </DialogTitle>

      <DialogContent className="demo-auth-dialog-base__content">
        <DialogContentText className="demo-auth-dialog-base__content-text">
          Login with the below role
        </DialogContentText>

        <Button
          wrapperClassName="demo-auth-dialog-base__btn-wrapper d-block is-width-full"
          className="demo-auth-dialog-base__btn btn--primary btn--rv1 btn--sv4 is-width-full"
          disabled={isAuthLoading}
          loading={isAuthLoading}
          loadingHeight="26"
          onClick={handleLogin.bind(null, "admin")}
        >
          Admin
        </Button>

        <Button
          wrapperClassName="demo-auth-dialog-base__btn-wrapper d-block is-width-full"
          className="demo-auth-dialog-base__btn btn--tertiary btn--rv1 btn--sv4 is-width-full"
          disabled={isAuthLoading}
          loading={isAuthLoading}
          loadingHeight="26"
          onClick={handleLogin.bind(null, "student")}
        >
          Student
        </Button>
      </DialogContent>
    </Dialog>
  );
};

DemoAuthDialogBase.defaultProps = {
  className: "",
};
