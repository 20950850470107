// Modules
import React from "react";

// Components
import { HomeStatItem } from "../..";

// Style
import "./home-stat-list.styles.scss";

export const HomeStatList = ({ className, items }) => {
  // Home Stat Items
  const renderedHomeStatItems = items.map((item) => (
    <HomeStatItem
      className="home-stat-list__item"
      key={item.id}
      stat={item.stat}
      title={item.title}
      image={item.image}
    />
  ));

  return (
    <div className={`home-stat-list ${className}`}>{renderedHomeStatItems}</div>
  );
};

HomeStatList.defaultProps = {
  className: "",
  items: [],
};
