// Firebase
import {
  getDocs,
  doc,
  query,
  where,
  orderBy,
  updateDoc,
} from "firebase/firestore";
import { bookingCollectionRef, firebaseLooper } from "../../utils";

// Utils
import { AppError } from "../../utils";

// Apis
import * as apis from "../";

export const getCurrentMonthCountUsingStatusValue = () => {
  return 0;
};

/**
 * Populate Student Field In Bookings
 * @param {array} bookings Bookings
 */
const populateStudentInBookings = async (bookings) => {
  try {
    // Get Bookings With Student Populated
    const bookingsWithStudent = await Promise.all(
      bookings.map(async (booking) => ({
        ...booking,
        studentId: booking.studentId.id,
        student:
          (await apis.getStudent(booking.studentId.id, false, false)) || {},
      }))
    );

    // Return Bookings With Student
    return bookingsWithStudent;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get All Bookings
 * @param {boolean} populateStudentField If true populate student field
 */
export const getBookings = async (populateStudentField) => {
  try {
    // Create Booking Query
    const bookingQuery = query(
      bookingCollectionRef,
      where("isActive", "==", true),
      orderBy("createdAt", "desc")
    );

    // Get Bookings
    const querySnapshot = await getDocs(bookingQuery);

    // If Bookings Is Empty, Then  Throw Error
    if (querySnapshot.empty) {
      throw new AppError("empty-data");
    }

    // Populate Student Field In Bookings If PopulateStudentField Is True
    let bookings = firebaseLooper(querySnapshot);
    bookings = populateStudentField
      ? await populateStudentInBookings(bookings)
      : bookings;

    // Return Transactions
    return bookings;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Bookings Stat
 * @param {array} bookings Bookings
 */
export const getBookingsStat = (bookings) => {
  // Get Total Bookings Count
  const totalBookings = bookings.length;

  // Get Total Completed Bookings Count For Current Month
  const currentMonthCompletedBookingCount =
    getCurrentMonthCountUsingStatusValue(
      bookings,
      "createdAt",
      "status",
      "completed"
    );

  // Get Total Pending Bookings Count For Current Month
  const currentMonthPendingBookingCount = getCurrentMonthCountUsingStatusValue(
    bookings,
    "createdAt",
    "status",
    "pending"
  );

  // Return Stat
  return {
    totalBookings,
    currentMonthCompletedBookingCount,
    currentMonthPendingBookingCount,
  };
};

/**
 * Get All Bookings And Stat
 * @param {boolean} populateStudentField If true populate student field
 */
export const getBookingsAndStat = async (populateStudentField) => {
  try {
    // Get Bookings
    const bookings = await getBookings(populateStudentField);

    // Get Bookings stat
    const stat = getBookingsStat(bookings);

    // Return Bookings And Stat
    return { bookings, stat };
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Update A Booking
 * @param {string} bookingId Booking id
 * @param {string} statusValue Booking status value
 */
export const updateBookingStatus = async (bookingId, statusValue) => {
  try {
    // Booking Doc Reference
    const bookingRef = doc(bookingCollectionRef, bookingId);

    // Update Booking
    await updateDoc(bookingRef, { status: statusValue });
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Delete A Booking
 * @param {string} bookingId Booking id
 */
export const deleteBooking = async (bookingId) => {
  try {
    // Booking Doc Reference
    const bookingRef = doc(bookingCollectionRef, bookingId);

    // Delete Booking
    await updateDoc(bookingRef, { isActive: true });

    // Get Bookings Stat
    const { stat } = await getBookingsAndStat(true);

    // Return Bookings Stat
    return stat;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};
