// Modules
import React from "react";

// Components
import { Form, Input, Button } from "../../../..";

// Hooks
import { useValidateFormOnMouseEnter, useLevel } from "../../../../../hooks";

export const AddLevelForm = ({ className, formik, isEditMode }) => {
  const { isLevelLoading } = useLevel();

  const {
    values,
    errors,
    isValid,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    validateForm,
    setTouched,
  } = formik;

  const { triggerFormValidation } = useValidateFormOnMouseEnter(
    validateForm,
    setTouched
  );

  return (
    <Form
      className={`add-level-form container ${className}`}
      onSubmit={handleSubmit}
    >
      <Input
        className="add-level-form__control admin-form__control"
        wrapperClassName="add-level-form__group admin-form__group"
        labelClassName="add-level-form__label admin-form__label"
        placeholder="Level Name"
        name="name"
        id="levelName"
        value={values.name}
        error={errors.name}
        touched={touched.name}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Input
        className="add-level-form__control admin-form__control"
        wrapperClassName="add-level-form__group admin-form__group"
        labelClassName="add-level-form__label admin-form__label"
        label="Price Per Month(Online)"
        placeholder="Price"
        name="pricePerMonthOnline"
        id="pricePerMonthOnline"
        isInline
        value={values.pricePerMonthOnline}
        error={errors.pricePerMonthOnline}
        touched={touched.pricePerMonthOnline}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Input
        className="add-level-form__control admin-form__control"
        wrapperClassName="add-level-form__group admin-form__group"
        labelClassName="add-level-form__label admin-form__label"
        label="Price Per Month(Offline)"
        placeholder="Price"
        name="pricePerMonthOffline"
        id="pricePerMonthOffline"
        isInline
        value={values.pricePerMonthOffline}
        error={errors.pricePerMonthOffline}
        touched={touched.pricePerMonthOffline}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Input
        className="add-level-form__control admin-form__control"
        wrapperClassName="add-level-form__group admin-form__group"
        labelClassName="add-level-form__label admin-form__label"
        label="Price Per Quarter(Online)"
        placeholder="Price"
        name="pricePerQuarterOnline"
        id="pricePerQuarterOnline"
        isInline
        value={values.pricePerQuarterOnline}
        error={errors.pricePerQuarterOnline}
        touched={touched.pricePerQuarterOnline}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Input
        className="add-level-form__control admin-form__control"
        wrapperClassName="add-level-form__group admin-form__group"
        labelClassName="add-level-form__label admin-form__label"
        label="Price Per Quarter(Offline)"
        placeholder="Price"
        name="pricePerQuarterOffline"
        id="pricePerQuarterOffline"
        isInline
        value={values.pricePerQuarterOffline}
        error={errors.pricePerQuarterOffline}
        touched={touched.pricePerQuarterOffline}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Input
        className="add-level-form__control admin-form__control"
        wrapperClassName="add-level-form__group admin-form__group"
        labelClassName="add-level-form__label admin-form__label"
        label="Price Per Year(Online)"
        placeholder="Price"
        name="pricePerYearOnline"
        id="pricePerYearOnline"
        isInline
        value={values.pricePerYearOnline}
        error={errors.pricePerYearOnline}
        touched={touched.pricePerYearOnline}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Input
        className="add-level-form__control admin-form__control"
        wrapperClassName="add-level-form__group admin-form__group"
        labelClassName="add-level-form__label admin-form__label"
        label="Price Per Year(Offline)"
        placeholder="Price"
        name="pricePerYearOffline"
        id="pricePerYearOffline"
        isInline
        value={values.pricePerYearOffline}
        error={errors.pricePerYearOffline}
        touched={touched.pricePerYearOffline}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <div className="add-level-form__btn-group admin-form__btn-group">
        <Button
          className="add-level-form__btn admin-form__btn btn--primary btn--rv1 btn--sv9"
          type="submit"
          disabled={!isValid || isLevelLoading}
          loading={isLevelLoading}
          loadingHeight="26"
          onMouseEnter={() => triggerFormValidation(isValid)}
        >
          {isEditMode ? "Update" : "Publish"}
        </Button>
      </div>
    </Form>
  );
};

AddLevelForm.defaultProps = {
  className: "",
};
