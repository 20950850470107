// Modules
import React from "react";

// Components
import { Form, Input, Button, InputSelect } from "../../../..";

// Hooks
import { useEffect, useState } from "react";
import {
  useValidateFormOnMouseEnter,
  useSubject,
  useStaff,
} from "../../../../../hooks";

export const AddSubjectForm = ({ className, formik, isEditMode }) => {
  const { isSubjectLoading } = useSubject();
  const [transformedStaffs, setTransformedStaffs] = useState([]);
  const {
    isStaffLoading,
    staffErrorMessage,
    staffs,
    dispatch,
    getStaffsAndStat,
  } = useStaff();

  const {
    values,
    errors,
    isValid,
    touched,
    setFieldTouched,
    handleChange,
    setFieldValue,
    handleBlur,
    validateForm,
    setTouched,
    handleSubmit,
  } = formik;

  const { triggerFormValidation } = useValidateFormOnMouseEnter(
    validateForm,
    setTouched
  );

  useEffect(() => {
    // Get All Staffs And Stat
    dispatch(getStaffsAndStat());
  }, []);

  useEffect(() => {
    // Transform Staffs If Staffs Exist
    if (staffs.length) {
      setTransformedStaffs(
        staffs.map((staff) => ({
          id: staff.id,
          type: `${staff.firstName} ${staff.lastName}`,
        }))
      );
    }
  }, [staffs]);

  return (
    <Form
      className={`add-subject-form container ${className}`}
      onSubmit={handleSubmit}
    >
      <Input
        className="add-subject-form__control admin-form__control"
        wrapperClassName="add-subject-form__group admin-form__group"
        placeholder="Subject Name"
        labelClassName="add-subject-form__label"
        name="name"
        id="name"
        value={values.name}
        error={errors.name}
        touched={touched.name}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <InputSelect
        className="add-subject-form__control admin-form__control admin-form__control--select"
        wrapperClassName="add-subject-form__group admin-form__group admin-form__group--select"
        labelClassName="add-subject-form__label admin-form__label admin-form__label--select"
        placeholder="Tutor Name"
        name="tutorId"
        id="tutorName"
        useKey
        items={transformedStaffs}
        isLoading={isStaffLoading}
        fetchError={staffErrorMessage}
        value={values.tutorId}
        error={errors.tutorId}
        touched={touched.tutorId}
        onChange={setFieldValue}
      />

      <Input
        className="add-subject-form__control admin-form__control"
        wrapperClassName="add-subject-form__group admin-form__group"
        labelClassName="add-subject-form__label admin-form__label"
        label="Price Per Subject(Online)"
        placeholder="Price"
        name="onlinePrice"
        id="onlinePrice"
        value={values.onlinePrice}
        error={errors.onlinePrice}
        touched={touched.onlinePrice}
        onChange={handleChange}
        onBlur={handleBlur}
        isInline
      />

      <Input
        className="add-subject-form__control admin-form__control"
        wrapperClassName="add-subject-form__group admin-form__group"
        labelClassName="add-subject-form__label admin-form__label"
        label="Price Per Subject(Offline)"
        placeholder="Price"
        name="offlinePrice"
        id="offlinePrice"
        value={values.offlinePrice}
        error={errors.offlinePrice}
        touched={touched.offlinePrice}
        onChange={handleChange}
        onBlur={handleBlur}
        isInline
      />

      <Input
        type="upload"
        className="add-subject-form__control admin-form__control admin-form__control--upload"
        wrapperClassName="add-subject-form__group add-subject-form__group--upload admin-form__group"
        labelClassName="add-subject-form__label admin-form__label"
        placeholder="Upload"
        label="Upload Thumbnail"
        name="thumbnail"
        id="thumbnail"
        value={values.thumbnail}
        error={errors.thumbnail}
        touched={touched.thumbnail}
        onChange={setFieldValue}
        isInline
      />

      <Input
        type="date"
        className="add-subject-form__control admin-form__control admin-form__control--date"
        wrapperClassName="add-subject-form__group add-subject-form__group--date admin-form__group"
        labelClassName="add-subject-form__label admin-form__label"
        placeholder="MM/DD/YYYY"
        label="Exam Date"
        name="examDate"
        id="examDate"
        value={values.examDate}
        error={errors.examDate}
        touched={touched.examDate}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
        isInline
      />

      <div className="add-subject-form__btn-group admin-form__btn-group">
        <Button
          className="add-subject-form__btn admin-form__btn btn--primary btn--rv1 btn--sv9"
          type="submit"
          disabled={!isValid || isSubjectLoading}
          loading={isSubjectLoading}
          loadingHeight="26"
          onMouseEnter={() => triggerFormValidation(isValid)}
        >
          {isEditMode ? "Update" : "Publish"}
        </Button>
      </div>
    </Form>
  );
};

AddSubjectForm.defaultProps = {
  className: "",
};
