// Modules
import React from "react";

// Hooks
import { useTransaction } from "../../../../hooks";

// Components
import { EmptyContent, TransactionTable } from "../../..";

export const TransactionBase = ({ className }) => {
  const { isTransactionLoading, transactionErrorMessage } = useTransaction();

  return (
    <div className={`transaction-base ${className} is-height-full`}>
      <div className="transaction-base__container is-height-full">
        <EmptyContent
          isLoading={isTransactionLoading}
          errorMessage={transactionErrorMessage}
        >
          <div className="admin-layout__content-main">
            <TransactionTable className="transaction-base__table" />
          </div>
        </EmptyContent>
      </div>
    </div>
  );
};
