// Firebase
import {
  getDocs,
  query,
  where,
  orderBy,
  doc,
  addDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import {
  chapterCollectionRef,
  subjectCollectionRef,
  firebaseLooper,
} from "../../utils";

// Utils
import { AppError } from "../../utils";

/**
 * Get Subject Chapters
 * @param {string} subjectId Subject id
 * @param {boolean} throwNotFound If true throw not found error
 */
export const getSubjectChapters = async (subjectId, throwNotFound = true) => {
  try {
    // Subject Doc Reference
    const subjectRef = doc(subjectCollectionRef, subjectId);

    // Create Chapter Query
    const chapterQuery = query(
      chapterCollectionRef,
      where("subjectId", "==", subjectRef),
      where("isActive", "==", true),
      orderBy("createdAt")
    );

    // Get Subject Chapters
    const querySnapshot = await getDocs(chapterQuery);

    // If Subject Chapters Is Empty & ThrowNotFound Is True, Throw Custom Error
    if (querySnapshot.empty && throwNotFound) {
      throw new AppError("empty-data");
    }

    // Return Subject Chapters
    return firebaseLooper(querySnapshot);
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Create New Chapter
 * @param {object} chapterData Chapter data
 * @param {string} subjectId Subject id
 */
export const createChapter = async (chapterData, subjectId) => {
  try {
    // Create Chapter
    const newChapter = await addDoc(chapterCollectionRef, {
      ...chapterData,
      isActive: true,
      subjectId: doc(subjectCollectionRef, subjectId),
      createdAt: serverTimestamp(),
    });

    // Get Subject Chapters
    const chapters = await getSubjectChapters(subjectId);

    // Return Subject Chapters And New Chapter Id
    return { chapters, chapterId: newChapter.id };
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Update A Chapter
 * @param {string} chapterId Chapter id
 * @param {object} chapterData Chapter data
 * @param {string} subjectId Subject id
 */
export const updateChapter = async (chapterId, chapterData, subjectId) => {
  try {
    // Chapter Doc Reference
    const chapterRef = doc(chapterCollectionRef, chapterId);

    // Update Chapter
    await updateDoc(chapterRef, {
      ...chapterData,
      subjectId: doc(subjectCollectionRef, subjectId),
    });

    // Get Subject Chapters
    const chapters = await getSubjectChapters(subjectId);

    // Return Subject Chapters
    return chapters;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Delete A Chapter
 * @param {string} chapterId Chapter id
 * @param {string} subjectId Subject id
 */
export const deleteChapter = async (chapterId, subjectId) => {
  try {
    // Chapter Doc Reference
    const chapterRef = doc(chapterCollectionRef, chapterId);

    // Update Chapter
    await updateDoc(chapterRef, {
      isActive: false,
    });

    // Get Subject Chapters
    const chapters = await getSubjectChapters(subjectId, true);

    // Return Subject Chapters
    return chapters;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};
