// Modules
import React from "react";

// Components
import { Outlet } from "react-router-dom";
import { StudentNavbar } from "../../components";

// Hooks
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useChapter, useChapterFile } from "../../hooks";

// Style
import "./video-layout.styles.scss";

export const VideoLayout = () => {
  const [searchParams] = useSearchParams();
  const isEditMode = searchParams.get("edit") === "true";
  const subjectId = searchParams.get("subject");
  const { dispatch, getSubjectChapters } = useChapter();
  const { getSubjectChapterFiles } = useChapterFile();

  useEffect(() => {
    // Get Subject Chapters
    dispatch(getSubjectChapters(subjectId));

    // Get Subject Chapter Files
    dispatch(getSubjectChapterFiles(subjectId));
  }, [subjectId]);

  return (
    <div className="video-layout">
      <div className="video-layout__container">
        <div className="video-layout__navbar-wrapper">
          <StudentNavbar
            className="video-layout__navbar"
            goBack
            isEditMode={isEditMode}
          />
        </div>

        <div className="video-layout__main container">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
