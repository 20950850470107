// Modules
import React from "react";

// Components
import { CourseCatalogBase } from "../../../components";

// Hooks
import { useEffect } from "react";
import { useAuth, useStudent, useUrlAsTitle } from "../../../hooks";

export const CourseCatalog = () => {
  const { user } = useAuth();
  const { getStudentEnrolledSubjects, dispatch } = useStudent();
  // Set Page Custom Title
  useUrlAsTitle("Course Catalog");

  useEffect(() => {
    // Get Student Enrolled Subjects
    dispatch(getStudentEnrolledSubjects(user.uid));
  }, []);

  return (
    <div className="course-catalog is-height-full">
      <div className="course-catalog__container is-height-full">
        <CourseCatalogBase />
      </div>
    </div>
  );
};
