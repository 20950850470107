// Hooks
import { useState } from "react";

/**
 * Delete Dialog Hook
 * @param {function} onConfirm Function to be called after confirmation
 * @param {function} onClose Function to be called after dialog Close
 */
export const useDelete = (onConfirm, onClose) => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [data, setData] = useState([]);
  const deleteCloseActionText = "No";
  const deleteConfirmActionText = "Yes";

  /**
   * Open Delete Dialog
   * @param {any[]} data Data to be passed to onCofirm function
   */
  const onHandleOpenDeleteDialog = (...data) => {
    // Open Dialog
    setDeleteDialogOpen(true);

    // Update Data State
    setData(data);
  };

  /**
   * Close Delete Dialog
   */
  const onHandleCloseDeleteDialog = () => {
    // Close Dialog
    setDeleteDialogOpen(false);

    // Call OnClose Function If It Exist
    onClose && onClose();
  };

  /**
   * Called On Confirm Button Click
   */
  const onHandleDeleteConfirm = () => {
    // Close Dialog
    onHandleCloseDeleteDialog();

    // Call OnConfirm Function If It Exist
    onConfirm && onConfirm(...data);
  };

  return {
    isDeleteDialogOpen,
    setDeleteDialogOpen,
    deleteCloseActionText,
    deleteConfirmActionText,
    onHandleCloseDeleteDialog,
    onHandleOpenDeleteDialog,
    onHandleDeleteConfirm,
  };
};
