// Modules
import React from "react";

// Components
import { IconButton } from "@mui/material";
import { MdDelete } from "react-icons/md";
import { IoMdTime } from "react-icons/io";
import { Button } from "../../../../../..";

// Hooks
import { useState, useRef, useEffect } from "react";

// Style
import "./time.styles.scss";
import "./booking-time-card.styles.scss";

const Time = ({ className, isActive, setIsActive, value, name, onChange }) => {
  // Human Readable Time
  const humanReadableTime = new Date(value).toString("hh:mm tt");

  // Destructure Human Readable Time
  const [initHour, initMinute, initAMPM] = humanReadableTime
    .split(":")
    .join(" ")
    .split(" ");

  const [AMPM, setAMPM] = useState(initAMPM);
  const hourRef = useRef();
  const minuteRef = useRef();

  /**
   * Toggle AMPM
   */
  const toggleAMPM = () => {
    setAMPM(AMPM === "AM" ? "PM" : "AM");
  };

  /**
   * Handle Time Change
   */
  const onHandleTimeChange = () => {
    // Get Hour
    const hour = hourRef.current.textContent;

    // Get Minute
    const minute = minuteRef.current.textContent;

    // Create A New Date And Set The Hour And Minute
    const date = new Date(new Date().setHours(hour, minute));

    // Use 12 Hours Format For Consistency
    const to12HoursFormat = date.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    // Get Time
    const time = Date.parse(
      `${Date.parse(to12HoursFormat).toString("hh:mm")} ${AMPM}`
    ).getTime();

    // Update State
    onChange(name, time);

    // Close Dropdown Visibility
    setIsActive(false);
  };

  /**
   * Toggle Time Dropdown Visibility
   */
  const toggleTimeDropdownVisibility = () => {
    setIsActive(!isActive);
  };

  return (
    <div className={`time ${className}`}>
      <div className="time__container">
        <div className="time__card" onClick={toggleTimeDropdownVisibility}>
          <IoMdTime className="time__icon" />
          <span className="time__text">{humanReadableTime}</span>
        </div>

        <div
          className={`time__card-dropdown ${
            isActive ? "time__card-dropdown--active" : ""
          }`}
        >
          <div className="time__group">
            <span
              className="time__hour time__input"
              contentEditable
              ref={hourRef}
              suppressContentEditableWarning={true}
            >
              {initHour}
            </span>

            <span className="time__separator">:</span>

            <span
              className="time__minute time__input"
              contentEditable
              ref={minuteRef}
              suppressContentEditableWarning={true}
            >
              {initMinute}
            </span>

            <span className="time__ampm" onClick={toggleAMPM}>
              {AMPM}
            </span>
          </div>

          <Button
            className="time__btn btn--tertiary btn--sv17 btn--rv1"
            onClick={onHandleTimeChange}
          >
            OK
          </Button>
        </div>
      </div>
    </div>
  );
};

export const BookingTimeCard = ({
  className,
  value,
  dateIndex,
  timeIndex,
  name,
  availabilityDates,
  onAvailabilityDatesChange,
}) => {
  const [startTimeIsActive, setStartTimeIsActive] = useState(false);
  const [endTimeIsActive, setEndTimeIsActive] = useState(false);

  useEffect(() => {
    /**
     * Close Start And End Time Visibility
     * @param {Event} event Event
     */
    const closeStartTimeAndEndTimeVisibility = (event) => {
      // Get Booking Time Card Element From The Target
      const bookingTimeCardEl = event.target.closest(".booking-time-card");

      // If Booking Time Card Element Exist, Then Return Function
      if (bookingTimeCardEl) return;

      // Else Close Start And End Time Visibility
      setStartTimeIsActive(false);
      setEndTimeIsActive(false);
    };

    // Attach Event Listener To The Body Element
    document.body.addEventListener("click", closeStartTimeAndEndTimeVisibility);

    // Remove Event Listener When Page Un-Mount
    return () =>
      document.body.removeEventListener(
        "click",
        closeStartTimeAndEndTimeVisibility
      );
  }, []);

  /**
   * Handle Time Change
   * @param {number} timeValue Time value
   */
  const onHandleTimeChange = (name, timeValue) => {
    // Get Date Object
    const date = availabilityDates[dateIndex];

    // Get Time Object
    const time = date.times[timeIndex];

    // Update Either Start Or End Time
    time[name] = timeValue;

    // If Name Is Start Time, Then Open The End Time Dropdown Visibility
    name === "start" && setEndTimeIsActive(true);

    // Update State
    onAvailabilityDatesChange(name, availabilityDates);
  };

  /**
   * Handle Delete Time Using Date And Time Index
   */
  const onHandleDeleteTime = () => {
    // Get Date Object
    const date = availabilityDates[dateIndex];

    // Delete Time From Date
    date.times.splice(timeIndex, 1);

    // Update State
    onAvailabilityDatesChange(name, availabilityDates);
  };

  return (
    <div className={`booking-time-card ${className}`}>
      <div className="booking-time-card__container">
        <div className="booking-time-card__left">
          <Time
            className="booking-time-card__start-time-card"
            name="start"
            isActive={startTimeIsActive}
            value={value.start}
            setIsActive={setStartTimeIsActive}
            onChange={onHandleTimeChange}
          />

          <span className="booking-time-card__spacer"></span>

          <Time
            className="booking-time-card__end-time-card"
            name="end"
            isActive={endTimeIsActive}
            value={value.end}
            setIsActive={setEndTimeIsActive}
            onChange={onHandleTimeChange}
          />
        </div>

        <div className="booking-time-card__right">
          <IconButton
            className="booking-time-card__icon-btn"
            arial-label="delete Date Time"
            onClick={onHandleDeleteTime}
          >
            <MdDelete
              className="booking-time-card__delete-icon"
              title="Delete Time"
            />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

BookingTimeCard.defaultProps = {
  className: "",
  availabilityDates: [],
};
