// Modules
import { createSlice } from "@reduxjs/toolkit";

// Common Reducers
import { loadingReducers, errorMessageReducers } from "../common";

// Utils
import { stateSelector } from "../../utils";

// Initial State
const initialState = {
  isLoading: false,
  errorMessage: "",
  levels: [],
};

// Slice Name
const sliceName = "level";

// Levels Slice
const levelSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ...loadingReducers,
    ...errorMessageReducers,
    setLevels(state, action) {
      state.levels = action.payload;
    },
    resetLevels(state) {
      state.levels = [];
    },
    delLevel(state, action) {
      // Get Level Id
      const levelId = action.payload;

      // Get Levels
      const { levels } = state;

      // Update Levels With Level That Does Not Contain The Specified Level Id
      state.levels = levels.filter((level) => level.id !== levelId);

      // If Levels Is Empty, Then Set Error Message
      if (!state.levels.length) {
        state.errorMessage = "No Data Available. Wait For Data Upload";
      }
    },
  },
});

export const {
  startLoading: levelStartLoading,
  stopLoading: levelStopLoading,
  setErrorMessage: setLevelErrorMessage,
  resetErrorMessage: resetLevelErrorMessage,
  setLevels,
  resetLevels,
  delLevel,
} = levelSlice.actions;
export * from "./levelAction";
export const selectLevels = stateSelector(sliceName, "levels");
export const selectIsLevelLoading = stateSelector(sliceName, "isLoading");
export const selectLevelErrorMessage = stateSelector(sliceName, "errorMessage");
export default levelSlice.reducer;
