// Modules
import React from "react";

// Components
import { Outlet } from "react-router-dom";
import { AdminSideNavbar, AdminNavbar, AdminFooter } from "../../components";

// Style
import "./admin-layout.styles.scss";

export const AdminLayout = () => {
  return (
    <div className="admin-layout">
      <div className="admin-layout__group">
        <div className="admin-layout__container container">
          <div className="admin-layout__left">
            <AdminSideNavbar className="admin-layout__sidenavbar" />
          </div>

          <div className="admin-layout__right">
            <AdminNavbar className="admin-layout__navbar" />

            <div className="admin-layout__content">
              <Outlet />
            </div>
          </div>
        </div>

        <AdminFooter className="admin-layout__footer" />
      </div>
    </div>
  );
};
