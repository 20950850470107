// Modules
import React from "react";

// Components
import { Link } from "react-router-dom";

// Images
import { ReactComponent as FinaccoLogo } from "../../../assets/images/finacco-logo.svg";

// Style
import "./admin-footer.styles.scss";

// Static Data
const currentYear = new Date().getFullYear();

export const AdminFooter = ({ className }) => {
  return (
    <footer className={`admin-footer ${className}`}>
      <div className="admin-footer__container container">
        <div className="admin-footer__brand">
          <Link className="admin-footer__brand-link" to="/">
            <FinaccoLogo
              className="admin-footer__brand-logo"
              aria-label="finacco logo"
            />
          </Link>
        </div>

        <div className="admin-footer__copyright">
          <p className="admin-footer__copyright-text">
            &copy; {currentYear} FinaccoHub. All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

AdminFooter.defaultProps = {
  className: "",
};
