// Redux
import {
  setUser,
  authStartLoading,
  authStopLoading,
  setOAuthLoadingName,
  resetOAuthLoadingName,
  setIsLoggedIn,
  setCheckedAuth,
  resetIsLoggedIn,
  resetUser,
} from ".";
import { uiSA } from "..";

// Utils
import { getSuccess, wait } from "../../utils";

// Apis
import * as apis from "../../apis";

// Destructure Redux Imports
const { showNotification } = uiSA;

// Static Data
let userSubscription;

/**
 * Login User With Email And Password
 * @param {object} userData Login form data
 */
export const loginUser = (userData) => async (dispatch) => {
  try {
    // Start Loading
    dispatch(authStartLoading());

    // Login User With Email And Password
    const userProfile = await apis.loginUser(userData);

    // Update User
    dispatch(setUser(userProfile));

    // Update IsLoggedIn
    dispatch(setIsLoggedIn(true));
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  } finally {
    // Stop Loading
    dispatch(authStopLoading());
  }
};

/**
 * Initialize Authentication
 */
export const initAuth = () => async (dispatch) => {
  try {
    // Init Auth
    const userProfile = await apis.initAuth();

    // Update User And IsLoggedIn State If User Exist
    if (userProfile) {
      // Update User
      dispatch(setUser(userProfile));

      // Update isLoggedIn
      dispatch(setIsLoggedIn(true));
    }
  } finally {
    // Update CheckedAuth
    dispatch(setCheckedAuth(true));
  }
};

/**
 * Signout Authenticated User
 */
export const logout = () => async (dispatch) => {
  try {
    // Signout The Auth User
    await apis.logoutUser();

    // Reset User
    dispatch(resetUser());

    // Reset IsLoggedIn
    dispatch(resetIsLoggedIn());

    // Cancel User Subscription If It Exist
    userSubscription && userSubscription();
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  }
};

/**
 * Signup User With Email And Password
 * @param {object} userData Signup form data
 */
export const signUpUser = (userData) => async (dispatch) => {
  try {
    // Start Loading
    dispatch(authStartLoading());

    // SignUp User With Email And Password
    const userProfile = await apis.signUpUser(userData);

    // Update User
    dispatch(setUser(userProfile));

    // Update IsLoggedIn
    dispatch(setIsLoggedIn(true));

    // Show Success Notification On Sending Verification Email Link
    dispatch(showNotification(getSuccess("auth/sendVerificationEmail")));
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  } finally {
    // Stop Loading
    dispatch(authStopLoading());
  }
};

/**
 * Sign-In User With Google OAuth Provider
 */
const signInWithGoogle = () => async (dispatch) => {
  try {
    // Set OAuthLoadingName To Google
    dispatch(setOAuthLoadingName("google"));

    // Sign-In User With Google Provider
    const authResponse = await apis.signInWithGoogle();

    // Return Auth Response
    return authResponse;
  } finally {
    // Reset OAuthLoadingName
    dispatch(resetOAuthLoadingName());
  }
};

/**
 * Sign-In User With Facebook OAuth
 */
const signInWithFacebook = () => async (dispatch) => {
  try {
    // Set OAuthLoadingName To Facebook
    dispatch(setOAuthLoadingName("facebook"));

    // Sign-In User With Facebook Provider
    const authResponse = await apis.signInWithFacebook();

    // Return Auth Response
    return authResponse;
  } finally {
    // Reset OAuthLoadingName
    dispatch(resetOAuthLoadingName());
  }
};

/**
 * Sign User In With A OAuth Provider
 * @param {string} providerName OAuth provider name
 * @param {function} onSuccess Function to be called on successful authentication
 * @param {function} onError Function to be called on error when authenticating
 * @example
 * signInWithOAuth("google")
 * signInWithOAuth("facebook")
 */
export const signInWithOAuth =
  (providerName, onSuccess, onError) => async (dispatch) => {
    try {
      // Start Loading
      dispatch(authStartLoading());

      // Sign-In With Google Or Facebook Provider Based On Provider Name
      let { userProfile, isNewUser } =
        providerName === "google"
          ? await dispatch(signInWithGoogle())
          : await dispatch(signInWithFacebook());

      // Update User
      dispatch(setUser(userProfile));

      // Update IsLoggedIn
      dispatch(setIsLoggedIn(true));

      // Show Success Notification On Sending Verification Email Link
      !userProfile.isVerified &&
        isNewUser &&
        dispatch(showNotification(getSuccess("auth/sendVerificationEmail")));

      // Call OnSuccess If It Exist
      onSuccess && (await onSuccess());
    } catch (err) {
      // Show Error In Notification
      dispatch(showNotification(err.message));

      // Call OnError If It Exist
      onError && onError();
    } finally {
      // Stop Loading
      dispatch(authStopLoading());
    }
  };

/**
 * Send User A Forgot Password Link To Email
 * @param {string} email User email
 */
export const sendForgotPasswordEmail = (email) => async (dispatch) => {
  try {
    // Start Loading
    dispatch(authStartLoading());

    // Send Password Reset Link To Email
    await apis.sendForgotPasswordEmail(email);

    // Show Success Notification On Sending Forgot Password Email Link
    dispatch(showNotification(getSuccess("auth/sendForgotPasswordEmail")));
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  } finally {
    // Stop Loading
    dispatch(authStopLoading());
  }
};

/**
 * Send User A Verification Link To Email
 */
export const sendVerificationEmail = () => async (dispatch) => {
  try {
    // Start Loading
    dispatch(authStartLoading());

    // Send Email Verification Link
    await apis.sendVerificationEmail();

    // Show Success Notification On Sending Verification Email Link
    dispatch(showNotification(getSuccess("auth/sendVerificationEmail")));
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  } finally {
    // Stop Loading
    dispatch(authStopLoading());
  }
};

/**
 * Update User Profile
 * @param {string} uid User id
 * @param {object} newProfile New profile data
 * @param {boolean} emailChanged If true perform email update and verification
 */
export const updateProfile =
  (uid, newProfile, emailChanged) => async (dispatch) => {
    try {
      // Start loading
      dispatch(authStartLoading());

      // Update User Profile
      const userProfile = await apis.updateProfile(
        uid,
        newProfile,
        emailChanged
      );

      // Update User
      dispatch(setUser(userProfile));

      // Show Success Notification On User Profile Updated
      dispatch(showNotification(getSuccess("auth/updateProfile")));

      // Show Success Notification On Sending Verification Email Link If EmailChanged Is True
      if (emailChanged) {
        await wait(1000);
        dispatch(showNotification(getSuccess("auth/sendVerificationEmail")));
      }
    } catch (err) {
      // Show Error In Notification
      dispatch(showNotification(err.message));
    } finally {
      // Stop Loading
      dispatch(authStopLoading());
    }
  };

/**
 * Upload/Update User Profile Image
 * @param {object|string} avatar Avatar url(base64)
 */
export const updateProfileImage = (avatar) => async (dispatch) => {
  try {
    // Start Loading
    dispatch(authStartLoading());

    // Update User Profile Image
    const userProfile = await apis.updateProfileImage(avatar);

    // Update User
    dispatch(setUser(userProfile));

    // Show Success Notification On Uploading User Avatar
    dispatch(showNotification(getSuccess("auth/updateProfileImage")));
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  } finally {
    // Stop Loading
    dispatch(authStopLoading());
  }
};

/**
 * Re-Authenticate User
 * @param {object} userData User auth data
 * @param {function} onSuccess Function to be called on successful authentication
 */
export const reauthenticateUser = (userData, onSuccess) => async (dispatch) => {
  try {
    // Start Loading
    dispatch(authStartLoading());

    // Re-Authenticate User
    const userProfile = await apis.reauthenticateUser(userData);

    // Update User
    dispatch(setUser(userProfile));

    // Call OnSuccess If It Exist
    onSuccess && (await onSuccess());
  } catch (err) {
    // Show Error In Notification
    dispatch(showNotification(err.message));
  } finally {
    // Stop Loading
    dispatch(authStopLoading());
  }
};

/**
 * Enforce Single Device Active At A Time For A User
 * @param {string} userId User id
 * @param {function} signOut Signout function
 */
export const enforceSingleActiveDevice = (userId, signOut) => async () => {
  // Enforce Single Active Device Feature
  userSubscription = await apis.enforceSingleActiveDevice(userId, signOut);
};
