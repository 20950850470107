// Modules
import React from "react";

// Components
import { Fragment } from "react";
import {
  AccountingConsultancyHero,
  AccountingConsultancyBase,
} from "../../components";

export const AccountingConsultancy = () => {
  return (
    <Fragment>
      <AccountingConsultancyHero />
      <AccountingConsultancyBase />
    </Fragment>
  );
};
