// Modules
import React from "react";

// Components
import { StatsCard, StatsList } from "../../../";

// Hooks
import { useStudent } from "../../../../hooks";

// Utils
import { formatToCompactNumber } from "../../../../utils";

export const StudentStatsBase = ({ className }) => {
  const { studentStat } = useStudent();
  const {
    currentMonthStat: {
      currentWeekLastActiveAtStudentCount,
      currentMonthTotalStudentCount,
    },
    totalStudents,
  } = studentStat;

  return (
    <div className={`student-stats-base ${className}`}>
      <div className="student-stats-base__container">
        <StatsList className="student-stats-base__list">
          <StatsCard
            className="student-stats-base__card"
            title={`${formatToCompactNumber(
              currentMonthTotalStudentCount
            )} Students`}
            subtitle="This Month"
          />

          <StatsCard
            className="student-stats-base__card"
            title={`${formatToCompactNumber(
              currentWeekLastActiveAtStudentCount
            )} Students`}
            subtitle="Active this week"
          />

          <StatsCard
            className="student-stats-base__card"
            title={`${formatToCompactNumber(totalStudents)} Students`}
            subtitle="In Total"
            isSecondary
          />
        </StatsList>
      </div>
    </div>
  );
};

StudentStatsBase.defaultProps = {
  className: "",
};
