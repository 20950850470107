// Modules
import React from "react";

// Components
import { Outlet } from "react-router-dom";
import { StudentStatsBase, StudentBase } from "../../../components";

// Hooks
import { useEffect } from "react";
import { useUrlAsTitle, useStudent } from "../../../hooks";

export const Student = () => {
  // Set Dashboard Page Title
  useUrlAsTitle("Students");
  const { getStudentsAndStat, dispatch } = useStudent();

  useEffect(() => {
    // Get All Students And Stat
    dispatch(getStudentsAndStat(false));
  }, []);

  return (
    <div className="student is-height-full">
      <div className="student__container is-height-full">
        <StudentStatsBase />
        <StudentBase />
        <Outlet />
      </div>
    </div>
  );
};
