/**
 * Get Data From Firebase Snapshot
 * @param {object} snapshot Firebase snapshot
 */
export const dataFromSnapshot = (snapshot) => {
  // Get Data From Snapshot
  const data = {
    id: snapshot.id,
    ...snapshot.data(),
  };

  // Get CreatedAt Field As Date ISOString
  if (data.createdAt) {
    data.createdAt = data.createdAt.toDate().toISOString();
  }

  // Get UpdatedAt Field As Date ISOString
  if (data.updatedAt) {
    data.updatedAt = data.updatedAt.toDate().toISOString();
  }

  // Get LastLogin Field As Date ISOString
  if (data.lastLogin) {
    data.lastLogin = data.lastLogin.toDate().toISOString();
  }

  // Get LastActiveAt Field As Date ISOString
  if (data.lastActiveAt) {
    data.lastActiveAt = data.lastActiveAt.toDate().toISOString();
  }

  // Return The Snapshot Data
  return data;
};

/**
 * Get Documents From Firebase QuerySnapshot
 * @param {object} querySnapshot Firebase query snapshot
 * @return {array} Array of documents data
 */
export const firebaseLooper = (querySnapshot) => {
  // Get Documents Data From QuerySnapshot
  const documentData = querySnapshot.docs.map(dataFromSnapshot);

  // Return Documents Data
  return documentData;
};
