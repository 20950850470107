// Modules
import React from "react";

// Components
import { Checkbox } from "@mui/material";

// Style
import "./input-checkbox.styles.scss";

export const InputCheckbox = ({
  wrapperClassName,
  className,
  labelClassName,
  label,
  name,
  id,
  checked,
  touched,
  error,
  indeterminate,
  disabled,
  onChange,
  onBlur,
}) => {
  // Checkbox Style
  const checkboxStyle = {
    color: error && touched ? "#f25b6e" : "#f9a22f",
    "& .MuiSvgIcon-root": { fontSize: 28 },
    "&.Mui-checked": {
      color: "#f9a22f",
    },
    "&.MuiCheckbox-indeterminate": {
      color: "#f9a22f",
    },
  };

  return (
    <div className={`input-checkbox__group ${wrapperClassName}`}>
      <Checkbox
        className={`input-checkbox__control ${className}`}
        checked={checked}
        name={name}
        id={id}
        disabled={disabled}
        indeterminate={indeterminate}
        sx={checkboxStyle}
        onChange={onChange}
        onBlur={onBlur}
      />

      {label && (
        <label
          htmlFor={id}
          className={`input-checkbox__label ${labelClassName}`}
        >
          {label}
        </label>
      )}

      {touched && error && (
        <div className="input-checkbox__error-wrapper form__error-wrapper">
          <p className="input-checkbox__error form__error">{error}</p>
        </div>
      )}
    </div>
  );
};

InputCheckbox.defaultProps = {
  wrapperClassName: "",
  className: "",
  labelClassName: "",
  checked: false,
  indeterminate: false,
  touched: false,
};
