// Modules
import React from "react";

// Components
import { HomeStatList } from "../..";

// Style
import "./home-stat-base.styles.scss";

// Static Data
import { HOME_STAT_ITEMS } from "../../../data";

export const HomeStatBase = ({ className }) => {
  return (
    <div className={`home-stat ${className}`}>
      <div className="home-stat__container container">
        <HomeStatList className="home-stat__list" items={HOME_STAT_ITEMS} />
      </div>
    </div>
  );
};

HomeStatBase.defaultProps = {
  className: "",
};
