// Modules
import React from "react";

// Components
import { Fragment } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import BoolFilter from "@inovua/reactdatagrid-community/BoolFilter";
import { IconButton, Avatar } from "@mui/material";
import { MdDelete, MdRestoreFromTrash } from "react-icons/md";
import { Dialog as DeleteConfirmationDialog, Button } from "../../../..";

// Hooks
import { useCallback } from "react";
import { useDelete, useStudent } from "../../../../../hooks";

// Utils
import { getProfileImage, tableCustomFilters } from "../../../../../utils";

export const StudentTable = ({
  className,
  selectedStudents,
  setSelectedStudents,
}) => {
  const {
    deleteCloseActionText,
    deleteConfirmActionText,
    isDeleteDialogOpen,
    onHandleCloseDeleteDialog,
    onHandleDeleteConfirm,
    onHandleOpenDeleteDialog,
  } = useDelete(onHandleToggleStudentActiveStatus);
  const { students, dispatch, deleteStudent, restoreStudent } = useStudent();

  /**
   * Handle Toggle Student Active Status
   * @param {string} studentId Student id
   * @param {boolean} isActive Is student active
   */
  function onHandleToggleStudentActiveStatus(studentId, isActive) {
    // If Student Is Active Then Delete Student, Else Restore Student
    if (isActive) {
      dispatch(deleteStudent(studentId));
    } else {
      dispatch(restoreStudent(studentId));
    }
  }

  /**
   * Handle Student Row Selection Change
   */
  const onStudentSelectionChange = useCallback(
    ({ selected: selectedStudents, originalData }) => {
      if (typeof selectedStudents !== "boolean") {
        // Update Selected Students
        setSelectedStudents(selectedStudents);
      }

      if (typeof selectedStudents === "boolean" && selectedStudents) {
        // Update Selected Students With All The Students
        setSelectedStudents(
          Object.fromEntries(originalData.map((data) => [data.uid, data]))
        );
      }
    },
    []
  );

  // Student Filter Value
  const studentFilterValue = [
    { name: "fullName", operator: "fullName", type: "fullName", value: "" },
    { name: "email", operator: "contains", type: "string", value: "" },
    { name: "isActive", operator: "eq", type: "boolean" },
    {
      name: "enrolledSubjectsCount",
      operator: "eq",
      type: "number",
    },
  ];

  // Student Columns
  const studentColumns = [
    {
      name: "avatar",
      header: "PICTURE",
      textAlign: "center",
      defaultFlex: 1,
      render: ({ value, data }) => {
        return (
          <div className="admin-table__avatar">
            <Avatar
              alt={data.firstName}
              src={getProfileImage(value?.url, 100, 100)}
            />
          </div>
        );
      },
    },
    {
      name: "fullName",
      header: "NAME",
      textAlign: "center",
      defaultFlex: 2,
      render: ({ data }) => {
        return `${data.firstName} ${data.lastName}`;
      },
    },
    {
      name: "email",
      header: "EMAIL",
      textAlign: "center",
      defaultFlex: 2,
    },
    {
      name: "isActive",
      header: "STATUS",
      defaultFlex: 2,
      textAlign: "center",
      type: "boolean",
      filterEditor: BoolFilter,
      render: ({ value: isActive, data: { lastActiveAt } }) => {
        // Get Status In Text
        const statusText = isActive ? "Active" : "Inactive";

        // Get Status In Text In LowerCase
        const statusTextLower = statusText.toLowerCase();

        // Get Formatted Last Active Date
        const lastActiveAtDate =
          Date.parse(lastActiveAt)?.toString("dd/MMM/yyyy");

        return (
          <Fragment>
            <span
              className={`admin-table__status admin-table__status--${statusTextLower}`}
            >
              {statusText}
            </span>

            <span className="admin-table__text-alt">
              Last Login on {lastActiveAtDate}
            </span>
          </Fragment>
        );
      },
    },
    {
      name: "enrolledSubjectsCount",
      header: "SUBJECTS",
      textAlign: "center",
      defaultFlex: 1.5,
      type: "number",
      filterEditor: NumberFilter,
    },
    {
      name: "id",
      header: "ACTION",
      defaultFlex: 1.5,
      textAlign: "center",
      render: ({ value: id, data: { isActive } }) => (
        <div className="admin-table__btn-group">
          <Button
            className="btn--sv16 btn--rv5 admin-table__btn"
            to={`/admin/students/${id}`}
          >
            View More
          </Button>

          <IconButton
            className="admin-table__icon-btn"
            onClick={
              isActive
                ? onHandleOpenDeleteDialog.bind(null, id, isActive)
                : onHandleToggleStudentActiveStatus.bind(null, id, isActive)
            }
          >
            {isActive ? (
              <MdDelete className="admin-table__icon" title="Delete Student" />
            ) : (
              <MdRestoreFromTrash
                className="admin-table__icon--restore"
                title="Restore Student"
              />
            )}
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        dialogTitle="Confirm Delete Student"
        dialogDescription="You are about to delete a student. Confirm with yes to continue"
        closeActionText={deleteCloseActionText}
        confirmActionText={deleteConfirmActionText}
        onClose={onHandleCloseDeleteDialog}
        onConfirm={onHandleDeleteConfirm}
      />

      <div className={`student-table admin-table admin-table ${className}`}>
        <ReactDataGrid
          className="student-table__table admin-table__table admin-table__table"
          idProperty="id"
          checkboxColumn
          columns={studentColumns}
          dataSource={students}
          selected={selectedStudents}
          filterTypes={tableCustomFilters}
          defaultFilterValue={studentFilterValue}
          headerHeight={64}
          rowHeight={64}
          defaultLimit={5}
          pagination
          onSelectionChange={onStudentSelectionChange}
        />
      </div>
    </Fragment>
  );
};

StudentTable.defaultProps = {
  className: "",
};
