// Firebase
import { collection } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { database, functions } from "../";

// NewsLetter Collection Reference
export const newsLetterCollectionRef = collection(database, "newsletters");

// ReferrerMedium Collection Reference
export const referrerMediumCollectionRef = collection(
  database,
  "referrerMediums"
);

// ContactUs Collection Reference
export const contactUsCollectionRef = collection(database, "enquiries");

// User Collection Reference
export const userCollectionRef = collection(database, "users");

// Training Collection Reference
export const trainingCollectionRef = collection(database, "trainings");

// Level Collection Reference
export const levelCollectionRef = collection(database, "levels");

// Subscription Collection Reference
export const subscriptionCollectionRef = collection(database, "subscriptions");

// PayPerSubjectSubscription Collection Reference
export const payPerSubjectSubscriptionCollectionRef = collection(
  database,
  "payPerSubjectSubscriptions"
);

// Subject Collection Reference
export const subjectCollectionRef = collection(database, "subjects");

// Transaction Collection Reference
export const transactionCollectionRef = collection(database, "transactions");

// Chapter Collection Reference
export const chapterCollectionRef = collection(database, "chapters");

// Feedback Collection Reference
export const feedbackCollectionRef = collection(database, "feedback");

// ChapterFiles Collection Reference
export const chapterFileCollectionRef = collection(database, "chapterFiles");

// VideoViews Collection Reference
export const videoViewsCollectionRef = collection(database, "videoViews");

// VideoStreamStats Collection Reference
export const videoStreamStatCollectionRef = collection(
  database,
  "videoStreamStats"
);

// Booking Collection Reference
export const bookingCollectionRef = collection(database, "bookings");

// PrivateSessions Collection Reference
export const privateSessionCollectionRef = collection(
  database,
  "privateSessions"
);

/**
 * Send User Email Callable Function Reference
 * @param {string} email User emails
 * @param {string} emailType Email type
 */
export const sendUserEmail = (email, emailType) =>
  httpsCallable(functions, "sendUserEmail")({ email, emailType });

/**
 * Get Private Session Booking Availability Dates And Time Callable Function Reference
 * @param {string} privateSessionId Private session id
 */
export const getPrivateSessionAvailabilityDatesAndTime = (privateSessionId) =>
  httpsCallable(
    functions,
    "getPrivateSessionAvailabilityDatesAndTime"
  )(privateSessionId);

/**
 * Upload User Avatar Callable Function Reference
 * @param {object|string} avatar Avatar url(base64)
 */
export const uploadUserAvatar = (avatar) =>
  httpsCallable(functions, "uploadUserAvatar")(avatar);
