// Modules
import React from "react";

// Components
import { Button } from "../../";

// Style
import "./recruitment-gateway-hero.styles.scss";

export const RecruitmentGatewayHero = ({ className }) => {
  return (
    <div className={`recruitment-gateway-hero secondary-hero ${className}`}>
      <div className="recruitment-gateway-hero__container container secondary-hero__container">
        <h1 className="recruitment-gateway-hero__heading secondary-hero__heading is-text-accent-300 primary-heading">
          Recruitment <span className="is-text-accent-400">Gateway</span>
        </h1>

        <p className="recruitment-gateway-hero__description secondary-hero__description">
          Linking trained professionals to appropriate job opportunities via our
          recruitment gateway
        </p>

        <Button className="recruitment-gateway-hero__btn secondary-hero__btn btn--tertiary btn--rv3 btn--sv6">
          Coming Soon
        </Button>
      </div>
    </div>
  );
};

RecruitmentGatewayHero.defaultProps = {
  className: "",
};
