// Modules
import React from "react";

// Components
import { Oval } from "react-loader-spinner";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

// Style
import "./bar-chart.styles.scss";

export const BarChart = ({ className, chartData, chartOptions, isLoading }) => {
  return (
    <div className={`chart-wrapper chart-wrapper--bar ${className}`}>
      {/* Show Loading Spinner When Getting Chart Data */}
      {isLoading && (
        <Oval
          height={30}
          width={60}
          color="#f9a22f"
          wrapperClass="chart__loading-wrapper"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#f9a22f"
          strokeWidth={6}
          strokeWidthSecondary={6}
        />
      )}

      {/* If Chart Data Does Not Exist Then Show Empty Message */}
      {!chartData && !isLoading && (
        <div className="chart__empty">
          <h6 className="chart__empty-heading">
            We don’t have enough data to show anything useful.
          </h6>
          <p className="chart__empty-content">
            It usually takes about a week to populate this graph.
          </p>
        </div>
      )}

      {chartData && !isLoading && (
        <Bar
          className="chart chart--bar"
          data={chartData}
          options={chartOptions}
        />
      )}
    </div>
  );
};

BarChart.defaultProps = {
  className: "",
  isLoading: false,
};
