// Modules
const { IpregistryClient } = require("@ipregistry/client");

// Init Ip Registry Client
const client = new IpregistryClient(process.env.REACT_APP_IPREGISTRY_KEY);

/**
 * Get User Ip Info
 * @param {string|undefined} ip User ip, if undefined, public ip is used
 */
export const getUserIp = async (ip) => {
  try {
    // Get User Ip
    const response = await client.lookup(ip);

    // Return Response Data
    return response.data;
  } catch (err) {
    // If Any Error Occur Return Ip Info As Unknown
    return "unknown";
  }
};
