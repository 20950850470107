// Modules
import React from "react";

// Hooks
import { useEffect } from "react";
import { useFormik } from "formik";
import {
  useValidateFormOnMouseEnter,
  useAuth,
  useReferrerMedium,
  useTraining,
} from "../../../hooks";

// Components
import { Link } from "react-router-dom";
import {
  Input,
  InputSelect,
  InputCheckbox,
  PhoneNumber,
  Form,
  Button,
  SocialLogin,
} from "../../../components";

// Utils
import { getTrainingAcronym } from "../../../utils";

// Schemas
import { authSchema } from "../../../schemas";

// Style
import "./signup.styles.scss";

export const Signup = () => {
  const { isAuthLoading, signUpUser, dispatch } = useAuth();
  const {
    referrerMediumErrorMessage,
    isReferrerMediumLoading,
    referrerMediums,
    getAllReferrerMediums,
  } = useReferrerMedium();
  const {
    trainings,
    isTrainingLoading,
    trainingErrorMessage,
    getTrainingsAndStat,
  } = useTraining();

  const {
    values,
    touched,
    errors,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    validateForm,
    setTouched,
  } = useFormik({
    initialValues: {
      authType: "register",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirm: "",
      phoneNumber: "",
      referrerMedium: "",
      dialCode: "",
      program: "",
      tc: false,
    },
    validationSchema: authSchema,
    onSubmit: async (values) => {
      // Compute Phone Number
      const phoneNumber = `(${values.dialCode}) ${values.phoneNumber.replace(
        values.dialCode,
        ""
      )}`;

      // Signup Data
      const signupData = {
        ...values,
        phoneNumber,
      };

      // Delete AuthType
      delete signupData.authType;

      // Signup User
      await dispatch(signUpUser(signupData));
    },
  });

  const { triggerFormValidation } = useValidateFormOnMouseEnter(
    validateForm,
    setTouched
  );

  useEffect(() => {
    // Get All Referrer Mediums
    dispatch(getAllReferrerMediums());

    // Get All Trainings
    dispatch(getTrainingsAndStat());
  }, []);

  return (
    <div className="signup">
      <div className="signup__container">
        <h1 className="signup__heading auth-layout__heading">
          Hey, We are glad you <br />
          choose FinaccoHub
        </h1>

        <p className="signup__spacer spacer">
          <span className="spacer__text">Lets get started</span>
        </p>

        <SocialLogin className="signup__social-login" />

        <Form
          className="signup__form auth-layout__form"
          onSubmit={handleSubmit}
        >
          <Input
            className="signup__control"
            labelClassName="signup__label"
            wrapperClassName="signup__group"
            id="firstName"
            name="firstName"
            label="First Name"
            placeholder="Enter your first name"
            value={values.firstName}
            error={errors.firstName}
            touched={touched.firstName}
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <Input
            className="signup__control"
            labelClassName="signup__label"
            wrapperClassName="signup__group"
            id="lastName"
            name="lastName"
            label="Last Name"
            placeholder="Enter your last name"
            value={values.lastName}
            error={errors.lastName}
            touched={touched.lastName}
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <Input
            className="signup__control"
            labelClassName="signup__label"
            wrapperClassName="signup__group"
            id="email"
            name="email"
            label="Email"
            type="email"
            placeholder="Enter your email"
            value={values.email}
            error={errors.email}
            touched={touched.email}
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <PhoneNumber
            className="signup__control"
            labelClassName="signup__label"
            wrapperClassName="signup__group"
            name="phoneNumber"
            label="Mobile number"
            placeholder="Enter your phone number"
            value={values.phoneNumber}
            error={errors.phoneNumber}
            touched={touched.phoneNumber}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
          />

          <Input
            className="signup__control"
            labelClassName="signup__label"
            wrapperClassName="signup__group"
            id="password"
            name="password"
            label="Password"
            type="password"
            placeholder="Please enter your password"
            value={values.password}
            error={errors.password}
            touched={touched.password}
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <Input
            className="signup__control"
            labelClassName="signup__label"
            wrapperClassName="signup__group"
            id="passwordConfirm"
            name="passwordConfirm"
            label="Confirm Password"
            type="password"
            placeholder="Please confirm your password"
            value={values.passwordConfirm}
            error={errors.passwordConfirm}
            touched={touched.passwordConfirm}
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <InputSelect
            className="signup__control signup__control--select"
            labelClassName="signup__label signup__label--select"
            wrapperClassName="signup__label signup__group--select"
            type="select"
            id="program"
            name="program"
            label="Which program are you registering for?"
            placeholder="Choose the program you are registering for"
            value={values.program}
            error={errors.program}
            touched={touched.program}
            isLoading={isTrainingLoading}
            fetchError={trainingErrorMessage}
            items={trainings.map(({ title, id }) => ({
              type: getTrainingAcronym(title),
              id,
            }))}
            onChange={setFieldValue}
          />

          <InputSelect
            className="signup__control signup__control--select"
            labelClassName="signup__label signup__label--select"
            wrapperClassName="signup__label signup__group--select"
            type="select"
            id="referrerMedium"
            name="referrerMedium"
            label="How did you Hear about Us?"
            placeholder="Choose the method you hear about us"
            value={values.referrerMedium}
            error={errors.referrerMedium}
            touched={touched.referrerMedium}
            isLoading={isReferrerMediumLoading}
            fetchError={referrerMediumErrorMessage}
            items={referrerMediums}
            onChange={setFieldValue}
          />

          <InputCheckbox
            className="signup__control--checkbox"
            labelClassName="signup__label--checkbox"
            wrapperClassName="signup__group--checkbox"
            id="tc"
            name="tc"
            checked={values.tc}
            touched={touched.tc}
            error={errors.tc}
            label={
              <p className="signup__checkbox-label-text">
                I agree with{" "}
                <Link
                  className="signup__checkbox-label-link is-underline"
                  to="/terms-condition"
                >
                  Terms and Condition
                </Link>
              </p>
            }
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <div className="signup__form-btn-group auth-layout__form-btn-group">
            <Button
              type="submit"
              className="signup__btn auth-layout__form-btn btn--primary btn--rv2 btn--sv2"
              disabled={isAuthLoading || !isValid}
              loading={isAuthLoading}
              onMouseEnter={() => triggerFormValidation(isValid)}
            >
              Sign up
            </Button>
          </div>

          <p className="signup__ref-link auth-layout__ref-link">
            Already Have Account?{" "}
            <Link className="is-underline" to="/auth">
              Sign In
            </Link>
          </p>
        </Form>
      </div>
    </div>
  );
};
