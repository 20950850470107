// Modules
import React from "react";

// Hooks
import { useEffect } from "react";
import { useUrlAsTitle, useStaff } from "../../../hooks";

// Components
import { Outlet } from "react-router-dom";
import { StaffStatsBase, StaffsBase } from "../../../components";

export const Staff = () => {
  // Set Dashboard Page Title
  useUrlAsTitle("Staffs");
  const { dispatch, getStaffsAndStat } = useStaff();

  useEffect(() => {
    // Get All Staffs And Stat
    dispatch(getStaffsAndStat());
  }, []);

  return (
    <div className="staff is-height-full">
      <div className="staff__container is-height-full">
        <StaffStatsBase />
        <StaffsBase />
        <Outlet />
      </div>
    </div>
  );
};
