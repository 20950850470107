// Modules
import React from "react";

// Components
import { NavLink, Link } from "react-router-dom";
import { AiOutlineAppstore } from "react-icons/ai";
import { FiUsers, FiImage, FiUser, FiTrendingUp } from "react-icons/fi";
import { BsBoxSeam } from "react-icons/bs";
import { Button } from "../..";

// Hooks
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks";

// Images
import { ReactComponent as FinaccoLogo } from "../../../assets/images/finacco-logo.svg";

// Style
import "./admin-sidenavbar.styles.scss";

export const AdminSideNavbar = ({ className }) => {
  const { handleLogout } = useAuth();
  const navigate = useNavigate();

  const logout = async () => {
    await handleLogout();
    navigate("/auth/login");
  };

  return (
    <div className={`admin-sidenavbar ${className}`}>
      <div className="admin-sidenavbar__container">
        <div className="admin-sidenavbar__brand">
          <Link className="admin-sidenavbar__brand-link" to="/">
            <FinaccoLogo
              className="admin-sidenavbar__brand-logo"
              aria-label="Finacco Logo"
            />
          </Link>
        </div>

        <nav className="admin-sidenavbar__navbar">
          <ul className="admin-sidenavbar__list">
            <div className="admin-sidenavbar__list-top">
              {/* <li className="admin-sidenavbar__item">
                <NavLink
                  to="/admin/dashboard"
                  className={({ isActive }) =>
                    isActive
                      ? "admin-sidenavbar__link admin-sidenavbar__link--active"
                      : "admin-sidenavbar__link"
                  }
                >
                  <AiOutlineAppstore
                    className="admin-sidenavbar__link-icon"
                    aria-label="dashboard icon"
                  />
                  Dashboard
                </NavLink>
              </li> */}

              <li className="admin-sidenavbar__item">
                <NavLink
                  to="/admin/students"
                  className={({ isActive }) =>
                    isActive
                      ? "admin-sidenavbar__link admin-sidenavbar__link--active"
                      : "admin-sidenavbar__link"
                  }
                >
                  <FiUsers
                    className="admin-sidenavbar__link-icon"
                    aria-label="student icon"
                  />
                  Students
                </NavLink>
              </li>

              <li className="admin-sidenavbar__item">
                <NavLink
                  to="/admin/trainings"
                  className={({ isActive }) =>
                    isActive
                      ? "admin-sidenavbar__link admin-sidenavbar__link--active"
                      : "admin-sidenavbar__link"
                  }
                >
                  <FiImage
                    className="admin-sidenavbar__link-icon"
                    aria-label="training icon"
                  />
                  Trainings
                </NavLink>
              </li>

              <li className="admin-sidenavbar__item">
                <NavLink
                  to="/admin/staffs"
                  className={({ isActive }) =>
                    isActive
                      ? "admin-sidenavbar__link admin-sidenavbar__link--active"
                      : "admin-sidenavbar__link"
                  }
                >
                  <FiUser
                    className="admin-sidenavbar__link-icon"
                    aria-label="staff icon"
                  />
                  Staffs
                </NavLink>
              </li>

              <li className="admin-sidenavbar__item">
                <NavLink
                  to="/admin/bookings"
                  className={({ isActive }) =>
                    isActive
                      ? "admin-sidenavbar__link admin-sidenavbar__link--active"
                      : "admin-sidenavbar__link"
                  }
                >
                  <BsBoxSeam
                    className="admin-sidenavbar__link-icon"
                    aria-label="booking icon"
                  />
                  Bookings
                </NavLink>
              </li>

              <li className="admin-sidenavbar__item">
                <NavLink
                  to="/admin/transactions"
                  className={({ isActive }) =>
                    isActive
                      ? "admin-sidenavbar__link admin-sidenavbar__link--active"
                      : "admin-sidenavbar__link"
                  }
                >
                  <FiTrendingUp
                    className="admin-sidenavbar__link-icon"
                    aria-label="transaction icon"
                  />
                  Transaction
                </NavLink>
              </li>
            </div>

            <div className="admin-sidenavbar__list-bottom">
              <li className="admin-sidenavbar__item">
                <Button
                  wrapperClassName="d-block"
                  className="admin-sidenavbar__link-btn btn--color-secondary is-width-full btn--rv5 btn--sv4"
                  onClick={logout}
                >
                  Log Out
                </Button>
              </li>
            </div>
          </ul>
        </nav>
      </div>
    </div>
  );
};

AdminSideNavbar.defaultProps = {
  className: "",
};
