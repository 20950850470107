// Modules
import React from "react";

// Components
import {
  Dialog as AlertDialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Button, Form, InputSelect } from "../";

// Style
import "./upgrade-subscription-dialog.styles.scss";

export const UpgradeSubscriptionDialog = ({
  open,
  onClose,
  onConfirm,
  dialogTitle,
  dialogDescription,
  upgradeSubscriptionTypes,
  upgradeSubscriptionType,
  setUpgradeSubscriptionType,
}) => {
  /**
   * Handle Subscription Value Change
   * @param {string} name Input name
   * @param {string} value Input value
   */
  const onHandleSubscriptionTypeChange = (_, value) => {
    setUpgradeSubscriptionType(value);
  };

  return (
    <AlertDialog
      className="upgrade-subscription-dialog"
      open={open}
      onClose={onClose}
      aria-labelledby={dialogTitle}
      aria-describedby={dialogDescription}
    >
      <DialogTitle className="upgrade-subscription-dialog__title">
        {dialogTitle}
      </DialogTitle>

      <DialogContent className="upgrade-subscription-dialog__content">
        <DialogContentText className="upgrade-subscription-dialog__content-text">
          {dialogDescription}
        </DialogContentText>

        <Form className="upgrade-subscription-dialog__form">
          <InputSelect
            labelClassName="upgrade-subscription-dialog__label"
            wrapperClassName="upgrade-subscription-dialog__group"
            className="upgrade-subscription-dialog__control"
            label="Select Subscription Type"
            name="subscriptionType"
            tertiary
            items={upgradeSubscriptionTypes}
            value={upgradeSubscriptionType}
            onChange={onHandleSubscriptionTypeChange}
          />

          <Button
            wrapperClassName="d-block is-width-full"
            className="upgrade-subscription-dialog__btn btn--primary btn--rv1 btn--sv11 is-width-full"
            disabled={!upgradeSubscriptionType}
            onClick={onConfirm}
          >
            Checkout
          </Button>
        </Form>
      </DialogContent>
    </AlertDialog>
  );
};

UpgradeSubscriptionDialog.defaultProps = {
  open: false,
};
