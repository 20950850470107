// Redux
import {
  subscriptionStartLoading,
  subscriptionStopLoading,
  setRecurrentSubscriptions,
  setSubscriptionErrorMessage,
  resetSubscriptionErrorMessage,
  setPayPerSubjectSubscriptions,
  setSubscriptionHistories,
} from ".";

// Apis
import * as apis from "../../apis";

/**
 * Get Student Pay Per Subject Subscriptions
 * @param {string} studentId Student Id
 * @param {boolean} onlyActive If true get only active pay per subject subscriptions
 */
export const getStudentPayPerSubjectSubscriptions =
  (studentId, onlyActive = true) =>
  async (dispatch) => {
    try {
      // Reset Error Message
      dispatch(resetSubscriptionErrorMessage());

      // Start Loading
      dispatch(subscriptionStartLoading());

      // Get Student PayPerSubject Subscriptions
      const payPerSubjectSubscriptions =
        await apis.getStudentPayPerSubjectSubscriptions(studentId, onlyActive);

      // Update PayPerSubject Subscriptions
      dispatch(setPayPerSubjectSubscriptions(payPerSubjectSubscriptions));
    } catch (err) {
      // Update Error Message
      dispatch(setSubscriptionErrorMessage(err.message.message));
    } finally {
      // Stop Loading
      dispatch(subscriptionStopLoading());
    }
  };

/**
 * Get Student Level Pay Per Subject Subscriptions
 * @param {string} studentId Student Id
 * @param {string} levelId Level Id
 * @param {boolean} onlyActive If true get only active pay per subject subscriptions
 * @param {boolean} throwNotFound If true throw not found error
 */
export const getStudentLevelPayPerSubjectSubscriptions =
  (studentId, levelId, onlyActive = true, throwNotFound = true) =>
  async (dispatch) => {
    try {
      // Reset Error Message
      dispatch(resetSubscriptionErrorMessage());

      // Start Loading
      dispatch(subscriptionStartLoading());

      // Get Student Level PayPerSubject Subscriptions
      const payPerSubjectSubscriptions =
        await apis.getStudentLevelPayPerSubjectSubscriptions(
          studentId,
          levelId,
          onlyActive,
          throwNotFound
        );

      // Update PayPerSubject Subscriptions
      dispatch(setPayPerSubjectSubscriptions(payPerSubjectSubscriptions));
    } catch (err) {
      // Update Error Message
      dispatch(setSubscriptionErrorMessage(err.message.message));
    } finally {
      // Stop Loading
      dispatch(subscriptionStopLoading());
    }
  };

/**
 * Get Student Recurrent Subscriptions
 * @param {string} studentId Student Id
 * @param {boolean} onlyActive If true get only active recurrent subscriptions
 */
export const getStudentRecurrentSubscriptions =
  (studentId, onlyActive = true) =>
  async (dispatch) => {
    try {
      // Reset Error Message
      dispatch(resetSubscriptionErrorMessage());

      // Start Loading
      dispatch(subscriptionStartLoading());

      // Get Student Recurrent Subscriptions
      const recurrentSubscriptions =
        await apis.getStudentRecurrentSubscriptions(studentId, onlyActive);

      // Update Recurrent Subscriptions
      dispatch(setRecurrentSubscriptions(recurrentSubscriptions));
    } catch (err) {
      // Update Error Message
      dispatch(setSubscriptionErrorMessage(err.message.message));
    } finally {
      // Stop Loading
      dispatch(subscriptionStopLoading());
    }
  };

/**
 * Get Student Level Recurrent Subscriptions
 * @param {string} studentId Student Id
 * @param {string} levelId Level Id
 * @param {boolean} onlyActive If true get only active recurrent subscriptions
 */
export const getStudentLevelRecurrentSubscriptions =
  (studentId, levelId, onlyActive = true) =>
  async (dispatch) => {
    try {
      // Reset Error Message
      dispatch(resetSubscriptionErrorMessage());

      // Start Loading
      dispatch(subscriptionStartLoading());

      // Get Student Level Recurrent Subscriptions
      const recurrentSubscriptions =
        await apis.getStudentLevelRecurrentSubscriptions(
          studentId,
          levelId,
          onlyActive
        );

      // Update Recurrent Subscriptions
      dispatch(setRecurrentSubscriptions(recurrentSubscriptions));
    } catch (err) {
      // Update Error Message
      dispatch(setSubscriptionErrorMessage(err.message.message));
    } finally {
      // Stop Loading
      dispatch(subscriptionStopLoading());
    }
  };

/**
 * Get Student Subscription Histories
 * @param {string} studentId Student id
 * @param {boolean} onlyActiveSubscription If true get only active recurrent subscription
 * @param {boolean} onlyActive If true get subscription that has not been deleted
 */
export const getStudentSubscriptionHistories =
  (studentId, onlyActiveSubscription = true, onlyActive = true) =>
  async (dispatch) => {
    try {
      // Reset Error Message
      dispatch(resetSubscriptionErrorMessage());

      // Start Loading
      dispatch(subscriptionStartLoading());

      // Get Student Subscription Histories
      const subscriptionHistories = await apis.getStudentSubscriptionHistories(
        studentId,
        onlyActiveSubscription,
        onlyActive
      );

      // Update Subscription Histories
      dispatch(setSubscriptionHistories(subscriptionHistories));
    } catch (err) {
      // Update Error Message
      dispatch(setSubscriptionErrorMessage(err.message.message));
    } finally {
      // Stop Loading
      dispatch(subscriptionStopLoading());
    }
  };
