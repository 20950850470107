// Modules
import React from "react";

// Components
import { Form, Input, Button, Editor } from "../../..";

// Hooks
import { useValidateFormOnMouseEnter, useTraining } from "../../../../hooks";

// Style
import "./add-training-form.styles.scss";

export const AddTrainingForm = ({ className, formik, isEditMode }) => {
  const { isTrainingLoading } = useTraining();

  const {
    touched,
    values,
    errors,
    isValid,
    handleChange,
    handleBlur,
    validateForm,
    setFieldValue,
    setTouched,
    setFieldTouched,
    handleSubmit,
  } = formik;

  const { triggerFormValidation } = useValidateFormOnMouseEnter(
    validateForm,
    setTouched
  );

  return (
    <Form
      className={`add-training-form admin-form container ${className}`}
      onSubmit={handleSubmit}
    >
      <Input
        className="add-training-form__control admin-form__control"
        wrapperClassName="add-training-form__group admin-form__group"
        labelClassName="add-training-form__label"
        placeholder="Training Name"
        name="title"
        id="trainingName"
        value={values.title}
        error={errors.title}
        touched={touched.title}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Input
        className="add-training-form__control admin-form__control"
        wrapperClassName="add-training-form__group admin-form__group"
        labelClassName="add-training-form__label"
        type="textarea"
        placeholder="Summary"
        name="summary"
        id="summary"
        value={values.summary}
        error={errors.summary}
        touched={touched.summary}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Editor
        wrapperClassName="add-training-form__editor"
        className="add-training-form__editor-control admin-form__control"
        name="description"
        placeholder="Description"
        value={values.description}
        touched={touched.description}
        error={errors.description}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
      />

      <div className="add-training-form__btn-group admin-form__btn-group">
        <Button
          className="add-training-form__btn admin-form__btn btn--primary btn--rv1 btn--sv9"
          type="submit"
          disabled={!isValid || isTrainingLoading}
          loading={isTrainingLoading}
          loadingHeight="26"
          onMouseEnter={() => triggerFormValidation(isValid)}
        >
          {isEditMode ? "Update" : "Publish"}
        </Button>
      </div>
    </Form>
  );
};
