// Modules
import React from "react";

// Components
import { PricingTag } from "../../";

// Style
import "./pricing-tag-list.styles.scss";

export const PricingTagList = ({ className, items }) => {
  // Pricing Tag Items
  const renderedPricingTagItems = items.map((item, index) => (
    <PricingTag key={index} className="pricing-tag-list__item" content={item} />
  ));

  return (
    <div className={`pricing-tag-list ${className}`}>
      {renderedPricingTagItems}
    </div>
  );
};

PricingTagList.defaultProps = {
  className: "",
  items: [],
};
