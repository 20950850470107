// Hooks
import { useSelector, useDispatch } from "react-redux";

// Redux
import { newsletterSA } from "../store";

// Destructure Redux Imports
const { selectIsNewsLetterLoading } = newsletterSA;

/**
 * Newsletter Hook
 */
export const useNewsletter = () => {
  const dispatch = useDispatch();
  const isNewsletterLoading = useSelector(selectIsNewsLetterLoading);

  return {
    isNewsletterLoading,
    dispatch,
    ...newsletterSA,
  };
};
