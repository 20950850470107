// Modules
import React from "react";

// Hooks
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Components
import { IoIosArrowUp } from "react-icons/io";

// Style
import "./scroll-to-top.styles.scss";

export const ScrollToTop = ({ className }) => {
  const { pathname } = useLocation();
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const isHomePage = pathname === "/";
    const targetElement = document.querySelector(".home-hero");

    /**
     * Intersection Observer Callback
     * @param {array} entries Threshold entries
     */
    const observerCallback = ([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    };

    // Create The Observer
    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      threshold: 0.7,
    });

    // Observe If It Is Home Page
    isHomePage && targetElement && observer.observe(targetElement);

    return () => {
      // Un-Observe/Reset IsIntersecting If Page Is Not Home Page
      if (targetElement) {
        observer.unobserve(targetElement);
        setIsIntersecting(false);
      }
    };
  }, [pathname]);

  /**
   * Scroll To Top Of A Page
   */
  const handleScrollToPageTop = () => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  };

  return (
    <div
      className={`scroll-to-top ${className} ${
        isIntersecting && "scroll-to-top--intersecting"
      }`}
      onClick={handleScrollToPageTop}
    >
      <IoIosArrowUp
        className="scroll-to-top__icon"
        aria-label="scroll to top of the page"
      />
    </div>
  );
};

ScrollToTop.defaultProps = {
  className: "",
};
