// Modules
import React from "react";

// Components
import { Form, InputSelect, InputToggle, Input } from "../../..";

// Static Data
import { SPONSORSHIP_TYPE_ITEMS } from "../../../../data";

export const ProfileEmploymentForm = ({
  className,
  onHandleChange,
  onToggleInputEditable,
  formik,
}) => {
  const { values, errors, touched, setFieldValue, handleBlur } = formik;

  return (
    <Form className={`profile-base__form ${className}`}>
      <Input
        className="profile-base__control"
        wrapperClassName="profile-base__group"
        labelClassName="profile-base__label"
        name="empName"
        id="empName"
        label="Employer Name"
        value={values.empName.value}
        placeholder="Enter employer name"
        secondary
        error={errors.empName?.value}
        touched={touched.empName}
        show={values.empName.editable}
        actionText="Change"
        onSecondaryActionClick={onToggleInputEditable}
        onChange={onHandleChange}
        onBlur={!values.empName.editable ? () => {} : handleBlur}
      />

      <Input
        className="profile-base__control"
        wrapperClassName="profile-base__group"
        labelClassName="profile-base__label"
        name="empEmail"
        id="empEmail"
        type="email"
        error={errors.empEmail?.value}
        touched={touched.empEmail}
        label="Employer Email"
        placeholder="Enter employer email"
        value={values.empEmail.value}
        secondary
        show={values.empEmail.editable}
        actionText="Change"
        onSecondaryActionClick={onToggleInputEditable}
        onChange={onHandleChange}
        onBlur={!values.empEmail.editable ? () => {} : handleBlur}
      />

      <Input
        className="profile-base__control"
        wrapperClassName="profile-base__group"
        labelClassName="profile-base__label"
        name="empRole"
        id="empRole"
        label="What is your Role"
        placeholder="Enter your role"
        value={values.empRole.value}
        secondary
        error={errors.empRole?.value}
        touched={touched.empRole}
        show={values.empRole.editable}
        actionText="Change"
        onSecondaryActionClick={onToggleInputEditable}
        onChange={onHandleChange}
        onBlur={!values.empRole.editable ? () => {} : handleBlur}
      />

      <Input
        className="profile-base__control"
        wrapperClassName="profile-base__group"
        labelClassName="profile-base__label"
        name="empCompany"
        id="empCompany"
        label="Employer Business Name"
        placeholder="Enter business name"
        value={values.empCompany.value}
        secondary
        error={errors.empCompany?.value}
        touched={touched.empCompany}
        show={values.empCompany.editable}
        actionText="Change"
        onSecondaryActionClick={onToggleInputEditable}
        onChange={onHandleChange}
        onBlur={!values.empCompany.editable ? () => {} : handleBlur}
      />

      <Input
        className="profile-base__control"
        wrapperClassName="profile-base__group"
        labelClassName="profile-base__label"
        name="officeAddress"
        id="officeAddress"
        label="Office Address"
        placeholder="Enter office address"
        value={values.officeAddress.value}
        secondary
        error={errors.officeAddress?.value}
        touched={touched.officeAddress}
        show={values.officeAddress.editable}
        actionText="Change"
        onSecondaryActionClick={onToggleInputEditable}
        onChange={onHandleChange}
        onBlur={!values.officeAddress.editable ? () => {} : handleBlur}
      />

      <Input
        className="profile-base__control-textarea"
        wrapperClassName="profile-base__group-textarea"
        labelClassName="profile-base__label-textarea"
        name="roleSummary"
        id="roleSummary"
        type="textarea"
        label="Role Summary"
        secondary
        error={errors.roleSummary?.value}
        touched={touched.roleSummary}
        actionText="Change"
        placeholder="Describe your Role"
        value={values.roleSummary.value}
        show={values.roleSummary.editable}
        onSecondaryActionClick={onToggleInputEditable}
        onChange={onHandleChange}
        onBlur={!values.roleSummary.editable ? () => {} : handleBlur}
      />

      <InputToggle
        className="profile-base__control-toggle"
        wrapperClassName="profile-base__group-toggle"
        labelClassName="profile-base__label-toggle"
        name="isSponsored"
        id="isSponsored"
        label="Are you been sponsored by Employer?"
        value={values.isSponsored}
        onChange={setFieldValue}
      />

      {values.isSponsored && (
        <InputSelect
          className="profile-base__control-select"
          wrapperClassName="profile-base__group-select"
          labelClassName="profile-base__label-select"
          name="sponsorshipType"
          id="sponsorshipType"
          label="Is it Partial or Full Sponsorhip"
          placeholder="Choose"
          value={values.sponsorshipType}
          touched={touched.sponsorshipType}
          error={errors.sponsorshipType}
          secondary
          items={SPONSORSHIP_TYPE_ITEMS}
          onChange={setFieldValue}
        />
      )}
    </Form>
  );
};
