// Modules
import React from "react";

// Components
import { DashboardChartStatistic } from "../../../";

// Style
import "./dashboard-base.styles.scss";

export const DashboardBase = ({ className }) => {
  return (
    <div className={`dashboard-base ${className}`}>
      <div className="dashboard-base__container admin-layout__content-main">
        <DashboardChartStatistic className="dashboard-base__chart-statistic" />
      </div>
    </div>
  );
};

DashboardBase.defaultProps = {
  className: "",
};
