// Modules
import React from "react";

// Components
import { StatsCard, StatsList } from "../../../";

// Hooks
import { useTraining } from "../../../../hooks";

export const TrainingStatsBase = ({ className }) => {
  const { trainingStat } = useTraining();

  return (
    <div className={`training-stats-base ${className}`}>
      <div className="training-stats-base__container">
        <StatsList className="training-stats-base__list">
          <StatsCard
            className="training-stats-base__card"
            title={`${trainingStat.totalTrainings} Trainings`}
            subtitle="In Total"
          />

          <StatsCard
            className="training-stats-base__card"
            title={`0 Subjects`}
            subtitle="In Total"
          />

          <StatsCard
            className="training-stats-base__card"
            title={`0 Videos`}
            subtitle="In Total"
            isSecondary
          />
        </StatsList>
      </div>
    </div>
  );
};

TrainingStatsBase.defaultProps = {
  className: "",
};
