// Hooks
import { useSelector, useDispatch } from "react-redux";

// Redux
import { studentSA } from "../store";

// Destructure Redux Imports
const {
  selectEnrolledSubjects,
  selectStudentErrorMessage,
  selectIsStudentLoading,
  selectStudents,
  selectStudentStat,
  selectPayPerSubjectSubscriptionEnrolledSubjects,
  selectRecurrentSubscriptionEnrolledSubjects,
  selectLoadingStatName,
} = studentSA;

/**
 * Student Hook
 */
export const useStudent = () => {
  const dispatch = useDispatch();
  const enrolledSubjects = useSelector(selectEnrolledSubjects);
  const students = useSelector(selectStudents);
  const studentErrorMessage = useSelector(selectStudentErrorMessage);
  const isStudentLoading = useSelector(selectIsStudentLoading);
  const studentStat = useSelector(selectStudentStat);
  const payPerSubjectSubscriptionEnrolledSubjects = useSelector(
    selectPayPerSubjectSubscriptionEnrolledSubjects
  );
  const recurrentSubscriptionEnrolledSubjects = useSelector(
    selectRecurrentSubscriptionEnrolledSubjects
  );
  const loadingStatName = useSelector(selectLoadingStatName);

  return {
    enrolledSubjects,
    students,
    studentErrorMessage,
    isStudentLoading,
    studentStat,
    payPerSubjectSubscriptionEnrolledSubjects,
    recurrentSubscriptionEnrolledSubjects,
    loadingStatName,
    dispatch,
    ...studentSA,
  };
};
