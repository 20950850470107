// Modules
import React from "react";

// Style
import "./message-banner.styles.scss";

export const MessageBanner = ({ className, message, show, children }) => {
  return (
    <div className={`message-banner ${className} ${!show ? "d-none" : ""}`}>
      <p className="message-banner__text">{message}</p>
      {children}
    </div>
  );
};

MessageBanner.defaultProps = {
  className: "",
  show: true,
};
