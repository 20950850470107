// Modules
import React from "react";

// Components
import { Button, EmptyContent, StudentTable } from "../../..";

// Hooks
import { useState } from "react";
import { useStudent, useUi } from "../../../../hooks";

// Utils
import { downloadDataAsExcel } from "../../../../utils";

export const StudentBase = ({ className }) => {
  const [selectedStudents, setSelectedStudents] = useState({});
  const [isDownloadingStudent, setIsDownloadingStudent] = useState(false);
  const { isStudentLoading, studentErrorMessage } = useStudent();
  const { dispatch, showNotification } = useUi();

  /**
   * Handle Download Student Data As Excel
   */
  const onHandleDownloadStudent = async () => {
    try {
      // Get Selected Students
      const selectedStudentsArr = Object.values(selectedStudents);

      // If Selected Students Is Not Empty, Then Download As Excel
      if (selectedStudentsArr.length) {
        // Update Is Downloading Student
        setIsDownloadingStudent(true);

        await downloadDataAsExcel(
          selectedStudentsArr,
          "student",
          `Students-${Date.now()}`
        );

        // Reset Is Downloading Student
        setIsDownloadingStudent(false);
      } else {
        // Show Info In Notification
        dispatch(
          showNotification({
            type: "info",
            message: "Select Students To Download",
          })
        );
      }
    } catch (_) {
      // Show Error In Notification
      dispatch(
        showNotification({
          type: "error",
          message: "Could not download student data",
        })
      );
    }
  };

  return (
    <div className={`student-base ${className} is-height-full`}>
      <div className="student-base__container is-height-full">
        <div className="student-base__header admin-layout__content-header">
          <Button
            className="student-base__btn btn--primary btn--sv8 btn--rv5 admin-layout__content-btn"
            loading={isDownloadingStudent}
            loadingHeight="20"
            disabled={isDownloadingStudent}
            onClick={onHandleDownloadStudent}
          >
            Download Data
          </Button>
        </div>

        <div className="student-base__main admin-layout__content-main">
          <EmptyContent
            className="student-base__empty"
            isLoading={isStudentLoading}
            errorMessage={studentErrorMessage}
          >
            <StudentTable
              className="student-base__table"
              selectedStudents={selectedStudents}
              setSelectedStudents={setSelectedStudents}
            />
          </EmptyContent>
        </div>
      </div>
    </div>
  );
};

StudentBase.defaultProps = {
  className: "",
};
