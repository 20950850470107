// Modules
import React from "react";

// Hooks
import { useState, useEffect } from "react";

// Components
import Calendar from "react-calendar";

// Style
import "react-calendar/dist/Calendar.css";
import "./input-date-picker.styles.scss";

export const InputDatePicker = ({
  className,
  value,
  id,
  name,
  placeholder,
  dateFormat,
  onChange,
  onBlur,
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  /**
   * Handle Input Change
   * @param {string} value Date value
   */
  const onHandleChange = (value) => {
    onChange(name, value);
    setIsCalendarOpen(false);
  };

  /**
   * Handle Input Blur
   * @param {object} e Event object
   */
  const onHandleBlur = (value) => {
    onBlur(name, value);
  };

  /**
   * Toggle Calendar Visibility
   */
  const toggleCalendarVisibility = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  useEffect(() => {
    /**
     * Close Calendar Visibility
     */
    const closeCalendarVisibility = (e) => {
      const datePickerEl = e.target.closest(".input-date-picker");

      // If The DatePicker Element Is Clicked, Return Function
      if (datePickerEl) return;

      // Else Close Calendar Visibility
      setIsCalendarOpen(false);
    };

    // Close Calendar On Body Click
    document.body.addEventListener("click", closeCalendarVisibility);

    // Remove Calendar on unmounted
    return () =>
      document.body.removeEventListener("click", closeCalendarVisibility);
  }, []);

  return (
    <div className="input-date-picker">
      <input
        className={`input-date-picker__control ${className}`}
        type="text"
        id={id}
        name={name}
        placeholder={placeholder}
        value={Date.parse(value).toString(dateFormat)}
        readOnly={true}
        onBlur={onHandleBlur}
        onClick={toggleCalendarVisibility}
      />

      {isCalendarOpen && (
        <Calendar
          className="input-date-picker__calendar"
          onChange={onHandleChange}
          value={value}
        />
      )}
    </div>
  );
};

InputDatePicker.defaultProps = {
  className: "",
};
