// Modules
import React from "react";

// Components
import Calendar from "react-calendar";

// Style
import "react-calendar/dist/Calendar.css";
import "./book-private-session-date.styles.scss";

export const BookPrivateSessionDate = ({
  className,
  bookingAvailableDatesAndTime,
  formik,
  setActiveMeetingDate,
}) => {
  const { setFieldValue, values } = formik;

  /**
   * Handle Date Change
   * @param {Date} newDate New date
   */
  const onDateChange = (newDate) => {
    const dateAsTimestamp = newDate.getTime();
    setFieldValue("meetingDate", dateAsTimestamp);
    setActiveMeetingDate(
      bookingAvailableDatesAndTime.find((date) => date.date === dateAsTimestamp)
    );
  };

  /**
   * Handle Disabled Dates
   * @param {object} date Date
   */
  const handleDisabledDates = ({ date }) => {
    const dateTimestamp = date.getTime();
    return !bookingAvailableDatesAndTime.some(
      (availableDate) => availableDate.date === dateTimestamp
    );
  };

  return (
    <div className={`book-private-session-date ${className}`}>
      <Calendar
        className="book-private-session-date__calendar"
        value={new Date(values.meetingDate || new Date())}
        tileDisabled={handleDisabledDates}
        onChange={onDateChange}
      />
    </div>
  );
};

BookPrivateSessionDate.defaultProps = {
  className: "",
};
