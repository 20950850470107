// Modules
import React from "react";

// Hooks
import { useTransaction, useDelete } from "../../../../../hooks";

// Components
import { Fragment } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import BoolFilter from "@inovua/reactdatagrid-community/BoolFilter";
import { IconButton } from "@mui/material";
import { MdDelete } from "react-icons/md";
import { Dialog as DeleteConfirmationDialog } from "../../../../";

// Utils
import { removeCharFromString, tableCustomFilters } from "../../../../../utils";

// Style
import "@inovua/reactdatagrid-community/index.css";

export const TransactionTable = ({ className }) => {
  const { transactions, dispatch, deleteTransaction } = useTransaction();
  const {
    isDeleteDialogOpen,
    deleteCloseActionText,
    deleteConfirmActionText,
    onHandleCloseDeleteDialog,
    onHandleDeleteConfirm,
    onHandleOpenDeleteDialog,
  } = useDelete(onDeleteTransaction);

  // Transaction Filter Value
  const transactionFilterValue = [
    {
      name: "service",
      operator: "transactionService",
      type: "service",
      value: "",
    },
    { name: "fullName", operator: "fullName", type: "fullName", value: "" },
    { name: "email", operator: "email", type: "email", value: "" },
    { name: "paymentMethod", operator: "contains", type: "string", value: "" },
    { name: "status", operator: "eq", type: "boolean" },
    {
      name: "priceUSD",
      operator: "inrange",
      type: "number",
    },
  ];

  // Transaction Columns
  const transactionColumns = [
    {
      name: "orderId",
      header: "ORDER ID",
      defaultFlex: 1,
    },
    {
      name: "service",
      header: "ORDER",
      defaultFlex: 2,
      render: ({ value: { serviceText } }) => {
        return serviceText;
      },
    },
    {
      name: "fullName",
      header: "CUSTOMER NAME",
      defaultFlex: 2,
      render: ({ data: { student } }) => {
        return `${student.firstName} ${student.lastName}`;
      },
    },
    {
      name: "email",
      header: "CUSTOMER EMAIL",
      defaultFlex: 2,
      render: ({ data: { student } }) => {
        return student.email;
      },
    },
    {
      name: "paymentMethod",
      header: "PAYMENT OPTIONS",
      defaultFlex: 2,
      render: ({ value: paymentMethod, data: { conversionCurrency } }) => {
        return (
          <Fragment>
            <span className="admin-table__text">{paymentMethod}</span>
            <span className="admin-table__text-alt">{conversionCurrency}</span>
          </Fragment>
        );
      },
    },
    {
      name: "status",
      header: "STATUS",
      defaultFlex: 2,
      type: "boolean",
      filterEditor: BoolFilter,
      render: ({ value: status, data: { paidAt } }) => {
        // Get Status In Text
        const statusText = status ? "Success" : "Pending";

        // Get Status In Text In LowerCase
        const statusTextLower = statusText.toLowerCase();

        // Get Formatted PaidAt Date
        const paidAtDate =
          status && Date.parse(paidAt.toDate()).toString("dd/MMM/yyyy");

        return (
          <Fragment>
            <span
              className={`admin-table__status admin-table__status--${statusTextLower}`}
            >
              {statusText}
            </span>
            {status && (
              <span className="admin-table__text-alt">
                Paid on {paidAtDate}
              </span>
            )}
          </Fragment>
        );
      },
    },
    {
      name: "priceUSD",
      header: "AMOUNT",
      defaultFlex: 2,
      type: "number",
      filterEditor: NumberFilter,
      render: ({ value: priceUSD, data: { subscriptionType } }) => {
        // Get Subscription Type Text
        const subscriptionTypeText =
          subscriptionType === "supportFee"
            ? "Support Fee"
            : subscriptionType === "oneTime"
            ? "Per Subject"
            : `Per ${removeCharFromString(subscriptionType, "ly")}`;

        return (
          <Fragment>
            <span className="admin-table__text">${priceUSD}</span>
            <span className="admin-table__text-alt is-capitalize">
              {subscriptionTypeText}
            </span>
          </Fragment>
        );
      },
    },
    {
      name: "id",
      header: "ACTION",
      defaultFlex: 1,
      textAlign: "center",
      render: ({ value }) => (
        <IconButton
          className="admin-table__icon-btn"
          onClick={onHandleOpenDeleteDialog.bind(null, value)}
        >
          <MdDelete className="admin-table__icon" />
        </IconButton>
      ),
    },
  ];

  /**
   * Delete A Transaction
   * @param {string} transactionId Transaction id
   */
  function onDeleteTransaction(transactionId) {
    dispatch(deleteTransaction(transactionId));
  }

  return (
    <Fragment>
      <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        dialogTitle="Confirm Delete Transaction"
        dialogDescription="You are about to delete transaction data. Confirm with yes to continue"
        closeActionText={deleteCloseActionText}
        confirmActionText={deleteConfirmActionText}
        onClose={onHandleCloseDeleteDialog}
        onConfirm={onHandleDeleteConfirm}
      />

      <div className={`transaction-table admin-table ${className}`}>
        <ReactDataGrid
          className="transaction-table__table admin-table__table"
          idProperty="id"
          columns={transactionColumns}
          dataSource={transactions}
          filterTypes={tableCustomFilters}
          defaultFilterValue={transactionFilterValue}
          headerHeight={64}
          rowHeight={64}
          defaultLimit={5}
          pagination
        />
      </div>
    </Fragment>
  );
};

TransactionTable.defaultProps = {
  className: "",
};
