// Modules
import React from "react";

// Style
import "./team-item.styles.scss";

export const TeamItem = ({ className, avatar, fullName, orgRole }) => {
  return (
    <figure className={`team-item ${className}`}>
      <div className="team-item__img-wrapper">
        <img className="team-item__img" src={avatar} alt={fullName} />
      </div>

      <figcaption className="team-item__caption">
        <p className="team-item__name">{fullName}</p>
        <p className="team-item__orgRole">{orgRole}</p>
      </figcaption>
    </figure>
  );
};

TeamItem.defaultProps = {
  className: "",
};
