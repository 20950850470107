// Modules
import React from "react";
import { createRoot } from "react-dom/client";

// Components
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./app/app.component";

// Firebase
import "./utils/firebase/firebaseConfig.utils";

// Extend Date Prototype
import "datejs";

// Store
import store from "./store/store";

// Styles
import "./assets/sass/main.scss";

// Mount React To Dom
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
  // </React.StrictMode>
);
