// Modules
import React from "react";

// Components
import { Outlet } from "react-router-dom";
import { VideoPlayerBase, VideoChapterBase } from "../../components";

export const Video = () => {
  return (
    <div className="video">
      <VideoPlayerBase />
      <VideoChapterBase />
      <Outlet />
    </div>
  );
};
