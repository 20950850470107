// Modules
import React from "react";

// Hooks
import { useNavigate } from "react-router-dom";
import { useAuth, useUi } from "../../../hooks";

// Components
import { Link } from "react-router-dom";
import { MdMenu } from "react-icons/md";
import { Button } from "../../";

// Utils
import { getProfileImage } from "../../../utils";

// Style
import "./student-navbar.styles.scss";

export const StudentNavbar = ({
  className,
  goBack,
  isEditMode,
  isSidenavbarOpen,
  setIsSidenavbarOpen,
}) => {
  const { user } = useAuth();
  const { pageTitle } = useUi();
  const navigate = useNavigate();

  /**
   * Toggle Sidenavbar Visibility
   */
  const toggleSideNavbarVisibility = () => {
    setIsSidenavbarOpen(!isSidenavbarOpen);
  };

  return (
    <div className={`student-navbar ${className}`}>
      <div className="student-navbar__container container">
        {goBack && (
          <div className="student-navbar__left">
            <Button
              className="student-navbar__btn-go-back btn--rv1 btn--primary btn--sv4"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </div>
        )}

        {!isEditMode && !goBack && (
          <div className="student-navbar__left">
            <div className="student-navbar__menu">
              <MdMenu
                className="student-navbar__menu-icon"
                arial-label="toggle sidenavbar visibility"
                onClick={toggleSideNavbarVisibility}
              />
            </div>

            <h2 className="student-navbar__heading student-base-heading is-text-secondary-color">
              {pageTitle}
            </h2>
          </div>
        )}

        {!isEditMode && (
          <div className="student-navbar__right">
            <Link className="student-navbar__profile-box" to="/student/profile">
              <img
                className="student-navbar__profile-img crop-xm"
                src={getProfileImage(user.avatar?.url, "37", "37")}
                alt={user.firstName}
              />
              <p className="student-navbar__profile-name">{user.firstName}</p>
            </Link>

            <p className="student-navbar__student-id">
              Student ID: {user.studentId}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

StudentNavbar.defaultProps = {
  className: "",
  isEditMode: false,
  goBack: false,
};
