// Firebase
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBSBTeVV6En46hGJA1zeuey1MLL4fIReO8",
  authDomain: "finnacco-6ac9c.firebaseapp.com",
  projectId: "finnacco-6ac9c",
  storageBucket: "finnacco-6ac9c.appspot.com",
  messagingSenderId: "757341088603",
  appId: "1:757341088603:web:7c640a24ec21a96021887e",
  measurementId: "G-97Z9HRL2KQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics
export const analytics = getAnalytics(app);

// Initialize Firestore
export const database = getFirestore(app);

// Initialize Authentication
export const auth = getAuth(app);

// Initialize Functions
export const functions = getFunctions(app);

// Create Google Auth Provider Instance
export const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select_account" });

// Create Facebook Auth Provider Instance
export const facebookProvider = new FacebookAuthProvider();
