// Firebase
import { addDoc, serverTimestamp } from "firebase/firestore";
import { contactUsCollectionRef } from "../../utils";

// Utils
import { AppError } from "../../utils";

/**
 * Submit User Enquiry
 * @param {object} enquiry Enquiry data
 */
export const postEnquiry = async (enquiry) => {
  try {
    // Post User Enquiry
    await addDoc(contactUsCollectionRef, {
      ...enquiry,
      createdAt: serverTimestamp(),
    });
  } catch (err) {
    // Re-Throw Error
    throw AppError.checkNetworkConnectivityAndThrowError(err);
  }
};
