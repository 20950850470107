/**
 * Get A String Without A File Extension Attached
 * @param {string} str String to remove file extension from
 */
export const getStringWithoutFileExtension = (str) => {
  if (str && typeof str === "string") {
    // Get String As An Array
    const strArr = str.split(".");

    // Remove Extension From String
    strArr.pop();

    // Return String Without Extension
    return strArr.join(".");
  } else {
    return str;
  }
};
