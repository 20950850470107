// Redux
import {
  resetBookingErrorMessage,
  setBookingErrorMessage,
  bookingStartLoading,
  bookingStopLoading,
  setBookings,
  setBookingStat,
} from "./";
import { uiSA } from "../";

// Apis
import * as apis from "../../apis";

// Destructure Redux Imports
const { showNotification } = uiSA;

/**
 * Get Bookings And Stat
 * @param {boolean} populateStudentField If true populate student field
 */
export const getBookingsAndStat =
  (populateStudentField) => async (dispatch) => {
    try {
      // Reset Error Message
      dispatch(resetBookingErrorMessage());

      // Start Loading
      dispatch(bookingStartLoading());

      // Get Bookings And Stat
      const { stat, bookings } = await apis.getBookingsAndStat(
        populateStudentField
      );

      // Update Stat
      dispatch(setBookingStat(stat));

      // Update Bookings
      dispatch(setBookings(bookings));
    } catch (err) {
      // Update Error Message
      dispatch(setBookingErrorMessage(err.message.message));
    } finally {
      // Stop Loading
      dispatch(bookingStopLoading());
    }
  };
