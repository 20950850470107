// Modules
import { createSlice } from "@reduxjs/toolkit";

// Utils
import { stateSelector } from "../../utils";

// Initial State
const initialState = {
  notification: {
    type: "",
    message: "",
    open: false,
  },
  pageTitle: "",
};

// Static Data
const sliceName = "ui";

// UI Slice
const uiSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    showNotification(state, action) {
      const { type, message } = action.payload;
      state.notification = {
        type,
        message,
        open: true,
      };
    },
    closeNotification(state) {
      state.notification = {
        type: "",
        message: "",
        open: false,
      };
    },
    setPageTitle(state, action) {
      state.pageTitle = action.payload;
    },
    resetPageTitle(state) {
      state.pageTitle = "";
    },
  },
});

export const {
  showNotification,
  closeNotification,
  setPageTitle,
  resetPageTitle,
} = uiSlice.actions;
export const selectNotification = stateSelector(sliceName, "notification");
export const selectPageTitle = stateSelector(sliceName, "pageTitle");
export default uiSlice.reducer;
