// Modules
import { createSlice } from "@reduxjs/toolkit";

// Common Reducers
import { loadingReducers } from "../common";

// Utils
import { stateSelector } from "../../utils";

// Initial State
const initialState = {
  isLoading: false,
};

// Slice Name
const sliceName = "contactUs";

// ContactUs Slice
const contactUsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: { ...loadingReducers },
});

export const {
  startLoading: contactUsStartLoading,
  stopLoading: contactUsStopLoading,
} = contactUsSlice.actions;
export * from "./contactUsAction";
export const selectIsContactUsLoading = stateSelector(sliceName, "isLoading");
export default contactUsSlice.reducer;
