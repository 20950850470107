// Hooks
import { useSelector, useDispatch } from "react-redux";

// Redux
import { referrerMediumSA } from "../store";

// Destructure Redux Imports
const {
  selectReferrerMediums,
  selectReferrerMediumErrorMessage,
  selectIsReferrerMediumLoading,
  selectReferrerMediumsStat,
} = referrerMediumSA;

// ReferrerMedium Hook
export const useReferrerMedium = () => {
  const dispatch = useDispatch();
  const isReferrerMediumLoading = useSelector(selectIsReferrerMediumLoading);
  const referrerMediumErrorMessage = useSelector(
    selectReferrerMediumErrorMessage
  );
  const referrerMediums = useSelector(selectReferrerMediums);
  const referrerMediumStat = useSelector(selectReferrerMediumsStat);

  return {
    isReferrerMediumLoading,
    referrerMediumErrorMessage,
    referrerMediums,
    referrerMediumStat,
    dispatch,
    ...referrerMediumSA,
  };
};
