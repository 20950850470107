// Modules
import React from "react";

// Style
import "./accounting-consultancy-base.styles.scss";

export const AccountingConsultancyBase = ({ className }) => {
  return (
    <section className={`accounting-consultancy-base ${className}`}>
      <div className="accounting-consultancy-base__container container">
        <div className="accounting-consultancy-base__group">
          <h3 className="accounting-consultancy-base__subheading">Overview</h3>

          <p className="accounting-consultancy-base__content">
            At Finacco Hub we provide financial advice and guidance to
            individuals, businesses, and organizations. The service can include
            a wide range of financial planning and management services, such as
            investment advice, tax planning, budgeting, and risk management. Our
            financial consultants work with clients to understand their
            financial goals and objectives and develop a personalized plan to
            help them achieve those goals. They also help clients to understand
            and navigate the complex financial landscape, including financial
            products, regulations, and market trends.
          </p>
        </div>

        <div className="accounting-consultancy-base__group">
          <h3 className="accounting-consultancy-base__subheading">
            Our Services
          </h3>

          <ul className="accounting-consultancy-base__list">
            <li className="accounting-consultancy-base__list-item">
              Risk management
            </li>
            <li className="accounting-consultancy-base__list-item">
              Tax planning and advisory
            </li>
            <li className="accounting-consultancy-base__list-item">
              Portfolio management
            </li>
            <li className="accounting-consultancy-base__list-item">
              Cash flow analysis
            </li>
            <li className="accounting-consultancy-base__list-item">
              Financial Modelling and pitch deck development
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

AccountingConsultancyBase.defaultProps = {
  className: "",
};
