// Modules
import React from "react";
import { toast } from "react-toastify";

// Hooks
import { useEffect, useState, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { useFlutterwave } from "flutterwave-react-v3";
import {
  useAuth,
  useSubscription,
  usePayment,
  useLevel,
  useStudent,
} from "../../hooks";

// HOCS
import { preventIfNotAuthHoc } from "../../hocs";

// Components
import {
  EmptyContent,
  ModalOverlay,
  Modal,
  CheckoutForm,
  WireTransferCheckoutModal,
} from "../";

export const CheckoutModalBase = preventIfNotAuthHoc(() => {
  const [errorMessage, setErrorMessage] = useState();
  const [isWiredTransferModalOpen, setIsWiredTransferModalOpen] =
    useState(false);
  const { getTrainingLevels, levels, isLevelLoading } = useLevel();
  const { user } = useAuth();
  const {
    recurrentSubscriptions,
    getStudentLevelRecurrentSubscriptions,
    isSubscriptionlLoading,
    dispatch,
    subscriptionErrorMessage,
  } = useSubscription();
  const { createPaymentTransaction } = usePayment();
  const {
    enrollStudentPayPerSubjectSubscription,
    enrollStudentRecurrentSubscription,
  } = useStudent();
  const { state } = useLocation();
  const [searchParam] = useSearchParams();

  //  If Cart Does Not Exist Then Render Error Message
  if (!state?.cart) {
    return (
      <ModalOverlay className="checkout-modal-base-overlay" to={-1} replace>
        <Modal className="checkout-modal-base">
          <EmptyContent errorMessage="Operation Denied. You do not have anything in your cart." />
        </Modal>
      </ModalOverlay>
    );
  }

  // Destructure Cart
  const cart = state?.cart;
  const cartItems = cart?.items;
  const firstCartItem = cartItems[0];
  const subscriptionType = firstCartItem?.subscriptionType;
  const totalPrice = cart?.totalPrice;
  const levelId = firstCartItem?.levelId;
  const studentId = user?.uid;
  const level = useMemo(
    () => levels.find((level) => level.id === cart.items[0].levelId),
    [levels]
  );
  console.log(cart);

  const checkoutFormik = useFormik({
    initialValues: {
      paymentMethod: "Card",
      cardPaymentCurrency: "USD",
      mobileMoneyPaymentCurrency: "RWF",
    },
    onSubmit(values) {
      // Payment Options
      const { paymentMethod, cardPaymentCurrency, mobileMoneyPaymentCurrency } =
        values;

      // Open Wire Transfer Payment Modal If Payment Method Is Wire Transfer
      if (paymentMethod === "Wire Transfer") setIsWiredTransferModalOpen(true);
      else onMakePayment();
    },
  });

  const paymentConfig = {
    public_key: "FLWPUBK-3e537bbeb3052ed8ae72479748839199-X",
    tx_ref: Date.now(),
    amount: totalPrice,
    currency:
      checkoutFormik.values.paymentMethod !== "Mobile Money"
        ? checkoutFormik.values.cardPaymentCurrency
        : checkoutFormik.values.mobileMoneyPaymentCurrency,
    payment_options: checkoutFormik.values.paymentMethod
      .split(" ")
      .join("")
      .toLowerCase(),

    customer: {
      email: user?.email,
      phone_number: user?.phoneNumber,
      name: `${user?.firstName} ${user?.lastName}`,
    },
  };

  const handleFlutterwavePayment = useFlutterwave(paymentConfig);

  function onMakePayment() {
    handleFlutterwavePayment({
      async callback(data) {
        // If Payment Method Is Not Wire Transfer And It Is Not A Recurrent Subscription
        if (
          checkoutFormik.values.paymentMethod !== "Wire Transfer" &&
          cart.items.length > 1
        ) {
          await enrollStudentPayPerSubjectSubscription(
            studentId,
            cart.items,
            searchParam.get("studyMethod")
          );
        }

        // If Payment Method Is Not Wire Transfer And It Is A Recurrent Subscription
        if (
          checkoutFormik.values.paymentMethod !== "Wire Transfer" &&
          cart.items.length === 1
        ) {
          // Enroll Student
          await enrollStudentRecurrentSubscription(
            studentId,
            cart.items[0].levelId,
            cart.items[0].trainingId,
            cart.paymentType,
            searchParam.get("studyMethod")
          );
        }
      },
      onClose() {
        toast("Fail to enroll student. Please try again later");
      },
    });
  }

  useEffect(() => {
    if (cart.items.length === 1) {
      dispatch(getTrainingLevels(cart.items[0].trainingId, true));
    }
  }, [cart]);

  useEffect(() => {
    // Get Student Level Recurrent Subscriptions
    dispatch(getStudentLevelRecurrentSubscriptions(studentId, levelId));
  }, [studentId, levelId]);

  useEffect(() => {
    // All Subscription Types
    const allSubscriptionTypes = ["monthly", "yearly", "quarterly", "oneTime"];

    // If Recurrent Subscription Exist For the Current Level. Then Disallow User From Creating A New Subscription
    if (
      recurrentSubscriptions.length &&
      !isSubscriptionlLoading &&
      allSubscriptionTypes.includes(subscriptionType)
    ) {
      setErrorMessage("Operation Denied. You have active subscription");
    }
  }, [recurrentSubscriptions, isSubscriptionlLoading]);

  useEffect(() => {
    // If SubscriptionErrorMessage Exist Then Reset Error Message
    if (subscriptionErrorMessage) {
      setErrorMessage("");
    }
  }, [subscriptionErrorMessage]);

  return (
    <ModalOverlay className="checkout-modal-base-overlay" to={-1} replace>
      <Modal className="checkout-modal-base">
        <EmptyContent
          isLoading={isSubscriptionlLoading || isLevelLoading}
          errorMessage={errorMessage}
        >
          {isWiredTransferModalOpen ? (
            <WireTransferCheckoutModal />
          ) : (
            <CheckoutForm
              className="checkout-modal-base__form"
              formik={checkoutFormik}
            />
          )}
        </EmptyContent>
      </Modal>
    </ModalOverlay>
  );
});
