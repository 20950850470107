// Modules
import React from "react";

// Components
import { Outlet } from "react-router-dom";
import { Navbar, Footer, ScrollToTop } from "../../components";

export const LandingLayout = () => {
  return (
    <div className="landing-layout">
      <Navbar />

      <main className="landing-layout__main">
        <Outlet />
      </main>

      <footer className="landing-layout__footer">
        <Footer />
      </footer>

      <ScrollToTop />
    </div>
  );
};
